
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
    components: {},
})
export default class WeightsCounterPopup extends Vue {
    @Prop({ default: false, type: Boolean })
    open!: boolean;

    @Prop({ default: false, type: Boolean })
    mainColumn!: boolean;

    @Prop()
    id!: string;

    mounted(): void {
        this.onOpenChange();
    }

    private bottom = false;

    private setEvents(): void {
        document.body.addEventListener("click", this.handleClick);
        document.body.addEventListener("vp-weights-toggle", this.handleToggle);
    }

    private removeEvents(): void {
        document.body.removeEventListener("click", this.handleClick);
        document.body.removeEventListener(
            "vp-weights-toggle",
            this.handleToggle
        );
    }

    /* Event needs to be "any" type due to Typescript issues with CustomEvents */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleToggle(e: any): void {
        if (e && this.id && e.detail && e.detail.id && e.detail.id != this.id ) {
            this.$emit("outside-click");
        }
    }

    /* Detects click outside the popup */
    private handleClick(e: Event): void {
        let wrapper = document.getElementById(`vp-weight-popup-${this.id}`);
        if (this.open && this.id && wrapper && e.target) {
            if (!wrapper.contains(e.target as Node)) {
                // Clicked outside popup
                this.$emit("outside-click");
            }
        }
    }

    @Watch("open")
    onOpenChange(): void {
        if (this.open) {
            this.setEvents();
        } else {
            this.removeEvents();
        }
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getS3FileUrl } from "@/helpers/MediaUploadHelper";

@Component({
    name: "CustomLogo",
    components: {},
})
export default class CustomLogo extends Vue {
    @Prop()
    file!: string;

    private url: string | null = null;
    private loading = false;

    mounted(): void {
        this.onFileChange(this.file);
    }

    @Watch("file")
    async onFileChange(val: string | null): Promise<void> {
        if (val) {
            this.loading = true;
            this.url = await getS3FileUrl({
                fileName: val,
                type: "image",
            });
            this.loading = false;
        } else {
            this.url = null;
        }
    }
}

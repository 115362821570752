import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Workspaces from "@/store/modules/Workspaces";
import Users from "@/store/modules/Users";
import { Workspace } from "@/graphql/API";
import { Urls } from "@/Urls";
import Decisions from "@/store/modules/Decisions";

const userModule = getModule(Users);
const workspacesModule = getModule(Workspaces);
const decisionsModule = getModule(Decisions);

@Component
export default class UiBase extends Vue {
    readonly URLS = Urls;

    get selectedWorkspace(): Workspace | null {
        return workspacesModule.selectedWorkspace;
    }

    async clearWorkspace(): Promise<void> {
        console.log("Clear Workspace");
        await workspacesModule.selectWorkspace(null);
        this.clearStorage(false);
        if (this.$route.name != "Home") {
            this.$router.push(this.URLS.HOME);
        }
    }

    async clearFramework(): Promise<void> {
        console.log("Clear Framework");
        await decisionsModule.selectDecision(null);
        this.clearStorage(true);
        if (this.$route.name != "Home") {
            this.$router.push(this.URLS.HOME);
        }
    }

    async clearStorage(workspace: boolean): Promise<void> {
        if (userModule.currentUserId) {
            if (workspace) {
                if (this.selectedWorkspace) {
                    localStorage.setItem(
                        "viewpoint-settings",
                        JSON.stringify({
                            user_id: userModule.currentUserId,
                            workspace_id: this.selectedWorkspace.id,
                        })
                    );
                }
            } else {
                localStorage.setItem(
                    "viewpoint-settings",
                    JSON.stringify({
                        user_id: userModule.currentUserId,
                    })
                );
            }
        }
    }
}

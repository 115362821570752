
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Rule, ClassSet, Factor } from "@/graphql/API";

@Component({
    components: {},
})
export default class RuleMinMax extends Vue {
    @Prop()
    factor!: Factor;

    @Prop()
    scoreRule!: Rule;

    @Prop()
    dialogLoading!: boolean;

    private loading = false;

    private maxValue = 1;
    private minValue = 0;
    get scoreRuleId(): number | null {
        if (this.scoreRule) {
            return this.scoreRule.id;
        } else {
            return null;
        }
    }

    private async changeMin(val: number): Promise<void> {
        this.$emit("save-min", val);
    }

    private async changeMax(val: number): Promise<void> {
        this.$emit("save-max", val);
    }

    mounted(): void {
        this.onScoreRuleChange();
    }

    @Watch("scoreRuleId")
    onScoreRuleChange(): void {
        if (this.scoreRule && this.scoreRule.max) {
            this.maxValue = this.scoreRule.max;
        } else {
            this.maxValue = 1;
        }

        if (this.scoreRule && this.scoreRule.min) {
            this.minValue = this.scoreRule.min;
        } else {
            this.minValue = 0;
        }
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Choice,
    Viewpoint,
    CompareValues,
    ScoreOptions,
    ScoreClassSettings,
    FactorOptions,
    FactorSelection,
} from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import CompareCell from "@/components/compare/CompareCell.vue";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";
import CompareTableViewpoints from "@/components/compare/table/CompareTableViewpoints.vue";
import CompareTableChoices from "@/components/compare/table/CompareTableChoices.vue";
import CompareRow from "@/components/compare/ui/CompareRow.vue";
import CompareColumnMain from "@/components/compare/ui/CompareColumnMain.vue";
import CompareColumn from "@/components/compare/ui/CompareColumn.vue";
import Choices from "@/store/modules/Choices";

const modelModule = getModule(Factors);

@Component({
    components: {
        CompareCell,
        FactorValueDisplay,
        CompareTableViewpoints,
        CompareTableChoices,
        CompareRow,
        CompareColumnMain,
        CompareColumn,
    },
})
export default class CompareTable extends Vue {
    @Prop({ type: Number, default: -1 })
    factorRoot!: number;

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop()
    hiddenFactors!: number[];

    @Prop()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    indexes!: any;

    @Prop()
    dataItem!: Factor | Choice | Viewpoint | null;

    @Prop()
    values!: CompareValues;

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoresEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop({ default: true, type: Boolean })
    scoreRule!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop()
    factorSelection!: FactorSelection;

    @Prop({ default: () => [], type: Array })
    lines!: number[];

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private loading = false;

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get displayValues(): CompareValues {
        return {
            factors: this.factorTree[this.factorRoot],
            choices: this.values["choices"],
            viewpoints: this.values["viewpoints"],
        };
    }

    get currentFactorTree(): Factor[] {
        if (modelModule.factorTree[this.factorRoot]) {
            return modelModule.factorTree[this.factorRoot].filter((factor) => {
                if (this.factorSelection && !this.factorSelection.all) {
                    return this.factorSelection.factors.includes(factor.id);
                } else {
                    return true;
                }
            });
        } else {
            return [];
        }
    }

    get showWeights(): boolean {
        if (this.indexes && this.dataItem) {
            if (
                this.indexes["rows"] == "factors" &&
                this.indexes["data"] == "viewpoints" &&
                this.dataItem
            ) {
                return true;
            }
        }
        return false;
    }

    get showScoreRuleBtn(): boolean {
        return (
            this.scoreRule &&
            ((this.indexes["rows"] == "factors" &&
                this.indexes["data"] == "viewpoints") ||
                (this.indexes["rows"] == "factors" &&
                    this.indexes["columns"] == "viewpoints"))
        );
    }

    private async loadChildren(factorId: number) {
        const factorIds = await modelModule.fetchChildFactors(factorId);
        factorIds?.forEach((factor: Factor) => {
            modelModule.fetchChildFactors(factor.id);
            // getModule(Choices).getDecisionValues({
            //     decision_id: factor.decision_id,
            //     parent_id: factorId,
            // });
        });
    }

    mounted(): void {
        this.onFactorRootChange();
    }

    @Watch("factorRoot")
    private async onFactorRootChange(): Promise<void> {
        if (this.factorRoot != -1) {
            this.loading = true;
            await this.loadChildren(this.factorRoot);
            this.loading = false;
        }
    }
}

export const en = {
    logout: "Logout",
    titles: {
        settings: "Settings",
        help: "Help",
        appBuilder: "App Builder",
        notifications: "Notifications",
    },
    viewpoint: {
        name: "Viewpoint AI"
    },
    locales: {
        en: 'English',
        fr: 'Français'
    },
    decisions: {
        select: "Select a Framework",
    },
    workspace: {
        select: "Select a Workspace",
    }
}

import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor } from "@/graphql/API";
import Factors from "@/store/modules/Factors";

const modelModule = getModule(Factors);

@Component({
    name: "ModelPagination",
    components: {
    },
})
export default class ModelPagination extends Vue {
    @Prop()
    selectedId!: number;

    @Prop({ default: -1, type: Number })
    rootId!: number;

    @Prop({ default: 0, type: Number })
    depth!: number;

    private loading = false;

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get currentTreeLevel(): Factor[] {
        if (this.factorTree[this.rootId]) {
            return this.factorTree[this.rootId];
        } else return [];
    }

    get levelIndexes() {
        return this.currentTreeLevel.reduce((acc, a, index) => {
            return {
                ...acc,
                [a.id]: a,
            };
        }, {});
    }

    mounted(): void {
        this.onRootChange();
    }

    @Watch("rootId")
    async onRootChange(): Promise<void> {
        if (!this.factorTree[this.rootId]) {
            this.loading = true;
            const factors = await modelModule.fetchChildFactors(this.rootId);
            this.loading = false;
        }
    }
}

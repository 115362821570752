
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ColourPalette from "@/components/mixins/ColourPalette";

@Component({
    components: {},
})
export default class IconColour extends mixins(ColourPalette) {
    @Prop()
    json!: string;

    @Prop({ default: "bg_colour", type: String })
    id!: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get styles(): any {
        if (this.json) {
            return JSON.parse(this.json);
        }
        return {};
    }

    get colour(): string | null {
        if (
            this.styles &&
            this.styles[this.id] &&
            this.palette[this.styles[this.id]]
        ) {
            return this.palette[this.styles[this.id]].hex;
        } else {
            return null;
        }
    }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import VpDialog from "@/components/ui/VpDialog.vue";

@Component({
    name: "ImagePreview",
    components: {
        VpDialog,
    },
})
export default class ImagePreview extends Vue {
    @Prop()
    path!: string;

    @Prop()
    name!: string;

    @Prop({ type: Boolean, default: false })
    isFactorTable!: boolean;

    @Prop({ type: String, default: "140px" })
    maxWidth!: string;

    @Prop({ type: String, default: "140px" })
    maxHeight!: string;

    private defaultStyles = "height: auto; width: auto; ";

    private previewOpen = false;

    get styles(): string {
        if (this.isFactorTable) {
            return (
                this.defaultStyles +
                `max-height: 10vw; max-width: ${this.maxWidth}`
            );
        } else {
            return (
                this.defaultStyles +
                `max-height: ${this.maxHeight}; max-width: ${this.maxWidth}`
            );
        }
    }

    private openPreview(): void {
        this.previewOpen = true;
        console.log("Open Dialog");
    }
}


import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Decision, TableHeader, TableAction } from "@/graphql/API";
import { TableIcons } from "@/IconLibrary";
import Decisions from "@/store/modules/Decisions";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import DecisionEditor from "@/components/decisions/DecisionEditor.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import Workspaces from "@/store/modules/Workspaces";
import UsersView from "@/views/UsersView.vue";
import { DateTimeFormatOptions } from "vue-i18n";
import TableManagerView from "@/components/managers/TableManagerView.vue";
import { Urls } from "@/Urls";

const decisionsModule = getModule(Decisions);
const workspacesModule = getModule(Workspaces);
@Component({
    components: {
        ViewWrapper,
        TableManager,
        TableManagerSettings,
        DecisionEditor,
        TableManagerTools,
        VpDialog,
        UsersView,
        TableManagerView,
    },
})
export default class DecisionsView extends Vue {
    private selected: number[] = [];
    private settingsMode = false;
    private isCopy = false;
    private isPort = false;

    private searchTerm = "";

    private deleteOpen = false;
    private sideTrigger = "settings";
    private manageUserOpen = false;
    private loading = false;

    private readonly Icons: Record<string, string> = TableIcons;

    private headers: { [id: string]: TableHeader } = {
        name: {
            label: "Name",
            property: "name",
            visible: false,
            required: true,
            enabled: false,
            locked: false,
        },
        description: {
            label: "Description",
            property: "description",
            visible: false,
            enabled: false,
            locked: false,
        },
        owner: {
            label: "Owner",
            property: "owner",
            visible: false,
            enabled: false,
            parseFunction: (value: string) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const usersDict = workspacesModule.workspaceUsers;
                return usersDict[value]?.user.email;
            },
            locked: false,
        },
        updated: {
            label: "Last Updated",
            property: "updated",
            visible: false,
            enabled: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date = timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions);
            },
            locked: false,
        },
        created: {
            label: "Created",
            property: "created",
            visible: false,
            enabled: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date = timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions);
            },
            locked: false,
            defaultOff: true,
        },
    };

    private actions: { [id: string]: TableAction } = {
        edit: {
            id: "edit",
            label: "Edit",
            icon: TableIcons.edit,
            active: true,
            enabled: false,
            locked: false,
        },
        duplicate: {
            id: "duplicate",
            label: "Duplicate",
            icon: TableIcons.duplicate,
            active: true,
            enabled: false,
            locked: false,
        },
        delete: {
            id: "delete",
            label: "Delete",
            icon: TableIcons.delete,
            active: true,
            multi: true,
            enabled: false,
            locked: false,
        },
        user: {
            id: "user",
            label: "Add User",
            icon: TableIcons.users,
            active: true,
            enabled: false,
            locked: false,
        },
    };

    private tools: { [id: string]: TableAction } = {
        new: {
            id: "new",
            label: "New",
            icon: TableIcons.new,
            active: true,
            enabled: false,
            locked: false,
        },
        import: {
            id: "import",
            label: "Import V2 Decision",
            icon: TableIcons.import,
            active: true,
            enabled: false,
            locked: false,
        },
    };

    /* Column / Tools Visibility */
    get tableHeaders(): TableHeader[] {
        return Object.values(this.headers).filter((item) => item.enabled);
    }

    get tableTools(): TableAction[] {
        return Object.values(this.tools).filter((item) => item.enabled);
    }

    get tableActions(): TableAction[] {
        return Object.values(this.actions).filter((item) => item.enabled);
    }

    get selectedId(): number | null {
        if (this.selected.length) {
            return this.selected[0];
        } else {
            return null;
        }
    }

    get workspaceId(): number | null {
        return getModule(Workspaces).selectedWorkspaceId;
    }

    get selectedDecisions(): Decision[] {
        if (this.selected.length) {
            return this.selected.map((id) => decisionsModule.decisions[id]);
        } else {
            return [];
        }
    }

    get selectedDecision(): Decision | null {
        if (this.selectedDecisions.length) {
            return this.selectedDecisions[0];
        } else {
            return null;
        }
    }

    get decisions(): Decision[] {
        return decisionsModule.decisionsList;
    }

    get decisionsList(): Decision[] {
        if (this.searchTerm && this.searchTerm.length) {
            return this.decisions.filter((decision) => {
                return decision.name
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase());
            });
        } else {
            return this.decisions;
        }
    }

    get pageLoading(): boolean {
        return workspacesModule.dataLoading;
    }

    private async selectDecision(decision: Decision | null): Promise<void> {
        decisionsModule.selectDecision(decision ? decision.id : null);
        if (decision && decision.id) this.$router.push(Urls.MODEL);
    }

    private editDecision(decision: Decision): void {
        if (decision) {
            this.selected = [decision.id];
            this.isCopy = false;
            this.isPort = false;
            this.settingsMode = false;
            this.sideTrigger = `edit-${decision.id}`;
        }
    }

    private duplicateDecision(decision: Decision): void {
        if (decision) {
            this.selected = [decision.id];
            this.isCopy = true;
            this.settingsMode = false;
            this.sideTrigger = `duplicate-${decision.id}`;
        }
    }

    private importDecision(): void {
        this.selected = [];
        this.isPort = true;
        this.isCopy = false;
        this.settingsMode = false;
        this.sideTrigger = "new";
    }

    private createNew(): void {
        this.selected = [];
        this.isCopy = false;
        this.isPort = false;
        this.settingsMode = false;
        this.sideTrigger = "new";
    }

    private deleteDecision(decision: Decision): void {
        if (decision) {
            this.selected = [decision.id];
            this.deleteOpen = true;
        }
    }

    private openUser(decision: Decision): void {
        if (decision) {
            this.selected = [decision.id];
            this.manageUserOpen = true;
        }
    }

    private async deleteSelectedDecision(): Promise<void> {
        if (this.selectedDecisions.length) {
            this.loading = true;
            await Promise.all(
                this.selectedDecisions.map(async (decision) => {
                    if (decision) {
                        await decisionsModule.deleteDecision(decision.id);
                    }
                })
            );
            this.deleteOpen = false;

            this.loading = false;
        }
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "new":
                this.createNew();
                break;
            case "import":
                this.importDecision();
                break;
            case "edit":
                this.sideTrigger = "edit";
                this.isCopy = false;
                break;
            case "duplicate":
                this.isCopy = true;
                this.sideTrigger = "duplicate";
                break;
            case "delete":
                this.deleteOpen = true;
                break;
            case "user":
                this.manageUserOpen = true;
                break;
        }
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";
import {
    uploadMediaFile,
    getS3FileUrl,
    removeFile,
} from "@/helpers/MediaUploadHelper";
import FilePreview from "@/components/common/media/FilePreview.vue";
import DragFileUpload from "@/components/common/media/DragFileUpload.vue";

@Component({
    components: {
        FilePreview,
        DragFileUpload,
    },
})
export default class WorkspaceLogoUpload extends Vue {
    @Prop()
    value!: string;

    @Prop({ default: false, type: Boolean })
    edit!: boolean;

    private loading = false;
    private preview: string | null = null;


    private getExtension(filename: string): string {
        var parts = filename.split(".");
        return "." + parts[parts.length - 1];
    }

    private async saveFile(file: File): Promise<void> {
        if (file) {
            this.loading = true;
            let reader = new FileReader();
            const fileName = file
                ? uuidv4() + this.getExtension(file.name)
                : uuidv4();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");

            let result = await uploadMediaFile({
                file: file,
                type: "image",
                name: fileName,
                srcFileName: file.name,
                hideMessage: !this.edit,
            });

            /* Code to delete old value goes here */

            this.$emit("upload", {
                fileName: result,
                srcFile: file.name,
            });

            this.loading = false;
        }
    }

    private async deleteLogo(): Promise<void> {
        if (this.value) {
            this.loading = true;
            await removeFile({
                fileName: this.value,
                type: "image",
                hideMessage: true,
            });
            this.$emit("delete");
            this.loading = false;
        }
    }

    mounted(): void {
        this.onFileValueChange(this.value);
    }

    @Watch("value")
    async onFileValueChange(val: string): Promise<void> {
        if (val) {
            this.loading = true;
            this.preview = await getS3FileUrl({
                fileName: this.value,
                type: "image",
            });
            this.loading = false;
        } else {
            this.preview = null;
        }
    }

}


import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Decisions from "@/store/modules/Decisions";
import DecisionCreator from "@/components/decisions/DecisionCreator.vue";
import { Decision } from "@/graphql/API";
import { Urls } from "@/Urls";
import Workspaces from "@/store/modules/Workspaces";
import VpDialog from "@/components/ui/VpDialog.vue";

const decisionsModule = getModule(Decisions);

@Component({
    components: { DecisionCreator, VpDialog },
})
export default class DecisionSelector extends Vue {
    @Prop({ default: false, type: Boolean })
    listView!: boolean;

    private actionsMenu = false;
    private createMenu = false;
    private confirmationMenu = false;
    private v2DecisionId = "";
    private open = false;

    get decisionsList(): Decision[] {
        return decisionsModule.decisionsList;
    }

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get selectedDecision(): Decision | null {
        if (decisionsModule.selectedDecision) {
            return decisionsModule.selectedDecision;
        }
        return null;
    }

    get selectedDecisionName(): string | null {
        if (decisionsModule.selectedDecision) {
            return decisionsModule.selectedDecision.name;
        }
        return null;
    }

    get selectedWorkspaceId(): number | null {
        return getModule(Workspaces).selectedWorkspaceId;
    }

    get styleEditorClasses(): Array<any> | undefined {
        if (
            decisionsModule.selectedDecision &&
            decisionsModule.selectedDecision.json
        ) {
            return JSON.parse(decisionsModule.selectedDecision.json);
        }
        return undefined;
    }

    private async changeDecision(newId: number): Promise<void> {
        if (newId) {
            await decisionsModule.selectDecision(newId);
            if (this.$router.currentRoute.path !== Urls.MODEL) {
                //this.$router.push(Urls.MODEL);
            }
        }
    }

    private async deleteDecision(): Promise<void> {
        if (this.selectedDecisionId) {
            await decisionsModule.deleteDecision(this.selectedDecisionId);
        }
        this.confirmationMenu = false;
        this.actionsMenu = false;
    }

    private async fetchDecisions(): Promise<void> {
        if (this.selectedWorkspaceId)
            await decisionsModule.fetchWorkspaceDecisions(
                this.selectedWorkspaceId
            );
    }

    private clearPortDecisionId(): void {
        this.v2DecisionId = "";
    }

    private async portV2ToV3(): Promise<void> {
        let task = await decisionsModule.portV2ToV3({
            v2_decision_id: this.v2DecisionId,
            workspace_id: this.selectedWorkspaceId,
        });

        if (task) {
            setTimeout(
                function (task) {
                    decisionsModule.getPdfResult(task.id);
                },
                10000,
                task
            );
        }
    }

    @Watch("selectedDecisionId", { deep: true })
    private updateClassesStyles() {
        if (this.styleEditorClasses) {
            let style: HTMLElement | null;
            if (document.getElementById("vp-text-styles")) {
                style = document.getElementById("vp-text-styles");
            } else {
                style = document.createElement("style");
                style.setAttribute("id", "vp-text-styles");
            }

            let newStyles: string[] = [];
            this.styleEditorClasses.forEach((styleClass) => {
                let styleVars = `--fontSize-${styleClass.varName}: ${styleClass.styles.fontSize} !important;
            --fontWeight-${styleClass.varName}: ${styleClass.styles.fontWeight} !important;
            --fontStyle-${styleClass.varName}: ${styleClass.styles.fontStyle} !important;
            --fontSpacing-${styleClass.varName}: -1.5px !important;
            --fontColor-${styleClass.varName}: ${styleClass.styles.fontColor} !important;
            --fontBgColor-${styleClass.varName}: ${styleClass.styles.fontBgColor} !important;`;

                newStyles.push(styleVars);
            });
            let newStylesString = newStyles.join(" ").toString();
            if (style) {
                style.textContent = `:root {${newStylesString}}`;
                document.head.appendChild(style);
            }
        } else {
            let style = document.getElementById("vp-text-styles");
            if (style) {
                document.head.removeChild(style);
            }
        }
    }
}



import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Workspaces from "@/store/modules/Workspaces";
import WorkspaceCreator from "@/components/workspaces/WorkspaceCreator.vue";
import { Workspace } from "@/graphql/API";
import { Urls } from "@/Urls";

const workspacesModule = getModule(Workspaces);

@Component({
    components: { WorkspaceCreator }
})
export default class WorkspaceSelector extends Vue {
    @Prop({ default: false, type: Boolean })
    listView!: boolean;

    private actionsMenu = false;
    private confirmationMenu = false;

    get workspaceList(): Workspace[] {
        return workspacesModule.workspaceList;
    }

    get selectedWorkspaceId(): number | null {
        return workspacesModule.selectedWorkspaceId;
    }

    get selectedWorkspaceName(): string | null {
        if (workspacesModule.selectedWorkspace) {
            return workspacesModule.selectedWorkspace.name;
        }
        return null;
    }

    private async changeWorkspace(newId: number): Promise<void> {
        if (newId) {
            await workspacesModule.selectWorkspace(newId)
            .then(() => {
                this.$emit('changed-workspace', workspacesModule.workspaces[newId]);
            });
            // if (this.$router.currentRoute.path !== Urls.MODEL) {
            //     this.$router.push(Urls.MODEL);
            // }
        }
    }

    private async deleteWorkspace(): Promise<void> {
        if (this.selectedWorkspaceId) {
            await workspacesModule.deleteWorkspace(this.selectedWorkspaceId);
        }
        this.confirmationMenu = false;
        this.actionsMenu = false;
    }

    private async fetchWorkspaces(): Promise<void> {
        await workspacesModule.fetchWorkspaces();
    }
    
}


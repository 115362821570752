import {
    App,
    AppTabMapping,
    Changes,
    CreateAppInput,
    CreateAppTabMapping,
    CreateTabInput,
    Tab,
    TabChoiceMapping,
    TabFactorMapping,
    TabViewpointMapping,
    UpdateAppInput,
    UpdateAppTabMapping,
    UpdateTabInput,
    Viewpoint,
    ViewpointMapping,
} from "@/graphql/API";
import {
    CreateApp,
    CreateAppTab,
    CreateTab,
    CreateTabChoice,
    CreateTabFactor,
    CreateTabViewpoint,
    DeleteApp,
    DeleteAppTab,
    DeleteTab,
    DeleteTabChoice,
    DeleteTabFactor,
    GetApp,
    GetApps,
    GetTabChoices,
    GetTabFactors,
    GetTabViewpoints,
    UpdateApp,
    UpdateAppTab,
    UpdateTab,
} from "@/graphql/custom";
import { API } from "aws-amplify";
import {
    Action,
    config,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import { GraphQLResult } from "@aws-amplify/api";
import store from "..";
import {
    createApp,
    createAppTab,
    createTab,
    createTabChoice,
    createTabFactor,
    createTabViewpoint,
    deleteApp,
    deleteAppTab,
    deleteTab,
    deleteTabChoice,
    deleteTabFactor,
    deleteTabViewpoint,
    updateApp,
    updateAppTab,
    updateTab,
} from "@/graphql/mutations";
import Vue from "vue";
import {
    getApp,
    getApps,
    getTabChoices,
    getTabFactors,
    getTabViewpoints,
} from "@/graphql/queries";
import Workspaces from "./Workspaces";
import Decisions from "./Decisions";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";

config.rawError = true;

const name = "apps";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Apps extends VuexModule {
    selectedAppId: number | null = null;

    keyedApps: {
        [appId: number]: App;
    } = {};

    keyedTabs: {
        [tabId: number]: Tab;
    } = {};

    appTabMappings: {
        [app_id: number]: {
            [tab_id: number]: AppTabMapping;
        };
    } = {};

    tabFactors: {
        [tab_id: number]: {
            [factor_id: number]: TabFactorMapping;
        };
    } = {};

    tabChoices: {
        [tab_id: number]: {
            [choice_id: number]: TabChoiceMapping;
        };
    } = {};

    tabViewpoints: {
        [tab_id: number]: {
            [viewpoint_id: number]: TabViewpointMapping;
        };
    } = {};

    navTabs: { app_id: number; tab: Tab }[] = [];

    appsLoading = false;

    get choiceLabel(): string {
        return getModule(Decisions).choiceLabelSingular;
    }

    get choicesLabel(): string {
        return getModule(Decisions).choiceLabelPlural;
    }

    get tabTypes(): {
        [id: string]: { name: string; value: string; table?: boolean };
    } {
        return {
            CompareView: {
                name: "Compare",
                value: "CompareView",
            },
            ChoiceEditor: {
                name: `${this.choiceLabel} Editor`,
                value: "ChoiceEditor",
                table: true,
            },
            RankView: {
                name: "Ranking",
                value: "RankView",
            },
            ModelView: {
                name: "Factors",
                value: "ModelView",
            },
            ChoicesView: {
                name: this.choicesLabel,
                value: "ChoicesView",
                table: true,
            },
            ViewpointsView: {
                name: "Viewpoints",
                value: "ViewpointsView",
                table: true,
            },
            UsersView: {
                name: "Users",
                value: "UsersView",
                table: true,
            },
            UnitView: {
                name: "Units",
                value: "UnitView",
                table: true,
            },
            ScoreClassTable: {
                name: "Scoring Classes",
                value: "ScoreClassTable",
                table: true,
            },
        };
    }

    get selectedApp(): App | null {
        if (this.selectedAppId && this.keyedApps[this.selectedAppId]) {
            return this.keyedApps[this.selectedAppId];
        } else {
            return null;
        }
    }

    get appsList(): App[] {
        return Object.values(this.keyedApps);
    }

    get tabsList(): Tab[] {
        return Object.values(this.keyedTabs);
    }

    get tabIndex(): { [id: number]: Tab[] } {
        return Object.values(this.keyedApps).reduce((acc, a) => {
            return {
                ...acc,
                [a.id]: a.tabs
                    ? a.tabs.sort((a, b) => {
                          if (a.order_str && b.order_str) {
                              return a.order_str.localeCompare(b.order_str);
                          } else if (a.order_str && !b.order_str) {
                              return -1;
                          } else if (!a.order_str && b.order_str) {
                              return 1;
                          } else {
                              return 0;
                          }
                      })
                    : [],
            };
        }, {});
    }

    @Mutation
    setSelectedApp(appId: number | null): void {
        if (appId != null) {
            this.selectedAppId = appId;
        } else {
            this.selectedAppId = null;
        }
    }

    @Mutation
    setApp(app: App): void {
        Vue.set(this.keyedApps, app.id, app);
        if (!this.appTabMappings[app.id]) {
            Vue.set(this.appTabMappings, app.id, {});
        }
        if (app.tabs) {
            app.tabs.forEach((tab) => {
                Vue.set(this.keyedTabs, tab.id, tab);
                Vue.set(this.appTabMappings[app.id], tab.id, {
                    app_id: app.id,
                    tab_id: tab.id,
                });
            });
        }
    }

    @Mutation
    setTab(tab: Tab): void {
        Vue.set(this.keyedTabs, tab.id, tab);
    }

    @Mutation
    setAppTab(appTab: AppTabMapping): void {
        if (this.appTabMappings)
            Vue.set(this.keyedApps[appTab.app_id], appTab.tab_id, appTab);
        if (this.keyedApps && this.keyedApps[appTab.app_id] && this.keyedTabs) {
            if (this.keyedApps[appTab.app_id].tabs == null) {
                this.keyedApps[appTab.app_id].tabs = [];
            }
            const index = this.keyedApps[appTab.app_id].tabs.findIndex(
                (searchAppTab: Tab) => searchAppTab.id == appTab.tab_id
            );
            if (index >= 0) {
                const order_str = this.keyedApps[appTab.app_id].tabs[index].order_str;
                this.keyedApps[appTab.app_id].tabs.splice(
                    index,
                    1,
                    this.keyedTabs[appTab.tab_id]
                );
                this.keyedApps[appTab.app_id].tabs[index].order_str = order_str;
                
            } else {
                this.keyedApps[appTab.app_id].tabs.push(
                    this.keyedTabs[appTab.tab_id]
                );
            }
        }
    }

    @Mutation
    setTabFactor(tabFactor: TabFactorMapping): void {
        const tab_id = tabFactor.tab_id;
        const factor_id = tabFactor.factor_id;

        if (this.tabFactors[tab_id] == null)
            Vue.set(this.tabFactors, tab_id, {});
        Vue.set(this.tabFactors[tab_id], factor_id, tabFactor);
    }

    @Mutation
    setTabChoice(tabChoice: TabChoiceMapping): void {
        const tab_id = tabChoice.tab_id;
        const choice_id = tabChoice.choice_id;

        if (this.tabChoices[tab_id] == null)
            Vue.set(this.tabChoices, tab_id, {});
        Vue.set(this.tabChoices[tab_id], choice_id, tabChoice);
    }

    @Mutation
    setTabViewpoint(tabViewpoint: TabViewpointMapping): void {
        const tab_id = tabViewpoint.tab_id;
        const viewpoint_id = tabViewpoint.viewpoint_id;

        if (this.tabViewpoints[tab_id] == null)
            Vue.set(this.tabViewpoints, tab_id, {});
        Vue.set(this.tabViewpoints[tab_id], viewpoint_id, tabViewpoint);
    }

    @Mutation
    removeApp(app: App): void {
        Vue.delete(this.keyedApps, app.id);
    }

    @Mutation
    removeTab(tab: Tab): void {
        Vue.delete(this.keyedTabs, tab.id);
    }

    @Mutation
    removeAppTab(appTab: AppTabMapping): void {
        if (this.appTabMappings[appTab.app_id])
            Vue.delete(this.keyedApps[appTab.app_id], appTab.tab_id);
        if (this.keyedApps && this.keyedApps[appTab.app_id] && this.keyedTabs) {
            const findIndex = this.keyedApps[appTab.app_id].tabs.findIndex(
                (tab) => tab.id === appTab.tab_id
            );

            if (findIndex >= 0) {
                this.keyedApps[appTab.app_id].tabs.splice(findIndex, 1);
            }
        }
    }

    @Mutation
    removeTabFactor(tabFactor: TabFactorMapping): void {
        const tab_id = tabFactor.tab_id;
        const factor_id = tabFactor.factor_id;

        if (this.tabFactors[tab_id])
            Vue.delete(this.tabFactors[tab_id], factor_id);
    }

    @Mutation
    removeTabChoice(tabChoice: TabChoiceMapping): void {
        const tab_id = tabChoice.tab_id;
        const choice_id = tabChoice.choice_id;

        if (this.tabChoices[tab_id])
            Vue.delete(this.tabChoices[tab_id], choice_id);
    }

    @Mutation
    removeTabViewpoint(tabViewpoint: TabViewpointMapping): void {
        const tab_id = tabViewpoint.tab_id;
        const viewpoint_id = tabViewpoint.viewpoint_id;

        if (this.tabViewpoints[tab_id])
            Vue.delete(this.tabViewpoints[tab_id], viewpoint_id);
    }

    @Mutation
    addNavTab(appId: number, tab: Tab): void {
        Vue.set(this.navTabs, this.navTabs.length, {
            app_id: appId,
            tab: tab,
        });
    }

    @Mutation
    removeNavTab(index: number): void {
        Vue.delete(this.navTabs, index);
    }

    @Mutation
    updateAppTabMap(payload: {
        appTabMap: UpdateAppTabMapping;
        tabIndex: number;
    }): void {
        if (payload.appTabMap.order_str && payload.tabIndex > -1) {
            Vue.set(
                this.keyedApps[payload.appTabMap.app_id].tabs[payload.tabIndex],
                "order_str",
                payload.appTabMap.order_str
            );
        }
    }

    @Mutation
    removeAllApps(): void {
        this.keyedApps = {};
        this.keyedTabs = {};
    }

    @Mutation
    setLoading(loading: boolean): void {
        this.appsLoading = loading;
    }

    /* Edit App Tab Mappings */
    @Mutation
    addAppTabMap(appTab: AppTabMapping): void {
        Vue.set(this.appTabMappings[appTab.app_id], appTab.tab_id, appTab);
    }

    @Mutation
    removeAppTabMap(appTab: AppTabMapping): void {
        Vue.delete(this.appTabMappings[appTab.app_id], appTab.tab_id);
    }

    @Action
    async selectApp(id: number | null): Promise<void> {
        this.setSelectedApp(id);
    }

    @Action
    async updateLoading(loading: boolean): Promise<void> {
        this.setLoading(loading);
    }

    @Action
    async addTabNav(payload: { appId: number; tab: Tab }): Promise<void> {
        this.addNavTab(payload.appId, payload.tab);
    }

    @Action
    async removeTabNav(index: number): Promise<void> {
        this.removeNavTab(index);
    }

    @Action
    async getApp(app_id: number): Promise<App | null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getApp,
            variables: {
                app_id,
            },
        })) as GraphQLResult<GetApp>;

        const app = res.data?.getApp;
        if (app) {
            this.setApp(app);
            return app;
        } else return null;
    }

    @Action
    async getApps(decision_id: number): Promise<App[] | null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getApps,
            variables: { decision_id },
        })) as GraphQLResult<GetApps>;

        const apps = res.data?.getApps;
        if (apps) {
            apps.forEach((app) => this.setApp(app));
            return apps;
        } else return null;
    }

    @Action
    async createApp(newApp: CreateAppInput): Promise<App | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createApp,
            variables: {
                ...newApp,
            },
        })) as GraphQLResult<CreateApp>;

        const createdApp = res.data?.createApp?.apps;
        if (createdApp) {
            this.setApp(createdApp[0]);
            return createdApp[0];
        } else {
            return null;
        }
    }

    @Action
    async updateApp(newApp: UpdateAppInput): Promise<null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateApp,
            variables: {
                ...newApp,
            },
        })) as GraphQLResult<UpdateApp>;

        const updatedApp = res.data?.updateApp?.apps;
        if (updatedApp) {
            this.setApp(updatedApp[0]);
        }
        return null;
    }

    @Action
    async deleteApp(id: number): Promise<App | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteApp,
            variables: {
                id,
            },
        })) as GraphQLResult<DeleteApp>;

        const deletedApp = res.data?.deleteApp?.apps[0];
        if (deletedApp) {
            this.removeApp(deletedApp);
            return deletedApp;
        }
        return null;
    }

    @Action
    async createTab(newTab: CreateTabInput): Promise<Tab | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createTab,
            variables: {
                ...newTab,
            },
        })) as GraphQLResult<CreateTab>;

        const createdTab = res.data?.createTab?.tabs;
        if (createdTab) {
            this.setTab(createdTab[0]);
            return createdTab[0];
        }
        return createdTab ? createdTab[0] : null;
    }

    @Action
    async updateTab(payload: {
        newTab: UpdateTabInput;
        appId?: number | null;
    }): Promise<Tab | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateTab,
            variables: {
                ...payload.newTab,
            },
        })) as GraphQLResult<UpdateTab>;

        const updatedTab = res.data?.updateTab?.tabs[0];
        if (updatedTab) {
            this.setTab(updatedTab);
            if (payload.appId != null)
                this.setAppTab({
                    app_id: payload.appId,
                    tab_id: updatedTab.id,
                });
            return updatedTab;
        }
        return null;
    }

    @Action
    async deleteTab(id: number): Promise<Tab | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteTab,
            variables: {
                id,
            },
        })) as GraphQLResult<DeleteTab>;

        const deletedTab = res.data?.deleteTab?.tabs[0];
        if (deletedTab) {
            this.removeTab(deletedTab);
            this.getApps(deletedTab.decision_id);
            return deletedTab;
        }
        return null;
    }

    @Action
    async deleteAppTab(payload: {
        app_id: number;
        tab_id: number;
    }): Promise<null> {
        const app_id = payload.app_id;
        const tab_id = payload.tab_id;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteAppTab,
            variables: {
                app_id,
                tab_id,
            },
        })) as GraphQLResult<DeleteAppTab>;

        const deletedTab = res.data?.deleteAppTab;
        if (deletedTab) {
            this.removeAppTabMap({
                app_id: app_id,
                tab_id: tab_id,
            });
        }
        return null;
    }

    @Action
    async createAppTabMapping(
        newTabMapping: CreateAppTabMapping
    ): Promise<null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createAppTab,
            variables: {
                ...newTabMapping,
            },
        })) as GraphQLResult<CreateAppTab>;

        const createdAppTabMapping = res.data?.createAppTab?.app_tabs[0];
        if (createdAppTabMapping) {
            this.setAppTab(createdAppTabMapping);
            this.addAppTabMap(newTabMapping);
        }
        return null;
    }

    @Action
    async updateAppTabMapping(tabMapping: UpdateAppTabMapping): Promise<null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateAppTab,
            variables: {
                ...tabMapping,
            },
        })) as GraphQLResult<UpdateAppTab>;

        const updatedAppTabMapping = res.data?.updateAppTab?.app_tabs;
        if (updatedAppTabMapping) {
            const index = await this.getAppTabIndex(updatedAppTabMapping[0]);
            this.updateAppTabMap({
                appTabMap: updatedAppTabMapping[0],
                tabIndex: index,
            });
        }
        return null;
    }

    @Action
    async getAppTabIndex(tabMap: UpdateAppTabMapping): Promise<number> {
        return this.keyedApps[tabMap.app_id].tabs.findIndex(
            (tab) => tab.id == tabMap.tab_id
        );
    }

    @Action
    async getTabFactors(tab_id: number): Promise<null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getTabFactors,
            variables: {
                tab_id,
            },
        })) as GraphQLResult<GetTabFactors>;

        const tabFactors = res.data?.getTabFactors;
        if (tabFactors) this.addTabFactors(tabFactors);
        return null;
    }

    @Action
    async getTabChoices(tab_id: number): Promise<null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getTabChoices,
            variables: {
                tab_id,
            },
        })) as GraphQLResult<GetTabChoices>;

        const tabChoices = res.data?.getTabChoices;
        if (tabChoices != null) this.addTabChoices(tabChoices);

        return null;
    }

    @Action
    async getTabViewpoints(tab_id: number): Promise<null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getTabViewpoints,
            variables: {
                tab_id,
            },
        })) as GraphQLResult<GetTabViewpoints>;

        const tabViewpoints = res.data?.getTabViewpoints;
        if (tabViewpoints != null) this.addTabViewpoints(tabViewpoints);
        return null;
    }

    @Action
    async createTabFactor(input: TabFactorMapping[]): Promise<null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createTabFactor,
            variables: {
                input,
            },
        })) as GraphQLResult<CreateTabFactor>;

        const createdTabFactors = res.data?.createTabFactor?.tab_factors;
        return null;
    }

    @Action
    async deleteTabFactor(payload: {
        tab_id: number;
        factors: number[];
    }): Promise<null> {
        const tab_id = payload.tab_id;
        const factor_ids = payload.factors;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteTabFactor,
            variables: {
                tab_id,
                factor_ids,
            },
        })) as GraphQLResult<DeleteTabFactor>;

        const deletedTabFactor = res.data?.deleteTabFactor?.tab_factors;
        return null;
    }

    @Action
    async createTabChoice(input: TabChoiceMapping[]): Promise<null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createTabChoice,
            variables: {
                input,
            },
        })) as GraphQLResult<CreateTabChoice>;

        const createdTabChoice = res.data?.createTabChoice?.tab_choices;
        return null;
    }

    @Action
    async deleteTabChoice(payload: {
        tab_id: number;
        choice_ids: number[];
    }): Promise<null> {
        const tab_id = payload.tab_id;
        const choice_ids = payload.choice_ids;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteTabChoice,
            variables: {
                tab_id,
                choice_ids,
            },
        })) as GraphQLResult<DeleteTabChoice>;

        const deletedTabChoice = res.data?.deleteTabChoice?.tab_choices;
        return null;
    }

    @Action
    async createTabViewpoint(input: TabViewpointMapping[]): Promise<null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createTabViewpoint,
            variables: {
                input,
            },
        })) as GraphQLResult<CreateTabViewpoint>;

        const createdTabViewpoint =
            res.data?.createTabViewpoint?.tab_viewpoints;
        return null;
    }

    @Action
    async deleteTabViewpoint(payload: {
        tab_id: number;
        viewpoint_ids: number[];
    }): Promise<null> {
        const tab_id = payload.tab_id;
        const viewpoint_ids = payload.viewpoint_ids;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteTabViewpoint,
            variables: {
                tab_id,
                viewpoint_ids,
            },
        })) as GraphQLResult<DeleteTabFactor>;

        const deletedTabViewpoint = res.data?.deleteTabFactor?.tab_viewpoints;
        return null;
    }

    @Action
    addApps(apps: App[]): void {
        if (apps == null || apps.length == 0) {
            return;
        }

        apps.forEach((app: App) => {
            this.setApp(app);
        });
    }

    @Action
    addTabs(tabs: Tab[]): void {
        if (tabs == null || tabs.length == 0) {
            return;
        }

        tabs.forEach((tab: Tab) => {
            this.setTab(tab);
        });
    }

    @Action
    addAppTabs(appTabs: AppTabMapping[]): void {
        console.log(appTabs);
        if (appTabs == null || appTabs.length == 0) {
            return;
        }

        appTabs.forEach((appTab: AppTabMapping) => {
            this.setAppTab(appTab);
        });
    }

    @Action
    addTabFactors(tabFactors: TabFactorMapping[]): void {
        if (tabFactors == null || tabFactors.length == 0) {
            return;
        }

        tabFactors.forEach((tabFactor: TabFactorMapping) => {
            this.setTabFactor(tabFactor);
        });
    }

    @Action
    addTabChoices(tabChoices: TabChoiceMapping[]): void {
        if (tabChoices == null || tabChoices.length == 0) {
            return;
        }

        tabChoices.forEach((tabChoice: TabChoiceMapping) => {
            this.setTabChoice(tabChoice);
        });
    }

    @Action
    addTabViewpoints(tabViewpoints: TabViewpointMapping[]): void {
        if (tabViewpoints == null || tabViewpoints.length == 0) {
            return;
        }

        tabViewpoints.forEach((tabViewpoints: TabViewpointMapping) => {
            this.setTabViewpoint(tabViewpoints);
        });
    }

    @Action
    removeLocalApps(apps: App[]): void {
        if (apps == null || apps.length == 0) {
            return;
        }

        apps.forEach((app: App) => {
            this.removeApp(app);
        });
    }

    @Action
    removeLocalTabs(tabs: Tab[]): void {
        if (tabs == null || tabs.length == 0) {
            return;
        }

        tabs.forEach((tab: Tab) => {
            this.removeTab(tab);
        });
    }

    @Action
    removeLocalAppTabs(appTabs: AppTabMapping[]): void {
        if (appTabs == null || appTabs.length == 0) {
            return;
        }

        appTabs.forEach((appTab: AppTabMapping) => {
            this.removeAppTab(appTab);
        });
    }

    @Action
    removeLocalTabFactors(tabFactors: TabFactorMapping[]): void {
        if (tabFactors == null || tabFactors.length == 0) {
            return;
        }

        tabFactors.forEach((tabFactor: TabFactorMapping) => {
            this.removeTabFactor(tabFactor);
        });
    }

    @Action
    removeLocalTabChoices(tabChoices: TabChoiceMapping[]): void {
        if (tabChoices == null || tabChoices.length == 0) {
            return;
        }

        tabChoices.forEach((tabChoice: TabChoiceMapping) => {
            this.removeTabChoice(tabChoice);
        });
    }

    @Action
    removeLocalTabViewpoints(tabViewpoints: TabViewpointMapping[]): void {
        if (tabViewpoints == null || tabViewpoints.length == 0) {
            return;
        }

        tabViewpoints.forEach((tabViewpoints: TabViewpointMapping) => {
            this.removeTabViewpoint(tabViewpoints);
        });
    }

    @Action
    clearApps(): void {
        this.removeAllApps();
    }
}

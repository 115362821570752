
import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { App } from "@/graphql/API";
import { Urls } from "@/Urls";
import Apps from "@/store/modules/Apps";
import Ui from "@/store/modules/Ui";
import AppListView from "@/views/AppListView.vue";
import TabsView from "@/views/TabsView.vue";

const appsModule = getModule(Apps);
const uiModule = getModule(Ui);

@Component({
    components: {
        AppListView,
        TabsView,
    },
})
export default class AppManagerView extends Vue {
    private readonly URLS = Urls;

    get appIdParam(): number | null {
        return this.$route.params.appId
            ? parseInt(this.$route.params.appId)
            : null;
    }

    get currentRoutePath(): string | null {
        return this.$route.name ? this.$route.name : null;
    }

    get mainScreen(): boolean {
        return this.$route.name
            ? this.$route.name.localeCompare("AppsListEditorView") == 0
            : false;
    }

    get selectedApp(): App | null {
        return appsModule.selectedApp;
    }

    get breadCrumb(): string | null {
        if (this.selectedApp) {
            return this.selectedApp.title;
        } else {
            return null;
        }
    }

    get isRoute(): boolean {
        return this.currentRoutePath == this.URLS.APPSLISTEDITOR;
    }

    mounted(): void {
        this.onBreadCrumbChange(this.breadCrumb);
        appsModule.selectApp(this.appIdParam);
    }

    /*
    @Watch("appIdParam")
    onAppIdParamChange(): void {
        appsModule.selectApp(this.appIdParam);
    }
    */

    @Watch("breadCrumb")
    onBreadCrumbChange(val: string | null): void {
        if (this.mainScreen) {
            uiModule.changeBreadCrumb(val);
        }
    }

    @Watch("isRoute")
    onIsRouteChange(val: boolean): void {
        if (val) {
            uiModule.changeBreadCrumb(this.breadCrumb);
        }
    }

    @Watch("mainScreen")
    onMainScreenChange(val: boolean): void {
        if (val) {
            this.onBreadCrumbChange(this.breadCrumb);
        }
    }
}

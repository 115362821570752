
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, Choice, Viewpoint, FactorOptions, Value } from "@/graphql/API";
import { FactorsIcons } from "@/IconLibrary";
import WeightCounterMenu from "@/components/weights/WeightCounterMenu.vue";
import Choices from "@/store/modules/Choices";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";

const choiceModule = getModule(Choices);

@Component({
    components: {
        WeightCounterMenu,
        FactorValueDisplay,
    },
})
export default class CompareColumnHeaders extends Vue {
    @Prop({ default: () => [], type: Array })
    items!: Factor[] | Choice[] | Viewpoint[];

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: "viewpoints", type: String })
    columnIndex!: string;

    @Prop({ default: "factors", type: String })
    rowIndex!: string;

    @Prop()
    dataItem!: Factor | Choice | Viewpoint | null;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoreRule!: boolean;

    @Prop({ default: true, type: Boolean })
    showWeights!: boolean;

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    showChoices!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private readonly Icons: Record<string, string> = FactorsIcons;

    get factorColumns(): boolean {
        return this.columnIndex == "factors";
    }

    get showChoiceData(): boolean {
        return (
            this.columnIndex == "choices" &&
            this.rowIndex == "viewpoints" &&
            this.dataItem != null &&
            this.showChoices
        );
    }

    get showDescription(): boolean {
        if (this.factorColumns) {
            return this.factorDisplay.factor_description;
        } else {
            return true;
        }
    }

    get showViewpointCell(): boolean {
        if (this.rowIndex == "choices" && this.dataItem != null) {
            return this.scoreRule || this.showWeights;
        } else {
            return false;
        }
    }

    get indexes(): { [id: string]: string } {
        if (this.factorColumns) {
            return {
                rows: "factors",
                columns: "choices",
                data: "viewpoints",
            };
        } else {
            return {
                rows: "viewpoints",
                columns: "choices",
                data: "factors",
            };
        }
    }

    get choiceValues(): { [choice_id: number]: { [id: string]: Value } } {
        return choiceModule.choiceValues;
    }

    private toggleScoreRule(item: Choice | Viewpoint | Factor): void {
        if (this.dataItem) {
            if (this.factorColumns) {
                this.$emit("factor-select", {
                    factor: item,
                    viewpoint: this.dataItem,
                });
            } else {
                this.$emit("factor-select", {
                    factor: this.dataItem,
                    viewpoint: item,
                });
            }
        }
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{attrs:{"accordion":"","flat":"","hover":""}},[_c('v-expansion-panel',{on:{"change":function($event){return _vm.loadChildren(_vm.factor.id)}}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"factor__offset factor__offset--ingroup",class:index == _vm.depth ? 'factor__offset--lines' : ''})}),_c('v-btn',{staticClass:"btn-vp-expand",attrs:{"icon":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.languageRight ? "mdi-menu-left" : "mdi-menu-right")+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"factor-row"},[_c('v-btn',{staticClass:"factor-group",attrs:{"plain":"","disabled":!_vm.isTableActive},on:{"click":function($event){$event.stopPropagation();_vm.loadChildren(_vm.factor.id);
                        _vm.clickFactor($event, _vm.factor, false);}}},[_c('div',{staticClass:"factor__label",class:_vm.selectedFactors.includes(_vm.factor.id)
                                ? 'factor-label--active'
                                : ''},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(_vm._s(_vm.Icons["table"]))]),_c('div',{staticClass:"factor__name"},[_vm._v(_vm._s(_vm.factor.name))])],1)]),_c('div',{staticClass:"factor__mobile-toggle sidebar-mobile-show"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickFactor($event, _vm.factor, true)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('div',{staticClass:"factor__drag",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-drag")])],1)],1)]),_c('v-expansion-panel-content',{staticClass:"factor-table__content"},[_c('div',{staticClass:"factor__expansion__offset"},_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"factor__offset factor__offset--ingroup"})}),0),_c('draggable',{attrs:{"list":_vm.columns,"disabled":!_vm.editModel,"ghost-class":"factor-ghost","drag-class":"factor-drag-class","handle":".factor__drag"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":function($event){return _vm.dragChange($event, _vm.factor.id)}}},_vm._l((_vm.columns),function(column,index){return _c('div',{key:index,staticClass:"factor-wrapper",class:{
                        'factor-wrapper--active': _vm.selectedFactors.includes(
                            column.id
                        ),
                        'factor-wrapper--disabled': !column.filterActive,
                    }},[_c('div',{staticClass:"factor-row"},[_c('v-btn',{staticClass:"factor factor--single",attrs:{"plain":"","disabled":!column.filterActive},on:{"click":function($event){$event.stopPropagation();return _vm.clickFactor($event, column, false)}}},[_vm._l((_vm.depth + 1),function(index){return _c('div',{key:index,staticClass:"factor__offset factor__offset--ingroup",class:index == _vm.depth + 1
                                        ? 'factor__offset--lines'
                                        : ''})}),_c('div',{staticClass:"factor__label",class:_vm.selectedFactors.includes(column.id)
                                        ? 'factor-label--active'
                                        : ''},[_c('v-icon',[_vm._v(_vm._s(_vm.Icons[column.type]))]),_c('div',{staticClass:"factor__name"},[_vm._v(" "+_vm._s(column.name)+" ")])],1)],2),_c('div',{staticClass:"factor__mobile-toggle sidebar-mobile-show"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickFactor($event, column, true)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('div',{staticClass:"factor__drag"},[_c('v-icon',[_vm._v("mdi-drag")])],1)],1)])}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Choice,
    Viewpoint,
    Locks,
    FactorOptions,
    ScoreOptions,
    ScoreClassSettings,
} from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
const decisionsModule = getModule(Decisions);

@Component({
    components: {},
})
export default class CompareOptions extends Vue {
    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @PropSync("locks")
    syncedLocks!: Locks;

    @PropSync("scoresEditable")
    syncedScoresEditable!: boolean;

    @PropSync("customScore")
    syncedCustomScore!: boolean;

    @PropSync("choiceEditable")
    syncedChoiceEditable!: boolean;

    @PropSync("factorDisplay")
    syncedFactorDisplay!: FactorOptions;

    @PropSync("weightsEditable")
    syncedWeightsEditable!: boolean;

    @PropSync("classRating")
    syncedClassRating!: boolean;

    @PropSync("scoreRule")
    syncedScoreRule!: boolean;

    @PropSync("scoreDisplay")
    syncedScoreDisplay!: ScoreOptions;

    @PropSync("selectorSettings")
    syncedClassSelector!: ScoreClassSettings;

    @PropSync("scoreRuleEditable")
    syncedScoreRuleEditable!: boolean;

    private classSelectorLabels = {
        label: "Labels",
        description: "Descriptions",
        icons: "Icons",
        colour: "Color",
    };

    get scoreDisplayLabels(): { [id: string]: string } {
        return {
            bar: "Bar",
            score: "Score",
            weights: "Weights",
            weights_icon: "Weight Icons",
            normalized_score: "Normalized Score",
            class_label: "Class Label",
            class_icons: "Class Icons",
            class_description: "Class Description",
            class_colour: "Heat Map",
            choice_data: `${this.choiceLabel} Data`,
        };
    }

    private factorDisplayLabels = {
        factor_description: "Factor Description",
    };

    get choiceLabel(): string {
        return decisionsModule.choiceLabelSingular;
    }
}


import { Vue, Component, Prop } from "vue-property-decorator";
import {
    Choice,
    Viewpoint,
    Factor,
    ScoreClassSettings,
    ScoreOptions,
    FactorOptions,
} from "@/graphql/API";
import CompareRow from "@/components/compare/ui/CompareRow.vue";
import CompareColumnMain from "@/components/compare/ui/CompareColumnMain.vue";
import CompareColumn from "@/components/compare/ui/CompareColumn.vue";
import CompareCell from "@/components/compare/CompareCell.vue";
import RankColumnScore from "@/components/ranking/RankColumnScore.vue";

@Component({
    components: {
        CompareRow,
        CompareColumnMain,
        CompareColumn,
        CompareCell,
        RankColumnScore,
    },
})
export default class RankTable extends Vue {
    @Prop({ default: () => [], type: Array })
    choices!: Choice[];

    @Prop({ default: () => [], type: Array })
    viewpoints!: Viewpoint[];

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop()
    factor!: Factor;

    @Prop()
    factorRow!: Factor[];

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoresEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop({ default: true, type: Boolean })
    scoreRule!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: true, type: Boolean })
    hoverHighlight!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop()
    order!: string;

    @Prop()
    selectedId!: number;

    private loading = false;
    private highlightChoiceId: number | null = null;
    private tableDrag = false;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private indexes = {
        rows: "choices",
        columns: "viewpoints",
        data: "factors",
    };

    private toggleChoice(choice: Choice, id: number) {
        if (this.selectedId && this.selectedId == id) {
            this.$emit("toggle-choice", choice);
        }
    }

    private toggleHover(id: number): void {
        if (!this.tableDrag) {
            this.highlightChoiceId = id;
        }
    }
}

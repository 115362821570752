
import { Vue, Component, Prop } from "vue-property-decorator";
import Logo from "@/components/ui/Logo.vue";
import { Urls } from "@/Urls";

@Component({
    components: {
        Logo,
    },
})
export default class LoginInterface extends Vue {
    @Prop({ default: false, type: Boolean })
    centerContent!: boolean;

    @Prop({ default: false, type: Boolean })
    showLogo!: boolean;

    @Prop({ default: true, type: Boolean })
    isParent!: boolean;

    private readonly URLS = Urls;
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.choices),function(choice,rowIndex){return _c('div',{key:rowIndex,staticClass:"vp-compare__row"},[_c('div',{staticClass:"vp-compare__row__item vp-compare__row__item--main",class:{
                'vp-compare__row__item--border-x': _vm.borderX,
                'vp-compare__row__item--border-y': _vm.borderY,
            },style:(`width: ${_vm.mainWidth}px;`)},[_c('div',{staticClass:"vp-compare__row__item--main__wrapper",class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY}`},[_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"vp-compare__row__item__offset"})}),_c('div',{staticClass:"vp-compare__row__item__label"},[_vm._v(" "+_vm._s(choice.name)+" ")])],2)]),_vm._l((_vm.tableLength),function(rowIndex){return _c('div',{key:rowIndex,staticClass:"d-flex"},_vm._l((_vm.headers),function(factor,colIndex){return _c('div',{key:colIndex,staticClass:"vp-compare__row__item",class:{
                    'vp-compare__row__item--border-x': _vm.borderX,
                    'vp-compare__row__item--border-y': _vm.borderY,
                },style:(`width: ${_vm.columnWidth}px;`)},[(_vm.columnData[choice.id].data[rowIndex - 1])?[(
                            colIndex == 0 &&
                            _vm.viewpoint &&
                            _vm.viewpointMapping[_vm.viewpoint.id] &&
                            _vm.viewpointMapping[_vm.viewpoint.id][_vm.headers[0].id]
                        )?[_c('FactorScore',{attrs:{"factor-id":_vm.headers[0].id,"choice-id":choice.id,"viewpoint-id":_vm.viewpoint.id,"row-id":_vm.columnData[choice.id].data[rowIndex - 1].row_id,"score-display":_vm.scoreDisplay,"viewpoint-map":_vm.viewpointMapping[_vm.viewpoint.id][_vm.headers[0].id],"selector-settings":_vm.selectorSettings,"editable":_vm.scoresEditable,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY}},[_c('FactorValueDisplay',{attrs:{"factor":factor,"showName":false,"factor-display":_vm.factorDisplay,"editable":_vm.choiceEditable,"choice-id":choice.id,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"isThumbnailDisplay":true,"autoSaveMedia":true,"clear-btn":false,"row-id":_vm.columnData[choice.id].data[rowIndex - 1].row_id,"value":_vm.valueMap[choice.id][`${factor.id}-${_vm.columnData[choice.id].data[rowIndex - 1].row_id}`] ? _vm.valueMap[choice.id][`${factor.id}-${_vm.columnData[choice.id].data[rowIndex - 1].row_id}`] : {}}})],1)]:[_c('div',{class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY}`},[_c('FactorValueDisplay',{attrs:{"factor":factor,"showName":false,"factor-display":_vm.factorDisplay,"editable":_vm.choiceEditable,"choice-id":choice.id,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"isThumbnailDisplay":true,"autoSaveMedia":true,"clear-btn":false,"row-id":_vm.columnData[choice.id].data[rowIndex - 1].row_id,"value":_vm.valueMap[choice.id][`${factor.id}-${_vm.columnData[choice.id].data[rowIndex - 1].row_id}`] ? _vm.valueMap[choice.id][`${factor.id}-${_vm.columnData[choice.id].data[rowIndex - 1].row_id}`] : {}}})],1)]]:_c('div',{staticClass:"vp-compare__row__empty"},[_c('div',{class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY}`},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-dots-square")])],1)])],2)}),0)})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
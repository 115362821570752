
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BreadCrumb } from "@/graphql/API";
import BreadCrumbItem from "@/components/ui/BreadCrumbItem.vue";

@Component({
    components: {
        BreadCrumbItem,
    },
})
export default class BreadCrumbItems extends Vue {
    @Prop({ default: () => [], type: Array })
    items!: BreadCrumb[];

    @Prop()
    label!: string;

    private goToLink(item: {
        name: string;
        path: string;
        label: string;
        abrv: string | null;
    }): void {
        this.$emit(item.name);
        if (item.path != this.$route.path) {
            this.$router.push(item.path);
        }
    }
}


import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
    components: {},
})
export default class CompareColumnMain extends Vue {
    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    showContent!: boolean;

    @Prop({ default: false, type: Boolean })
    showBtns!: boolean;

    @Prop({ default: false, type: Boolean })
    group!: boolean;

    @Prop({ default: false, type: Boolean })
    last!: boolean;

    @Prop({ default: false, type: Boolean })
    hideLines!: boolean;

    @Prop({ default: () => [], type: Array })
    lines!: number[];

    get borderClass(): string {
        if (this.borderY) {
            return "vp-compare__row__item--border-y";
        } else {
            return "no-border";
        }
    }

    get paddingClass(): string {
        if (this.group) {
            return `vp-padding-right-${this.paddingX}`;
        } else {
            return `vp-padding-x-${this.paddingX}`;
        }
    }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, FactorType, Enum } from "@/graphql/API";
import FactorCreator from "@/components/model/FactorCreator.vue";
import Decisions from "@/store/modules/Decisions";
import Factors from "@/store/modules/Factors";
import FactorScoring from "@/components/model/FactorScoring.vue";
import DecisionImport from "@/components/model/DecisionImport.vue";
import FactorTypeEditor from "@/components/model/FactorTypeEditor.vue";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const mudder = require("mudder");

@Component({
    components: {
        FactorCreator,
        FactorScoring,
        FactorTypeEditor,
        DecisionImport,
    },
})
export default class ModelEditor extends Vue {
    @Prop()
    selectedFactors!: Factor[];

    @Prop({ default: false, type: Boolean })
    isNew!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: false, type: Boolean })
    isAdmin!: boolean;

    @Prop({ default: false, type: Boolean })
    defaultRoute!: boolean;

    @Prop()
    backFactor!: Factor;

    @Prop({ default: "ModelView", type: String })
    view!: string;

    private editTab = "";
    private newTab = "";
    private multiTab = "";
    private factorEnum: Enum | null = null;

    get routeName(): string {
        if (this.defaultRoute) {
            return "Model";
        } else {
            return this.$route.name ? this.$route.name : "";
        }
    }

    get factorLabelSingular(): string {
        return getModule(Decisions).factorLabelSingular;
    }

    get factorLabelPlural(): string {
        return getModule(Decisions).factorLabelPlural;
    }

    get factorTypesList(): FactorType[] {
        return getModule(Factors).factorTypeList;
    }

    get selectedFactor(): Factor | null {
        if (this.selectedFactors.length == 1) {
            return this.selectedFactors[0];
        }
        return null;
    }

    get parentId(): number {
        if (
            this.selectedFactor &&
            (this.selectedFactor.is_group || this.selectedFactor.is_table)
        ) {
            return this.selectedFactor.id;
        } else {
            return this.rootFactorId;
        }
    }

    get createNew(): boolean {
        return (
            (this.isNew || this.selectedFactor == null) &&
            this.selectedFactors.length < 2
        );
    }

    get factorTree(): { [id: number]: Factor[] } {
        return getModule(Factors).factorTree;
    }

    get treeLevel(): Factor[] {
        return this.factorTree[this.parentId].sort((a: Factor, b: Factor) =>
            a.order_str.localeCompare(b.order_str)
        );
    }

    get valueTypes(): { text: string; id: string }[] {
        const modelModule = getModule(Factors);

        if (
            this.selectedFactors.length == 1 &&
            this.selectedFactors[0].is_table
        ) {
            return getModule(Factors).valueTypes.filter(
                (type) => !["table", "group"].includes(type.id)
            );
        }
        return modelModule.valueTypes;
    }

    /* Determines if selected Factors have the same score map */
    get sameScoreMap(): boolean {
        if (this.selectedFactors.length > 1) {
            return this.selectedFactors.every((factor) =>
                factor.score_map_id ? factor.score_map_id : -1
            );
        } else {
            return true;
        }
    }

    get selectedFactorsType(): string {
        if (
            this.selectedFactors.reduce((acc, curr) => {
                return acc && curr.is_group;
            }, true)
        ) {
            return "groups";
        } else if (
            this.selectedFactors.reduce((acc, curr) => {
                return acc && curr.is_table;
            }, true)
        ) {
            return "tables";
        } else if (
            this.selectedFactors.reduce((acc, curr) => {
                return acc && curr.is_column;
            }, true)
        ) {
            return "columns";
        } else if (
            this.selectedFactors.reduce((acc, curr) => {
                return acc && curr.type != null;
            }, true)
        ) {
            return "factors";
        } else {
            return "mixed";
        }
    }

    //Use mudder to find a new order_string based on last value in level of factor tree
    get nextOrderStr(): unknown {
        if (this.factorTree && this.factorTree[this.parentId]) {
            const endFactor = this.factorTree[this.parentId].at(-1);
            if (endFactor != null) {
                const order = mudder.base62.mudder(
                    endFactor.order_str,
                    "",
                    1,
                    undefined,
                    20
                )[0];
                return order;
            } else {
                return mudder.base62.mudder(
                    "0",
                    undefined,
                    1,
                    undefined,
                    20
                )[0];
            }
        }
    }

    get factorTypeDict(): { [id: number]: FactorType } {
        return getModule(Factors).factorTypes;
    }

    get rootFactorId(): number {
        return getModule(Factors).factorRoot?.id ?? -1;
    }

    private sendToggledFactor(factor: Factor) {
        this.$emit("toggle-factor", factor);
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VpDialog from "@/components/ui/VpDialog.vue";

@Component({
    name: "AudioPreview",
    components: {
        VpDialog,
    },
})
export default class AudioPreview extends Vue {
    @Prop()
    path!: string;

    @Prop({ type: String, default: "File" })
    name!: string;

    private previewOpen = false;

    private pauseAudio(): void {
        this.previewOpen = false;

        if (this.$refs["audioPlayer"]) {
            const player = this.$refs["audioPlayer"] as HTMLAudioElement | null;
            if (player) {
                player.pause();
            }
        }
    }

    @Watch("previewOpen")
    onOpen(val: boolean): void {
        if (val) {
            if (this.$refs["audioPlayer"]) {
                const player = this.$refs[
                    "audioPlayer"
                ] as HTMLAudioElement | null;
                if (player) {
                    player.play();
                }
            }
        }
    }
}

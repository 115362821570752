var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-compare-table-group"},[(_vm.mainColumn && _vm.mainColumn.length)?_c('v-expansion-panels',{staticClass:"vp-accordion vp-accordion--btn-left vp-accordion--plain",class:{
            'vp-accordion--full': _vm.choices && _vm.choices.length == 1,
        },attrs:{"accordion":"","flat":"","hover":"","focusable":""}},_vm._l((_vm.tableLength),function(rowIndex){return _c('v-expansion-panel',{key:rowIndex},[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return undefined},proxy:true}],null,true)},[_c('CompareRow',{attrs:{"shadow":rowIndex == 1}},[_c('CompareColumnMain',{attrs:{"main-width":_vm.mainWidth,"depth":_vm.depth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"lines":_vm.lines,"last":rowIndex == _vm.tableLength}},[_c('v-icon',{staticClass:"vp-accordion__expand-icon"},[_vm._v(" mdi-menu-right ")]),_vm._v(" Row "+_vm._s(rowIndex)+" ")],1),_vm._l((_vm.choices),function(choice,index){return _c('CompareColumn',{key:index,attrs:{"column-width":_vm.columnWidth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"full":_vm.choices.length == 1 && _vm.fullColumn,"table-cell":""}},[(
                                _vm.dataItem &&
                                _vm.columnData[choice.id].data[rowIndex - 1]
                            )?_c('FactorScore',{attrs:{"factor-id":_vm.headers[0].id,"choice-id":choice.id,"viewpoint-id":_vm.dataItem.id,"viewpoint-map":_vm.viewpointMapping[_vm.dataItem.id][_vm.headers[0].id],"row-id":_vm.columnData[choice.id].data[rowIndex - 1]
                                    .row_id,"score-display":_vm.scoreDisplay,"selector-settings":_vm.selectorSettings,"editable":_vm.scoresEditable,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY}}):_c('div',{staticClass:"vp-compare__row__empty"},[(
                                    _vm.columnData[choice.id].data.length + 1 ==
                                    rowIndex
                                )?_c('div',[(_vm.choiceEditable)?_c('v-btn',{attrs:{"small":"","icon":"","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.addRow(choice.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1):(
                                    _vm.columnData[choice.id].data[rowIndex - 1]
                                )?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()])],1)})],2)],1),_c('v-expansion-panel-content',_vm._l((_vm.headers),function(column,factIndex){return _c('div',{key:factIndex},[(
                            _vm.hiddenFactors &&
                            !_vm.hiddenFactors.includes(column.id)
                        )?_c('CompareRow',{attrs:{"shadow":factIndex == 0}},[_c('CompareColumnMain',{attrs:{"main-width":_vm.mainWidth,"depth":_vm.depth + 1,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"last":factIndex == _vm.headers.length - 1,"lines":rowIndex == _vm.tableLength ? [..._vm.lines, _vm.depth] : _vm.lines}},[_c('div',{staticClass:"vp-compare__factor"},[_vm._v(" "+_vm._s(column.name)+" "),(
                                        column.description &&
                                        _vm.factorDisplay.factor_description
                                    )?_c('div',{staticClass:"vp-compare__factor__description"},[_c('span',{domProps:{"innerHTML":_vm._s(column.description)}})]):_vm._e()])]),_vm._l((_vm.choices),function(choice,index){return _c('CompareColumn',{key:index,attrs:{"column-width":_vm.columnWidth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"full":_vm.choices.length == 1 && _vm.fullColumn,"table-cell":""}},[(_vm.columnData[choice.id].data[rowIndex - 1])?_c('div',{class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY}`,staticStyle:{"width":"100%"}},[(factIndex == 0)?_c('div',{staticClass:"text-right"},[(_vm.choiceEditable)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteDialog(
                                                choice,
                                                rowIndex,
                                                _vm.columnData[choice.id].data[
                                                    rowIndex - 1
                                                ].row_id
                                            )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle")])],1):_vm._e()],1):_vm._e(),_c('FactorValueDisplay',{attrs:{"factor":column,"showName":false,"factor-display":_vm.factorDisplay,"editable":_vm.choiceEditable,"choice-id":choice.id,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"isThumbnailDisplay":true,"autoSaveMedia":true,"clear-btn":false,"hide-desc":true,"row-id":_vm.columnData[choice.id].data[rowIndex - 1]
                                            .row_id,"value":_vm.valueMap[choice.id][
                                            `${column.id}-${
                                                _vm.columnData[choice.id].data[
                                                    rowIndex - 1
                                                ].row_id
                                            }`
                                        ]
                                            ? _vm.valueMap[choice.id][
                                                `${column.id}-${
                                                    _vm.columnData[choice.id]
                                                        .data[rowIndex - 1]
                                                        .row_id
                                                }`
                                            ]
                                            : {}}})],1):_c('div',{staticClass:"vp-compare__row__empty"},[_c('div',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-dots-square")])],1)])])})],2):_vm._e()],1)}),0)],1)}),1):_vm._e(),(_vm.choiceEditable)?_c('CompareRow',[_c('CompareColumnMain',{attrs:{"main-width":_vm.mainWidth,"depth":_vm.depth + 1,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"lines":[..._vm.lines, _vm.depth, _vm.depth + 1]}}),_vm._l((_vm.choices),function(choice,index){return _c('CompareColumn',{key:index,attrs:{"column-width":_vm.columnWidth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"full":_vm.choices.length == 1 && _vm.fullColumn,"table-cell":""}},[_c('div',{staticClass:"vp-compare__row__empty",class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY}`},[(
                        _vm.columnData[choice.id].data.length ==
                        _vm.mainColumn.length
                    )?_c('div',[_c('v-btn',{attrs:{"small":"","icon":"","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.addRow(choice.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1):_vm._e()])])})],2):_vm._e(),_c('VpDialog',{staticClass:"mt-3",attrs:{"titleText":`Delete?`,"btnConfirm":"Confirm","btnCancel":"","headerActionMinWidth":"300px"},on:{"confirm":function($event){return _vm.deleteRow(_vm.deleteChoice, _vm.deleteRowId)},"cancel":function($event){_vm.deleteChoice = null;
            _vm.deleteIndex = null;
            _vm.deleteRowId = null;
            _vm.deleteOpen = false;},"close":function($event){_vm.deleteChoice = null;
            _vm.deleteIndex = null;
            _vm.deleteRowId = null;
            _vm.deleteOpen = false;}},model:{value:(_vm.deleteOpen),callback:function ($$v) {_vm.deleteOpen=$$v},expression:"deleteOpen"}},[_c('div',{staticClass:"mt-2 text-center"},[_vm._v(" Do you really want to remove: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Row "+_vm._s(_vm.deleteIndex)+" "+_vm._s(_vm.deleteChoice ? `from ${_vm.deleteChoice.name}` : "")+" ")]),_vm._v(" ? This process cannot be undone. ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Viewpoint,
    Choice,
    ChartValues,
    ViewpointMapping,
} from "@/graphql/API";
import BarChart from "@/components/charts/BarChart.vue";
import StackedChart from "@/components/charts/StackedChart.vue";
import Scoring from "@/store/modules/Scoring";
import WorkspaceOptions from "@/store/modules/WorkspaceOptions";
import Viewpoints from "@/store/modules/Viewpoints";

const workspaceOptions = getModule(WorkspaceOptions);
const scoringModule = getModule(Scoring);

@Component({
    components: {
        BarChart,
        StackedChart,
    },
})
export default class ChartVps extends Vue {
    @Prop()
    chartDataValue!: ChartValues;

    @Prop()
    viewpoints!: Viewpoint[];

    @Prop()
    selectedChoice!: Choice;

    @Prop()
    selectedViewpoint!: Viewpoint;

    @Prop({ default: false, type: Boolean })
    isBarChartHorizontal!: boolean;

    get viewpointMappings(): {
        [viewpointId: number]: {
            [factorId: number]: ViewpointMapping;
        };
    } {
        return getModule(Viewpoints).viewpointMappings;
    }

    get barChartData() {
        var res = [];
        var series = [];
        let datas = [];
        let cats = this.selectedViewpoint ? this.selectedViewpoint.name : null;
        if (cats !== null) {
            cats = cats;
        }
        for (let N = 0; N < this.chartDataValue.f_ids.length; N++) {
            let inside_datas: number[] = [];
            datas.push(inside_datas);
            var inside_series = {
                id: this.chartDataValue.f_ids[N],
                name: this.chartDataValue.categories_factors[N],
                data:
                    this.selectedChoice && this.selectedViewpoint
                        ? this.getScore(
                              this.selectedViewpoint.id,
                              this.selectedChoice.id,
                              this.chartDataValue.f_ids[N]
                          )
                        : [0],
            };
            series.push(inside_series);
        }
        var res_data = {
            series: series,
        };
        const res_xaxis = {
            categories: [cats],
        };

        res.push({
            data: res_data,
            title: "Viewpoint",
            xaxis: res_xaxis,
        });
        return res[0];
    }

    private getScore(
        viewpointId: number,
        choiceId: number,
        factorId: string
    ): number[] {
        const score =
            scoringModule.keyedScores[`${viewpointId}-${choiceId}-${factorId}`];
        if (
            score &&
            this.viewpointMappings[viewpointId] &&
            this.viewpointMappings[viewpointId][score.f_id]
        ) {
            if (
                this.viewpointMappings[viewpointId][score.f_id].use_m_score
            ) {
                return [this.roundNumber(score.m_score, 2)];
            } else {
                return [this.roundNumber(score.c_score, 2)];
            }
        } else {
            return [0];
        }
    }

    private roundNumber(value: number, digits: number): number {
        return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
    }
}


import { Unit, UnitConv } from "@/graphql/API";
import Domains from "@/store/modules/Domains";
import FlashNotifications from "@/store/modules/FlashNotifications";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UnitConvEditor from "@/components/domains/units/UnitConvEditor.vue";
import UnitCreatorAbbreviation from "@/components/domains/units/UnitCreatorAbbreviation.vue";

const domainsModule = getModule(Domains);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    components: { UnitConvEditor, UnitCreatorAbbreviation },
})
export default class UnitCreator extends Vue {
    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop({
        default: () => ({}),
        type: Object,
    })
    editUnit!: Unit;

    private loading = false;

    private unitName = "";
    private unitAbrv = "";
    private multiplier = 1;
    private toId: number | null = null;
    private addNew = false;

    get title(): string {
        if (this.isEdit && this.editUnit) {
            return "Edit Measurement Unit";
        } else {
            return "Create Measurement Unit";
        }
    }

    get unitConvs(): UnitConv[] {
        console.log(domainsModule.unitConvs);
        return Object.values(domainsModule.unitConvs);
    }

    get fromUnitConvs(): UnitConv[] {
        return this.unitConvs.filter(
            (unitConv) => unitConv.from_id === this.editUnit.id
        );
    }

    get unitsDict(): { [id: number]: Unit } {
        return domainsModule.units;
    }

    get unitsList(): Unit[] {
        return domainsModule.unitsList;
    }

    get filteredUnitsList(): Unit[] {
        return this.unitsList.filter(
            (unit: Unit) => unit.id != this.editUnit.id
        );
    }

    get otherUnitsList(): number[] {
        return this.unitConvs
            .filter((unit: UnitConv) => unit.from_id === this.editUnit.id)
            .map((unit) => unit.to_id);
    }

    mounted(): void {
        if (this.isEdit) this.onChangeEditUnit();
    }

    private async createUnit(): Promise<void> {
        try {
            await domainsModule.createUnit({
                name: this.unitName,
                abrv: this.unitAbrv,
            });
            flashNotificationsModule.success({
                message: `${this.unitName} was successfully created`,
                duration: 3000,
            });
            this.$emit("create-unit");
        } catch (e) {
            flashNotificationsModule.error({
                message: "Something went wrong. Please try again",
                duration: 3000,
            });
        }
    }

    private async saveUnit(): Promise<void> {
        if (this.editUnit.id) {
            try {
                await domainsModule.updateUnit({
                    name: this.unitName,
                    abrv: this.unitAbrv,
                    unit_id: this.editUnit.id,
                });
                flashNotificationsModule.success({
                    message: `${this.unitName} was successfully saved`,
                    duration: 3000,
                });
            } catch(e) {
                flashNotificationsModule.error({
                    message: "Something went wrong. Please try again",
                    duration: 3000,
                });
            }
        }
    }

    private async createUnitConv(): Promise<void> {
        if (this.editUnit != null && this.toId != null) {
            await domainsModule.createUnitConversion({
                from_id: this.editUnit.id,
                to_id: this.toId,
                multiplier: `${this.multiplier}`,
            });
        }
    }

    @Watch("editUnit", { deep: true })
    private onChangeEditUnit() {
        if (this.editUnit) {
            this.unitName = this.editUnit.name;
            this.unitAbrv = this.editUnit.abrv;
        } else {
            this.unitName = "";
            this.unitAbrv = "";
        }
    }
}


import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ColourPalette from "@/components/mixins/ColourPalette";

@Component({
    components: {},
})
export default class IconDisplay extends mixins(ColourPalette) {
    @Prop()
    json!: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get styles(): any {
        if (this.json) {
            return JSON.parse(this.json);
        }
        return {};
    }
}

import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Choice, Tab } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Apps from "@/store/modules/Apps";
import Choices from "@/store/modules/Choices";

const appsModule = getModule(Apps);
const choiceModule = getModule(Choices);

@Component
export default class ChoiceEditBase extends Vue {
    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop()
    tabSettings!: Tab;

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop()
    header!: string;

    @Prop()
    bannerImage!: string;

    @Prop()
    bannerAlign!: string;

    allChoices = false;

    choiceSortDef = true;
    choiceSortDesc = true;

    newChoicesEnabled = true;

    /* User can edit choices */
    editable = true;

    loadingTabSettings = false;

    get isMainTool(): boolean {
        return !this.isEdit && !this.embed;
    }

    get tabId(): number | null {
        if (this.tabSettings) {
            return this.tabSettings.id;
        } else {
            return null;
        }
    }

    get tabEditFlagString(): string {
        if (this.tabSettings) {
            return this.tabSettings.edit_flags;
        } else {
            return "";
        }
    }

    /* Objects created by current User */
    get userChoices(): Choice[] {
        return choiceModule.userChoices;
    }

    get choices(): Choice[] {
        if (this.allChoices) {
            return choiceModule.choices;
        } else {
            return choiceModule.userChoices;
        }
    }

    get choicesList(): Choice[] {
        return [...this.choices].sort((a, b) => {
            return (
                a.name.localeCompare(b.name) * (this.choiceSortDesc ? 1 : -1)
            );
        });
    }

    mounted(): void {
        if (this.isMainTool) {
            this.allChoices = true;
        }
        this.onEditFlagChange();
    }

    private async loadTabEditOptions(
        flags: {
            editable: boolean;
            allChoices: boolean;
            newChoices: boolean;
            sortDef: boolean;
            sortDesc: boolean;
            showAll?: boolean;
        } | null
    ): Promise<void> {
        if (flags) {
            if (flags.showAll) {
                this.allChoices = true;
            } else {
                this.editable = flags.editable;
                this.allChoices = flags.allChoices;
                this.newChoicesEnabled = flags.newChoices;
                this.choiceSortDef = flags.sortDef;
                this.choiceSortDesc = flags.sortDesc;
            }
        }
    }

    @Watch("tabEditFlagString")
    async onEditFlagChange(): Promise<void> {
        this.loadingTabSettings = true;
        if (this.tabId) {
            await this.loadTabEditOptions(
                this.tabEditFlagString != ""
                    ? JSON.parse(this.tabEditFlagString)
                    : null
            );
        }
        this.loadingTabSettings = false;
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import { getModule } from "vuex-module-decorators";
import { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import VpDialog from "@/components/ui/VpDialog.vue";

import FlashNotifications from "@/store/modules/FlashNotifications";

const flashNotificationsModule = getModule(FlashNotifications);
@Component({
    name: "FactorPdfUploader",
    components: {
        DialogCardTitle,
        VpDialog,
    },
})
export default class FactorPdfUploader extends Vue {
    @Prop({ default: false, type: Boolean })
    clearForm!: boolean;

    private file: Array<File> | null = null;
    private userName = "";
    private fileUploaded = false;
    private isLoading = false;

    async mounted(): Promise<void> {
        let authUser = await Auth.currentAuthenticatedUser();
        this.userName = authUser.username;
    }

    get uploadedPdf(): File | string {
        return this.file && this.file.length > 0
            ? URL.createObjectURL(this.file[0])
            : "";
    }

    private renderFile(e: any): void {
        this.fileUploaded = false;
        if (e.length > 0 && this.file) {
            const reader = new FileReader();
            let pageNumber = 1;
            reader.readAsBinaryString(this.file[0]);
            reader.onload = (e: any) => {
                pageNumber = e.target.result.match(
                    /\/Type[\s]*\/Page[^s]/g
                ).length;
                this.$emit("loaded", pageNumber);
            };
        } else {
            this.$emit("loaded", 1);
        }
    }

    private async uploadMediaFile(): Promise<void> {
        this.isLoading = true;
        let file = this.file ? this.file : "";
        const fileName = uuidv4();
        const key = `${this.userName}/${fileName}.pdf`;

        try {
            await Storage.put(key, file[0], {
                // level: "private",
                resumable: true,
                contentType: "application/pdf",
                // customPrefix: {
                //     public:'private/'
                // }
                completeCallback: () => {
                    flashNotificationsModule.success({
                        message: `Successfully uploaded`,
                        duration: 5000,
                    });
                    this.fileUploaded = true;
                    this.isLoading = false;
                    this.$emit("uploaded", fileName);
                },
                errorCallback: (err) => {
                    this.fileUploaded = false;
                    this.isLoading = false;
                    flashNotificationsModule.error({
                        message: `Unexpected error while uploading ${err}`,
                        duration: 10000,
                    });
                },
            });
        } catch (error) {
            console.log(error);
            this.isLoading = false;
            flashNotificationsModule.error({
                message: `Unexpected error while uploading ${error}`,
                duration: 10000,
            });
        }
    }

    @Watch("clearForm")
    private onFactorsCreated() {
        this.file = null;
    }
}

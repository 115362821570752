
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Workspace, Decision, App } from "@/graphql/API";
import Interface from "@/layouts/Interface.vue";
import Workspaces from "@/store/modules/Workspaces";
import Decisions from "@/store/modules/Decisions";
import Apps from "@/store/modules/Apps";
import Ui from "@/store/modules/Ui";
import WorkspacesView from "@/views/WorkspacesView.vue";
import DecisionsView from "@/views/DecisionsView.vue";
import AppListView from "@/views/AppListView.vue";
import TabsView from "@/views/TabsView.vue";
import AccountView from "@/views/AccountView.vue";

const workspacesModule = getModule(Workspaces);
const decisionsModule = getModule(Decisions);
const appsModule = getModule(Apps);
const uiModule = getModule(Ui);

@Component({
    components: {
        Interface,
        WorkspacesView,
        DecisionsView,
        AppListView,
        TabsView,
        AccountView,
    },
})
export default class HomeView extends Vue {
    @Prop(String)
    queryWId!: string;

    @Prop(String)
    queryFId!: string;

    @Prop({ default: false, type: Boolean })
    querySettings!: boolean;

    @Prop({ type: Boolean, default: false })
    loggedIn!: boolean;

    private loading = false;

    private keepAlive = ["ParentRouteKeep"];

    get selectedWorkspaceId(): number | null {
        return workspacesModule.selectedWorkspaceId;
    }

    get selectedWorkspace(): Workspace | null {
        return workspacesModule.selectedWorkspace;
    }

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get selectedDecision(): Decision | null {
        return decisionsModule.selectedDecision;
    }

    get selectedApp(): App | null {
        return appsModule.selectedApp;
    }

    get requiresDecision(): boolean {
        if (this.$route && this.$route.meta && this.$route.meta.requireModel) {
            return true;
        } else {
            return false;
        }
    }

    get isHome(): boolean {
        return this.$route.name == "Home";
    }

    get isMyAccount(): boolean {
        return this.$route.name == "AccountView";
    }

    get showRouter(): boolean {
        if (this.selectedDecisionId) {
            return true;
        } else {
            return !this.requiresDecision;
        }
    }

    get routeKey(): string {
        return `${this.$route.path}-${
            this.selectedDecisionId ? this.selectedDecisionId : "null"
        }`;
    }

    get metaTitle(): string | null {
        if (this.$route.meta) {
            if (typeof this.$route.meta.title == "function") {
                return this.$route.meta.title();
            } else {
                return this.$route.meta.title;
            }
        } else {
            return null;
        }
    }

    get pageTitle(): string {
        let title = process.env.VUE_APP_TITLE
            ? process.env.VUE_APP_TITLE
            : "Viewpoint AI";
        if (this.metaTitle && this.selectedDecisionId) {
            return `${this.metaTitle} | ${title}`;
        } else {
            return title;
        }
    }

    get breadCrumb(): string | null {
        if (this.isHome) {
            if (this.selectedWorkspace) {
                if (this.selectedDecision) {
                    if (this.selectedApp) {
                        return "Tabs";
                    } else {
                        return "Apps";
                    }
                } else {
                    return "Frameworks";
                }
            } else {
                return "Workspaces";
            }
        } else {
            return null;
        }
    }

    mounted(): void {
        if (this.loggedIn) {
            this.onPageTitleChange(this.pageTitle);
            this.loadParams();
            if (this.isHome) {
                uiModule.changeBreadCrumb(this.breadCrumb);
            } else {
                uiModule.changeBreadCrumb(null);
            }
        }
    }

    private async loadParams(): Promise<void> {
        this.loading = true;
        if (this.queryWId) {
            await workspacesModule.selectWorkspace(parseInt(this.queryWId));

            if (this.queryFId) {
                await decisionsModule.selectDecision(parseInt(this.queryFId));
            }
            if (!(this.$route.meta && this.$route.meta.retainQuery)) {
                if (!this.querySettings) {
                    this.$router.replace({ query: {} });
                }
            }
        }
        this.loading = false;
    }

    @Watch("breadCrumb")
    onBreadCrumbChange(val: string | null): void {
        uiModule.changeBreadCrumb(val);
    }

    @Watch("pageTitle")
    onPageTitleChange(val: string): void {
        document.title = val;
    }
}

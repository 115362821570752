
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Viewpoint, Choice, ChartValues, ViewpointMapping, Factor } from "@/graphql/API";
import BarChart from "@/components/charts/BarChart.vue";
import StackedChart from "@/components/charts/StackedChart.vue";
import FactorScore from "@/components/scoring/FactorScore.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import Scoring from "@/store/modules/Scoring";
import WorkspaceOptions from "@/store/modules/WorkspaceOptions";
import Viewpoints from "@/store/modules/Viewpoints";

const workspaceOptions = getModule(WorkspaceOptions);
const scoringModule = getModule(Scoring);
const viewpointModule = getModule(Viewpoints);

@Component({
    components: {
        BarChart,
        VpDialog,
        StackedChart,
        FactorScore
    },
})
export default class ChartDialog extends Vue {
    @Prop()
    chartDataValue!: ChartValues;

    @Prop()
    choices!: Choice[];

    @Prop()
    viewpoints!: Viewpoint[];

    @Prop()
    selectedViewpoint!: Viewpoint;

    @Prop({ default: () => ({}), type: Array })
    factors!: Factor[];

    get isBarChartHorizontal(): boolean {
        return workspaceOptions.isBarChartHorizontal;
    }

    set isBarChartHorizontal(isHorizontal: boolean) {
        workspaceOptions.setIsBarChartHorizontal(isHorizontal);
    }

    get viewpointMappings(): {
        // [viewpointId: number]: {
        //     [factorId: number]: ViewpointMapping;
            
        // };
    } {
        return getModule(Viewpoints).viewpointMappings;
    }

    get newBarChartData() {
        if (this.selectedViewpoint) {
            return {
                data: {
                    series: this.factors.map((factor) => {
                        return {
                            id: factor.id.toString(),
                            name: factor.name,
                            data: this.choices.map((choice) => {
                                if (
                                    viewpointModule.viewpointMappings &&
                                    viewpointModule.viewpointMappings[this.selectedViewpoint.id] &&
                                    viewpointModule.viewpointMappings[this.selectedViewpoint.id][factor.id] &&
                                    scoringModule.keyedScores[`${this.selectedViewpoint.id}-${choice.id}-${factor.id}`]
                                ) {
                                    if (viewpointModule.viewpointMappings[this.selectedViewpoint.id][factor.id].use_m_score) {
                                        // Use Manual Score
                                        if (scoringModule.keyedScores[`${this.selectedViewpoint.id}-${choice.id}-${factor.id}`].m_score) {
                                            return scoringModule.keyedScores[`${this.selectedViewpoint.id}-${choice.id}-${factor.id}`].m_score;
                                        } else {
                                            return 0;
                                        }
                                    } else {
                                        // Use Calc Score
                                        if (scoringModule.keyedScores[`${this.selectedViewpoint.id}-${choice.id}-${factor.id}`].c_score) {
                                            return scoringModule.keyedScores[`${this.selectedViewpoint.id}-${choice.id}-${factor.id}`].c_score;
                                        } else {
                                            return 0;
                                        }
                                    }
                                } else {
                                    // No Viewpoint Mapping return 0
                                    return 0;
                                }
                            }),
                        };
                    }),
                },
                title: "Viewpoint",
                xaxis: {
                    categories: this.choices.map((choice) => choice.name),
                },
            };
        } else {
            return {};
        }
    }
}


import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Decisions from "@/store/modules/Decisions";
import { columnOptions } from "@/Options";
import { Pagination } from "@/graphql/API";

const decisionsModule = getModule(Decisions);

@Component({
    components: {},
})
export default class CompareSettings extends Vue {
    @Prop({ default: "tab-settings", type: String })
    mode!: string;

    @Prop()
    data!: string;

    @Prop()
    rows!: string;

    @Prop()
    columns!: string;

    @PropSync("mainWidth", Number)
    syncedMainWidth!: number;

    @PropSync("dataWidth", Number)
    syncedDataWidth!: number;

    @PropSync("paddingX", Number)
    syncedPaddingX!: number;

    @PropSync("paddingY", Number)
    syncedPaddingY!: number;

    @PropSync("borderX", Boolean)
    syncedBorderX!: boolean;

    @PropSync("borderY", Boolean)
    syncedBorderY!: boolean;

    @PropSync("pagination")
    syncedPagination!: Pagination;

    @PropSync("axisLock", Boolean)
    syncedAxisLock!: boolean;

    @PropSync("highlight", Boolean)
    syncedHighlight!: boolean;

    @PropSync("fullColumn", Boolean)
    syncedFullColumn!: boolean;

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop({ default: false, type: Boolean })
    rankView!: boolean;

    private rowSelect = "";
    private columnSelect = "";
    private dataSelect = "";

    private readonly columnWidths = columnOptions;

    private paddingOptions = [
        {
            label: "0",
            value: 0,
        },
        {
            label: "1",
            value: 1,
        },
        {
            label: "2",
            value: 2,
        },
        {
            label: "3",
            value: 3,
        },
        {
            label: "4",
            value: 4,
        },
        {
            label: "5",
            value: 5,
        },
        {
            label: "6",
            value: 6,
        },
    ];

    get axisOptions(): { [id: string]: { [id: string]: string } } {
        return {
            factors: {
                name: "Factors",
                value: "factors",
                singular: "Factor",
            },
            choices: {
                name: decisionsModule.choiceLabelPlural,
                value: "choices",
                singular: decisionsModule.choiceLabelSingular,
            },
            viewpoints: {
                name: "Viewpoints",
                value: "viewpoints",
                singular: "Viewpoint",
            },
        };
    }

    get dropdownOptions() {
        return Object.values(this.axisOptions);
    }

    get updateBtnData(): boolean {
        return (this.dataSelect != this.data ||
            this.rowSelect != this.rows ||
            this.columnSelect != this.columns) &&
            this.dataSelect != this.rowSelect &&
            this.dataSelect != this.columnSelect &&
            this.columnSelect != this.rowSelect;
    }

    private updateAxis(): void {
        this.$emit(
            "axis-changed",
            this.rowSelect,
            this.columnSelect,
            this.dataSelect
        );
    }

    private changeValue(type: string, value: string) {
        if (type == "columns") {
            Vue.set(this.syncedPagination.columns, "items", parseInt(value));
        }
    }

    private updateMainWidth(value: number): void {
        this.$emit("column-width-change", "main", value);
    }

    private updateDataWidth(value: number): void {
        this.$emit("column-width-change", "data", value);
    }

    mounted(): void {
        this.onDataChange(this.data);
        this.onRowsChange(this.rows);
        this.onColumnsChange(this.columns);
    }

    @Watch("data")
    onDataChange(val: string): void {
        this.dataSelect = val;
    }

    @Watch("rows")
    onRowsChange(val: string): void {
        this.rowSelect = val;
    }

    @Watch("columns")
    onColumnsChange(val: string): void {
        this.columnSelect = val;
    }
}

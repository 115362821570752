
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { Factor } from "@/graphql/API";
import AiFactor from "@/components/model/Ai/AiFactor.vue";

@Component({
    name: "RecursiveAiFactors",
    components: {
        AiFactor,
    },
})
export default class RecursiveAiFactors extends Vue {
    @Prop({ default: () => ({}), type: Object })
    factorTree!: { [id: number]: Factor[] };

    @Prop({ default: -1, type: Number })
    rootId!: number;

    @Prop({ default: () => [], type: Array })
    selected!: number[];

    @Prop({ default: 0, type: Number })
    depth!: number;

    get factorList(): Factor[] {
        if (this.factorTree && this.factorTree[this.rootId]) {
            return this.factorTree[this.rootId];
        } else {
            return [];
        }
    }

    private sendToggledFactor(factor: Factor): void {
        this.$emit("toggle-factor", factor);
    }
}

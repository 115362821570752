
import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor } from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import FactorNav from "@/components/ui/FactorNav.vue";

const modelModule = getModule(Factors);

@Component({
    components: {
        FactorNav,
    },
})
export default class FactorSelector extends Vue {
    @Prop({ default: false, type: Boolean })
    singleSelect!: boolean;

    @PropSync("selectedFactor")
    syncedSelectedFactor!: number | null;

    @Prop()
    lockedFactor!: number | null;

    @PropSync("selected")
    syncedSelected!: Factor[];

    get factorTree(): Factor[] {
        if (modelModule.factorTree) {
            return modelModule.factorTree[-1];
        } else {
            return [];
        }
    }
}

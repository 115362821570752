
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, Value } from "@/graphql/API";
import { createUpdateChoiceValue } from "@/helpers/ChoiceHelper";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import ChoiceTableRow from "@/components/choices/editor/ChoiceTableRow.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import draggable from "vuedraggable";

const modelModule = getModule(Factors);
const choiceModule = getModule(Choices);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mudder = require("mudder");

@Component({
    components: {
        ChoiceTableRow,
        draggable,
        VpDialog,
    },
})
export default class ChoiceTable extends Vue {
    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop()
    choiceId!: number;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    private factorsLoading = false;
    private choiceLoading = false;

    private dragList: Value[] = [];

    private reOrderEnabled = true;
    private drag = false;
    private rowsLoading = false;

    private deleteOpen = false;
    private deleteIndex: null | number = null;
    private deleteRowId: null | string = null;

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get valueMap(): { [id: string]: Value } {
        if (this.choiceId) {
            return choiceModule.choiceValues[this.choiceId];
        } else {
            return {};
        }
    }

    get currentTreeLevel(): Factor[] {
        if (this.factorTree[this.parentId]) {
            return this.factorTree[this.parentId];
        } else return [];
    }

    /* Table Computed Properties */
    get headers(): Factor[] {
        return this.currentTreeLevel;
    }

    get valueKeys(): string[] {
        if (this.valueMap) {
            return Object.keys(this.valueMap);
        } else {
            return [];
        }
    }

    get tableKeys(): string[][] {
        if (this.headers) {
            return this.headers.map((factor) => {
                return this.valueKeys.filter((item) =>
                    item.startsWith(`${factor.id}-`)
                );
            });
        } else {
            return [];
        }
    }

    get columnLengths(): number[] {
        if (this.tableKeys) {
            return this.tableKeys.map((column) => column.length);
        } else {
            return [];
        }
    }

    get longestIndex(): number {
        if (this.columnLengths && this.columnLengths.length) {
            return this.columnLengths.indexOf(Math.max(...this.columnLengths));
        } else {
            return 0;
        }
    }

    get mainColumn(): Value[] {
        if (this.tableKeys && this.tableKeys.length) {
            return this.tableKeys[0]
                .map((key) => this.valueMap[key])
                .sort((a, b) => {
                    return a.row_id && b.row_id
                        ? a.row_id.localeCompare(b.row_id)
                        : 1;
                });
        } else {
            return [];
        }
    }

    get nextRowId(): string {
        if (
            this.mainColumn &&
            this.mainColumn.length &&
            this.mainColumn[this.mainColumn.length - 1].row_id
        ) {
            return mudder.base62.mudder(
                this.mainColumn[this.mainColumn.length - 1].row_id,
                "",
                1,
                undefined,
                20
            )[0];
        } else {
            return mudder.base62.mudder("0", undefined, 1, undefined, 20)[0];
        }
    }

    /* End of Table Computed Properties */

    private async addRow(): Promise<void> {
        this.rowsLoading = true;
        await createUpdateChoiceValue({
            choice_id: this.choiceId,
            factor_id: this.headers[this.longestIndex].id,
            row_id: this.nextRowId,
            value: "",
        });
        this.rowsLoading = false;
    }

    /* Drag Change Functions */
    private async reOrderRow(
        rowId: string,
        oldIndex: number,
        newIndex: number
    ): Promise<void> {
        let beforeIndex = null;
        let afterIndex = null;

        if (newIndex == 0) {
            // Item has moved to start of list
            beforeIndex = "0";
            afterIndex = this.mainColumn[1]?.row_id;
        } else if (newIndex == this.mainColumn.length - 1) {
            // Item has moved to end of list
            beforeIndex = this.mainColumn[newIndex]?.row_id;
            afterIndex = null;
        } else if (newIndex > oldIndex) {
            // Item has moved down list
            beforeIndex = this.mainColumn[newIndex]?.row_id;
            afterIndex = this.mainColumn[newIndex + 1]?.row_id;
        } else {
            // Item has moved up list
            beforeIndex = this.mainColumn[newIndex - 1]?.row_id;
            afterIndex = this.mainColumn[newIndex]?.row_id;
        }

        const newRowId = mudder.base62.mudder(
            beforeIndex,
            afterIndex,
            1,
            undefined,
            20
        )[0];

        if (this.parentId && newRowId) {
            await choiceModule.moveRowL({
                table_id: this.parentId,
                old_row_id: rowId,
                new_row_id: newRowId,
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private dragChange(e: any) {
        this.reOrderRow(
            e.moved.element.row_id,
            e.moved.oldIndex,
            e.moved.newIndex
        );
    }
    /* End of Drag Change Functions */

    /* Opens the delete dialog */
    private deleteDialog(item: { id: string; index: number }): void {
        if (item && item.id && item.index) {
            this.deleteOpen = true;
            this.deleteRowId = item.id;
            this.deleteIndex = item.index;
        }
    }

    private async deleteRow(rowId: string): Promise<void> {
        this.rowsLoading = true;
        await Promise.all(
            this.headers.map(async (factor) => {
                if (this.valueMap[`${factor.id}-${rowId}`]) {
                    await choiceModule.deleteValueL({
                        choice_id: this.choiceId,
                        factor_id: factor.id,
                        row_id: rowId,
                    });
                }
            })
        );

        this.rowsLoading = false;
    }

    private async loadFactors(): Promise<void> {
        if (
            (this.currentTreeLevel && this.currentTreeLevel.length == 0) ||
            !this.currentTreeLevel
        ) {
            this.factorsLoading = true;
            await modelModule.fetchChildFactors(this.parentId);
            this.factorsLoading = false;
        }
    }

    private async loadChoiceValues(): Promise<void> {
        if (this.choiceId && this.parentId && this.parentId != -1) {
            this.choiceLoading = true;
            await choiceModule.getChoiceValues({
                choice_id: this.choiceId,
                parent_id: this.parentId,
            });
            this.choiceLoading = false;
        }
    }


    mounted(): void {
        this.onParentIdChange();
    }

    @Watch("choiceId")
    async onChoiceChange(): Promise<void> {
        this.loadChoiceValues();
    }

    @Watch("parentId")
    async onParentIdChange(): Promise<void> {
        this.loadFactors();
        this.loadChoiceValues();
    }

    @Watch("mainColumn", { immediate: true, deep: true })
    onMainColumnChange(): void {
        this.dragList = [...this.mainColumn];
    }
}


import { Vue, Component, Prop } from "vue-property-decorator";
import Logo from "@/components/ui/Logo.vue";
import { Auth } from "aws-amplify";
import PasswordInput from "@/components/ui/PasswordInput.vue";

@Component({
    components: { PasswordInput },
})
export default class SignupForm extends Vue {
    private email = "";
    private password = "";
    private confirmPassword = "";
    private errorMessage = "";
    private isFormValid = false;
    private first = "";
    private last = "";
    private redeemVerification = false;
    private user: any = null;
    private confirmationCode = "";
    private loading = false;
    private signupLoading = false;

    private updatePassword(value: string): void {
        this.password = value;
    }

    private updateConfirmPassword(value: string): void {
        this.confirmPassword = value;
    }

    //Does signup call
    private async signup(): Promise<void> {
        this.signupLoading = true;
        try {
            this.user = await Auth.signUp({
                username: this.email,
                password: this.password,
                attributes: {
                    given_name: this.first, // optional
                    family_name: this.last, // optional - E.164 number convention
                    // other custom attributes
                },
                autoSignIn: {
                    // optional - enables auto sign in after user is confirmed
                    enabled: true,
                },
            });
            this.redeemVerification = true;
        } catch (error: any) {
            console.log("error signing up:", error);
            this.setErrorMsg(error.message);
        }
        this.signupLoading = false;
    }

    //Send confirmation code to complete signup
    private async confirmSignup(): Promise<void> {
        this.$emit("loading-start");
        this.loading = true;
        try {
            await Auth.confirmSignUp(
                this.user.user.username,
                this.confirmationCode
            );
        } catch (error: any) {
            console.log("error confirming sign up", error);
            this.setErrorMsg(error.message);
        }
        this.loading = false;
        this.$emit("loading-end");
    }

    private setErrorMsg(error: any): void {
        this.errorMessage = error;
        setTimeout(() => {
            this.errorMessage = "";
        }, 3000);
    }
}


import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ViewpointMapping } from "@/graphql/API";
import FlashNotifications from "@/store/modules/FlashNotifications";

import Factors from "@/store/modules/Factors";

const modelModule = getModule(Factors);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({})
export default class WeightCounter extends Vue {
    @Prop({ default: () => null, type: Object })
    viewpointMapping!: ViewpointMapping;

    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    @Prop({ default: false, type: Boolean })
    isDialog!: boolean;

    private internalWeight = 0;
    private timeout: any = null;

    mounted(): void {
        this.onChangeViewpointMapping();
    }

    get weight(): number {
        if (this.viewpointMapping) {
            return this.viewpointMapping.weight ?? 0;
        }

        return 1;
    }

    private async loadChildren(factorId: number) {
        if (factorId != null) {
            await modelModule.fetchChildFactors(factorId);
        }
    }

    @Watch("viewpointMapping", { deep: true })
    private onChangeViewpointMapping() {
        if (this.viewpointMapping)
            this.internalWeight = this.viewpointMapping.weight ?? 0;
    }

    private weightChanged(value: number) {
        if (value != this.weight && !this.isDialog)
            this.$emit("weight-change", {
                mapping: this.viewpointMapping,
                weight: value,
            });
    }

    private saveWeight() {
        if (this.internalWeight != this.weight && this.internalWeight >= 0) {
            this.$emit("weight-change", {
                mapping: this.viewpointMapping,
                weight: this.internalWeight,
            });
        } else if (
            this.internalWeight != this.weight &&
            this.internalWeight < 0
        ) {
            flashNotificationsModule.warning({
                message: "Sorry, the value can't be less than 0",
                duration: 5000,
            });
        }
    }

    private close() {
        this.$emit("cancel");
    }

    private incWeight() {
        this.internalWeight++;
        if (!this.isDialog) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$emit("weight-change", {
                    mapping: this.viewpointMapping,
                    weight: this.internalWeight,
                });
            }, 3000);
        }
    }

    private decWeight() {
        if (this.internalWeight === 0) {
            return;
        } else {
            this.internalWeight--;
            if (!this.isDialog) {
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.$emit("weight-change", {
                        mapping: this.viewpointMapping,
                        weight: this.internalWeight,
                    });
                }, 3000);
            }
        }
    }
}

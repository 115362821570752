
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ColourPicker from "@/components/values/ColourPicker.vue";
import ColourPaletteSelector from "@/components/values/ColourPaletteSelector.vue";

@Component({
    components: {
        ColourPicker,
        ColourPaletteSelector,
    },
})
export default class IconPicker extends Vue {
    @Prop({ default: "Icon Code", type: String })
    placeholder!: string;

    @Prop({ default: false, type: Boolean })
    selectColour!: boolean;

    @Prop({ default: false, type: Boolean })
    attach!: boolean;

    private iconCode = "";
    private iconPreview = "";
    private iconColour = "";

    private updateColour(val: string): void {
        this.iconColour = val;
    }

    private saveIcon(): void {
        this.$emit("new-icon-selected", this.iconPreview, this.iconColour);
    }

    @Watch("iconCode")
    onIconCodeChange(val: string): void {
        if (!val.startsWith("mdi-")) {
            this.iconPreview = "mdi-" + val.toLowerCase();
        } else {
            this.iconPreview = val.toLowerCase();
        }
    }
}

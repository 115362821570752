
import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import { Factor } from "@/graphql/API";

const modelModule = getModule(Factors);

@Component({
    name: "FactorToggle",
    components: {},
})
export default class FactorToggle extends Vue {
    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop({ default: false, type: Boolean })
    openAll!: boolean;

    @Prop({ default: false, type: Boolean })
    parentSelected!: boolean;

    @Prop({ default: false, type: Boolean })
    isLast!: boolean;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop({ default: false, type: Boolean })
    eyeIcon!: boolean;

    @Prop({ default: true, type: Boolean })
    multiSelect!: boolean;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: true, type: Boolean })
    groupSelect!: boolean;

    @PropSync("selected")
    syncedSelected!: number[];

    @Prop({ default: () => [], type: Array })
    lockedFactors!: number[];

    private parentToggle = false;
    private loading = false;
    private allOpen = true;
    private isOpen: number[] = [0];

    get isRoot(): boolean {
        return this.parentId == -1;
    }

    get factors(): Factor[] {
        return modelModule.factors;
    }

    get factorMap(): { [id: number]: Factor } {
        return modelModule.factorMap;
    }

    get parentFactor(): Factor | null {
        if (this.parentId != -1) {
            return this.factorMap[this.parentId];
        } else {
            return null;
        }
    }

    get rootSelected(): boolean {
        return (
            this.parentFactor != null &&
            this.syncedSelected.includes(this.parentFactor.id)
        );
    }

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get currentTreeLevel(): Factor[] {
        if (this.factorTree[this.parentId]) {
            return this.factorTree[this.parentId];
        } else return [];
    }

    private generateToggles(): { [id: number]: boolean } {
        return this.currentTreeLevel.reduce((a, v) => {
            return {
                ...a,
                [v.id]: this.syncedSelected.includes(v.id),
            };
        }, {});
    }

    private toggleChildren(factor: Factor, val: boolean) {
        this.toggleFactor(factor, val);
        if (this.groupSelect) {
            if (this.factorTree[factor.id]) {
                this.factorTree[factor.id].forEach((item) => {
                    this.toggleChildren(item, val);
                });
            }
        }
    }

    private toggleFactor(factor: Factor, val: boolean) {
        if (val) {
            if (!this.syncedSelected.includes(factor.id)) {
                if (this.multiSelect) {
                    this.syncedSelected.push(factor.id);
                } else {
                    this.syncedSelected = [factor.id];
                }
            }
        } else {
            if (this.syncedSelected.includes(factor.id)) {
                if (this.multiSelect) {
                    this.syncedSelected.splice(
                        this.syncedSelected.indexOf(factor.id),
                        1
                    );
                } else {
                    this.syncedSelected = [];
                }
            }
        }
        if (factor.parent_id && !val && this.groupSelect) {
            this.toggleFactor(this.factorMap[factor.parent_id], val);
        }
    }

    async mounted(): Promise<void> {
        this.onParentIdChange();
        this.onOpenAllChange(true);
    }

    @Watch("parentId")
    async onParentIdChange(): Promise<void> {
        if (
            !this.factorTree[this.parentId] ||
            this.factorTree[this.parentId].length == 0
        ) {
            this.loading = true;
            await modelModule.fetchChildFactors(this.parentId);
            this.loading = false;
        }
    }

    @Watch("allOpen")
    onAllOpenChange(): void {
        this.onOpenAllChange(this.allOpen);
    }

    @Watch("openAll")
    onOpenAllChange(val: boolean): void {
        this.isOpen = val ? [0] : [];
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Factor, Viewpoint, Choice, ChartValues} from "@/graphql/API";
import BarChart from "@/components/charts/BarChart.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import ChartMain from "@/components/charts/ChartMain.vue";
import ChartMainFc from "@/components/charts/ChartMainFc.vue";
import ChartMainVp from "@/components/charts/ChartMainVp.vue";
import ChartFcs from "@/components/charts/ChartFcs.vue";
import ChartFv from "@/components/charts/ChartFv.vue";
import ChartVps from "@/components/charts/ChartVps.vue";
import ChartCs from "@/components/charts/ChartCs.vue";
import ChartVCs from "@/components/charts/ChartVCs.vue";

@Component({
    components: {
        BarChart,
        VpDialog,
        ChartMain,
        ChartMainFc,
        ChartMainVp,
        ChartFcs,
        ChartFv,
        ChartVps,
        ChartCs,
        ChartVCs
    },
})
export default class ChartDialog extends Vue {
    @Prop()
    selectedFactor!: Factor;

    @Prop()
    selectedChoice!: Choice;

    @Prop()
    selectedViewpoint!: Viewpoint;

    @Prop({ default: () => ({}), type: Array })
    choices!: Choice[];

    @Prop({ default: () => ({}), type: Array })
    viewpoints!: Viewpoint[];

    @Prop({ default: () => ({}), type: Array })
    factors!: Factor[];

    @Prop({ default: "", type: String })
    chartId!: string;

    @Prop({ type: Boolean, default: false })
    horizontal!: boolean;

    private modalOpen = false;

    /* Returns a string of Ids for selected items */
    get propIds(): string {
        return `${this.selectedFactor ? this.selectedFactor.id : "null"}-${
            this.selectedChoice ? this.selectedChoice.id : "null"
        }-${this.selectedViewpoint ? this.selectedViewpoint.id : "null"}`;
    }

    get title(): string {
        if (this.chartId) {
            if (this.chartId == "main") {
                return `Factor values for ${
                    this.selectedChoice ? this.selectedChoice.name : "No Choice"
                }`;

            
            } else if (this.chartId == "Vps") {
                return `Factor values for ${
                    this.selectedChoice ? this.selectedChoice.name : "No Choice"
                } and ${
                    this.selectedViewpoint
                        ? this.selectedViewpoint.name
                        : "No Viewpoint"
                }`;
            } else if (this.chartId == "Fcs") {
                return `Viewpoint values for ${
                    this.selectedChoice ? this.selectedChoice.name : "No Choice"
                } and ${
                    this.selectedFactor
                        ? this.selectedFactor.name
                        : "No Viewpoint"
                }`;
            } else if (this.chartId == "Fv") {
                return `Choice values for ${
                    this.selectedViewpoint ? this.selectedViewpoint.name : "No Viewpoint"
                } and ${
                    this.selectedFactor
                        ? this.selectedFactor.name
                        : "No Factor"
                }`;
            }else if (this.chartId == "mainFc") {
                return `Viewpoint and Choice values for ${
                    this.selectedFactor ? this.selectedFactor.name : "No Factor"
                }`;

            
            } else if (this.chartId == "mainVp") {
                return `Viewpoint values for ${
                    this.selectedViewpoint ? this.selectedViewpoint.name : "No Viewpoint"
                }`;

            
            } else if (this.chartId == "Cs") {
               return `Factor values for ${
                    this.selectedViewpoint ? this.selectedViewpoint.name : "No Viewpoint"
                } and ${
                    this.selectedChoice
                        ? this.selectedChoice.name
                        : "No Choice"
                }`;
            }else if (this.chartId == "VCs") {
                return `Choice values for ${
                    this.selectedViewpoint ? this.selectedViewpoint.name : "No Viewpoint"
                } and ${
                    this.selectedFactor
                        ? this.selectedFactor.name
                        : "No Factor"
                }`;
            }
        }
        return "Chart";
    }

    get chartDataValue(): ChartValues {
        return {
            categories_choice: this.choices.map((choice) => choice.name),
            categories_viewpoint: this.viewpoints.map((viewpoint) => viewpoint.name),
            f_ids: this.factors.map((factor) => factor.id.toString()),
            categories_factors: this.factors.map((factor) => factor.name),
            vp_ids: this.viewpoints.map((viewpoint) => viewpoint.id.toString()),
            c_ids: this.choices.map((choice) => choice.id.toString()),
        };
    }

    private closeDialog(): void {
        this.modalOpen = false;
        this.$emit("dialog-closed");
    }

    @Watch("propIds")
    onPropIdsChange(): void {
        if (this.propIds != `null-null-null`) {
            this.modalOpen = true;
        }
    }
}

import { render, staticRenderFns } from "./WorkspaceSelector.vue?vue&type=template&id=0dc24422&scoped=true&"
import script from "./WorkspaceSelector.vue?vue&type=script&lang=ts&"
export * from "./WorkspaceSelector.vue?vue&type=script&lang=ts&"
import style0 from "./WorkspaceSelector.vue?vue&type=style&index=0&id=0dc24422&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dc24422",
  null
  
)

export default component.exports
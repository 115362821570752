
import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { TableAction } from "@/graphql/API";

@Component({
    components: {},
})
export default class TableManagerTools extends Vue {
    @Prop({ default: () => [], type: Array })
    tools!: TableAction[];

    @Prop({ default: () => [], type: Array })
    actions!: TableAction[];

    @PropSync("search")
    syncedSearch!: string;

    @Prop({ default: false, type: Boolean })
    showSearch!: boolean;

    @Prop({ default: false, type: Boolean })
    bubbleEvent!: boolean;

    @Prop({ default: 0, type: Number })
    numberOfSelected!: number;

    @Prop({ default: null, type: [Number, String] })
    selectedId!: number | string;

    @PropSync("checkBoxes")
    syncedCheckBoxes!: boolean;

    private btnClick(id: string): void {
        if (this.bubbleEvent) {
            this.$emit("tool-click", id);
        } else {
            this.$emit(id);
        }
    }
}

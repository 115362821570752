
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import ColourPalette from "@/components/mixins/ColourPalette";
import {
    ScoreClass,
    ScoreOptions,
    ViewpointMapping,
    ScoreClassSettings,
    Rule,
} from "@/graphql/API";
import Scoring from "@/store/modules/Scoring";
import FactorClassScore from "@/components/scoring/FactorClassScore.vue";

const scoringModule = getModule(Scoring);

@Component({
    components: {
        FactorClassScore,
    },
})
export default class FactorClassSelector extends mixins(ColourPalette) {
    @Prop({ default: -1, type: Number })
    viewpointId!: number;

    @Prop({ default: -1, type: Number })
    factorId!: number;

    @Prop()
    scoreClass!: ScoreClass;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    viewpointMap!: ViewpointMapping | null;

    @Prop({ default: false, type: Boolean })
    showClassSelector!: boolean;

    @Prop({ default: true, type: Boolean })
    showClassDescription!: boolean;

    @Prop({ default: false, type: Boolean })
    disableSelector!: boolean;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    private ruleLoading = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get scoreClassStyle(): any {
        if (this.scoreClass) {
            return JSON.parse(this.scoreClass.json);
        } else {
            return {};
        }
    }

    get manualScore(): boolean {
        if (this.viewpointMap && this.viewpointMap.use_m_score != undefined) {
            return this.viewpointMap.use_m_score;
        }
        return true;
    }

    get indexRuleId(): number | null {
        if (
            this.manualScore &&
            this.viewpointMap &&
            this.viewpointMap.index_rule_id
        ) {
            return this.viewpointMap.index_rule_id;
        } else {
            return null;
        }
    }

    get scoreRuleId(): number | null {
        if (
            this.manualScore &&
            this.viewpointMap &&
            this.viewpointMap.score_rule_id
        ) {
            return this.viewpointMap.score_rule_id;
        } else {
            return null;
        }
    }

    get scoreRule(): Rule | null {
        if (this.scoreRuleId && scoringModule.scoreRules[this.scoreRuleId]) {
            return scoringModule.scoreRules[this.scoreRuleId];
        } else {
            return null;
        }
    }

    get indexRule(): Rule | null {
        if (this.indexRuleId) {
            return scoringModule.scoreRules[this.indexRuleId];
        } else {
            return null;
        }
    }

    get showDescription(): boolean {
        if (
            this.showClassDescription &&
            this.scoreClass &&
            this.scoreClass.description &&
            this.scoreClass.description.length > 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    get nameColor(): string {
        if (this.scoreClassStyle && this.scoreClassStyle.name_colour) {
            return this.palette[this.scoreClassStyle.name_colour]
                ? `color: ${this.palette[this.scoreClassStyle.name_colour].hex}`
                : "";
        } else {
            return "";
        }
    }

    get descColor(): string {
        if (this.scoreClassStyle && this.scoreClassStyle.desc_colour) {
            return this.palette[this.scoreClassStyle.desc_colour]
                ? `color: ${this.palette[this.scoreClassStyle.desc_colour].hex}`
                : "";
        } else {
            return "";
        }
    }

    /* Redesign */
    get selectorVisible(): boolean {
        if (
            this.manualScore &&
            this.indexRule &&
            this.indexRule.index_uses_score &&
            this.showClassSelector &&
            !this.disableSelector
        ) {
            return true;
        } else {
            return false;
        }
    }

    /* End of Redesign */

    mounted(): void {
        this.onIndexRuleChange();
        this.onScoreRuleChange();
    }

    @Watch("indexRuleId")
    async onIndexRuleChange(): Promise<void> {
        if (this.indexRuleId) {
            if (!scoringModule.scoreRules[this.indexRuleId]) {
                this.ruleLoading = true;
                await scoringModule.getRule(this.indexRuleId);
                this.ruleLoading = false;
            }
        }
    }

    @Watch("scoreRuleId")
    async onScoreRuleChange(): Promise<void> {
        if (this.scoreRuleId) {
            if (!scoringModule.scoreRules[this.scoreRuleId]) {
                await scoringModule.getRule(this.scoreRuleId);
            }
        }
    }
}

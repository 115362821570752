
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DecisionLabels } from "@/graphql/API";
@Component({
    components: {},
})
export default class DecisionLabelInputs extends Vue {
    @Prop({
        default: () => {
            return {};
        },
        type: Object,
    })
    labels!: DecisionLabels;
    
    /*
    private blankLabels: DecisionLabels = {
        single: null,
        plural: null,
    }

    get labels(): { [id: string]: DecisionLabels } {
        if (this.options?.labels) {
            return this.options.labels;
        } else {
            return {};
        }
    }

    get choiceLabels(): DecisionLabels {
        if (this.labels?.choices) {
            return this.labels.choices;
        } else {
            return this.blankLabels;
        }
    }
    */

    private changeVal(id: string, val: string): void {
        this.$emit("label-change", {
            id: id,
            val: val,
        });
    }
}

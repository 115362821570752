
import { Component, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ColourPalette from "@/components/mixins/ColourPalette";
import { ScoreClassSettings } from "@/graphql/API";
import IconDisplay from "@/components/values/IconDisplay.vue";

@Component({
    components: {
        IconDisplay,
    },
})
export default class ClassSelection extends mixins(ColourPalette) {
    @Prop()
    item!: {
        index: number;
        score: number;
        class_id: number;
        json: string;
        name: string;
    };

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: true, type: Boolean })
    showColour!: boolean;

    @Prop({ default: true, type: Boolean })
    showIcons!: boolean;

    get iconSpacing(): boolean {
        return (
            this.selectorSettings &&
            this.selectorSettings.icons &&
            !this.selectorSettings.label
        );
    }

    get onlyColour(): boolean {
        return (
            this.selectorSettings.colour &&
            !this.selectorSettings.icons &&
            !this.selectorSettings.label
        );
    }

    get bgColour(): string | null {
        if (this.item && this.item.json) {
            try {
                let styles = JSON.parse(this.item.json);
                if (styles["bg_colour"]) {
                    return styles["bg_colour"];
                } else {
                    return null;
                }
            } catch {
                return null;
            }
        } else {
            return null;
        }
    }
}

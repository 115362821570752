
import { Vue, Component, PropSync, Watch, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import FlashNotifications from "@/store/modules/FlashNotifications";
import Scoring from "@/store/modules/Scoring";
import { ScoreClass } from "@/graphql/API";
import ColourPalette from "@/components/mixins/ColourPalette";
import DragResizeHandlerVertical from "@/components/ui/DragResizeHandlerVertical.vue";

const scoringModule = getModule(Scoring);
const flashModule = getModule(FlashNotifications);

@Component({
    components: {
        DragResizeHandlerVertical,
    },
})
export default class NumberEditor extends mixins(ColourPalette) {
    @PropSync("items")
    internalItems!: {
        at_least: number;
        score: number;
        class_id: number | null;
    }[];

    @PropSync("classes")
    internalClassList!: { [index: number]: number };

    @PropSync("minRangeScore")
    internalMinRangeScore!: number;

    @PropSync("minRangeClass")
    internalMinRangeClass!: number | null;

    @Prop({ default: false, type: Boolean })
    sliderToggle!: boolean;

    @Prop({ default: null, type: Number })
    forcedMin!: number;

    @Prop({ default: null, type: Number })
    forcedMax!: number;

    @Prop({ default: false, type: Boolean })
    manualRule!: boolean;

    @Prop({ default: false, type: Boolean })
    agRule!: boolean;

    @Prop({ default: "", type: String })
    factorType!: string;

    private loading = false;

    private adjustedValues: number[] = [];
    private max = 100;
    private min = 0;
    private selectedScoreClass: ScoreClass | null = null;
    private editModal = false;
    private isFormValid = false;
    private editIndex = -1;

    get allScoreClasses(): { [id: number]: ScoreClass } {
        return scoringModule.scoreClasses;
    }

    get rangeHeights(): number[] {
        if (this.internalItems && this.internalItems.length > 0) {
            return this.internalItems.map((item, index) => {
                if (index == this.internalItems.length - 1) {
                    return this.max - item.at_least;
                } else {
                    return (
                        this.internalItems[index + 1].at_least - item.at_least
                    );
                }
            });
        }

        return [];
    }

    get newValues(): number[] {
        return this.adjustedValues.map((item, index) => {
            return this.roundNumber(
                this.max -
                    this.adjustedValues
                        .slice(-(this.adjustedValues.length - index))
                        .reduce((a, b) => a + b)
            );
        });
    }

    get numRange(): number {
        return this.max - this.min;
    }

    get internalScores(): number[] {
        return this.internalItems
            .map((item) => item.score)
            .sort((a, b) => a - b);
    }

    get scoreMin(): number {
        if (this.internalScores.length > 1) {
            return this.internalScores[0];
        }
        return 0;
    }

    get scoreMax(): number {
        if (this.internalScores.length) {
            return this.internalScores[this.internalScores.length - 1];
        }
        return 1;
    }

    get valueMin(): number {
        if (this.internalItems.length > 1) {
            return this.internalItems[0].at_least;
        }
        return 0;
    }

    get valueMax(): number {
        if (this.internalItems.length) {
            return this.internalItems[this.internalItems.length - 1].at_least;
        }
        return 0;
    }

    get scoreClasses(): ScoreClass[] {
        return Object.values(scoringModule.scoreClasses);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get scoreClassStyles(): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let styles: any = {};
        for (const key in this.internalClassList) {
            let currentClass =
                scoringModule.scoreClasses[this.internalClassList[key]];
            if (currentClass && currentClass.hasOwnProperty("json")) {
                styles[key] = JSON.parse(currentClass.json);
            }
        }
        return styles;
    }

    get classIndexes(): number[] {
        if (this.internalClassList) {
            return Object.keys(this.internalClassList)
                .map((item) => {
                    return parseInt(item);
                })
                .sort((a, b) => {
                    return a - b;
                });
        } else {
            return [];
        }
    }

    private roundNumber(value: number): number {
        return Math.round(value * 100) / 100;
    }

    private rangeSmallView(height: number): boolean {
        const wrapper = this.$refs.vpScoreRuleSlider as HTMLElement | null;
        if (wrapper) {
            return (height / this.numRange) * wrapper.offsetHeight < 52;
        }
        return false;
    }

    private adjustHeights(index: number, val: number) {
        const wrapper = this.$refs.vpScoreRuleSlider as HTMLElement | null;
        if (!wrapper) {
            return;
        }
        const newVal = (val / wrapper.offsetHeight) * this.numRange;
        const minus = this.adjustedValues[index] - newVal;

        if (
            index > 0
                ? this.adjustedValues[index - 1] + minus > 0
                : newVal <= this.newValues[index + 1] - this.min
        ) {
            if (index > 0) {
                Vue.set(
                    this.adjustedValues,
                    index - 1,
                    this.adjustedValues[index - 1] + minus
                );
            }
            Vue.set(this.adjustedValues, index, newVal);
        } else if (index == 0) {
            Vue.set(
                this.adjustedValues,
                index,
                this.newValues[index + 1] - this.min
            );
        }
    }

    private async changeRange(value: number, index: number): Promise<void> {
        if (
            (this.forcedMin == null || value >= this.forcedMin) &&
            (this.forcedMax == null || value <= this.forcedMax)
        ) {
            Vue.set(this.internalItems[index], "at_least", value);
            this.internalItems.sort((a, b) => {
                return a.at_least - b.at_least;
            });
        } else {
            let error = "";
            if (this.forcedMin != null && this.forcedMax != null) {
                error = `Value must be between ${this.forcedMin} and ${this.forcedMax}`;
            } else if (this.forcedMin != null) {
                error = `Value must be greater than ${this.forcedMin}`;
            } else if (this.forcedMax != null) {
                error = `Value must be less than ${this.forcedMax}`;
            }

            flashModule.error({
                message: error,
                duration: 3000,
            });
        }
    }

    private toggleEditScreen(id: number, index: number): void {
        this.$emit("selected-class", { class: id, range: index });
    }

    private async createRange(): Promise<void> {
        this.loading = true;

        let highest = this.max;
        let lowest = this.min;
        if (this.newValues.length > 0) {
            // check if we have only one range
            if (this.newValues.length === 1) {
                highest = this.newValues[this.newValues.length - 1];
            } else {
                highest = this.newValues[1];
            }
        }

        let newRange = (highest - lowest) / 2 + lowest;
        // check if we have only one range
        if (this.newValues.length === 1) {
            this.internalItems.splice(this.newValues.length - 1, 0, {
                at_least: newRange,
                score: 0,
                class_id: null,
            });
        } else {
            this.internalItems.unshift({
                at_least: this.min,
                score: 0,
                class_id: null,
            });

            this.internalItems[1].at_least = newRange;
        }

        this.loading = false;
    }

    private async updateInternalRange(index: number): Promise<void> {
        Vue.set(this.internalItems[index], "at_least", this.newValues[index]);
    }

    private async updateScore(value: string, index: number): Promise<void> {
        Vue.set(this.internalItems[index], "score", parseFloat(value));
        if (this.manualRule) {
            this.internalItems.sort((a, b) => {
                return a.score - b.score;
            });
        }
    }

    private async deleteRange(index: number): Promise<void> {
        this.$emit("delete-range", this.internalItems[index]);
        Vue.delete(this.internalItems, index);
    }

    private async generateRanges(): Promise<void> {
        this.loading = true;
        const interval = this.numRange / 5;
        let ranges = [...Array(6).keys()];

        this.internalItems = ranges.map((item) => {
            return {
                at_least: item * interval + this.min,
                score: 0,
                class_id: null,
            };
        });
        this.loading = false;
    }

    private async distributeRanges(): Promise<void> {
        const intervals = this.internalItems.length - 1;
        let scoreInterval =
            (this.scoreMax - this.scoreMin) /
            (this.scoreMin == 0 ? intervals : intervals - 1);
        let valueInterval = (this.valueMax - this.valueMin) / intervals;

        if (this.internalItems.length > 2) {
            await Promise.all(
                this.internalItems.map(async (item, index) => {
                    let val = this.valueMin + index * valueInterval;
                    let score =
                        this.scoreMin +
                        (this.scoreMin == 0 ? index + 1 : index) *
                            scoreInterval;

                    if (index == this.internalItems.length - 1) {
                        score = 0;
                    }

                    Vue.set(this.internalItems[index], "value", val);
                    Vue.set(this.internalItems[index], "score", score);
                })
            );
        }
    }

    mounted(): void {
        this.onForcedMinChange();
        this.onForcedMaxChange();
        this.onRangeHeightsChange();
        this.onManualRuleChagne();
    }

    @Watch("manualRule")
    onManualRuleChagne(): void {
        this.internalItems.sort((a, b) => {
            return this.manualRule
                ? a.score - b.score
                : a.at_least - b.at_least;
        });
    }

    @Watch("forcedMin")
    onForcedMinChange(): void {
        if (this.forcedMin != null) {
            this.min = this.forcedMin;
        }
    }

    @Watch("forcedMax")
    onForcedMaxChange(): void {
        if (this.forcedMax != null) {
            this.max = this.forcedMax;
        }
    }

    @Watch("rangeHeights")
    onRangeHeightsChange(): void {
        this.adjustedValues = [...this.rangeHeights];
    }

    @Watch("scoreMin")
    onScoreMinChange(): void {
        this.$emit("change-min-score", this.scoreMin);
    }

    @Watch("scoreMax")
    onScoreMaxChange(): void {
        this.$emit("change-max-score", this.scoreMax);
    }

    @Watch("internalItems", { immediate: true, deep: true })
    onInternalItemsChange(): void {
        if (this.factorType !== "number" && this.factorType !== "date_time") {
            this.internalItems.forEach((range) => {
                if (range.at_least !== range.score) {
                    range.at_least = range.score;
                }
            });
        }
    }
}

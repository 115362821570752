
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ColourPalette from "@/components/mixins/ColourPalette";

@Component({
    components: {},
})
export default class ColourPaletteSelector extends mixins(ColourPalette) {
    @Prop({ default: "", type: String })
    selectedColour!: string;

    @Prop({ default: "", type: String })
    btnClass!: string;

    @Prop({ default: false, type: Boolean })
    accordion!: boolean;

    @Prop({ default: false, type: Boolean })
    blankBtn!: boolean;

    @Prop({ default: false, type: Boolean })
    fullWidth!: boolean;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: false, type: Boolean })
    attach!: boolean;

    @Prop()
    dataProp!: string;

    get colourList() {
        let accent = Object.values(this.palette).slice(27, 33);
        accent.splice(3, 0, this.palette["vp--bg-accent"]);
        let accent2 = Object.values(this.palette).slice(33, 39);
        accent2.splice(3, 0, this.palette["vp--bg-accent-2"]);
        let accent3 = Object.values(this.palette).slice(39, 45);
        accent3.splice(3, 0, this.palette["vp--bg-accent-3"]);
        let accent4 = Object.values(this.palette).slice(45, 51);
        accent4.splice(3, 0, this.palette["vp--bg-accent-4"]);
        let accent5 = Object.values(this.palette).slice(51, 57);
        accent5.splice(3, 0, this.palette["vp--bg-accent-5"]);
        let accent6 = Object.values(this.palette).slice(57, 63);
        accent6.splice(3, 0, this.palette["vp--bg-accent-6"]);
        let grey1 = Object.values(this.palette).slice(6, 13);
        let grey2 = Object.values(this.palette).slice(13, 20);
        let preset = Object.values(this.palette).slice(20, 27);

        return [
            accent,
            accent2,
            accent3,
            accent4,
            accent5,
            accent6,
            grey1,
            grey2,
            preset,
        ];
    }

    private colourIndex = "";

    private selectColour(val: string): void {
        this.$emit("colour-change", val, this.dataProp);
    }

    mounted(): void {
        this.selectColourChange();
    }

    @Watch("selectedColour")
    selectColourChange(): void {
        this.colourIndex = this.selectedColour;
    }
}

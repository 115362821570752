
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Factor, ModelFilters } from "@/graphql/API";
import { FactorsIcons } from "@/IconLibrary";

@Component({
    components: {},
})
export default class RecursiveModelFactor extends Vue {
    @Prop()
    factor!: Factor;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop()
    type!: string;

    @Prop({ default: false, type: Boolean })
    isGroup!: boolean;

    @Prop({ default: false, type: Boolean })
    active!: boolean;

    @Prop({ default: false, type: Boolean })
    last!: boolean;

    @Prop({ default: false, type: Boolean })
    parentLast!: boolean;

    @Prop()
    filters!: ModelFilters;

    @Prop({ default: false, type: Boolean })
    drag!: boolean;

    @Prop({ default: () => [], type: Array })
    hiddenDepth!: number[];

    private readonly Icons: Record<string, string> = FactorsIcons;

    private clickFactor(e: KeyboardEvent, factor: Factor, mobile: boolean) {
        this.$emit("toggle-factor", {
            item: factor,
            multiple: e.metaKey,
            mobile,
        });
    }

    get disabled(): boolean {
        if (this.factor && this.filters && this.type) {
            if (
                this.filters.type.length &&
                !this.filters.type.includes(this.type)
            ) {
                return true;
            } else if (this.filters.search != "") {
                return !this.factor.name
                    .toLowerCase()
                    .includes(this.filters.search.toLowerCase());
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}

export const iconLibrary = [
    {
        name: "Star Empty",
        id: "mdi-star-outline",
    },
    {
        name: "Star",
        id: "mdi-star",
    },
    {
        name: "Heart Empty",
        id: "mdi-heart-outline",
    },
    {
        name: "Heart",
        id: "mdi-heart",
    },
    {
        name: "Thumbs Up",
        id: "mdi-thumb-up",
    },
    {
        name: "Thumbs Down",
        id: "mdi-thumb-down",
    },
    {
        name: "Circle Slice",
        id: "mdi-circle-slice-1",
    },
    {
        name: "Thumbs Down",
        id: "mdi-thumb-down",
    },
    {
        name: "Thumbs Down",
        id: "mdi-thumb-down",
    },
    {
        name: "Thumbs Down",
        id: "mdi-thumb-down",
    },
    {
        name: "Thumbs Down",
        id: "mdi-thumb-down",
    },
    {
        name: "Thumbs Down",
        id: "mdi-thumb-down",
    },
];

export const FactorsIcons = {
    string: "mdi-format-text",
    number: "mdi-pound",
    group: "mdi-file-tree-outline",
    table: "mdi-table-large",
    date_time: "mdi-calendar-clock",
    image: "mdi-image-outline",
    video: "mdi-play-circle-outline",
    audio: "mdi-music-box-outline",
    document: "mdi-file-document-outline",
    category: "mdi-format-list-checkbox",
    label: "mdi-label-outline",
};

export const TableIcons = {
    new: "mdi-plus",
    edit: "mdi-pencil",
    copy: "mdi-content-copy",
    paste: "mdi-content-paste",
    merge: "mdi-merge",
    checkpoint: "mdi-pin",
    archive: "mdi-archive",
    delete: "mdi-delete",
    users: "mdi-account",
    duplicate: "mdi-content-duplicate",
    import: "mdi-application-import",
};

import { Domain, Unit, UnitConv } from "@/graphql/API";
import {
    CreateDomain,
    CreateUnit,
    CreateUnitConv,
    CreateUnitDomain,
    DeleteUnit,
    DeleteUnitConv,
    GetAllUnitConvs,
    GetAllUnits,
    GetUnits,
    UpdateUnit,
    UpdateUnitConv,
} from "@/graphql/custom";
import {
    createDomain,
    createUnit,
    createUnitConv,
    createUnitDomain,
    deleteUnit,
    deleteUnitConv,
    updateUnit,
    updateUnitConv,
} from "@/graphql/mutations";
import { API } from "aws-amplify";
import { graphql } from "graphql";
import {
    Action,
    config,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Vue from "vue";
import { getAllUnitConvs, getAllUnits, getUnits } from "@/graphql/queries";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";

config.rawError = true;

const name = "domains";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Domains extends VuexModule {
    domains: { [id: number]: Domain } = {};
    units: { [id: number]: Unit } = {};
    unitConvs: { [fromToId: string]: UnitConv } = {};
    dataLoading = false;

    get domainList(): Domain[] {
        return Object.values(this.domains);
    }

    get unitsList(): Unit[] {
        return Object.values(this.units);
    }

    @Mutation
    setLoading(val: boolean): void {
        this.dataLoading = val;
    }

    @Mutation
    setDomain(domain: Domain): void {
        Vue.set(this.domains, domain.id, domain);
    }

    @Mutation
    setUnits(units: Unit[]): void {
        units.forEach((unit: Unit) => Vue.set(this.units, unit.id, unit));
    }

    //TODO: Default to -1 domain for units
    @Mutation
    setUnit(unit: Unit): void {
        Vue.set(this.units, unit.id, unit);
    }

    @Mutation
    removeUnit(unit: Unit): void {
        Vue.delete(this.units, unit.id);
    }

    @Mutation
    setUnitConvs(unitConvs: UnitConv[]): void {
        const dictionary = Object.assign(
            {},
            ...unitConvs.map((x) => ({ [`${x.from_id}-${x.to_id}`]: x }))
        );
        this.unitConvs = dictionary;
    }

    @Mutation
    setUnitConv(unitConv: UnitConv): void {
        const key = `${unitConv.from_id}-${unitConv.to_id}`;
        Vue.set(this.unitConvs, key, unitConv);
    }

    @Mutation
    removeUnitConv(unitConv: UnitConv): void {
        const key = `${unitConv.from_id}-${unitConv.to_id}`;
        Vue.delete(this.unitConvs, key);
    }

    @Action
    async fetchAllUnits(): Promise<Unit[]> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getAllUnits,
            variables: {
                name,
            },
        })) as GraphQLResult<GetAllUnits>;

        const units = res.data?.getAllUnits;

        if (units != null) {
            this.setUnits(units);
        }

        return [];
    }

    @Action
    async fetchUnits(domain_id: number): Promise<Unit[]> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getUnits,
            variables: {
                domain_id,
            },
        })) as GraphQLResult<GetUnits>;

        const units = res.data?.getUnits;

        if (units != null) {
            this.setUnits(units);
        }

        return [];
    }

    @Action
    async fetchAllUnitConvs(): Promise<Unit[]> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getAllUnitConvs,
            variables: {
                name,
            },
        })) as GraphQLResult<GetAllUnitConvs>;

        const unitConvs = res.data?.getAllUnitConvs;

        if (unitConvs != null) {
            this.setUnitConvs(unitConvs);
        }

        return [];
    }

    @Action
    async createDomain(payload: { name: string }): Promise<void> {
        //TODO
        const name = payload.name;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createDomain,
            variables: {
                name,
            },
        })) as GraphQLResult<CreateDomain>;

        const domain = res.data?.createDomain;

        if (domain) {
            this.setDomain(domain);
        }
    }

    @Action
    async createUnit(payload: {
        name: string;
        abrv: string;
        domain_id?: number;
    }): Promise<void> {
        const name = payload.name;
        const abrv = payload.abrv;
        const domain_id = payload.domain_id ? payload.domain_id : -1;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createUnit,
            variables: {
                name,
                abrv,
            },
        })) as GraphQLResult<CreateUnit>;

        const unit = res.data?.createUnit;

        if (unit) {
            this.createUnitDomain({ unit_id: unit.id, domain_id: domain_id });
            this.setUnit(unit);
        }
    }

    @Action
    async createUnitDomain(payload: {
        unit_id: number;
        domain_id?: number;
    }): Promise<void> {
        const unit_id = payload.unit_id;
        const domain_id = payload.domain_id ? payload.domain_id : -1;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createUnitDomain,
            variables: {
                unit_id,
                domain_id,
            },
        })) as GraphQLResult<CreateUnitDomain>;

        const unit = res.data?.createUnitDomain;
    }

    @Action
    async updateUnit(payload: {
        name: string;
        abrv: string;
        unit_id: number;
    }): Promise<void> {
        const name = payload.name;
        const abrv = payload.abrv;
        const unit_id = payload.unit_id;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateUnit,
            variables: {
                name,
                abrv,
                unit_id,
            },
        })) as GraphQLResult<UpdateUnit>;

        const unit = res.data?.updateUnit;

        if (unit) {
            this.setUnit(unit);
        }
    }

    @Action
    async deleteUnit(payload: { unit_id: number }): Promise<void> {
        const unit_id = payload.unit_id;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteUnit,
            variables: {
                unit_id,
            },
        })) as GraphQLResult<DeleteUnit>;

        const unit = res.data?.deleteUnit;

        if (unit) {
            this.removeUnit(unit);
        }
    }

    @Action
    async createUnitConversion(payload: {
        from_id: number;
        to_id: number;
        multiplier: string;
    }): Promise<void> {
        const from_id = payload.from_id;
        const to_id = payload.to_id;
        const multiplier = payload.multiplier ? payload.multiplier : -1;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createUnitConv,
            variables: {
                from_id,
                to_id,
                multiplier,
            },
        })) as GraphQLResult<CreateUnitConv>;

        const unitConv = res.data?.createUnitConv;

        if (unitConv) {
            this.setUnitConv(unitConv);
        }
    }

    @Action
    async updateUnitConversion(payload: {
        from_id: number;
        to_id: number;
        multiplier: string;
    }): Promise<void> {
        const from_id = payload.from_id;
        const to_id = payload.to_id;
        const multiplier = payload.multiplier ? payload.multiplier : -1;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateUnitConv,
            variables: {
                from_id,
                to_id,
                multiplier,
            },
        })) as GraphQLResult<UpdateUnitConv>;

        const unitConv = res.data?.updateUnitConv;

        if (unitConv) {
            this.setUnitConv(unitConv);
        }
    }

    @Action
    async deleteUnitConversion(payload: {
        from_id: number;
        to_id: number;
    }): Promise<void> {
        const from_id = payload.from_id;
        const to_id = payload.to_id;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteUnitConv,
            variables: {
                from_id,
                to_id,
            },
        })) as GraphQLResult<DeleteUnitConv>;

        const unitConv = res.data?.deleteUnitConv;

        if (unitConv) {
            this.removeUnitConv(unitConv);
        }
    }

    @Action
    updateLoading(loading: boolean): void {
        this.setLoading(loading);
    }
}

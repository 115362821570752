
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Urls } from "@/Urls";
import { Decision, Workspace } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Workspaces from "@/store/modules/Workspaces";
import Interface from "@/layouts/Interface.vue";
import DecisionSelector from "@/components/decisions/DecisionSelector.vue";
import WorkspaceSelector from "@/components/workspaces/WorkspaceSelector.vue";

const decisionsModule = getModule(Decisions);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {
        Interface,
        DecisionSelector,
        WorkspaceSelector,
    },
})
export default class Home extends Vue {
    @Prop(String)
    queryWorkId!: string;

    @Prop(String)
    queryDecId!: string;

    private loading = false;

    get selectedDecision(): Decision | null {
        return decisionsModule.selectedDecision;
    }

    get selectedWorkspace(): Workspace | null {
        return workspaceModule.selectedWorkspace;
    }

    get selectedWorkspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get notificationCount(): number {
        return 0;
    }

    get routeKey(): string {
        return `${this.$route.fullPath}-${
            this.selectedDecisionId ? this.selectedDecisionId : "null"
        }`;
    }

    get metaTitle(): string | null {
        if (this.$route.meta) {
            if (typeof this.$route.meta.title == "function") {
                return this.$route.meta.title();
            } else {
                return this.$route.meta.title;
            }
        } else {
            return null;
        }
    }

    get pageTitle(): string {
        let title = process.env.VUE_APP_TITLE
            ? process.env.VUE_APP_TITLE
            : "Viewpoint AI";
        if (this.metaTitle && this.selectedDecisionId) {
            title = `${this.metaTitle} | ${title}`;
        }
        return (
            title +
            (this.notificationCount > 0 ? ` (${this.notificationCount})` : "")
        );
    }

    mounted(): void {
        this.onPageTitleChange(this.pageTitle);
        this.loadParams();
    }

    private async loadParams(): Promise<void> {
        this.loading = true;
        if (this.queryWorkId) {
            await workspaceModule.selectWorkspace(parseInt(this.queryWorkId));

            if (this.queryDecId) {
                await decisionsModule.selectDecision(parseInt(this.queryDecId));
                if (this.$router.currentRoute.path === Urls.DECISION) {
                    this.$router.push(Urls.MODEL);
                } else {
                    this.$router.push(this.$router.currentRoute.path);
                }
            }
        }
        this.loading = false;
    }

    private backToWorkspace(): void {
        workspaceModule.selectWorkspace(null);
    }

    @Watch("pageTitle")
    onPageTitleChange(val: string): void {
        document.title = val;
    }
}

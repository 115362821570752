
import { Component, Prop, Watch } from "vue-property-decorator";
import ColourPaletteSelector from "@/components/values/ColourPaletteSelector.vue";
import ColourPalette from "@/components/mixins/ColourPalette";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import { mixins } from "vue-class-component";

@Component({
    name: "StyleEditorClass",
    components: { ColourPaletteSelector, DialogCardTitle },
})
export default class StyleEditorClass extends mixins(ColourPalette) {
    @Prop({ default: "Body 1", type: String })
    className!: string;

    @Prop({ default: "vp-body-1", type: String })
    styleClass!: string;

    @Prop({ default: "body1", type: String })
    varName!: string;

    @Prop({ default: () => ({}), type: Object })
    styleClassObject!: any;

    private styleClassObjectCopy = { ...this.styleClassObject };

    private sizes = [
        {
            name: "12px",
            value: "12px",
        },
        {
            name: "14px",
            value: "14px",
        },
        {
            name: "16px",
            value: "16px",
        },
        {
            name: "18px",
            value: "18px",
        },
        {
            name: "20px",
            value: "20px",
        },
        {
            name: "24px",
            value: "24px",
        },
        {
            name: "34px",
            value: "34px",
        },
        {
            name: "48px",
            value: "48px",
        },
        {
            name: "60px",
            value: "60px",
        },
        {
            name: "96px",
            value: "96px",
        },
    ];

    private weight = [
        {
            name: "Thin",
            value: "100",
        },
        {
            name: "Extra-Light",
            value: "200",
        },
        {
            name: "Light",
            value: "300",
        },
        {
            name: "Regular",
            value: "400",
        },
        {
            name: "Medium",
            value: "500",
        },
        {
            name: "Semi-Bold",
            value: "600",
        },
        {
            name: "Bold",
            value: "700",
        },
        {
            name: "Extra-Bold",
            value: "800",
        },
        {
            name: "Heavy",
            value: "900",
        },
    ];

    private styles = [
        {
            name: "Normal",
            value: "normal",
        },
        {
            name: "Italic",
            value: "italic",
        },
    ];

    get newStyles(): any {
        return {
            "font-size": this.styleClassObjectCopy.fontSize,
            "font-weight": this.styleClassObjectCopy.fontWeight,
            "font-style": this.styleClassObjectCopy.fontStyle,
            "letter-spacing": "-1.5px",
            color: this.styleClassObjectCopy.fontColor,
            "background-color": this.styleClassObjectCopy.fontBgColor,
        };
    }

    private updateColour(color: string) {
        if (color && color !== "") {
            this.styleClassObjectCopy.fontColor = this.palette[color].hex;
        } else {
            this.styleClassObjectCopy.fontColor = "inherit";
        }
    }

    private updateBgColour(color: string) {
        if (color && color !== "") {
            this.styleClassObjectCopy.fontBgColor = this.palette[color].hex;
        } else {
            this.styleClassObjectCopy.fontBgColor = "inherit";
        }
    }

    @Watch("styleClassObjectCopy", { deep: true })
    private onStyleClassObjectChange() {
        this.$emit("changed", this.className, this.styleClassObjectCopy);
    }
}

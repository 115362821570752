
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { User } from "@/graphql/API";
import Logo from "@/components/ui/Logo.vue";
import { Urls } from "@/Urls";
import Users from "@/store/modules/Users";
import WelcomeView from "./WelcomeView.vue";
import LoginInterface from "@/layouts/LoginInterface.vue";
import InviteForm from "@/components/login/InviteForm.vue";
import LoginForm from "@/components/login/LoginForm.vue";
import { Auth } from "aws-amplify";
import ResetForm from "@/components/login/ResetForm.vue";
import SignupForm from "@/components/login/SignupForm.vue";

const userModule = getModule(Users);

@Component({
    components: {
        Logo,
        WelcomeView,
        LoginInterface,
        InviteForm,
        LoginForm,
        ResetForm,
        SignupForm,
    },
})
export default class InvitationView extends Vue {
    @Prop({ type: Boolean, default: false })
    loggedIn!: boolean;

    private readonly URLS = Urls;

    private loading = false;

    private view = "login";
    private showContent = false;

    get currentUser(): User | null {
        return userModule.currentUser;
    }

    get showError(): boolean {
        if (!(this.workspaceParam || this.permisionCode)) {
            return true;
        }
        return false;
    }

    get workspaceParam(): number | null {
        return this.$route.params.workspaceId
            ? parseInt(this.$route.params.workspaceId)
            : null;
    }

    get permisionCode(): string | null {
        if (
            this.$route.query &&
            this.$route.query.p_code &&
            typeof this.$route.query.p_code == "string"
        ) {
            return this.$route.query.p_code;
        } else {
            return null;
        }
    }

    private backBtn(): void {
        if (this.currentUser && this.loggedIn) {
            this.view = "login";
            this.showContent = false;
        } else {
            this.view = "login";
        }
    }
}

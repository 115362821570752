
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Viewpoint,
    Choice,
    ChartValues,
    ViewpointMapping,
} from "@/graphql/API";
import BarChart from "@/components/charts/BarChart.vue";
import StackedChart from "@/components/charts/StackedChart.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import Scoring from "@/store/modules/Scoring";
import WorkspaceOptions from "@/store/modules/WorkspaceOptions";
import Viewpoints from "@/store/modules/Viewpoints";

const workspaceOptions = getModule(WorkspaceOptions);
const scoringModule = getModule(Scoring);

@Component({
    components: {
        BarChart,
        VpDialog,
        StackedChart,
    },
})
export default class ChartFcs extends Vue {
    @Prop()
    chartDataValue!: ChartValues;

    @Prop({ default: () => ({}), type: Array })
    viewpoints!: Viewpoint[];

    @Prop()
    selectedFactor!: Factor;

    @Prop()
    selectedChoice!: Choice;

    @Prop({ default: false, type: Boolean })
    isBarChartHorizontal!: boolean;

    get viewpointMappings(): {
        [viewpointId: number]: {
            [factorId: number]: ViewpointMapping;
        };
    } {
        return getModule(Viewpoints).viewpointMappings;
    }

    get barChartData() {
        return {
            data: {
                series: this.viewpoints.map((viewpoint) => {
                    return {
                        id: viewpoint.id.toString(),
                        name: viewpoint.name,
                        data:
                            this.selectedChoice && this.selectedFactor
                                ? this.getScore(
                                      viewpoint.id,
                                      this.selectedChoice.id,
                                      this.selectedFactor.id.toString()
                                  )
                                : [0],
                    };
                }),
            },
            title: "Factor",
            xaxis: {
                categories: [
                    this.selectedFactor ? this.selectedFactor.name : "Factor",
                ],
            },
        };
    }

    private roundNumber(value: number, digits: number): number {
        return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
    }

    private getScore(
        viewpointId: number,
        choiceId: number,
        factorId: string
    ): number[] {
        const score =
            scoringModule.keyedScores[`${viewpointId}-${choiceId}-${factorId}`];
        if (score && this.viewpointMappings[viewpointId] && this.viewpointMappings[viewpointId][score.f_id]) {
            if (this.viewpointMappings[viewpointId][score.f_id]) {
                return [this.roundNumber(score.m_score, 2)];
            } else {
                return [this.roundNumber(score.c_score, 2)];
            }
        } else {
            return [0];
        }
    }
}

import { Factor, FactorAI } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Scoring from "@/store/modules/Scoring";

const scoringModule = getModule(Scoring);

export async function createFactorObj(
    factor: FactorAI,
    parent: number,
    decisionId: number,
    id: number,
    order: string,
    default_set: number | undefined
): Promise<Factor> {
    const newFactor: Factor = {
        id: id,
        name: factor.name,
        description: factor.description,
        parent_id: factor.parent_id ? factor.parent_id : parent,
        type: "",
        order_str: order,
        value_type: "",
        json: "",
        is_table: false,
        is_group: false,
        is_column: false,
        format: "",
        m_score: 0,
        m_index: undefined,
        weight: 1,
        index_rule_id: -2,
        score_rule_id: -1,
        set_id: default_set,
        enum_id: undefined,
        unit_id: undefined,
        decision_id: decisionId,
    };

    const factorType = factor.type.toLowerCase();
    switch (factorType) {
        case "text":
            newFactor.value_type = "string";
            newFactor.format = "single-line";
            newFactor.index_rule_id = scoringModule.defaultRules[-6].id;
            newFactor.score_rule_id = scoringModule.defaultRules[-5].id;
            break;
        case "number":
        case "numerical":
            newFactor.value_type = "number";
            newFactor.index_rule_id = scoringModule.defaultRules[-2].id;
            newFactor.score_rule_id = scoringModule.defaultRules[-1].id;
            break;
        case "categorical":
        case "boolean":
            newFactor.value_type = "category";
            newFactor.index_rule_id = scoringModule.defaultRules[-4].id;
            newFactor.score_rule_id = scoringModule.defaultRules[-3].id;
            break;
        default:
            newFactor.value_type = "string";
            newFactor.format = "single-line";
            newFactor.index_rule_id = scoringModule.defaultRules[-6].id;
            newFactor.score_rule_id = scoringModule.defaultRules[-5].id;
    }

    return newFactor;
}

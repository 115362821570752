import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Amplify, Auth } from "aws-amplify";
import {
    applyPolyfills,
    defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import "vuetify/dist/vuetify.min.css";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import VueApexCharts from "vue-apexcharts";
import "@/styles/scss/index.scss";
import { locales } from "@/assets/locales/locales";
import VueTippy, { TippyComponent } from "vue-tippy";

// Auth.currentSession().then(res => {
//     const accessToken = res.getAccessToken()
//     const jwt = accessToken.getJwtToken()
//     //You can print them to see the full objects
//     console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
//     console.log(`myJwt: ${jwt}`)
// })

// async function defaultGetAuth() {
//     try {
//         const cognitoUser = await Auth.currentAuthenticatedUser();
//         const currentSession = cognitoUser.signInUserSession;
//         let token = currentSession.idToken.getJwtToken();
//         if (token) {
//             // console.log("token:", (token.substring(0, 10) + "..." + token.substring(token.length - 10, token.length)));
//         }
//         return token ? `Bearer ${token}` : '';
//     } catch (e) {
//         console.error("error getting jwt", e);
//     }
// }

// const httpLink = createHttpLink({
//     // You should use an absolute URL here
//     uri: 'https://jetqluhqsva4jpeuykqyzzgy74.appsync-api.us-west-2.amazonaws.com/graphql',
// })

// const authLink = setContext(async (_, { headers }) => {
//     // get the authentication token from local storage if it exists
//     const token = JSON.parse(localStorage.getItem('apollo-token'))
//     // Return the headers to the context so httpLink can read them
//     return {
//         headers: {
//             ...headers,
//             authorization: token || ''
//         }
//     }
// })

// const apolloClient = new ApolloClient({
//     link: authLink.concat(httpLink), // Chain it with the HttpLink
//     cache: new InMemoryCache()
// });

// const apolloProvider = new VueApollo({
//     defaultClient: apolloClient,
// })

Amplify.configure({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: "us-west-2:58599930-32aa-4901-8cfe-33b77adcbbb0",

        // REQUIRED - Amazon Cognito Region
        region: "us-west-2",

        identityPoolId: process.env.VUE_APP_AWS_IDENTITY_POOL_ID,
        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it"s different from Amazon Cognito Region
        // identityPoolRegion: "XX-XXXX-X",

        // cookieStorage: {
        //     domain: "localhost",
        //     path: "/",
        //     expires: 365,
        //     secure: true
        // },
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.VUE_APP_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

        authenticationFlowType: "USER_SRP_AUTH",

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: process.env.VUE_APP_AUTH_DOMAIN,
            scope: [
                "aws.cognito.signin.user.admin",
                "email",
                "openid",
                "phone",
                "profile",
            ], //aws.cognito.signin.user.admin+email+openid+phone+profile
            redirectSignIn: `${process.env.VUE_APP_ENV_VARIABLE_FRONTEND_REDIRECT_URL}`,
            redirectSignOut: `${process.env.VUE_APP_ENV_VARIABLE_FRONTEND_REDIRECT_URL}`,
            responseType: "code", // or "token", note that REFRESH token will only be generated when the responseType is code
        },
    },
    Storage: {
        AWSS3: {
            bucket: process.env.VUE_APP_PDF_STORAGE_S3_BUCKET, //REQUIRED -  Amazon S3 bucket
            region: process.env.VUE_APP_AWS_REGION, //OPTIONAL -  Amazon service region
        }
    },
    //   API: {
    //     graphql_endpoint:
    //       "https://jetqluhqsva4jpeuykqyzzgy74.appsync-api.us-west-2.amazonaws.com/graphql",
    //     // graphql_headers: async () => ({
    //     //     "My-Custom-Header": "my value" // Set Custom Request Headers for non-AppSync GraphQL APIs
    //     // })
    //     graphql_headers: async () => {
    //       const currentSession = await Auth.currentSession();
    //       return { Authorization: currentSession.getIdToken().getJwtToken() };
    //     },
    //   },
    //   AppSync: {
    //       Default: {
    //           ApiUrl: "https://jetqluhqsva4jpeuykqyzzgy74.appsync-api.us-west-2.amazonaws.com/graphql",
    //           Region: "us-west-2",
    //           AuthMode: "AMAZON_COGNITO_USER_POOLS",
    //           jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
    //           //ClientDatabasePrefix: "vp-graphql-dev_AMAZON_COGNITO_USER_POOLS"
    //           aws_appsync_graphqlEndpoint: 'https://jetqluhqsva4jpeuykqyzzgy74.appsync-api.us-west-2.amazonaws.com/graphql',
    //           aws_appsync_region: 'us-west-2',
    //           aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    //         //   'aws_appsync_apiKey': 'da2-xxxxxxxxxxxxxxxxxxxxxxxxxx',
    //       },
    //   "vp-graphql-dev_API_KEY": {
    //       "ApiUrl": "https://jetqluhqsva4jpeuykqyzzgy74.appsync-api.us-west-2.amazonaws.com/graphql",
    //       "Region": "us-west-2",
    //       "AuthMode": "API_KEY",
    //       "ApiKey": "da2-o5whgm6gfjbclab2xsbamerbyi",
    //       "ClientDatabasePrefix": "vp-graphql-dev_API_KEY"
    //   },
    //   "vp-graphql-dev_AWS_IAM": {
    //       "ApiUrl": "https://jetqluhqsva4jpeuykqyzzgy74.appsync-api.us-west-2.amazonaws.com/graphql",
    //       "Region": "us-west-2",
    //       "AuthMode": "AWS_IAM",
    //       "ClientDatabasePrefix": "vp-graphql-dev_AWS_IAM"
    //   }
    //   }
});

const myAppConfig = {
    // ...
    aws_appsync_graphqlEndpoint: process.env.VUE_APP_BACKEND_GRAPHQL,
    aws_appsync_region: "us-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
    // ...
};

Amplify.configure(myAppConfig);

Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(async () => {
    await defineCustomElements(window);
});

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.component("tippy", TippyComponent);
Vue.use(VueTippy, {
    theme: "viewpoint",
    arrow: true,
    arrowType: "round",
});

const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: locales,
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");


import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Viewpoint,
    Choice,
    ChartValues,
    ViewpointMapping,
    Factor
} from "@/graphql/API";
import BarChart from "@/components/charts/BarChart.vue";
import StackedChart from "@/components/charts/StackedChart.vue";
import Scoring from "@/store/modules/Scoring";
import WorkspaceOptions from "@/store/modules/WorkspaceOptions";
import Viewpoints from "@/store/modules/Viewpoints";

const workspaceOptions = getModule(WorkspaceOptions);
const scoringModule = getModule(Scoring);
const viewpointModule = getModule(Viewpoints);

@Component({
    components: {
        BarChart,
        StackedChart,
    },
})
export default class ChartCs extends Vue {
    @Prop()
    chartDataValue!: ChartValues;

    @Prop()
    viewpoints!: Viewpoint[];

    @Prop()
    selectedChoice!: Choice;

    @Prop()
    selectedViewpoint!: Viewpoint;

    @Prop({ default: () => [], type: Array })
    factors!: Factor[];

    @Prop()
    choices!: Choice[];

    @Prop({ default: false, type: Boolean })
    isBarChartHorizontal!: boolean;

    get viewpointMappings(): {
        [viewpointId: number]: {
            [factorId: number]: ViewpointMapping;
        };
    } {
        return getModule(Viewpoints).viewpointMappings;
    }


    get newBarChartData() {
        if (this.selectedViewpoint && this.selectedChoice) {
            return {
                data: {
                    series: this.factors.map((factor) => {
                        return {
                            id: factor.id.toString(),
                            name: factor.name,
                            data: [this.getScore(this.selectedViewpoint.id, this.selectedChoice.id, factor.id)],
                        }
                    }),
                },
                title: "Factors",
                xaxis: {
                    categories: [this.selectedChoice ? this.selectedChoice.name : ""],
                },
            };
        } else {
            return {};
        }
    }

    private getScore(
        viewpointId: number,
        choiceId: number,
        factorId: number
    ): number {
        const score =
            scoringModule.keyedScores[`${viewpointId}-${choiceId}-${factorId}`];

        // console.log(`${viewpointId}-${choiceId}-${factorId}`);
        // console.log(score);
        if (
            score &&
            this.viewpointMappings[viewpointId] &&
            this.viewpointMappings[viewpointId][factorId]
        ) {
            // console.log(`VP Map: ${viewpointId}-${choiceId}-${factorId}`)
            // console.log(this.viewpointMappings[viewpointId][factorId]);
            if (
                this.viewpointMappings[viewpointId][factorId].use_m_score
            ) {
                return this.roundNumber(score.m_score, 2);
            } else {
                return this.roundNumber(score.c_score, 2);
            }
        } else {
            console.log(`No VP Map ${viewpointId}-${choiceId}-${factorId}`)
            return 0;
        }
    }

    private roundNumber(value: number, digits: number): number {
        return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
    }
}


import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, Value } from "@/graphql/API";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";

@Component({
    components: {
        FactorValueDisplay,
    },
})
export default class ChoiceEditRow extends Vue {
    @Prop()
    factor!: Factor;

    @Prop()
    choiceId!: number;

    @Prop({ default: false, type: Boolean })
    group!: boolean;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop()
    value!: Value;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    get showChoice(): boolean {
        if (this.factor) {
            return !this.factor.is_table && !this.factor.is_group;
        } else {
            return false;
        }
    }

    get factorJson(): string {
        if (
            this.factor &&
            this.factor.json &&
            !this.factor.json.startsWith("v")
        ) {
            return this.factor.json;
        } else {
            return "{}";
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get factorStyle(): any {
        return JSON.parse(this.factorJson);
    }

    get factorTextClass(): string {
        if (this.factorStyle && this.factorStyle.style_class) {
            return this.factorStyle.style_class;
        } else {
            return "vp-body-1";
        }
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.item)?_c('tr',{staticClass:"vp-table-manager__row",class:{
        'vp-table-manager__row--active': _vm.active,
        'vp-table-manager__row--expanded': _vm.syncedOpen,
        'vp-table-manager__row--menu': _vm.menuOpen
    }},[(_vm.checkboxes)?_c('td',{staticClass:"vp-table-manager__cell vp-table-manager__cell--checkbox"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.checkboxToggle}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"))])],1)],1):_vm._e(),(_vm.expand)?_c('td',{staticClass:"vp-table-manager__cell vp-table-manager__cell--expand"},[_c('v-btn',{staticClass:"vp-table-manager__expand-btn",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedOpen = !_vm.syncedOpen}}},[_c('v-icon',[_vm._v("mdi-menu-right")])],1)],1):_vm._e(),(_vm.offset)?_c('td',{staticClass:"vp-table-manager__cell vp-table-manager__cell--offset"}):_vm._e(),_vm._l((_vm.columns),function(column,index){return _c('td',{key:index,staticClass:"vp-table-manager__cell",style:(column.width ? `min-width: ${column.width}` : ``),on:{"click":function($event){$event.stopPropagation();return _vm.clickItem.apply(null, arguments)},"dblclick":function($event){$event.stopPropagation();return _vm.dblClickItem.apply(null, arguments)},"contextmenu":function($event){$event.stopPropagation();return _vm.rightClick.apply(null, arguments)}}},[_vm._t(column.property,function(){return [(column.type)?[(column.type == 'boolean')?[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.item[column.property] ? "mdi-check" : "mdi-close")+" ")])]:_vm._e()]:_c('span',{staticClass:"vp-table-manager__cell__content",domProps:{"innerHTML":_vm._s(
                    column.parseFunction != null
                        ? _vm.item[column.property] != null
                            ? column.parseFunction(_vm.item[column.property])
                            : _vm.item[column.property]
                        : _vm.item[column.property] != null && _vm.item[column.property] != {}
                        ? _vm.item[column.property]
                        : ''
                )}})]},{"item":_vm.item})],2)}),(_vm.draggable)?_c('td',{staticClass:"vp-table-manager__cell vp-table-manager__cell--drag vp-table-manager__cell--drag__icon"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-drag")])],1):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
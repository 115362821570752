
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Choice,
    Viewpoint,
    CompareValues,
    ScoreOptions,
    Value,
    ScoreClassSettings,
    FactorOptions,
    ViewpointMapping,
} from "@/graphql/API";
import { createUpdateChoiceValue } from "@/helpers/ChoiceHelper";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";
import { getScore } from "@/helpers/ScoreHelper";
import FactorScore from "@/components/scoring/FactorScore.vue";

const choiceModule = getModule(Choices);
const modelModule = getModule(Factors);
const viewpointsModule = getModule(Viewpoints);

@Component({
    components: {
        FactorValueDisplay,
        FactorScore,
    },
})
export default class CompareTableColumnsChoices extends Vue {
    @Prop()
    tableId!: number;

    @Prop({ default: () => [], type: Array })
    headers!: Factor[];

    @Prop({ default: () => [], type: Array })
    choices!: Choice[];

    @Prop()
    viewpoint!: Viewpoint;

    @PropSync("tableRows", Number)
    syncedRows!: number;

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoresEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    get valueMap(): { [choice_id: number]: { [id: string]: Value } } {
        return choiceModule.choiceValues;
    }

    get viewpointMapping(): {
        [viewpointId: number]: { [factorId: number]: ViewpointMapping };
    } {
        return viewpointsModule.viewpointMappings;
    }

    get valueKeys(): { [chioce_id: number]: { id: number; keys: string[][] } } {
        if (this.choices && this.headers) {
            return this.choices.reduce((acc, choice) => {
                return {
                    ...acc,
                    [choice.id]: {
                        id: choice.id,
                        keys: this.headers.map((factor) => {
                            return Object.keys(this.valueMap[choice.id]).filter(
                                (item) => item.startsWith(`${factor.id}-`)
                            );
                        }),
                    },
                };
            }, {});
        } else {
            return {};
        }
    }

    get columnData(): {
        [id: number]: {
            id: number;
            values: { index: number; length: number };
            data: Value[];
        };
    } {
        if (this.columnLengths && this.valueKeys) {
            return this.columnLengths.reduce((acc, item) => {
                return {
                    ...acc,
                    [item.id]: {
                        ...item,
                        data: this.valueKeys[item.id] &&
                        this.valueKeys[item.id].keys && 
                        this.valueKeys[item.id].keys[item.values.index] ?
                            this.valueKeys[item.id].keys[
                                item.values.index
                            ].map((key: string) => {
                                return this.valueMap[item.id][key];
                            }) : [],
                    },
                };
            }, {});
        } else {
            return {};
        }
    }

    get columnLengths(): {
        id: number;
        values: { index: number; length: number };
    }[] {
        if (this.valueKeys) {
            return Object.values(this.valueKeys).map((choice) => {
                return {
                    id: choice.id,
                    values: this.maxValue(
                        choice.keys.map((column) => column.length)
                    ),
                };
            });
        } else {
            return [];
        }
    }

    get tableLength(): number {
        return this.columnLengths.reduce((acc, item) => {
            if (item.values.length > acc) {
                return item.values.length;
            } else {
                return acc;
            }
        }, 0);
    }

    get longestChoice(): {
        id: number;
        values: { index: number; length: number };
    } {
        return this.columnLengths.reduce((acc, item) => {
            if (item.values.length > acc.values.length) {
                return item;
            } else {
                return acc;
            }
        }, this.columnLengths[0]);
    }

    get mainColumn(): Value[] {
        if (
            this.valueKeys &&
            this.valueKeys[this.longestChoice.id].keys &&
            this.valueKeys[this.longestChoice.id].keys[
                this.longestChoice.values.index
            ]
        ) {
            return this.valueKeys[this.longestChoice.id].keys[
                this.longestChoice.values.index
            ].map((key) => {
                return this.valueMap[this.longestChoice.id][key];
            });
        } else {
            return [];
        }
    }

    private maxValue(list: number[]) {
        return {
            index: list.indexOf(Math.max(...list)),
            length: Math.max(...list),
        };
    }

    /* Load Values */
    private async loadChoiceValues(choices: Choice[]): Promise<void> {
        if (choices.length) {
            await Promise.all(
                choices.map(async (choice) => {
                    if (this.tableId) {
                        await choiceModule.getChoiceValues({
                            choice_id: choice.id,
                            parent_id: this.tableId,
                        });
                    }
                })
            );
        }
    }

    private async loadChoiceScores(choices: Choice[]): Promise<void> {
        if (choices.length && this.viewpoint) {
            await Promise.all(
                choices.map(async (choice) => {
                    if (this.tableId) {
                        getScore({
                            viewpoint_id: this.viewpoint.id,
                            choice_id: choice.id,
                            factor_id: this.tableId,
                        });
                    }
                })
            );
        }
    }

    private async loadVpData(): Promise<void> {
        if (this.tableId && this.viewpoint) {
            await viewpointsModule.fetchViewpointMappings({
                viewpoint_id: this.viewpoint.id,
                parent_id: this.tableId,
            });
        }
    }

    mounted(): void {
        this.onTableIdChange();
    }

    @Watch("tableId")
    onTableIdChange(): void {
        if (this.tableId) {
            this.loadChoiceValues(this.choices);
            if (this.viewpoint) {
                this.loadChoiceScores(this.choices);
                this.loadVpData();
            }
        }
    }

    @Watch("viewpoint", { immediate: true, deep: true })
    onViewpointChange(): void {
        this.loadChoiceScores(this.choices);
        this.loadVpData();
    }

    @Watch("choices", { immediate: true, deep: true })
    onChoicesChange(newVal: Choice[], oldVal: Choice[] = []): void {
        if (newVal && newVal.length) {
            this.loadChoiceValues(newVal.filter((x) => !oldVal.includes(x)));
            this.loadChoiceScores(newVal.filter((x) => !oldVal.includes(x)));
        }
    }

    @Watch("tableLength")
    onTableLengthChange(): void {
        this.syncedRows = this.tableLength;
    }
}

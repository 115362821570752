
import { Component, Vue, Prop } from "vue-property-decorator";
import { Auth } from "aws-amplify";
import Users from "@/store/modules/Users";
import { getModule } from "vuex-module-decorators";
import { User } from "@/graphql/API";
import { Urls } from "@/Urls";

const usersModule = getModule(Users);
@Component({ components: {} })
export default class HeaderActions extends Vue {
    @Prop({ default: "", type: String })
    modalMode!: string;

    @Prop({ default: false, type: Boolean })
    isModalOpen!: boolean;

    @Prop({ default: true, type: Boolean })
    showAccount!: boolean;

    @Prop({ default: false, type: Boolean })
    showAppButton!: boolean;

    private denseViewMode = false;
    private readonly URLS = Urls;

    get notificationCount(): number {
        return 0;
    }

    private async logout() {
        // this.showAccount = false;
        setTimeout(async () => {
            try {
                await Auth.signOut();
                if (this.$route.name != "Home") {
                    this.$router.push(this.URLS.HOME);
                }
            } catch (error) {
                console.log("error signing out: ", error);
            }
        });
    }

    get currentUser(): User | null {
        return usersModule.currentUser;
    }

    get name(): string {
        let tempName = "";
        if (this.currentUser && this.currentUser.first) {
            tempName += this.currentUser.first;
            tempName += " ";
        }

        if (this.currentUser && this.currentUser.last) {
            tempName += this.currentUser.last;
        }
        return tempName;
    }
}


import { Component, Vue, Prop, PropSync } from "vue-property-decorator";

@Component({
    components: {},
})
export default class DragResizeHandler extends Vue {
    @Prop({ default: false, type: Boolean })
    rightAligned!: boolean;

    @Prop()
    elementRef!: string;

    @Prop()
    max!: number;

    @PropSync("width", { default: 0, type: Number })
    syncedWidth!: number;

    mounted(): void {
        if (this.elementRef) {
            this.setEvents();
        }
    }

    private setEvents() {
        this.$el?.addEventListener("mousedown", () => {
            document.body.style.cursor = "ew-resize";
            document.addEventListener("mousemove", this.resize, false);
        });
        document.addEventListener("mouseup", () => {
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", this.resize, false);
        });
    }

    private resize(e: MouseEvent) {
        const parentElem = this.$parent?.$refs[
            this.elementRef
        ] as HTMLElement | null;
        if (parentElem) {
            const newWidth = this.rightAligned
                ? window.innerWidth - e.clientX
                : e.clientX;
            if (!this.max || newWidth < window.innerWidth * this.max) {
                parentElem.style.width = `${newWidth}px`;
                this.syncedWidth = newWidth;
            }
        }
    }
}

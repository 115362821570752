
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import { Factor, ModelFilters } from "@/graphql/API";
import FactorCreator from "@/components/model/FactorCreator.vue";
import { FactorsIcons } from "@/IconLibrary";
import draggable from "vuedraggable";
import vuetify from "@/plugins/vuetify";
import {
    reorderFactors,
    updateOrderParentId,
    filterFactor,
} from "@/helpers/FactorHelper";

const modelModule = getModule(Factors);
@Component({
    components: {
        FactorCreator,
        draggable,
    },
})
export default class ModelTableFactor extends Vue {
    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop({ required: true })
    factor!: Factor;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop()
    selectedFactors!: number[];

    @Prop()
    editModel!: boolean;

    @Prop()
    filters!: ModelFilters;

    private readonly Icons: Record<string, string> = FactorsIcons;
    private drag = false;
    private open = false;

    get columns(): Factor[] {
        if (this.currentTreeLevel) {
            return this.currentTreeLevel
                .sort((a: Factor, b: Factor) =>
                    a.order_str.localeCompare(b.order_str)
                )
                .map((factor) => {
                    return Object.assign(factor, {
                        filterActive: filterFactor(factor, this.filters),
                    });
                });
        }
        return [];
    }

    get languageRight(): boolean {
        return vuetify.framework.rtl;
    }

    get types(): { text: string; id: string }[] {
        return modelModule.valueTypes;
    }

    private async loadChildren(factorId: number) {
        if (factorId != null) {
            const factorIds = await modelModule.fetchChildFactors(factorId);
            factorIds?.forEach((factor: Factor) => {
                modelModule.fetchChildFactors(factor.id);
            });
        }
    }

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get currentTreeLevel(): Factor[] {
        return this.factorTree[this.factor.id];
    }

    get isTableActive(): boolean {
        return filterFactor(this.factor, this.filters);
    }

    private clickFactor(e: KeyboardEvent, factor: Factor, mobile: boolean) {
        this.sendToggledFactor({ item: factor, multiple: e.shiftKey, mobile });
    }

    private sendToggledFactor(event: {
        item: Factor;
        multiple: boolean;
        mobile: boolean;
    }) {
        this.$emit("toggle-factor", event);
    }

    /* Eslint disabled for draggable event */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async dragChange(e: any, parentId: number) {
        //If factor moved in level - reorder factor within it's group
        if (e["moved"]) {
            const newOrderStr = reorderFactors(
                e.moved.element,
                parentId,
                e.moved.newIndex,
                e.moved.oldIndex,
                this.currentTreeLevel
            );
            if (newOrderStr)
                updateOrderParentId(e.moved.element, parentId, newOrderStr);
        }

        //Factor moved into different group- reorder factor within the new group
        // Table may not need this functionality as columns won't be moved between groups
        if (e["added"]) {
            const newOrderStr = reorderFactors(
                e.added.element,
                parentId,
                e.added.newIndex,
                e.added.newIndex,
                this.currentTreeLevel
            );
            if (newOrderStr)
                updateOrderParentId(e.added.element, parentId, newOrderStr);
        }
    }
}

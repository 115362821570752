
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Colour } from "@/graphql/API";
import ColourPicker from "@/components/values/ColourPicker.vue";
import ColourPalette from "@/components/mixins/ColourPalette";
import { mixins } from "vue-class-component";

@Component({
    components: {
        ColourPicker,
    },
})
export default class ColourPaletteList extends mixins(ColourPalette) {
    @Prop()
    list!: { [id: string]: Colour };

    @Prop()
    isComplementaryToggle!: boolean;

    // private readonly DefaultPalette: { [id: string]: Colour } = Palette;
    private colourList: { [id: string]: Colour } = {};
    private isComplementary = true;

    private async changeColour(index: string, colour: string): Promise<void> {
        if (this.colourList.hasOwnProperty(index)) {
            Vue.set(this.colourList, index, {
                hex: colour,
                name: `Accent ${index}`,
                id: index,
                editable: true,
            });
        } else {
            if (colour !== this.colourList[index].hex) {
                console.log("CHANGED");
                Vue.set(this.colourList, index, {
                    hex: colour,
                    name: `Accent ${index}`,
                    id: index,
                    editable: true,
                });
            }
        }
        await this.setColourList();
        this.$emit("new-list", this.colourList, this.saturation_palette);
    }

    private changeColourLabel(index: string, label: string) {
        if (this.colourList.hasOwnProperty(index)) {
            Vue.set(this.colourList[index], "name", label);
        } else {
            if (label !== this.editableDefaults[index].name) {
                console.log("CHANGED");
                Vue.set(
                    this.colourList,
                    index,
                    Object.assign(
                        { ...this.editableDefaults[index] },
                        { name: label }
                    )
                );
            }
        }
        this.$emit("new-list", this.colourList, this.saturation_palette);
    }

    private clearColour(index: string) {
        Vue.delete(this.colourList, index);
    }

    mounted(): void {
        this.onListChange();
    }

    @Watch("isComplementaryToggle")
    onisComplementaryToggleChange(): void {
        this.isComplementary = this.isComplementaryToggle;
    }

    @Watch("list")
    async onListChange(): Promise<void> {
        if (!this.list) {
            this.colourList = { ...this.editableDefaults };
        } else {
            this.colourList = { ...this.list };
        }
    }

    @Watch("isComplementary")
    async onComplemenatryChanged(val: boolean): Promise<void> {
        await this.setColourList();
        this.$emit("is-complementary-toggle", val);
        this.$emit("new-list", this.colourList, this.saturation_palette);
    }

    private resetPalette() {
        this.colourList = { ...this.editableDefaults };
    }

    private lightenColour(index: string) {
        const newColour = this.lightenDarkenColor({
            color: this.colourList[index].hex,
            percent: 0.1,
        });
        this.changeColour(index, newColour);
    }

    private darkenColour(index: string) {
        const newColour = this.lightenDarkenColor({
            color: this.colourList[index].hex,
            percent: -0.1,
        });
        this.changeColour(index, newColour);
    }

    private incRotateColor(index: string, val: number) {
        const newColour = this.rotateColor(this.colourList[index].hex, val);
        this.changeColour(index, newColour);
    }

    private incSaturateColor(index: string, val: number) {
        const newColour = this.saturateColor(this.colourList[index].hex, val);
        this.changeColour(index, newColour);
    }

    private async setColourList(): Promise<void> {
        const defaultPaletteValues = Object.values(this.editableDefaults);
        const rotationArray = this.isComplementary
            ? [-180, -60, 0, 60, 120, 240]
            : [-40, -20, 0, 20, 40, 60];

        Object.keys(this.editableDefaults).map((key: string, index: number) => {
            Vue.set(this.colourList, key, {
                hex: this.rotateColor(
                    this.colourList["vp--bg-accent-3"].hex,
                    rotationArray[index]
                ),
                name: `Accent ${index}`,
                id: defaultPaletteValues[index].id,
                editable: true,
            });
        });
    }
}

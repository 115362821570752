
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import { ClassSet, Factor, FactorType } from "@/graphql/API";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import Decisions from "@/store/modules/Decisions";
import Scoring from "@/store/modules/Scoring";
import FactorSelectMenu from "@/components/model/FactorSelectMenu.vue";

const modelModule = getModule(Factors);
@Component({
    components: {
        DialogCardTitle,
    },
})
export default class FactorTypeEditor extends Vue {
    private isFormValid = false;
    private newTypeScreen = false;
    private rules = [
        (v: string) =>
            v == null || !v.includes('"') || "Please use single quotes",
    ];

    private open = false;
    private typeName = "";
    private editingType: FactorType | null = null;
    private valueType = "";
    private clusterId = -1;
    private isEdit = false;
    private loading = false;
    private headers = [
        { text: "Name", value: "name", width: 100 },
        { text: "Value Type", value: "json", width: 30 },
        { text: "actions", value: "actions", width: 30 },
    ];
    private selectedFactor: number | null = null;
    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get classClusterList(): ClassSet[] {
        return getModule(Scoring).classClusterList;
    }

    get factors(): Factor[] {
        return modelModule.factors;
    }

    get types(): FactorType[] {
        return modelModule.factorTypeList;
    }

    get factorLabelSingular(): string {
        return getModule(Decisions).factorLabelSingular;
    }

    get valueTypes(): {
        text: string;
        id: string;
    }[] {
        return modelModule.valueTypes.filter(
            (type: { text: string; id: string }) =>
                !["group", "table"].includes(type.id)
        );
    }

    get factorMap(): { [id: number]: Factor } {
        return modelModule.factorMap;
    }

    private jsonFormatString(key: string, value: string): string {
        const keyValue = `{"${key}": "${value}"}`;
        const jsonString = JSON.stringify(keyValue);
        console.log(jsonString);
        return keyValue;
    }

    private getValueType(type: FactorType): string {
        const jsonOptions = type.json;
        console.log(jsonOptions);

        if (jsonOptions) {
            try {
                const jsonString = JSON.parse(jsonOptions);
                return jsonString["valueType"];
            } catch (e) {
                // Try / Catch block in case there is an issue with the JSON string loaded from Backend
                return "";
            }
        }

        return "";
    }

    private async saveType() {
        this.loading = true;
        // if (!this.isEdit)
        //     modelModule.createFactorType({
        //         name: this.typeName,
        //         json: this.jsonFormatString("valueType", this.valueType),
        //         defaultClusterId:
        //             this.clusterId >= 0 ? this.clusterId : undefined,
        //         factorId: this.selectedFactor,
        //     });
        // else if (this.editingType != null) {
        //     //Update Factor Type
        //     modelModule.updateFactorType({
        //         name: this.typeName,
        //         id: this.editingType.id,
        //         json: this.jsonFormatString("valueType", this.valueType),
        //         defaultClusterId:
        //             this.clusterId >= 0 ? this.clusterId : undefined,
        //         factorId: this.selectedFactor,
        //     });
        // }
        this.loading = false;
        this.resetType();
    }

    private async deleteType(id: number) {
        console.log(id);
        // await modelModule.deleteFactorType(id);
    }

    private selectFactorId(id: number) {
        console.log(id);
        this.selectedFactor = id;
    }

    private navigateToNewTypeView() {
        this.typeName = "";
        this.valueType = "";
        this.isEdit = false;
        this.editingType = null;
        this.clusterId = -1;
        this.newTypeScreen = true;
    }

    private resetType() {
        this.typeName = "";
        this.valueType = "";
        this.editingType = null;
        this.newTypeScreen = false;
        this.clusterId = -1;
    }

    private editType(type: FactorType) {
        this.typeName = type.name;
        this.editingType = type;
        this.valueType = this.getValueType(type);
        this.clusterId = type.default_cluster_id;
        this.isEdit = true;
        this.newTypeScreen = true;
    }
}

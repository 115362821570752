import { render, staticRenderFns } from "./DragFileUpload.vue?vue&type=template&id=29023b65&scoped=true&"
import script from "./DragFileUpload.vue?vue&type=script&lang=ts&"
export * from "./DragFileUpload.vue?vue&type=script&lang=ts&"
import style0 from "./DragFileUpload.vue?vue&type=style&index=0&id=29023b65&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29023b65",
  null
  
)

export default component.exports
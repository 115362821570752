
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import FactorCreator from "@/components/model/FactorCreator.vue";
import FactorDisplay from "@/components/model/FactorDisplay.vue";
import { Factor, ModelFilters } from "@/graphql/API";
import ModelTableFactor from "@/components/model/ModelTableFactor.vue";
import { FactorsIcons } from "@/IconLibrary";
import vuetify from "@/plugins/vuetify";
import draggable from "vuedraggable";
import {
    reorderFactors,
    updateOrderParentId,
    filterFactor,
    getAttributeFromJson,
} from "@/helpers/FactorHelper";

const modelModule = getModule(Factors);
@Component({
    name: "RecursiveModelFactors",
    components: {
        FactorCreator,
        FactorDisplay,
        ModelTableFactor,
        draggable,
    },
})
export default class RecursiveModel extends Vue {
    @Prop({ default: () => [], type: Array })
    recursiveFactors!: Factor[];

    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop()
    selectedFactors!: number[];

    @Prop()
    editModel!: boolean;

    @Prop()
    filters!: ModelFilters;

    private drag = false;
    private readonly Icons: Record<string, string> = FactorsIcons;

    get languageRight(): boolean {
        return vuetify.framework.rtl;
    }

    get factors(): Factor[] {
        return modelModule.factors;
    }

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get currentTreeLevel(): Factor[] {
        if (this.factorTree[this.parentId]) {
            return this.factorTree[this.parentId];
        } else return [];
    }

    get currentTreeLevelLength(): number {
        return this.currentTreeLevel.length;
    }

    private getFactorStyleClass(factor: Factor): string {
        if (factor.json && !factor.json.startsWith("v")) {
            return JSON.parse(factor.json).style_class
                ? JSON.parse(factor.json).style_class
                : "vp-body-1";
        }
        return "vp-body-1";
    }

    private async loadChildren(factorId: number) {
        if (factorId != null) {
            await modelModule.fetchChildFactors(factorId);
        }
    }

    private async deleteFactor(factor: Factor) {
        if (factor != null) {
            await modelModule.deleteFactorL([factor.id]);
            // await modelModule.deleteFactor(factor.id);
        }
    }

    private async deleteGroup(group: Factor) {
        if (group != null) {
            await modelModule.deleteGroupL([group.id]);
            // await modelModule.deleteGroup(group.id);
        }
    }

    private clickFactor(e: KeyboardEvent, factor: Factor, mobile: boolean) {
        this.sendToggledFactor({ item: factor, multiple: e.shiftKey, mobile });
    }

    private sendToggledFactor(event: {
        item: Factor;
        multiple: boolean;
        mobile: boolean;
    }) {
        this.$emit("toggle-factor", event);
    }

    /* Eslint disabled for draggable event */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async dragChange(e: any, parentId: number) {
        //If factor moved in level - reorder factor within it's group
        if (e["moved"]) {
            const newOrderStr = reorderFactors(
                e.moved.element,
                parentId,
                e.moved.newIndex,
                e.moved.oldIndex,
                this.currentTreeLevel
            );
            if (newOrderStr)
                updateOrderParentId(e.moved.element, parentId, newOrderStr);
        }

        //Factor moved into different group- reorder factor within the new group
        if (e["added"]) {
            const newOrderStr = reorderFactors(
                e.added.element,
                parentId,
                e.added.newIndex,
                e.added.newIndex,
                this.currentTreeLevel
            );
            if (newOrderStr)
                updateOrderParentId(e.added.element, parentId, newOrderStr);
        }
    }

    private getIconFromType(typeId: number): string {
        const type = getModule(Factors).factorTypes[typeId];
        if (type) {
            const jsonOptions = type.json;
            return this.Icons[
                getAttributeFromJson(jsonOptions, "valueType", "string")
            ];
        }
        return this.Icons["string"];
    }
}


import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import DecisionSelector from "@/components/decisions/DecisionSelector.vue";
import WorkspaceSelector from "@/components/workspaces/WorkspaceSelector.vue";
import { Urls } from "@/Urls";
import { Auth } from "aws-amplify";
import Decisions from "@/store/modules/Decisions";

@Component({
    components: {
        DecisionSelector,
        WorkspaceSelector,
    },
})
export default class SideNav extends Vue {
    private readonly URLS = Urls;

    private logout() {
        Auth.signOut();
    }

    get choiceLabelSingular(): string {
        return getModule(Decisions).choiceLabelSingular;
    }

    get factorLabelSingular(): string {
        return getModule(Decisions).factorLabelSingular;
    }
}

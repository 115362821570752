import { render, staticRenderFns } from "./NumberEditor.vue?vue&type=template&id=076a0fba&scoped=true&"
import script from "./NumberEditor.vue?vue&type=script&lang=ts&"
export * from "./NumberEditor.vue?vue&type=script&lang=ts&"
import style0 from "./NumberEditor.vue?vue&type=style&index=0&id=076a0fba&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "076a0fba",
  null
  
)

export default component.exports
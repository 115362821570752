var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppInterface',{attrs:{"header":_vm.appTitle,"app":_vm.selectedApp,"tab":_vm.selectedTab,"open-left":_vm.leftMenuOpen,"mobile-open":_vm.mobileOpen},on:{"update:openLeft":function($event){_vm.leftMenuOpen=$event},"update:open-left":function($event){_vm.leftMenuOpen=$event},"update:mobileOpen":function($event){_vm.mobileOpen=$event},"update:mobile-open":function($event){_vm.mobileOpen=$event}},scopedSlots:_vm._u([(_vm.showTabEdit)?{key:"appEdit",fn:function(){return [_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'bottom' }),expression:"{ placement: 'bottom' }"}],attrs:{"icon":"","small":"","content":"Edit this tab","href":_vm.editorLink}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]},proxy:true}:null],null,true)},[(_vm.loadingOverlay)?[_c('div',{staticClass:"flex-direction-column flex-grow-1 d-flex align-center justify-center"},[_c('div',{staticClass:"vp-warning"},[_c('Logo',{attrs:{"icon":"","loading":""}})],1)])]:[(_vm.selectedApp && _vm.decisionId)?[_vm._l((_vm.activeTabs),function(tabId,index){return _c('div',{key:index,staticClass:"vp-app-wrapper",class:{
                    'vp-app-wrapper--active':
                        _vm.selectedTab && _vm.selectedTab.id == tabId,
                }},[_c('keep-alive',[_c(_vm.tabLoading || !_vm.tabIndex[tabId]
                                ? 'TabLoading'
                                : _vm.tabIndex[tabId].type &&
                                  _vm.tabIndex[tabId].type != ''
                                ? _vm.tabIndex[tabId].type
                                : 'TabError',{tag:"component",attrs:{"app":_vm.selectedApp,"tab-settings":_vm.tabIndex[tabId],"embed":true,"header":_vm.currApp && _vm.currApp.header != ''
                                ? _vm.currApp.header
                                : null,"banner-image":_vm.currApp && _vm.currApp.image != ''
                                ? _vm.currApp.image
                                : null,"banner-align":_vm.appOptions && _vm.appOptions.image_align
                                ? _vm.appOptions.image_align
                                : 'center'}})],1)],1)}),(
                    !_vm.selectedTab && (_vm.tabIdParam || !_vm.selectedApp.tabs.length)
                )?_c('div',{staticClass:"flex-direction-column flex-grow-1 d-flex align-center justify-center"},[_c('div',{staticClass:"vp-warning"},[_c('v-icon',{attrs:{"color":"grey","x-large":""}},[_vm._v("mdi-alert")]),_c('h1',[_vm._v("Tab Not Found")]),(_vm.selectedApp.tabs.length)?_c('p',[_vm._v(" Please choose a tab in the sidebar. ")]):_c('p',[_vm._v("This app has no tabs setup.")])],1)]):_vm._e()]:[_c('div',{staticClass:"flex-direction-column flex-grow-1 d-flex align-center justify-center"},[_c('div',{staticClass:"vp-warning"},[_c('v-icon',{attrs:{"color":"grey","x-large":""}},[_vm._v("mdi-alert")]),_c('h1',[_vm._v("App Not Found")])],1)])]]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
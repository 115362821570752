
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Choice,
    Viewpoint,
    Value,
    ScoreOptions,
    FactorOptions,
} from "@/graphql/API";
import Choices from "@/store/modules/Choices";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";

const choiceModule = getModule(Choices);

@Component({
    components: {
        FactorValueDisplay,
    },
})
export default class CompareTableHeader extends Vue {
    @Prop({ default: 1, type: Number })
    rows!: number;

    @Prop()
    factors!: Factor[];

    @Prop()
    choice!: Choice;

    @Prop()
    rowIds!: string[];

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: true, type: Boolean })
    showRowLabel!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    get rowLength(): number {
        return 0;
    }

    get valueMap(): { [id: string]: Value } {
        if (this.choice) {
            return choiceModule.choiceValues[this.choice.id];
        } else {
            return {};
        }
    }
}

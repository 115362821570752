import vuetify from "@/plugins/vuetify";
import { WorkspaceStyles } from "@/graphql/API";

export async function loadWorkspaceStyles(json: string): Promise<void> {
    const styles: WorkspaceStyles = JSON.parse(json);
    if (styles?.ui_colours) {
        updateUiColours(styles.ui_colours);
    } else {
        await resetWorkspaceStyles();
    }
}

export async function resetWorkspaceStyles(): Promise<void> {
    vuetify.framework.theme.themes.light.primary = "#673ab7";
    vuetify.framework.theme.themes.dark.primary = "#673ab7";
    await setPrimaryColours("#673ab7");
}

export async function updateUiColours(colours: {
    [id: string]: string;
}): Promise<void> {
    await Promise.all(
        Object.keys(colours).map(async (key) => {
            if (key == "primary" && colours[key]) {
                await setPrimaryColours(colours[key]);
            }
            vuetify.framework.theme.themes.light[key] = colours[key];
            vuetify.framework.theme.themes.dark[key] = colours[key];
        })
    );
}

async function convertHexRgb(colour: string): Promise<string> {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const hex = colour.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`;
    } else {
        return "";
    }
}

async function convertHexRgba(colour: string, opacity: number): Promise<string> {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const hex = colour.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`;
    } else {
        return "";
    }
}

async function setPrimaryColours(color: string): Promise<void> {
    const light = await convertHexRgba(color, 0.2);
    const dark = await convertHexRgba(color, 0.3);

    let style: HTMLElement | null = null;
    style = document.querySelector(":root");
    if (style) {
        style.setAttribute(
            "style", `--vp-primary-hover: ${light}; --vp-primary-hover-darken-1: ${dark}`
        );
    }
}

import {
    Action,
    config,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { API } from "aws-amplify";
import { getAllWorkspaces } from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import {
    CreateWorkspace,
    DeleteWorkspace,
    GetAllWorkspaces,
    UpdateWorkspace,
} from "@/graphql/custom";
import {
    createWorkspace,
    deleteWorkspace,
    updateWorkspace,
} from "@/graphql/mutations";
import {
    User,
    UserPermission,
    Workspace,
    WorkspaceStyles,
} from "@/graphql/API";
import Vue from "vue";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";

config.rawError = true;

const name = "workspaces";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Workspaces extends VuexModule {
    workspaces: { [workspaceId: number]: Workspace } = {};
    selectedWorkspaceId: number | null = null;
    createdWorkspaceId: number | null = null;
    dataLoading = false;
    workspaceUsers: { [id: string]: UserPermission } = {};

    get selectedWorkspace(): Workspace | null {
        if (this.selectedWorkspaceId) {
            return this.workspaces[this.selectedWorkspaceId];
        } else {
            return null;
        }
    }

    get workspaceList(): Workspace[] {
        if (this.workspaces != null) {
            return Object.values(this.workspaces).sort((a, b) =>
                a.name
                    .toLocaleLowerCase()
                    .localeCompare(b.name.toLocaleLowerCase())
            );
        }
        return [];
    }

    get workspaceStyles(): WorkspaceStyles {
        if (this.selectedWorkspace && this.selectedWorkspace.json) {
            return JSON.parse(this.selectedWorkspace.json);
        } else {
            return {};
        }
    }

    get workspaceLogo(): string | null {
        if (this.workspaceStyles && this.workspaceStyles.logo) {
            return this.workspaceStyles.logo;
        } else {
            return null;
        }
    }

    @Mutation
    clear(): void {
        this.workspaceUsers = {};
    }

    @Mutation
    setWorkspaceUsers(users: UserPermission[]): void {
        users.forEach((user: UserPermission) =>
            Vue.set(this.workspaceUsers, user.user.id, user)
        );
    }

    @Mutation
    setWorkspaces(workspaces: { [workspaceId: number]: Workspace }): void {
        this.workspaces = workspaces;
    }

    @Mutation
    addWorkspace(workspace: Workspace): void {
        Vue.set(this.workspaces, workspace.id, workspace);
    }

    @Mutation
    removeWorkspace(workspace: Workspace): void {
        Vue.delete(this.workspaces, workspace.id);
    }

    @Mutation
    setWorkspace(workspaceId: number | null): void {
        if (workspaceId != null) {
            this.selectedWorkspaceId = workspaceId;
        } else {
            this.selectedWorkspaceId = null;
        }
    }

    @Mutation
    setCreatedWorkspace(workspaceId: number | null): void {
        if (workspaceId != null) {
            this.createdWorkspaceId = workspaceId;
        } else {
            this.createdWorkspaceId = null;
        }
    }

    @Mutation
    setLoading(loading: boolean): void {
        this.dataLoading = loading;
    }

    @Action
    async clearActiveWorkspace(): Promise<void> {
        this.clear();
    }

    @Action
    async updateLoading(loading: boolean): Promise<void> {
        this.setLoading(loading);
    }

    @Action
    async fetchWorkspaces(): Promise<Workspace[]> {
        const workspaces = (await GRAPHQL_API.graphqlQueryRequest({
            query: getAllWorkspaces,
            variables: {},
            error: true,
        })) as GraphQLResult<GetAllWorkspaces>;
        if (workspaces.data && workspaces.data.getAllWorkspaces != null) {
            const dictionary = Object.assign(
                {},
                ...workspaces.data.getAllWorkspaces.map((x: any) => ({
                    [x.id]: x,
                }))
            );
            this.setWorkspaces(dictionary);
            return workspaces.data.getAllWorkspaces;
        }
        return [];
    }

    @Action
    async selectWorkspace(id: number | null): Promise<void> {
        this.setWorkspace(id);
    }

    @Action
    async createWorkspace(payload: {
        name: string;
        json?: string;
        description?: string;
    }): Promise<Workspace | null> {
        const name = payload.name;
        if (name != null) {
            const json = payload.json;
            const description = payload.description;
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createWorkspace,
                variables: {
                    name,
                    json,
                    description,
                },
            })) as GraphQLResult<CreateWorkspace>;
            const workspace = res.data?.createWorkspace?.workspaces[0];

            if (workspace) {
                this.setCreatedWorkspace(workspace.id);
                this.addWorkspace(workspace);
                this.setWorkspace(workspace.id);
                return workspace;
            }
        }
        return null;
    }

    @Action
    async updateWorkspace(payload: {
        workspace_id: number;
        name: string;
        json?: string;
        description?: string;
    }): Promise<Workspace | null> {
        const name = payload.name;
        const id = payload.workspace_id;
        const json = payload.json;
        const description = payload.description;
        if (name != null && id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: updateWorkspace,
                variables: {
                    name,
                    id,
                    json,
                    description,
                },
            })) as GraphQLResult<UpdateWorkspace>;

            const workspace = res.data?.updateWorkspace?.workspaces[0];
            if (workspace) {
                this.addWorkspace(workspace);

                return workspace;
            }
        }
        return null;
    }

    @Action
    async deleteWorkspace(id: number): Promise<Workspace | null> {
        if (id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteWorkspace,
                variables: {
                    id,
                },
            })) as GraphQLResult<DeleteWorkspace>;
            const workspace = res.data?.deleteWorkspace?.workspaces[0];

            if (workspace) {
                this.removeWorkspace(workspace);
                this.selectWorkspace(null);
                return workspace;
            }
        }

        return null;
    }

    @Action
    addWorkspaces(workspaces: Workspace[]): void {
        if (workspaces == null) {
            return;
        }

        workspaces.forEach((ws: Workspace) => {
            this.addWorkspace(ws);
            if (this.createdWorkspaceId == ws.id) {
                this.setWorkspace(ws.id);
                this.setCreatedWorkspace(null);
            }
        });
    }

    @Action
    removeWorkspaces(workspaces: Workspace[]): void {
        if (workspaces == null) {
            return;
        }

        workspaces.forEach((ws: Workspace) => {
            this.removeWorkspace(ws);
            if (this.selectedWorkspaceId == ws.id) {
                this.setWorkspace(null);
            }
        });
    }

    @Action
    async getWorkspaceUsers(id: number): Promise<UserPermission[] | null> {
        if (id != null) {
            try {
                const users = await GRAPHQL_API.getWorkspaceUsers(id);
                this.setWorkspaceUsers(users);
                return users;
            } catch (e) {
                console.log("%cError fetching users:", "color: red; font-weight: bold;");
                console.log(e);
                return [];
            }
        }

        return null;
    }
}


import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Viewpoint } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import FlashNotifications from "@/store/modules/FlashNotifications";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import Viewpoints from "@/store/modules/Viewpoints";
import VpDialog from "@/components/ui/VpDialog.vue";
import RichTextEditor from "@/components/ui/RichTextEditor.vue";

const viewpointsModule = getModule(Viewpoints);
const decisionsModule = getModule(Decisions);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    components: {
        DialogCardTitle,
        VpDialog,
        RichTextEditor,
    },
})
export default class ViewpointCreator extends Vue {
    @PropSync("open", Boolean)
    syncedOpen!: boolean;

    @Prop({ default: true, type: Boolean })
    showIcon!: boolean;

    @Prop()
    viewpoint!: Viewpoint;

    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop({ default: true, type: Boolean })
    showBtn!: boolean;

    @Prop({ default: true, type: Boolean })
    dialog!: boolean;

    @Prop({ default: false, type: Boolean })
    isCopy!: boolean;

    @Prop({ default: false, type: Boolean })
    isDelete!: boolean;

    private viewpointName = "";
    private viewpointDescription = "";
    private deleteMode = false;

    get dialogTitle(): string {
        if (this.isCopy) {
            return `Duplicate ${this.viewpointLabelSingular}`;
        } else if (this.isEdit) {
            return `Edit ${this.viewpointLabelSingular}`;
        } else {
            return `Create ${this.viewpointLabelSingular}`;
        }
    }

    get viewpointId(): number | null {
        return this.viewpoint ? this.viewpoint.id : null;
    }

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get viewpointLabelSingular(): string {
        return decisionsModule.viewpointLabelSingular;
    }

    get deleteToggle(): boolean {
        return this.deleteMode || this.isDelete;
    }

    private async saveViewpoint(): Promise<void> {
        if (this.selectedDecisionId && this.viewpoint && this.viewpointName) {
            try {
                await viewpointsModule.updateViewpoint({
                    viewpointId: this.viewpoint.id,
                    name: this.viewpointName,
                    description: this.viewpointDescription,
                });
                flashNotificationsModule.success({
                    message: `${this.viewpointName} was successfully saved`,
                    duration: 3000,
                });
            } catch (err) {
                console.log(err);
                flashNotificationsModule.error({
                    message: "Something went wrong. Please try again",
                    duration: 3000,
                });
            }
        }
        this.closeDialog();
    }

    private async createViewpoint(): Promise<void> {
        if (this.selectedDecisionId && this.viewpointName) {
            this.$emit("loading-start");
            if (this.isCopy && this.viewpoint) {
                let viewpoints = await this.copyViewpoint();
                this.$emit("new-viewpoints", [viewpoints]);
            } else {
                let viewpoints = await Promise.all(
                    this.viewpointName.split(/\r?\n/).map(async (name) => {
                        return await this.newViewpoint(name);
                    })
                );
                this.$emit("new-viewpoints", viewpoints);
            }
            this.viewpointName = "";
            this.$emit("loading-end");
            this.closeDialog();
        }
    }

    private async newViewpoint(name: string): Promise<Viewpoint | null> {
        if (this.selectedDecisionId) {
            try {
                let newViewpoint = await viewpointsModule.createViewpointL({
                    name: name,
                    decisionId: this.selectedDecisionId,
                });
                flashNotificationsModule.success({
                    message: `${this.viewpointName} was successfully created`,
                    duration: 3000,
                });
                return newViewpoint ? newViewpoint.viewpoints[0] : null;
            } catch (err) {
                console.log(err);
                flashNotificationsModule.error({
                    message: "Something went wrong. Please try again",
                    duration: 3000,
                });
                return null;
            }
        } else {
            return null;
        }
    }

    private async copyViewpoint(): Promise<Viewpoint | null> {
        try {
            let newViewpoint = await viewpointsModule.copyViewpoint({
                name: this.viewpointName,
                id: this.viewpoint.id,
                description: this.viewpointDescription,
            });
            flashNotificationsModule.success({
                message: `${this.viewpointName} was successfully created`,
                duration: 3000,
            });
            return newViewpoint;
        } catch (err) {
            console.log(err);
            flashNotificationsModule.error({
                message: "Something went wrong. Please try again",
                duration: 3000,
            });
            return null;
        }
    }

    private async deleteViewpoint(): Promise<void> {
        this.$emit("loading-start");
        try {
            await viewpointsModule.deleteViewpoint(this.viewpoint.id);
            flashNotificationsModule.success({
                message: `${this.viewpointName} was successfully deleted`,
                duration: 3000,
            });
        } catch (err) {
            console.log(err);
            flashNotificationsModule.error({
                message: "Something went wrong. Please try again",
                duration: 3000,
            });
        }
        this.$emit("loading-end");
        this.closeDialog();
    }

    private closeDialog(): void {
        this.syncedOpen = false;
        this.viewpointName = "";
        this.deleteMode = false;
        this.$emit("dialog-close");
    }

    private async updateTextDescription(descriptionValue: string) {
        this.viewpointDescription = descriptionValue;
    }

    mounted(): void {
        this.onViewpointChange();
    }

    @Watch("isEdit")
    onIsEdit(): void {
        this.onViewpointChange();
    }

    @Watch("viewpointId")
    onViewpointChange(): void {
        if (this.isEdit && this.viewpoint) {
            this.viewpointName = this.viewpoint.name;
            this.viewpointDescription = this.viewpoint.description
                ? this.viewpoint.description
                : "";
        } else if (this.isCopy && this.viewpoint) {
            this.viewpointName = `${this.viewpoint.name} copy`;
            this.viewpointDescription = this.viewpoint.description
                ? this.viewpoint.description
                : "";
        } else {
            this.viewpointName = "";
            this.viewpointDescription = "";
        }
    }

    @Watch("isCopy")
    onIsCopyChange(): void {
        this.onViewpointChange();
    }
}


import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor } from "@/graphql/API";
import Factors from "@/store/modules/Factors";

const modelModule = getModule(Factors);


@Component({
    name: "ModelNav",
    components: {
    },
})
export default class ModelNav extends Vue {
    @PropSync("factorId")
    syncedFactorId!: number;

    @Prop({ default: -1, type: Number })
    rootId!: number;

    private currParent = -1;
    private currIndex = 0;

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get currentTreeLevel(): Factor[] {
        if (this.factorTree[this.rootId]) {
            return this.factorTree[this.rootId];
        } else return [];
    }

    mounted(): void {
        this.currParent = this.rootId;
    }
}

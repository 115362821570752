
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import ColourPalette from "@/components/mixins/ColourPalette";
import Viewpoints from "@/store/modules/Viewpoints";
import Scoring from "@/store/modules/Scoring";
import {
    ViewpointMapping,
    Rule,
    ClassSet,
    ScoreClass,
    ScoreClassSettings,
} from "@/graphql/API";
import ClassSelection from "@/components/ui/ClassSelection.vue";

const viewpointsModule = getModule(Viewpoints);
const scoringModule = getModule(Scoring);

@Component({
    components: {
        ClassSelection,
    },
})
export default class FactorClassScore extends mixins(ColourPalette) {
    @Prop({ default: -1, type: Number })
    viewpointId!: number;

    @Prop({ default: -1, type: Number })
    factorId!: number;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop()
    classId!: number;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop()
    classRule!: Rule;

    @Prop()
    viewpointMap!: ViewpointMapping | null;

    private selectedItem: {
        index: number;
        score: number;
        class_id: number | null;
        json: string;
        name: string;
    } | null = null;

    private cluster: ClassSet | null = null;

    get scoreClasses(): { [id: number]: ScoreClass } {
        return scoringModule.scoreClasses;
    }

    get viewpointMappings(): {
        [viewpointId: number]: {
            [factorId: number]: ViewpointMapping;
        };
    } {
        return viewpointsModule.viewpointMappings;
    }

    get currentViewpointMapping(): ViewpointMapping | null {
        return this.viewpointMap;
    }

    get showSelector(): boolean {
        if (this.classRule) {
            return true;
        } else {
            return false;
        }
    }

    get clusterId(): number | null {
        if (
            this.currentViewpointMapping &&
            this.currentViewpointMapping.set_id
        ) {
            return this.currentViewpointMapping.set_id;
        } else {
            return null;
        }
    }

    get classSets(): { [id: number]: ClassSet } | null {
        return scoringModule.classClusters;
    }

    get clusterMappings(): { [id: string]: ScoreClass } {
        if (this.cluster && this.cluster.mappings) {
            return this.cluster.mappings.reduce((obj, item) => {
                return {
                    ...obj,
                    [item.index]: this.scoreClasses[item.class_id],
                };
            }, {});
        }
        return {};
    }

    get items(): {
        index: number;
        score: number;
        class_id: number | null;
        json: string;
        name: string;
    }[] {
        if (this.classRule && this.classRule.is_range) {
            return this.classRule.ranges
                .filter((item) => !item.bigger_than)
                .map((item) => {
                    return {
                        index: item.score,
                        score: item.at_least,
                        class_id: this.clusterMappings[item.score]
                            ? this.clusterMappings[item.score].id
                            : null,
                        name: this.clusterMappings[item.score]
                            ? this.clusterMappings[item.score].name
                            : "",
                        json: this.clusterMappings[item.score]
                            ? this.clusterMappings[item.score].json
                            : "",
                    };
                })
                .filter((item) => item.class_id != null)
                .sort((a, b) => b.score - a.score);
        } else {
            return [];
        }
    }

    private setScoreClass(item: {
        index: number;
        score: number;
        class_id: number;
        name: string;
    }): void {
        this.$emit("class-select", item);
    }

    mounted(): void {
        this.onClassIdChange();
        this.onClusterIdChange();
    }

    @Watch("classId")
    onClassIdChange(): void {
        if (this.classId) {
            let searched = this.items.find(
                (item) => item.class_id == this.classId
            );
            this.selectedItem = searched ? searched : null;
        } else {
            this.selectedItem = null;
        }
    }

    @Watch("clusterId")
    async onClusterIdChange(): Promise<void> {
        if (this.clusterId) {
            if (this.classSets && this.classSets[this.clusterId] != null) {
                this.cluster = this.classSets[this.clusterId];
            } else {
                this.cluster = await scoringModule.fetchClassCluster(
                    this.clusterId
                );
            }
        } else {
            this.cluster = null;
        }
        this.onClassIdChange();
    }
}

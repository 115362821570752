
import {
    ApexChartOptions,
    BarChartOptions,
    HistogramChartOptions,
} from "@/store/modules/ApexChartOptions";
import { mixins } from "vue-class-component";
import ApexChart from "@/components/common/ApexChart.vue";
// import apexchart from "vue-apexcharts";
// import { HorizontalBar } from 'vue-chartjs';
import {
    Component,
    Prop,
    Vue,
    Watch,
    Mixins,
    Emit,
} from "vue-property-decorator";
import ColourPalette from "@/components/mixins/ColourPalette";

@Component({
    components: {},
})
export default class BarChart extends mixins(ColourPalette) {
    /**
     * Reset chart
     */

    @Prop({ type: String, default: '' })
    xTitle!: string;

    @Prop()
    offsetX!: number;

    @Prop()
    offsetY!: number;

    @Prop({ type: String, default: '' })
    yTitle!: string;

    @Prop({ type: Boolean, default: false })
    resetChart!: boolean;

    @Prop({ type: Boolean, default: true })
    isHorizontal!: boolean;

    @Prop({ type: Boolean, default: false })
    isHistogram!: boolean;

    @Prop({ type: Boolean, default: false })
    isBarCount!: boolean;

    @Prop({
        type: Object,
        default: () => ({
            data: {
                series: [],
            },
            title: {
                text: '',
            },
            xaxis: {
                categories: [],
            },
        }),
    })
    chartData!: any;

    /**
     * Chart options(If override default options)
     */
    @Prop({ type: Number, default: 500 })
    height!: number;

    /**
     * Chart options(If override default options)
     */
    @Prop({ type: Number, default: 900})
    width!: number;

    /**
     * Chart options(If override default options)
     */
    @Prop({ type: Boolean, default: false })
    stacked!: boolean;

    @Prop({ type: Boolean, default: true })
    showLabels!: boolean;

    get chartColours(): string[] {
        return Object.values(this.palette)
            .filter((item) => {
                return item.editable || item.name.startsWith("Preset");
            })
            .map((item) => item.hex);
    }

    get series() {
        return this.chartData.data.series;
    }
    get isHorizontal2(): boolean {
        if (this.isBarCount) {
            return false;
        } else {
            return this.isHorizontal;
        }
    }

    private barColors = ["#AF0069", "#511845", "#C70039", "#FF5733", "#FE346E", "#72147E", "#8843F2", "#EF2F88", "#370665", "#DB6B97", "#548CFF", "#005cce", "#247fb8", "#702121", "#780f49", "#641766", "#1f2391", "#003c88", "#18557b", "#e69293", "#f57cbf", "#df81e1", "#b1abef", "#6db4ed", "#94c9ea", "#f0b6b8", "#ffa3d9", "#e9abeb", "#cac7f5", "#a1ccf2", "#b8dbf1", "#ffbcc7", "#ffb4e3", "#ffbcff", "#d8d5ff", "#92e3ff", "#96ecff"]
    //when there are more than 25 bars needed (this would include the darkest colors in the first 6 positions). If there are less than 25 colors, the ramp should not include the 6 darkest ones at all.
    // heather's ["#4f2140","#8b5570","#F2C14E","#4a051c","#be93dc","#541412","#8c176d","#46237a","#f2c14e","#ba1215","#ec4067","#c34f5a","#c1363d","#d6953a","#c56630","#eb7f33","#febeb0","#1f363d","#40798c","#24928d","#4785ff","#8ac4ff","#70a9a1","#9ec1a3","#c4e675","#ebca86","#392d2e", "#013328", "#4E3822", "#182523", "#732C2C"] 
    //['#7C688D', '#B95096', '#BE93DC', '#311847', '#E09F7D', '#50BA73', '#C1363D', '#F47C36', '#EA9C83','#471A39', '#666150', '#DCD6E1', 'EBCA86', 'E2DFB6', 'A04082', '38618C']
    /**
     * initialize
     */
    private rearrangeBarColors(originalArray: any[]): any[] {
        let series = this.series
        if(series.length > 25) {
            this.barColors = [ "#ffbcc7", "#ffb4e3", "#ffbcff", "#d8d5ff", "#92e3ff", "#96ecff", "#a83332", "#b4176d", "#962399", "#3939c9", "#005cce", "#247fb8", "#702121", "#780f49", "#641766", "#1f2391", "#003c88", "#18557b", "#e69293", "#f57cbf", "#df81e1", "#b1abef", "#6db4ed", "#94c9ea", "#f0b6b8", "#ffa3d9", "#e9abeb", "#cac7f5", "#a1ccf2", "#b8dbf1"]
        }
        if(series[series.length -1].name == "Other Factors") {
            let numFactors = this.series.length
            let barColors = this.barColors.slice(0,series.length-1)
            // console.log("SLICE", 'length', series.length,"list", barColors)
            barColors.push('#D3D3D3')
            // console.log("adding grey to the end", barColors)      
            return barColors;
        } else {
            return originalArray;
        }
    }
    
    get chartOptions() {
        const data = this.chartData;
        //if(data.series)
        let chartOptions: BarChartOptions = {
            series: this.chartData.data.series,
            chart: {
                spacing:[20, 20, 20, 20],
                height: this.height,
                width: this.width,
                type: this.isHistogram? 'histogram': 'bar',
                redrawOnWindowResize: false,
                redrawOnParentResize: false,
                offsetX: this.offsetX,
                offsetY: this.offsetY,
                stacked: false,//this.stacked,
                toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                },
                animations: {
                    enabled: true,
                    easing: 'easein',
                    speed: 10,
                    animateGradually: {
                        enabled: false,
                        delay: 0
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 10
                    },
                },
            },
            colors: this.chartColours,
            plotOptions: { 
                bar: {
                    horizontal: this.isBarCount ? false: this.isHorizontal,
                    dataLabels: {
                        position: 'center', // top, center, bottom
                    },
                    // endingShape: 'round', // flat, rounded // deprecated
                    columnWidth: '40%',
                    barHeight: '50%',
                }
            },
            dataLabels: {
                enabled: false,
                formatter: function (val: any) {
                    return String(val ? val.toFixed(0) : '');
                },
                offsetY: 0,
                offsetX: 1,  //1
                style: {
                    fontSize: '14px',
                    colors: ['#ffffff'],
                },
            },
            xaxis: {
                offsetX: 0,
                offsetY: 0,
                tickAmount: this.isHistogram ? 10 : 10,
                // min: 0,
                // max: this.checkDataHasValue() ? undefined : 1,
                type: this.isHistogram? 'category' :'numberic',
                categories: this.chartData.xaxis.categories,
                position: 'bottom',
                //tickPlacement: this.isHistogram ? 'between' : undefined,
                labels: {
                    show: this.showLabels,
                    style: {
                        colors: '#0A4B71',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                    rotate: -40,

                    
                    formatter: this.isHistogram ? function (data: any) {
                        if (typeof data == 'string') {
                            return data.length >= 20 ? data.substring(0, 17) + '...' : data;
                        } else if (typeof data == 'number') {
                            return data.toFixed(0);
                        }
                    } : this.isBarCount ?
                    function (data: any) {
                        if (typeof data == 'string') {
                            return data.length >= 20 ? data.substring(0, 17) + '...' : data;
                        } else if (typeof data == 'number') {
                            return data.toFixed(0);
                        }
                    } :
                    function (data: any) {
                        if (typeof data == 'string') {
                            return data.length >= 14 ? data.substring(0, 10) + '...' : data;
                        } 
                        // else if (typeof data == 'number' && data.toString().charAt(data.toString().length -1) == '%')  {
                        //     return (data *100).toFixed(2) + '%';
                        // }
                        else if (typeof data == 'number')  {
                            return (data *100).toFixed(0) + '%';
                            // return (data).toFixed(2);
                        }
                    },
                },
                title: {
                    text: this.isHorizontal ? this.yTitle : this.xTitle,
                    offsetX: this.isHorizontal ? 12 : 0,
                    style: {
                        color: '#0A4B71',
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                    },
                },
                axisBorder: {
                    show: false,
                    offsetX: 0,
                },
                axisTicks: {
                    show: false,
                    offsetX: 0
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        },
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
            yaxis: {
                tickAmount: 10,
                max: this.checkDataHasValue() ? 1 : 1,
                axisBorder: {
                    show: false,
                    offsetX: -12
                },
                axisTicks: {
                    show: true,
                    offsetX: 25
                },
                title: {
                    text: this.isHorizontal ? this.xTitle : this.yTitle,
                    offsetX: this.isHorizontal ? 15 : 0,
                    style: {
                        color: '#0A4B71',
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                    },
                },
                labels: {
                   
                    maxWidth: 400,
                    minWidth: this.isHorizontal ? 80 : 80,
                    show: this.showLabels,
                    style: {
                        colors: '#0A4B71',
                        fontSize: '12px',
                        fontFamily: 'Nunito, Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                    offsetX: !this.isHorizontal ? 0 : 0,
                    offsetY: 0,

                    formatter: this.isHistogram 
                                ? function (data: any) {
                        if (typeof data == 'string') {
                            return data.length >= 30 ? data.substring(0, 27) + '...' : data;
                        } else if (typeof data == 'number') {
                            return data;
                        }
                    }: this.isBarCount ? 
                    function (data: any) {
                        if (typeof data == 'string') {
                            return data.length >= 30 ? data.substring(0, 27) + '...' : data;
                        } else if (typeof data == 'number') {
                            return data.toFixed(0);
                        }
                    } :
                    function (data: any) {
                        if (typeof data == 'string') {
                            return data.length >= 30 ? data.substring(0, 27) + '...' : data;
                        } else if (typeof data == 'number') {
                            return (data *100).toFixed(0) + '%';
                        }
                    }
                    ,
                },
            },
            tooltip: {
                enabled: true,
            },
            grid: {
                show: this.isHistogram ? false: this.isBarCount? false: true,
                borderColor: '#C0C0C0',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: this.isHorizontal,
                    },
                },
                yaxis: {
                    lines: {
                        show: !this.isHorizontal,
                    },
                },
                row: {
                    colors: undefined,
                    opacity: 0.5,
                },
                column: {
                    colors: undefined,
                    opacity: 0.5,
                },
                padding: {
                    top: 10,
                    right: 0,
                    bottom: 10,
                    left: 10,
                },
            },
            legend:  {
                // verticalAlign: 'right',
                // labelFormatter: function(labelText){
                //     if (labelText.length > 20) {
                //         return labelText.substring(0, 20) + '...';
                //     } else {
                //         return labelText;
                //     }

                // },
                itemWidth: 20,
                fontSize: "10",
                markers: {
                
                    width: 10,
                    height: 10,
                    offsetX: -2,
                    offsetY: 0
                    

                },
                
                itemMargin: {
                    horizontal: 15,
                    vertical: 5
                },
                offsetX: 50,
                offsetY: 10,
                
                position: 'bottom',
                floating: false,
                // horizontalAlign: 'right',
                // formatter: function (data: string) {
                //     return data.length >= 30 ? data.substring(0, 30) + '...' : data;
                // },
            },
            title: {
                floating: true,
                align: 'center',
                offsetY: 200,
                style: {
                    color: '#0A4B71',
                },
            },
        };
        return chartOptions;
    }

    /**
     * Default Options
     */
    private applyDefaultOptions() {
        return;
    }

    /**
     * onClick
     */
    // private onClick(_event?: MouseEvent | undefined, _activeElements?: {}[] | undefined): any {}

    /**
     * Reset chart position
     */
    // @Watch('')
    // private resetChartPosition() {}

    /**
     * get maximum x axis value for the bar chart
     */
    // private getXaxisMax() {
    //     let maxElementLength = [];
    //     let maxLength = 0;
    //     let isInit = false;
    //     if (this.chartData.data.series && this.chartData.data.series.length > 0) {
    //         this.chartData.data.series.forEach((e: any) => {
    //             if (!isInit) {
    //                 let i = 0;
    //                 e.data.forEach((_x: any) => {
    //                     maxElementLength[i] = 0;
    //                     i++;
    //                 });
    //                 isInit = true;
    //             }
    //             if (e.data && e.data.length > 0) {
    //                 let i = 0;
    //                 e.data.forEach((x) => {
    //                     maxElementLength[i] += +x;
    //                     i++;
    //                 });
    //             }
    //         });
    //         maxElementLength.forEach((x) => {
    //             if (x > maxLength) {
    //                 maxLength = x;
    //             }
    //         });
    //         return Math.floor(maxLength / 100) * 100 + 100; //not sure what this is for?
    //     } else {
    //         return 10;
    //     }
    // }

    /**
     * check if data has any value other than zero
     */
    private checkDataHasValue() {
        for (let key in this.chartData.data.series) {
            if (this.chartData.data.series[key].hasOwnProperty('data')) {
                for (let e of this.chartData.data.series[key].data) {
                    if (e !== 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * get y label div width if the chart is horizontal
     */
    // private getMaxCategoriesLength() {
    //     let max = 0;
    //     if (this.chartData.xaxis.categories && this.chartData.xaxis.categories.length > 0) {
    //         this.chartData.xaxis.categories.forEach((e: string) => {
    //             if (e.length > max) {
    //                 max = e.length;
    //             }
    //         });
    //     } else {
    //         max = 5;
    //     }
    //     return max;
    // }
    // private getYLabelSize() {
    //     let max = this.getMaxCategoriesLength();
    //     if (max > 30) {
    //         max = 30;
    //     }
    //     return 50 + 7 * max;
    // }
    /**
     * click a chart element
     */
    @Emit('click-chart-element')
    public clickChartElement(element: any) {
        return element;
    }
}

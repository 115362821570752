
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, ScoreMap } from "@/graphql/API";
import { FactorsIcons } from "@/IconLibrary";
import Scoring from "@/store/modules/Scoring";
import ScoreMapSelector from "@/components/scoring/ScoringMapSelector.vue";

const scoringModule = getModule(Scoring);

@Component({
    components: {
        ScoreMapSelector,
    },
})
export default class FactorScoring extends Vue {
    @Prop()
    factor!: Factor;

    private readonly Icons: Record<string, string> = FactorsIcons;
    private scoreSelectorScreen = false;

    get scoreMaps(): ScoreMap[] {
        return scoringModule.scoreMaps;
    }

    get factorIcon(): string {
        if (this.factor.is_table) {
            return this.Icons["table"];
        } else if (this.factor.is_group) {
            return this.Icons["group"];
        } else {
            return this.Icons[this.factor.type];
        }
    }

    @Watch("factor", { deep: true })
    onFactorIdChange(): void {
        this.scoreSelectorScreen = false;
    }
}

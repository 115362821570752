import { render, staticRenderFns } from "./DecisionSelector.vue?vue&type=template&id=36463c88&scoped=true&"
import script from "./DecisionSelector.vue?vue&type=script&lang=ts&"
export * from "./DecisionSelector.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36463c88",
  null
  
)

export default component.exports
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Palette } from "@/ColourDefaults";
import { Colour, WorkspaceStyles } from "@/graphql/API";
import Workspaces from "@/store/modules/Workspaces";
import Color from "color";

const workspacesModule = getModule(Workspaces);

@Component
export default class ColourPalette extends Vue {
    readonly DefaultPalette: { [id: string]: Colour } = Palette;
    saturation_palette:  { [id: string]: Colour } = {};

    get workspaceStyles(): WorkspaceStyles {
        return workspacesModule.workspaceStyles;
    }

    get customList(): { [id: string]: Colour } {
        if (this.workspaceStyles.palette_list) {
            return { ...this.workspaceStyles.palette_list, ...this.workspaceStyles.saturation_palette };
        } else {
            return {};
        }
    }

    get editableDefaults(): { [id: string]: Colour } {
        return Object.keys(this.DefaultPalette)
            .filter((id) => this.DefaultPalette[id].editable)
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: this.DefaultPalette[key],
                };
            }, {});
    }

    get palette(): { [id: string]: Colour } {
        if (this.customList) {
            return Object.assign(
                { ...this.DefaultPalette },
                Object.keys(this.customList)
                    .filter((key: string) => {
                        return (
                            this.DefaultPalette[key]
                            
                        );
                    })
                    .reduce((obj, key) => {
                        if (this.customList) {
                            return {
                                ...obj,
                                [key]: this.customList[key],
                            };
                        } else {
                            return obj;
                        }
                    }, {})
            );
        } else {
            return this.DefaultPalette;
        }
    }

    //Lighten/darken color using package: https://www.npmjs.com/package/color
    lightenDarkenColor(payload: {color: string, percent: number, id?: string, name?: string, index?: number}): string {
        const color = payload.color;
        const percent = payload.percent;
        const id = payload.id;
        const name = payload.name; 
        const index = payload.index; 
        const colorObject = Color(color);
        let returnColor = "";
        if (percent >= 0) {
            returnColor = colorObject.lighten(percent).hex();
        } else {
            returnColor = colorObject.darken(Math.abs(percent)).hex();
        }

        if(id && name && index != null) {
            const objKey = `${id}-saturation-${index}`;
            const objVal = {
                name: `${name} Saturation ${index}`,
                hex: returnColor,
                id: `${id}-saturation-${index}`,
                editable: false,
            }

            if(index != 3) {
                this.DefaultPalette[objKey] = objVal;
                this.saturation_palette[objKey] = objVal;
            }
        }

        return returnColor;
    }

    //Rotate color using package: https://www.npmjs.com/package/color
    rotateColor(color: string, percent: number): string {
        const colorObject = Color(color);
        let returnColor = "";
        if (percent >= 0) {
            returnColor = colorObject.rotate(percent).hex();
        } else {
            returnColor = colorObject.rotate(percent).hex();
        }
        return returnColor;
    }

    //Rotate color using package: https://www.npmjs.com/package/color
    saturateColor(color: string, percent: number): string {
        const colorObject = Color(color);
        let returnColor = "";
        if (percent >= 0) {
            returnColor = colorObject.saturate(percent).hex();
        } else {
            returnColor = colorObject.desaturate(Math.abs(percent)).hex();
        }
        return returnColor;
    }
}

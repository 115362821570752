import { Tab, App } from "@/graphql/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getModule } from "vuex-module-decorators";
import Apps from "@/store/modules/Apps";
import FlashNotifications from "@/store/modules/FlashNotifications";

export async function saveTab(payload: {
    tab: Tab;
    appId?: number;
    new: boolean;
    factors: number[];
    choices: number[];
    viewpoints: number[];
}): Promise<void> {
    const tab = payload.tab;
    const appId = payload.appId ? payload.appId : null;
    const isNew = payload.new;
    const factors = payload.factors;
    const choices = payload.choices;
    const viewpoints = payload.viewpoints;

    let savedVps: number[] = [];
    let savedChs: number[] = [];
    let savedFcs: number[] = [];

    if (!isNew && tab.id) {
        savedFcs = await getTabFactors(tab.id);
        savedChs = await getTabChoices(tab.id);
        savedVps = await getTabViewpoints(tab.id);
    }

    try {
        if (isNew) {
            const newTab = await getModule(Apps).createTab({
                decision_id: tab.decision_id,
                title: tab.title,
                json: tab.json,
                edit_flags: tab.edit_flags,
                display_flags: tab.display_flags,
                filter_type: tab.filter_type,
                row_type: tab.row_type,
                column_type: tab.column_type,
                default_filter_id: tab.default_filter_id,
                type: tab.type,
            });
            if (newTab && appId) {
                await getModule(Apps).createAppTabMapping({
                    app_id: appId,
                    tab_id: newTab.id,
                });
                await Promise.all([
                    createTabFactors(factors, newTab.id),
                    createTabChoices(choices, newTab.id),
                    createTabViewpoints(viewpoints, newTab.id),
                ]);
                getModule(FlashNotifications).success({
                    message: `${newTab.title} created.`,
                    duration: 3000,
                });
            }
        } else {
            await getModule(Apps).updateTab({
                newTab: {
                    ...tab,
                },
                appId: appId,
            });
            await Promise.all([
                saveTabFactors(savedFcs, factors, tab.id),
                saveTabChoices(savedChs, choices, tab.id),
                saveTabViewpoints(savedVps, viewpoints, tab.id),
            ]);
            getModule(FlashNotifications).success({
                message: `${tab.title} saved.`,
                duration: 3000,
            });
        }
    } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = e as GraphQLResult<any>;
        const message = err?.errors
            ? err.errors[0].message
            : "Something went wrong.";
        console.log("%cError:", "color: red; font-weight: bold;");
        console.log(e);
        getModule(FlashNotifications).error({
            message: message,
            duration: 3000,
        });
    }
}

async function saveTabFactors(
    oldIds: number[],
    newIds: number[],
    tabId: number
): Promise<void> {
    await deleteTabFactors(
        [...oldIds].filter((id) => !newIds.includes(id)),
        tabId
    );
    await createTabFactors(
        [...newIds].filter((id) => !oldIds.includes(id)),
        tabId
    );
}

async function createTabFactors(ids: number[], tabId: number): Promise<void> {
    if (ids.length > 0) {
        await getModule(Apps).createTabFactor(
            ids.map((id) => {
                return {
                    tab_id: tabId,
                    factor_id: id,
                };
            })
        );
    }
}

async function deleteTabFactors(ids: number[], tabId: number): Promise<void> {
    if (ids.length > 0) {
        await getModule(Apps).deleteTabFactor({
            tab_id: tabId,
            factors: ids,
        });
    }
}

async function saveTabChoices(
    oldIds: number[],
    newIds: number[],
    tabId: number
): Promise<void> {
    await deleteTabChoices(
        [...oldIds].filter((id) => !newIds.includes(id)),
        tabId
    );
    await createTabChoices(
        [...newIds].filter((id) => !oldIds.includes(id)),
        tabId
    );
}

async function createTabChoices(ids: number[], tabId: number): Promise<void> {
    if (ids.length > 0) {
        await getModule(Apps).createTabChoice(
            ids.map((id) => {
                return {
                    tab_id: tabId,
                    choice_id: id,
                };
            })
        );
    }
}

async function deleteTabChoices(ids: number[], tabId: number): Promise<void> {
    if (ids.length > 0) {
        await getModule(Apps).deleteTabChoice({
            tab_id: tabId,
            choice_ids: ids,
        });
    }
}

async function saveTabViewpoints(
    oldIds: number[],
    newIds: number[],
    tabId: number
): Promise<void> {
    await deleteTabViewpoints(
        [...oldIds].filter((id) => !newIds.includes(id)),
        tabId
    );
    await createTabViewpoints(
        [...newIds].filter((id) => !oldIds.includes(id)),
        tabId
    );
}

async function createTabViewpoints(
    ids: number[],
    tabId: number
): Promise<void> {
    if (ids.length > 0) {
        await getModule(Apps).createTabViewpoint(
            ids.map((id) => {
                return {
                    tab_id: tabId,
                    viewpoint_id: id,
                };
            })
        );
    }
}

async function deleteTabViewpoints(
    ids: number[],
    tabId: number
): Promise<void> {
    if (ids.length > 0) {
        await getModule(Apps).deleteTabViewpoint({
            tab_id: tabId,
            viewpoint_ids: ids,
        });
    }
}

async function getTabFactors(tabId: number): Promise<number[]> {
    if (getModule(Apps).tabFactors[tabId]) {
        return Object.values(getModule(Apps).tabFactors[tabId]).map((item) => {
            return item.factor_id;
        });
    } else {
        return [];
    }
}

async function getTabChoices(tabId: number): Promise<number[]> {
    if (getModule(Apps).tabChoices[tabId]) {
        return Object.values(getModule(Apps).tabChoices[tabId]).map((item) => {
            return item.choice_id;
        });
    } else {
        return [];
    }
}

async function getTabViewpoints(tabId: number): Promise<number[]> {
    if (getModule(Apps).tabViewpoints[tabId]) {
        return Object.values(getModule(Apps).tabViewpoints[tabId]).map(
            (item) => {
                return item.viewpoint_id;
            }
        );
    } else {
        return [];
    }
}


import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import { Factor } from "@/graphql/API";
import FactorToggle from "@/components/model/FactorNav/FactorToggle.vue";

const modelModule = getModule(Factors);
@Component({
    name: "FactorGroup",
    components: {
        FactorToggle,
    },
})
export default class FactorGroup extends Vue {
    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop({ default: 1, type: Number })
    depth!: number;

    @Prop({ default: true, type: Boolean })
    showAllToggle!: boolean;

    @Prop({ default: false, type: Boolean })
    eyeIcon!: boolean;

    @Prop({ default: true, type: Boolean })
    multiSelect!: boolean;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: false, type: Boolean })
    allAvailable!: boolean;

    @Prop({ default: () => [], type: Array })
    activeFactors!: number[];

    @Prop({ default: () => [], type: Array })
    lockedFactors!: number[];

    @PropSync("all")
    syncedAll!: boolean;

    @PropSync("selected")
    syncedSelected!: number[];

    @PropSync("rootFactor")
    syncedRootFactor!: number;

    @Prop({ default: true, type: Boolean })
    groupSelect!: boolean;

    @Prop({ default: false, type: Boolean })
    groupRoot!: boolean;

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop({ default: false, type: Boolean })
    rootToggled!: boolean;

    @Prop({ default: false, type: Boolean })
    last!: boolean;

    private loading = false;
    private expanded = true;

    get parentFactor(): Factor | null {
        if (this.parentId != -1) {
            return this.factorMap[this.parentId];
        } else {
            return null;
        }
    }

    get rootSelected(): boolean {
        return (
            this.embed ||
            this.syncedRootFactor == -1 ||
            this.rootToggled ||
            (this.parentFactor != null &&
                this.parentFactor.id == this.syncedRootFactor)
        );
    }

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get factorMap(): { [id: number]: Factor } {
        return modelModule.factorMap;
    }

    get currentTreeLevel(): Factor[] {
        if (this.factorTree[this.parentId]) {
            if (this.allAvailable) {
                return this.factorTree[this.parentId];
            } else {
                return this.factorTree[this.parentId].filter((factor) => {
                    return this.activeFactors.includes(factor.id);
                });
            }
        } else return [];
    }

    get allFactors(): number[] {
        if (this.allAvailable) {
            return Object.values(modelModule.factorMap).map(
                (factor) => factor.id
            );
        } else {
            return [...this.activeFactors];
        }
    }

    get allFactorsSelected(): boolean {
        return this.allFactors.length == this.syncedSelected.length;
    }

    private toggleAll(): void {
        if (this.groupRoot) {
            this.syncedRootFactor = -1;
        }
        if (this.allFactorsSelected) {
            this.syncedSelected = [];
        } else {
            this.syncedSelected = [...this.allFactors];
        }
    }

    private toggleFactor(factor: Factor) {
        if (factor.is_group || factor.is_table) {
            this.toggleChildren(
                factor,
                !this.syncedSelected.includes(factor.id)
            );
        } else {
            this.toggleSingleFactor(
                factor,
                !this.syncedSelected.includes(factor.id)
            );
        }
    }

    private toggleChildren(factor: Factor, val: boolean) {
        this.toggleSingleFactor(factor, val);
        if (this.groupSelect) {
            if (this.factorTree[factor.id]) {
                this.factorTree[factor.id].forEach((item) => {
                    if (
                        this.allAvailable ||
                        this.activeFactors.includes(item.id)
                    ) {
                        this.toggleChildren(item, val);
                    }
                });
            }
        }
    }

    private toggleSingleFactor(factor: Factor, val: boolean) {
        if (val && !this.syncedSelected.includes(factor.id)) {
            if (this.multiSelect) {
                this.syncedSelected.push(factor.id);
            } else {
                this.syncedSelected = [factor.id];
            }
        } else if (!val && this.syncedSelected.includes(factor.id)) {
            if (this.multiSelect) {
                this.syncedSelected.splice(
                    this.syncedSelected.indexOf(factor.id),
                    1
                );
            } else {
                this.syncedSelected = [];
            }
        }

        if (factor.parent_id && factor.parent_id != -1 && val && this.groupSelect) {
            this.toggleSingleFactor(this.factorMap[factor.parent_id], val);
        }
    }

    private toggleRoot(factor: Factor): void {
        if (this.syncedRootFactor == factor.id) {
            this.syncedRootFactor = -1;
            this.syncedSelected = [...this.allFactors];
        } else {
            this.syncedRootFactor = factor.id;
            this.syncedSelected = [...this.allFactors];
        }
    }

    async mounted(): Promise<void> {
        this.onParentIdChange();
    }

    @Watch("allFactors", { deep: true })
    onAllFactorsChange(): void {
        if (this.syncedAll && this.depth == 0) {
            this.syncedSelected = [...this.allFactors];
        }
    }

    @Watch("parentId")
    async onParentIdChange(): Promise<void> {
        if (
            !this.factorTree[this.parentId] ||
            this.factorTree[this.parentId].length == 0
        ) {
            this.loading = true;
            await modelModule.fetchChildFactors(this.parentId);
            this.loading = false;
        }
    }

    @Watch("allFactorsSelected")
    onAllItemsSelected(): void {
        this.$emit("all-items", {
            type: "factors",
            val: this.allFactorsSelected,
        });
    }
}

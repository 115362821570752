
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Decisions from "@/store/modules/Decisions";
import { Decision, Workspace } from "@/graphql/API";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import Workspaces from "@/store/modules/Workspaces";
import FlashNotifications from "@/store/modules/FlashNotifications";

const decisionsModule = getModule(Decisions);
const workspaceModule = getModule(Workspaces);
const flashNotificationsModule = getModule(FlashNotifications);
@Component({
    components: {
        DialogCardTitle,
    },
})
export default class DecisionCreator extends Vue {
    @Prop({ default: "mdi-plus", type: String })
    iconName!: string;

    @Prop({ default: null, type: String })
    listActivator!: string;

    @Prop({ default: false, type: Boolean })
    edit!: boolean;

    @Prop({ default: null, type: Number })
    decisionId!: number;

    @Prop()
    decision!: Decision;

    @PropSync("openModal", Boolean)
    syncedOpenModal!: boolean;

    @Prop({ default: false, type: Boolean })
    hideActivator!: boolean;

    @Prop({ default: false, type: Boolean })
    isCopy!: boolean;

    @Prop({ default: true, type: Boolean })
    showIcon!: boolean;

    private open = false;
    private decisionName = "";
    private formValid = false;
    private destWorkspaceId: number | null = null;
    private loading = false;

    mounted(): void {
        this.onWatch();
    }

    get selectedWorkspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get workspaces(): Workspace[] {
        return workspaceModule.workspaceList;
    }

    private async createDecision() {
        this.loading = true;
        let success = true;
        let msg = "";
        if (this.isCopy && this.decision && this.destWorkspaceId) {
            const tempDate = new Date();
            const appSuffix = ` ${tempDate.getFullYear()}-${(
                "0" +
                (tempDate.getUTCMonth() + 1)
            ).slice(-2)}-${("0" + tempDate.getUTCDate()).slice(-2)} ${
                ("00" + tempDate.getHours()).slice(-2) +
                ":" + ("00" + tempDate.getMinutes()).slice(-2)
            }`;
            // add date suffix to the inputed name
            this.decisionName = this.decisionName + appSuffix;

            try {
                await decisionsModule.copyDecision({
                    source_d_id: this.decisionId,
                    dest_name: this.decisionName,
                    dest_ws_id: this.destWorkspaceId,
                    app_suffix: appSuffix ? appSuffix : "",
                });
                msg = `Framework name - ${this.decisionName}, was successfully copied`;
                if (this.destWorkspaceId === this.selectedWorkspaceId) {
                    await decisionsModule.fetchWorkspaceDecisions(this.destWorkspaceId);
                }
            } catch (e) {
                success = false;
                msg = `Something went wrong. Please refresh and try again`;
            }
        } else {
            if (this.edit && this.decision) {
                await decisionsModule.updateDecision({
                    decision_id: this.decision.id,
                    name: this.decisionName,
                });
                msg = `Framework's name was successfully updated to - ${this.decisionName}`;
            } else {
                try {
                    await decisionsModule.createDecision({
                        name: this.decisionName,
                        workspace_id: getModule(Workspaces).selectedWorkspaceId,
                    });

                    msg = `Framework name - ${this.decisionName}, was successfully created`;
                } catch (e) {
                    success = false;
                    msg = `Something went wrong. Please refresh and try again`;
                }
            }
        }
        this.loading = false;
        this.open = false;
        this.syncedOpenModal = false;
        if (success) {
            flashNotificationsModule.success({
                message: msg,
                duration: 5000,
            });
        } else {
            flashNotificationsModule.error({
                message: msg,
                duration: 5000,
            });
        }
    }

    @Watch("syncedOpenModal")
    private onOpenModalChange(): void {
        if (this.syncedOpenModal) {
            this.open = true;
        }
    }

    @Watch("open")
    private onWatch(): void {
        if (this.edit && this.decision != null) {
            this.decisionName = this.decision.name || "";
            if (this.isCopy) {
                this.destWorkspaceId = this.selectedWorkspaceId;
            }
        } else {
            this.decisionName = "";
        }
        if (!this.open) {
            this.syncedOpenModal = false;
        }
    }
}


import { Vue, Component, Prop } from "vue-property-decorator";
import Logo from "@/components/ui/Logo.vue";
import { Urls } from "@/Urls";
import { Auth } from "aws-amplify";
import PasswordInput from "@/components/ui/PasswordInput.vue";
import { Rules } from "@/helpers/FormRules";

@Component({
    components: { PasswordInput },
})
export default class LoginForm extends Vue {
    private readonly URLS = Urls;
    private email = "";
    private password = "";
    private errorMessage = "";
    private loading = false;
    private formValid = false;

    private submitted = false;

    private rules = Rules;

    private updatePassword(value: string) {
        this.password = value;
    }
    private async login(): Promise<void> {
        if (!this.loading) {
            this.$emit("loading-start");
            this.loading = true;
            try {
                await Auth.signIn(this.email.trim(), this.password);
                this.$emit("logged-in");
            } catch (error: any) {
                this.setErrorMsg(error);
            }
            this.loading = false;
            this.$emit("loading-end");
        }
    }

    private setErrorMsg(error: any): void {
        console.log("error signing in");
        console.log(error);
        this.errorMessage = error;
        setTimeout(() => (this.errorMessage = ""), 3000);
    }
}


import { Vue, Component, PropSync, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import Scoring from "@/store/modules/Scoring";
import { ScoreClass } from "@/graphql/API";
import ColourPalette from "@/components/mixins/ColourPalette";
import DateTimePicker from "@/components/values/DateTimePicker.vue";

const scoringModule = getModule(Scoring);

@Component({
    components: {
        DateTimePicker,
    },
})
export default class DateEditor extends mixins(ColourPalette) {
    @PropSync("items")
    internalItems!: {
        value: number | null;
        score: number;
        class_id: number | null;
    }[];

    @PropSync("classes")
    internalClassList!: { [index: number]: number };

    @Prop({ default: false, type: Boolean })
    manualRule!: boolean;

    @PropSync("minRangeScore")
    internalMinRangeScore!: number;

    @PropSync("minRangeClass")
    internalMinRangeClass!: number | null;

    private isFormValid = false;
    private editIndex = -1;
    private loading = false;

    get scoreClasses(): ScoreClass[] {
        return Object.values(scoringModule.scoreClasses);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get scoreClassStyles(): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let styles: any = {};
        for (const key in this.internalClassList) {
            let currentClass =
                scoringModule.scoreClasses[this.internalClassList[key]];
            if (currentClass && currentClass.hasOwnProperty("json")) {
                styles[key] = JSON.parse(currentClass.json);
            }
        }
        return styles;
    }

    private toggleEditScreen(id: number, index: number): void {
        this.$emit("selected-class", { class: id, range: index });
    }

    private formatUnixToDate(unixVal: number): string {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const timestamp = require("unix-timestamp");
        const date = timestamp.toDate(unixVal);
        return `${date.getFullYear()}-${("0" + (date.getUTCMonth() + 1)).slice(
            -2
        )}-${("0" + date.getUTCDate()).slice(-2)}`;
    }

    private async changeRange(value: number, index: number): Promise<void> {
        this.editIndex = -1;
        Vue.set(this.internalItems[index], "value", value);
        this.internalItems.sort((a, b) => {
            if (a.value == null) {
                return 1;
            } else if (b.value == null) {
                return -1;
            } else {
                return a.value > b.value ? 1 : -1;
            }
        });
    }

    private async updateScore(value: string, index: number): Promise<void> {
        Vue.set(this.internalItems[index], "score", parseFloat(value));
        if (this.manualRule) {
            this.internalItems.sort((a, b) => {
                return a.score - b.score;
            });
        }
    }

    private async deleteRange(index: number): Promise<void> {
        Vue.delete(this.internalItems, index);
    }

    private async createRange(): Promise<void> {
        if (this.internalItems.length) {
            this.internalItems.splice(this.internalItems.length - 1, 0, {
                value: null,
                score: 0,
                class_id: null,
            });
        } else {
            this.internalItems = [{ value: null, score: 0, class_id: null }];
        }
    }

    mounted(): void {
        this.onManualRuleChagne();
    }

    @Watch("manualRule")
    onManualRuleChagne(): void {
        this.internalItems.sort((a, b) => {
            return this.manualRule ? a.score - b.score : (a.value && b.value) ? a.value - b.value : -1;
        });
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"viewWrapper",staticClass:"view-wrapper bg-main",class:{ 'view-wrapper--border-dark': _vm.darkBorder }},[(_vm.logoOverlay)?_c('div',{staticClass:"view-wrapper__logo-overlay"},[_c('div',{staticClass:"view-wrapper__logo-overlay__animation"},[_c('Logo',{attrs:{"icon":"","loading":""}})],1)]):_vm._e(),_c('div',{staticClass:"view-wrapper__loading",class:{
            'view-wrapper__loading--active': _vm.loading || _vm.entirePageOverlay,
        }},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('Modal',{attrs:{"active":_vm.pageModalOpen,"title":_vm.modalTitle,"page-modal":""},on:{"vp-modal-close":function($event){return _vm.$emit('page-modal-close')}}},[_vm._t("modal")],2),((_vm.header || _vm.bannerImage) && _vm.showbanner && !_vm.bannerScroll)?_c('div',{staticClass:"vp-padding-x-2 vp-padding-y-1 bg-main"},[_c('AppBanner',{attrs:{"header":_vm.header,"banner-image":_vm.bannerImage,"align":_vm.bannerAlign}})],1):_vm._e(),_c('div',{staticClass:"bg-main"},[_vm._t("intro")],2),(_vm.hasToolBar && !_vm.onlyContent)?_c('div',{staticClass:"tab-control"},[_c('div',{staticClass:"tab-control__slot"},[_vm._t("toolBar")],2),_c('div',{staticClass:"tab-control__actions"},[_vm._t("actions"),(_vm.hasSideBar)?_c('v-btn',{staticClass:"sidebar-desktop-show",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.sideBarOpen = !_vm.sideBarOpen}}},[(
                        (!_vm.languageRight && _vm.sideBarOpen) ||
                        (_vm.languageRight && !_vm.sideBarOpen)
                    )?_c('v-icon',{staticClass:"panel-left__icon"},[_vm._v("mdi-chevron-double-right")]):_c('v-icon',{staticClass:"panel-left__icon--hover"},[_vm._v("mdi-chevron-double-left")])],1):_vm._e(),(_vm.hasSideBar)?_c('v-btn',{staticClass:"sidebar-mobile-show",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.sideBarOpenMobile = !_vm.sideBarOpenMobile}}},[(
                        (!_vm.languageRight && _vm.sideBarOpenMobile) ||
                        (_vm.languageRight && !_vm.sideBarOpenMobile)
                    )?_c('v-icon',{staticClass:"panel-left__icon"},[_vm._v("mdi-chevron-double-right")]):_c('v-icon',{staticClass:"panel-left__icon--hover"},[_vm._v("mdi-chevron-double-left")])],1):_vm._e()],2)]):_vm._e(),_c('div',{staticClass:"content-wrapper",class:{
            'content-wrapper--bg-main': _vm.bgMain,
            'is-loading': _vm.loadingOverlay,
            'is-loading-no-progress': _vm.entirePageOverlay,
        }},[_c('div',{staticClass:"content-wrapper__loading"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('div',{ref:"contentColumn",staticClass:"content-column"},[_c('div',{staticClass:"vp-column__scrollable-wrapper"},[(_vm.hasScrollTop)?_c('div',{staticClass:"vp-column__scrollable-top"},[_vm._t("scrollTop")],2):_vm._e(),_c('div',{ref:"scroll-section",staticClass:"vp-column__scrollable"},[_c('div',{staticClass:"vp-column__scrollable__inner"},[(_vm.hasFilterBar)?_c('div',{staticClass:"filter-bar"},[_vm._t("filterBar")],2):_vm._e(),_c('div',{class:_vm.padding ? 'vp-scroll-padding' : ''},[(
                                    (_vm.header || _vm.bannerImage) &&
                                    _vm.showbanner &&
                                    _vm.bannerScroll
                                )?_c('div',{staticStyle:{"padding-bottom":"10px"}},[_c('AppBanner',{attrs:{"header":_vm.header,"banner-image":_vm.bannerImage}})],1):_vm._e(),_vm._t("default")],2)])])])]),(_vm.hasSideBar && !_vm.onlyContent)?_c('div',{staticClass:"sidebar",class:{
                'sidebar--mobile-active': _vm.sideBarOpenMobile,
                'sidebar--active': _vm.sideBarOpen,
                'sidebar--dir-alt': _vm.languageRight,
            }},[_c('div',{staticClass:"sidebar__shadow",on:{"click":function($event){_vm.sideBarOpenMobile = false}}}),(!_vm.hasToolBar)?_c('div',{staticClass:"sidebar__btn"},[(_vm.hasSideBar)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.sideBarOpen = !_vm.sideBarOpen}}},[(
                            (!_vm.languageRight && _vm.sideBarOpen) ||
                            (_vm.languageRight && !_vm.sideBarOpen)
                        )?_c('v-icon',{staticClass:"panel-left__icon"},[_vm._v("mdi-arrow-expand-right")]):_c('v-icon',{staticClass:"panel-left__icon--hover"},[_vm._v("mdi-arrow-expand-left")])],1):_vm._e()],1):_vm._e(),_c('div',{ref:"sidebar",staticClass:"sidebar__inner"},[_c('DragResizeHandler',{attrs:{"right-aligned":!_vm.languageRight,"element-ref":"sidebar"}}),_vm._t("sideBar")],2)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
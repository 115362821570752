
import { Vue, Component, Prop } from "vue-property-decorator";
import { Auth } from "aws-amplify";

@Component({
    components: {},
})
export default class AWSLogin extends Vue {
    private goToLogin(): void {
        Auth.federatedSignIn();
    }
}


import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { CategoryClass, EnumValue, ScoreClass } from "@/graphql/API";
import Scoring from "@/store/modules/Scoring";
import draggable from "vuedraggable";
import { mixins } from "vue-class-component";
import ColourPalette from "@/components/mixins/ColourPalette";

const scoringModule = getModule(Scoring);

@Component({
    components: {
        draggable,
    },
})
export default class OptionEditor extends mixins(ColourPalette) {
    @PropSync("items")
    internalOptions!: EnumValue[];

    @PropSync("classes")
    internalClassList!: CategoryClass[];

    @Prop({ default: false, type: Boolean })
    manualRule!: boolean;

    private loading = false;
    private drag = false;

    private selectedClass: ScoreClass | null = null;

    get newIndex(): number {
        if (this.internalClassList && this.internalClassList.length) {
            return (
                Math.max(...this.internalClassList.map((item) => item.index)) +
                1
            );
        } else {
            return 0;
        }
    }

    get scoreClasses(): ScoreClass[] {
        return Object.values(scoringModule.scoreClasses);
    }

    get internalScores(): number[] {
        return this.internalClassList
            .map((item) => {
                if (typeof item.score != "number") {
                    return parseInt(item.score);
                } else {
                    return item.score;
                }
            })
            .filter((score) => score != 0)
            .sort((a, b) => a - b);
    }

    get scoreMin(): number {
        if (this.internalScores.length > 1) {
            return this.internalScores[0];
        }
        return 0;
    }

    get scoreMax(): number {
        if (this.internalScores.length) {
            return this.internalScores[this.internalScores.length - 1];
        }
        return 1;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get classStyles(): any {
        return this.internalClassList.map((item) => {
            if (item.class && item.class.json) {
                try {
                    let styles = JSON.parse(item.class.json);
                    return styles;
                } catch {
                    return null;
                }
            }
            return null;
        });
    }

    private orderItems(): void {
        this.internalClassList.sort((a, b) => b.score - a.score);
    }

    private toggleEditScreen(item: CategoryClass, index: number): void {
        if (item) {
            this.$emit("selected-class", { class: item.id, range: index });
        }
    }

    private addClass(): void {
        if (this.selectedClass) {
            this.internalClassList.splice(this.internalClassList.length, 0, {
                id: this.selectedClass.id,
                class: this.selectedClass,
                score: 0,
                index: this.newIndex,
                bin: [],
            });
        }

        this.selectedClass = null;
    }

    private async deleteClass(index: number): Promise<void> {
        if (
            this.internalClassList[index] &&
            this.internalClassList[index].bin &&
            this.internalClassList[index].bin.length > 0
        )
            await Promise.all(
                this.internalClassList[index]?.bin.map(async (item) => {
                    if (item != null) this.internalOptions.push(item);
                })
            );
        Vue.delete(this.internalClassList, index);
    }

    private async distributeRanges(): Promise<void> {
        const intervals = this.internalClassList.length;
        let scoreInterval =
            (this.scoreMax - this.scoreMin) /
            (this.scoreMin == 0 ? intervals : intervals - 1);

        await Promise.all(
            this.internalClassList.map(async (item, index) => {
                let score =
                    this.scoreMin +
                    (this.scoreMin == 0 ? index + 1 : index) * scoreInterval;

                Vue.set(this.internalClassList[index], "score", score);
            })
        );

        this.orderItems();
    }
}

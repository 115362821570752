
import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { App, Tab } from "@/graphql/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Apps from "@/store/modules/Apps";
import VpDialog from "@/components/ui/VpDialog.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";

const appsModule = getModule(Apps);
const flashModule = getModule(FlashNotifications);

@Component({
    components: {
        VpDialog,
    },
})
export default class AppTabDeletor extends Vue {
    @Prop()
    tab!: Tab;

    @Prop()
    app!: App;

    private open = false;

    get itemIds(): string {
        return `${this.app ? this.app.id : "null"}-${
            this.tab ? this.tab.id : "null"
        }`;
    }

    private cancelDialog(): void {
        this.open = false;
        this.$emit("cancel-delete");
    }

    private async deleteItem(): Promise<void> {
        try {
            if (this.app) {
                const name = this.app.title;
                await appsModule.deleteApp(this.app.id);
                flashModule.success({
                    message: `${name} deleted`,
                    duration: 3000,
                });
                this.$emit("delete-app");
            } else if (this.tab) {
                const name = this.tab.title;
                await appsModule.deleteTab(this.tab.id);
                flashModule.success({
                    message: `${name} deleted`,
                    duration: 3000,
                });
                this.$emit("delete-tab");
            }
        } catch (e) {
            const err = e as GraphQLResult<any>;
            const message = err?.errors
                ? err.errors[0].message
                : "Something went wrong.";
            console.log("%cError:", "color: red; font-weight: bold;");
            console.log(e);
            flashModule.error({
                message: message,
                duration: 3000,
            });
        }


        this.cancelDialog();
    }

    mounted(): void {
        this.onItemsChange();
    }

    @Watch("itemIds")
    onItemsChange(): void {
        this.open = this.itemIds != "null-null";
    }

}


import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import ChoiceEditBase from "@/components/mixins/ChoiceEditBase";
import { mixins } from "vue-class-component";
import Choices from "@/store/modules/Choices";
import Ui from "@/store/modules/Ui";
import ChoiceCreator from "@/components/choices/ChoiceCreator.vue";
import RecursiveChoiceFactors from "@/components/choices/editor/RecursiveChoice.vue";
import Decisions from "@/store/modules/Decisions";
import { Choice, Tab } from "@/graphql/API";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import ModelNav from "@/components/model/ModelNav.vue";
import ModelPagination from "@/components/model/ModelPagination.vue";
import ChoiceEditSidebar from "@/components/choices/editor/ChoiceEditSidebar.vue";
import ViewAppCreator from "@/components/apps/ViewAppCreator.vue";
import { saveTab } from "@/helpers/TabHelper";

const choiceModule = getModule(Choices);
const decisionsModule = getModule(Decisions);
const uiModule = getModule(Ui);

@Component({
    components: {
        RecursiveChoiceFactors,
        ChoiceCreator,
        ViewWrapper,
        ModelNav,
        ModelPagination,
        ChoiceEditSidebar,
        ViewAppCreator,
    },
})
export default class ChoiceEditor extends mixins(ChoiceEditBase) {
    private sidebarIndex = "sidebar-close";
    private selectedChoice: Choice | null = null;
    private loading = false;

    private currentDepth = 0;

    private createOpen = false;

    private factorId: number | null = null;
    private appCreateOpen = false;
    private appSaving = false;

    get currentChoiceId(): number | null {
        return this.selectedChoice ? this.selectedChoice.id : null;
    }

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get choiceLabelSingular(): string {
        return getModule(Decisions).choiceLabelSingular;
    }

    get choiceName(): string | null {
        return this.selectedChoice ? this.selectedChoice.name : null;
    }

    get choiceLabel(): string {
        return decisionsModule.choiceLabelSingular;
    }

    get currentEditFlags(): string {
        return JSON.stringify({
            editable: this.editable,
            allChoices: this.allChoices,
            newChoices: this.newChoicesEnabled,
            sortDef: this.choiceSortDef,
            sortDesc: this.choiceSortDesc,
        });
    }

    get currentSettings(): Tab {
        if (this.tabSettings) {
            return {
                ...this.tabSettings,
                edit_flags: this.currentEditFlags,
            };
        } else {
            return {
                id: -1,
                decision_id: this.selectedDecisionId
                    ? this.selectedDecisionId
                    : -1,
                title: `${this.choiceLabel} Editor`,
                json: "",
                edit_flags: this.currentEditFlags,
                display_flags: "",
                filter_type: "choice",
                row_type: "factor",
                column_type: "viewpoint",
                type: "ChoiceEditor",
            };
        }
    }

    private openChoice(choices: Choice[]): void {
        if (choices && choices.length > 0) {
            this.selectedChoice = choices[0];
        }
    }

    private async saveSettings(): Promise<void> {
        if (this.tabSettings && this.isEdit) {
            this.appSaving = true;
            await saveTab({
                tab: {
                    ...this.currentSettings,
                },
                new: false,
                factors: [],
                choices: [],
                viewpoints: [],
            });
            this.appSaving = false;
        } else {
            this.appCreateOpen = true;
        }
    }

    @Watch("currentChoiceId")
    async onChoiceChange(): Promise<void> {
        if (this.currentChoiceId) {
            this.loading = true;
            await choiceModule.getChoiceValues({
                choice_id: this.currentChoiceId,
                root_only: true,
            });
            this.loading = false;
        }
    }

    @Watch("choiceName")
    onChoiceNameChange(val: string | null): void {
        if (this.isMainTool) {
            uiModule.changeBreadCrumb(val);
        }
    }
}

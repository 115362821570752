var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.internalItems.length > 0 && !_vm.manualRule)?_c('div',{staticClass:"vp-range-item vp-range-item--no-match vp-range-item--no-match--top"},[_c('div',{staticClass:"vp-range-item__title"},[_vm._v("Above Maximum")]),_c('v-form',{staticClass:"vp-range-item__form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"vp-range-item__label"},[_c('div',{staticClass:"vp-range-class"},[(_vm.scoreClasses.length > 0)?_c('v-autocomplete',{attrs:{"items":_vm.scoreClasses,"item-text":"name","item-value":"id","dense":"","hide-details":"","placeholder":"Score Class","clearable":""},model:{value:(
                            _vm.internalItems[_vm.newValues.length - 1].class_id
                        ),callback:function ($$v) {_vm.$set(_vm.internalItems[_vm.newValues.length - 1], "class_id", $$v)},expression:"\n                            internalItems[newValues.length - 1].class_id\n                        "}}):_vm._e(),(_vm.internalClassList[_vm.newValues.length])?_c('v-btn',{staticClass:"vp-range-class-edit",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleEditScreen(
                                _vm.internalItems[_vm.newValues.length - 1]
                                    .class_id,
                                _vm.internalItems.length - 1
                            )}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e()],1),(
                        _vm.scoreClassStyles[_vm.newValues.length] &&
                        _vm.scoreClassStyles[_vm.newValues.length].icons
                    )?_c('div',{staticClass:"vp-range-item__icons"},_vm._l((_vm.scoreClassStyles[
                            _vm.newValues.length
                        ].icons),function(icon,index){return _c('span',{key:index},[_c('v-icon',{attrs:{"color":icon.iconColour != ''
                                    ? _vm.palette[icon.iconColour].hex
                                    : '',"small":""}},[_vm._v(" "+_vm._s(icon.iconCode)+" ")])],1)}),0):_vm._e()]),_c('div',{staticClass:"vp-range-item__score"},[(_vm.internalItems[_vm.newValues.length - 1])?_c('v-text-field',{attrs:{"value":_vm.internalItems[_vm.newValues.length - 1].score,"prefix":"Score:","dense":"","hide-details":"","type":"number"},on:{"change":function($event){return _vm.updateScore($event, _vm.newValues.length - 1)}}}):_vm._e()],1),_c('div',{staticClass:"vp-range-item__offset"}),(
                    _vm.scoreClassStyles[_vm.newValues.length] &&
                    _vm.scoreClassStyles[_vm.newValues.length].bg_colour &&
                    _vm.palette[_vm.scoreClassStyles[_vm.newValues.length].bg_colour]
                )?_c('div',{staticClass:"vp-range-item__colour",style:(`background: ${
                    _vm.palette[_vm.scoreClassStyles[_vm.newValues.length].bg_colour][
                        'hex'
                    ]
                };`)}):_vm._e()])],1):_vm._e(),(_vm.internalItems.length > 0)?_c('div',{ref:"vpScoreRuleSlider",staticClass:"vp-viewpoint-scorerule",class:{
            'vp-viewpoint-scorerule--slider': _vm.sliderToggle,
            'vp-viewpoint-scorerule--list': !_vm.sliderToggle,
            'vp-viewpoint-scorerule--manual': _vm.manualRule,
        }},[(
                _vm.internalItems.length > 0 &&
                _vm.scoreClassStyles[0] &&
                _vm.scoreClassStyles[0].bg_colour &&
                _vm.palette[_vm.scoreClassStyles[0].bg_colour]
            )?_c('div',{staticClass:"vp-range-item__colour",style:(`background: ${
                _vm.palette[_vm.scoreClassStyles[0].bg_colour]['hex']
            };`)}):_vm._e(),_vm._l((_vm.internalItems),function(range,index){return _c('div',{key:index,ref:`vp-range-${index}`,refInFor:true,staticClass:"vp-range-item",class:{
                'vp-range-item--small': _vm.rangeSmallView(
                    _vm.adjustedValues[index]
                ),
                'vp-range-item--grey':
                    index == _vm.newValues.length - 1 && !_vm.manualRule,
            },style:(_vm.sliderToggle
                    ? `height: ${(_vm.adjustedValues[index] / _vm.numRange) * 100}%`
                    : ''),on:{"click":function($event){_vm.editIndex = -1}}},[(
                    _vm.manualRule
                        ? index < _vm.newValues.length
                        : index < _vm.newValues.length - 1
                )?_c('v-form',{staticClass:"vp-range-item__form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"vp-range-item__label"},[_c('div',{staticClass:"vp-range-class"},[(_vm.scoreClasses.length > 0)?_c('v-autocomplete',{attrs:{"items":_vm.scoreClasses,"item-text":"name","item-value":"id","dense":"","hide-details":"","placeholder":"Score Class","clearable":""},model:{value:(_vm.internalItems[index].class_id),callback:function ($$v) {_vm.$set(_vm.internalItems[index], "class_id", $$v)},expression:"internalItems[index].class_id"}}):_vm._e(),(_vm.internalItems[index].class_id)?_c('v-btn',{staticClass:"vp-range-class-edit",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleEditScreen(
                                    _vm.internalItems[index].class_id,
                                    index
                                )}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e()],1),(
                            _vm.scoreClassStyles[index + 1] &&
                            _vm.scoreClassStyles[index + 1].icons
                        )?_c('div',{staticClass:"vp-range-item__icons"},_vm._l((_vm.scoreClassStyles[
                                index + 1
                            ].icons),function(icon,index){return _c('span',{key:index},[_c('v-icon',{attrs:{"color":icon.iconColour != ''
                                        ? _vm.palette[icon.iconColour].hex
                                        : '',"small":""}},[_vm._v(" "+_vm._s(icon.iconCode)+" ")])],1)}),0):_c('div',{staticClass:"vp-range-item__icons"},[_c('v-icon',{attrs:{"small":""}})],1)]),_c('div',{staticClass:"vp-range-item__score"},[_c('v-text-field',{attrs:{"value":_vm.internalItems[index].score,"prefix":"Score:","dense":"","hide-details":"","type":"number"},on:{"change":function($event){return _vm.updateScore($event, index)}}})],1),_c('v-btn',{staticClass:"vp-range-item__delete",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.deleteRange(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle")])],1)],1):_vm._e(),(!_vm.manualRule)?_c('div',{staticClass:"vp-range-item__value",class:{
                    'vp-range-item__value--edit': _vm.editIndex == index,
                    'vp-range-item__value--bottom': index == 0,
                },on:{"click":function($event){$event.stopPropagation();_vm.editIndex = index}}},[(_vm.editIndex == index)?_c('v-text-field',{attrs:{"value":_vm.newValues[index],"dense":"","hide-details":"","type":"number"},on:{"change":function($event){return _vm.changeRange($event, index)}}}):_c('span',[_vm._v(_vm._s(_vm.newValues[index]))])],1):_vm._e(),(
                    _vm.scoreClassStyles[index + 1] &&
                    _vm.scoreClassStyles[index + 1].bg_colour &&
                    _vm.palette[_vm.scoreClassStyles[index + 1].bg_colour]
                )?_c('div',{staticClass:"vp-range-item__colour",style:(`background: ${
                    _vm.palette[_vm.scoreClassStyles[index + 1].bg_colour]['hex']
                };`)}):_vm._e(),(!_vm.manualRule)?_c('DragResizeHandlerVertical',{attrs:{"auto-adjust":false,"element-ref":`vp-range-${index}`,"disabled":_vm.loading || !_vm.sliderToggle,"triangle":index == 0,"no-minimum":index == _vm.newValues.length - 1},on:{"drag-resize-change":function($event){return _vm.adjustHeights(index, $event)},"drag-resize-complete":function($event){return _vm.updateInternalRange(index)}}}):_vm._e()],1)})],2):_vm._e(),(_vm.internalItems.length > 0 && !_vm.manualRule)?_c('div',{staticClass:"vp-range-item vp-range-item--no-match"},[_c('div',{staticClass:"vp-range-item__title"},[_vm._v("Below Minimum")]),_c('v-form',{staticClass:"vp-range-item__form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"vp-range-item__label"},[_c('div',{staticClass:"vp-range-class"},[(_vm.scoreClasses.length > 0)?_c('v-autocomplete',{attrs:{"items":_vm.scoreClasses,"item-text":"name","item-value":"id","dense":"","hide-details":"","placeholder":"Score Class","clearable":""},model:{value:(_vm.internalMinRangeClass),callback:function ($$v) {_vm.internalMinRangeClass=$$v},expression:"internalMinRangeClass"}}):_vm._e(),(_vm.internalMinRangeClass)?_c('v-btn',{staticClass:"vp-range-class-edit",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleEditScreen(_vm.internalMinRangeClass, -1)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e()],1),(_vm.scoreClassStyles[0] && _vm.scoreClassStyles[0].icons)?_c('div',{staticClass:"vp-range-item__icons"},_vm._l((_vm.scoreClassStyles[0].icons),function(icon,index){return _c('span',{key:index},[_c('v-icon',{attrs:{"color":icon.iconColour != ''
                                    ? _vm.palette[icon.iconColour].hex
                                    : '',"small":""}},[_vm._v(" "+_vm._s(icon.iconCode)+" ")])],1)}),0):_vm._e()]),_c('div',{staticClass:"vp-range-item__score"},[_c('v-text-field',{attrs:{"prefix":"Score:","dense":"","hide-details":"","type":"number"},model:{value:(_vm.internalMinRangeScore),callback:function ($$v) {_vm.internalMinRangeScore=$$v},expression:"internalMinRangeScore"}})],1),_c('div',{staticClass:"vp-range-item__offset"}),(
                    _vm.scoreClassStyles[0] &&
                    _vm.scoreClassStyles[0].bg_colour &&
                    _vm.palette[_vm.scoreClassStyles[0].bg_colour]
                )?_c('div',{staticClass:"vp-range-item__colour",style:(`background: ${
                    _vm.palette[_vm.scoreClassStyles[0].bg_colour]['hex']
                };`)}):_vm._e()])],1):_vm._e(),(_vm.internalItems.length == 0)?_c('div',{ref:"vpScoreRuleSlider",staticClass:"vp-viewpoint-scorerule vp-viewpoint-scorerule--empty",class:_vm.sliderToggle
                ? 'vp-viewpoint-scorerule--slider'
                : 'vp-viewpoint-scorerule--list'},[_c('div',{staticClass:"vp-viewpoint-scorerule__btns"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.generateRanges}},[_vm._v(" Generate Ranges ")])],1)]):_vm._e(),_c('div',{staticClass:"vp-viewpoint-scorerule__add"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.internalItems.length == 0},on:{"click":_vm.createRange}},[_vm._v(" Add Class ")])],1),_c('div',{staticClass:"vp-viewpoint-scorerule__add"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.internalItems.length < 3},on:{"click":_vm.distributeRanges}},[_vm._v(" Distribute ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
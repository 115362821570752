
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {},
})
export default class DialogCardTitle extends Vue {
    @Prop({ required: true })
    titleText!: string;

    @Prop({ default: false, type: Boolean })
    deleteBtn!: boolean;

    @Prop({ default: false, type: Boolean })
    disableSaveBtn!: boolean;

    @Prop({ default: false, type: Boolean })
    hideCancelBtn!: boolean;

    @Prop({ default: "Save", type: String })
    SaveBtnText!: string;

    @Prop({ default: "Cancel", type: String })
    cancelBtnText!: string;

    @Prop({ default: false, type: Boolean })
    hideSaveBtn!: boolean;

    private emitSave() {
        this.$emit("save");
    }

    private cancel() {
        this.$emit("cancel");
    }

    private del() {
        this.$emit("del");
    }
}

import { render, staticRenderFns } from "./ColourPaletteSelector.vue?vue&type=template&id=157842e0&scoped=true&"
import script from "./ColourPaletteSelector.vue?vue&type=script&lang=ts&"
export * from "./ColourPaletteSelector.vue?vue&type=script&lang=ts&"
import style0 from "./ColourPaletteSelector.vue?vue&type=style&index=0&id=157842e0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "157842e0",
  null
  
)

export default component.exports

import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Choice,
    Viewpoint,
    Factor,
    ScoreClassSettings,
    ScoreOptions,
    FactorOptions,
    Score,
    ViewpointMapping,
    EnhancedScore,
} from "@/graphql/API";
import Scoring from "@/store/modules/Scoring";
import Viewpoints from "@/store/modules/Viewpoints";
import CompareCell from "@/components/compare/CompareCell.vue";
import draggable from "vuedraggable";
import { createUpdateScoreHelper, getScore } from "@/helpers/ScoreHelper";

const scoringModule = getModule(Scoring);
const viewpointModule = getModule(Viewpoints);

@Component({
    components: {
        CompareCell,
        draggable,
    },
})
export default class RankColumnScore extends Vue {
    @Prop({ default: () => [], type: Array })
    choices!: Choice[];

    @Prop()
    viewpoint!: Viewpoint;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop()
    factor!: Factor;

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoresEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop({ default: true, type: Boolean })
    scoreRule!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: 0, type: Number })
    columnIndex!: number;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    @Prop({ default: false, type: Boolean })
    full!: boolean;

    @Prop({ default: "desc", type: String })
    order!: string;

    @Prop()
    hoverId!: number;

    private drag = false;
    private loading = false;

    private indexes = {
        rows: "choices",
        columns: "viewpoints",
        data: "factors",
    };

    private dragList: { choice: Choice; value: number }[] = [];

    get factorVpIndex(): string {
        return `${this.viewpoint ? this.viewpoint.id : "null"}-${
            this.factor ? this.factor.id : "null"
        }`;
    }

    get dragEnabled(): boolean {
        return (
            this.factor &&
            this.scoresEditable &&
            this.dragList.length > 1 &&
            this.manualScoring &&
            this.order != "alph"
        );
    }

    get viewpointMapping(): ViewpointMapping | undefined {
        if (
            this.viewpoint &&
            this.factor &&
            viewpointModule.viewpointMappings &&
            viewpointModule.viewpointMappings[this.viewpoint.id]
        ) {
            return viewpointModule.viewpointMappings[this.viewpoint.id][
                this.factor.id
            ];
        } else {
            return undefined;
        }
    }

    get manualScoring(): boolean {
        if (
            this.viewpointMapping &&
            this.viewpointMapping.use_m_score != undefined
        ) {
            return this.viewpointMapping.use_m_score;
        } else {
            return false;
        }
    }

    get keyedScores(): { [viewpoint_Choice_FactorId: string]: EnhancedScore } {
        return scoringModule.keyedScores;
    }

    get choiceScores(): { choice: Choice; score: EnhancedScore | undefined }[] {
        if (
            this.viewpoint &&
            this.factor &&
            this.choices &&
            this.choices.length
        ) {
            return this.choices.map((item) => {
                return {
                    choice: item,
                    score: this.keyedScores
                        ? scoringModule.keyedScores[
                              `${this.viewpoint.id}-${item.id}-${this.factor.id}`
                          ]
                        : undefined,
                };
            });
        } else {
            return [];
        }
    }

    get choiceScoreValues(): {
        choice: Choice;
        m: number;
        c: number;
        use_m: boolean;
    }[] {
        return this.choiceScores.map((item) => {
            return {
                choice: item.choice,
                m: item.score ? item.score.m_score : 0,
                c: item.score ? item.score.c_score : 0,
                use_m:
                    item.score &&
                    this.viewpointMapping &&
                    this.viewpointMapping.use_m_score != undefined
                        ? this.viewpointMapping.use_m_score
                        : true,
            };
        });
    }

    get choiceScoreValue(): { choice: Choice; value: number }[] {
        return this.choiceScoreValues.map((item) => {
            return {
                choice: item.choice,
                value: item.use_m ? item.m : item.c,
            };
        });
    }

    get choiceScoreValueSorted(): { choice: Choice; value: number }[] {
        if (this.order == "desc") {
            return this.choiceScoreValue.sort((a, b) => {
                return a.value > b.value ? -1 : 1;
            });
        } else if (this.order == "asc") {
            return this.choiceScoreValue.sort((a, b) => {
                return a.value < b.value ? -1 : 1;
            });
        } else {
            return this.choiceScoreValue.sort((a, b) => {
                return a.choice.name.localeCompare(b.choice.name);
            });
        }
    }

    private dragChange(e: any) {
        const prev =
            e.moved.newIndex > 0 ? this.dragList[e.moved.newIndex - 1] : null;
        const next =
            e.moved.newIndex < this.dragList.length - 1
                ? this.dragList[e.moved.newIndex + 1]
                : null;
        this.changeScore(
            prev ? prev.value : null,
            next ? next.value : null,
            e.moved.element.choice.id
        );
    }

    private toggleChoice(choice: Choice): void {
        this.$emit("toggle-choice", choice);
    }

    private async changeScore(
        prev: number | null,
        next: number | null,
        choiceId: number
    ): Promise<void> {
        const start = prev
            ? prev
            : this.order == "desc" && next
            ? next + 0.02
            : 0;
        const end = next ? next : this.order == "asc" && prev ? prev + 0.02 : 0;
        const newScore = this.middleNum(start, end);

        if (this.viewpoint && this.factor) {
            await createUpdateScoreHelper({
                viewpoint_id: this.viewpoint.id,
                choice_id: choiceId,
                factor_id: this.factor.id,
                m_score: this.roundNumber(newScore, 3),
            });
        }
    }

    private roundNumber(value: number, digits: number): number {
        return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
    }

    private middleNum(a: number, b: number): number {
        if (a > b) {
            return (a - b) / 2 + b;
        } else if (a < b) {
            return (b - a) / 2 + a;
        } else {
            return a;
        }
    }

    async mounted(): Promise<void> {
        await this.onFactorVpChange();
        this.onListChange();
    }

    @Watch("choiceScoreValueSorted", { immediate: true, deep: true })
    onListChange(): void {
        this.dragList = [...this.choiceScoreValueSorted];
    }

    @Watch("factorVpIndex")
    async onFactorVpChange(): Promise<void> {
        if (this.factor && this.viewpoint && this.choices.length) {
            this.loading = true;

            await Promise.all(
                this.choices.map(async (choice) => {
                    if (choice) {
                        await getScore({
                            viewpoint_id: this.viewpoint.id,
                            choice_id: choice.id,
                            factor_id: this.factor.id,
                        });
                    }
                })
            );
            this.loading = false;
        }
    }

    @Watch("drag")
    onDragChange(val: boolean): void {
        this.$emit("dragging", val);
    }
}

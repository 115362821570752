
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import StyleEditorClass from "@/components/common/StyleEditorClass.vue";
import { getModule } from "vuex-module-decorators";
import { Decision } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Workspaces from "@/store/modules/Workspaces";
import VpDialog from "@/components/ui/VpDialog.vue";

const decisionsModule = getModule(Decisions);

@Component({
    name: "StyleEditor",
    components: { DialogCardTitle, StyleEditorClass, VpDialog },
})
export default class StyleEditor extends Vue {
    @Prop({ default: "mdi-plus", type: String })
    iconName!: string;

    @Prop({ default: null, type: String })
    listActivator!: string;

    @Prop({ default: 0, type: Number })
    selectedDecisionId!: number;

    private open = false;
    private defaultStyleClasses = [
        {
            name: "Heading 1",
            value: "vp-heading-1",
            varName: "heading1",
            styles: {
                fontSize: "96px",
                fontWeight: "300",
                fontStyle: "normal",
                fontSpacing: "-1.5px",
                fontColor: "inherit",
                fontBgColor: "inherit",
            },
        },
        {
            name: "Heading 2",
            value: "vp-heading-2",
            varName: "heading2",
            styles: {
                fontSize: "60px",
                fontWeight: "300",
                fontStyle: "normal",
                fontSpacing: "-0.5px",
                fontColor: "inherit",
                fontBgColor: "inherit",
            },
        },
        {
            name: "Heading 3",
            value: "vp-heading-3",
            varName: "heading3",
            styles: {
                fontSize: "48px",
                fontWeight: "400",
                fontStyle: "normal",
                fontSpacing: "0px",
                fontColor: "inherit",
                fontBgColor: "inherit",
            },
        },
        {
            name: "Heading 4",
            value: "vp-heading-4",
            varName: "heading4",
            styles: {
                fontSize: "34px",
                fontWeight: "400",
                fontStyle: "normal",
                fontSpacing: "0.25px",
                fontColor: "inherit",
                fontBgColor: "inherit",
            },
        },
        {
            name: "Heading 5",
            value: "vp-heading-5",
            varName: "heading5",
            styles: {
                fontSize: "24px",
                fontWeight: "400",
                fontStyle: "normal",
                fontSpacing: "0px",
                fontColor: "inherit",
                fontBgColor: "inherit",
            },
        },
        {
            name: "Body 1",
            value: "vp-body-1",
            varName: "body1",
            styles: {
                fontSize: "14px",
                fontWeight: "400",
                fontStyle: "normal",
                fontSpacing: "0.5px",
                fontColor: "inherit",
                fontBgColor: "inherit",
            },
        },
        {
            name: "Body 2",
            value: "vp-body-2",
            varName: "body2",
            styles: {
                fontSize: "12px",
                fontWeight: "400",
                fontStyle: "normal",
                fontSpacing: "0.25px",
                fontColor: "inherit",
                fontBgColor: "inherit",
            },
        },
        {
            name: "Caption",
            value: "vp-caption",
            varName: "caption",
            styles: {
                fontSize: "10px",
                fontWeight: "400",
                fontStyle: "normal",
                fontSpacing: "0.4px",
                fontColor: "inherit",
                fontBgColor: "inherit",
            },
        },
    ];

    get styleClasses(): Array<any> {
        if (this.selectedDecision && this.selectedDecision.json) {
            return JSON.parse(this.selectedDecision.json);
        } else {
            return this.defaultStyleClasses;
        }
    }

    get selectedWorkspaceId(): number | null {
        return getModule(Workspaces).selectedWorkspaceId;
    }

    get selectedDecision(): Decision | null {
        return decisionsModule.selectedDecision;
    }

    private mergeClasses(factorJson: string | undefined): string {
        if (factorJson) {
            let currentFactorClass = JSON.parse(factorJson);
            console.log(currentFactorClass);
            currentFactorClass.factor_styles = this.styleClasses;
            return JSON.stringify(currentFactorClass);
        } else {
            console.log("empty");
            return "";
        }
    }

    private async updateDecisionStyleClasses() {
        if (this.selectedDecision) {
            await decisionsModule.updateDecision({
                decision_id: this.selectedDecision.id,
                name: this.selectedDecision.name,
                json: JSON.stringify(this.styleClasses),
            });
            if (this.selectedWorkspaceId) {
                await decisionsModule.fetchWorkspaceDecisions(
                    this.selectedWorkspaceId
                );
            }
            let style: HTMLElement | null;
            if (document.getElementById("vp-text-styles")) {
                style = document.getElementById("vp-text-styles");
            } else {
                style = document.createElement("style");
                style.setAttribute("id", "vp-text-styles");
            }

            let newStyles: string[] = [];
            this.styleClasses.forEach((styleClass) => {
                let styleVars = `--fontSize-${styleClass.varName}: ${styleClass.styles.fontSize} !important;
            --fontWeight-${styleClass.varName}: ${styleClass.styles.fontWeight} !important;
            --fontStyle-${styleClass.varName}: ${styleClass.styles.fontStyle} !important;
            --fontSpacing-${styleClass.varName}: -1.5px !important;
            --fontColor-${styleClass.varName}: ${styleClass.styles.fontColor} !important;
            --fontBgColor-${styleClass.varName}: ${styleClass.styles.fontBgColor} !important;`;

                newStyles.push(styleVars);
            });
            let newStylesString = newStyles.join(" ").toString();
            if (style) {
                style.textContent = `:root {${newStylesString}}`;
                document.head.appendChild(style);
            }
            this.open = false;
        }
    }

    private updateStyleClasses(className: string, styleClassObject: any) {
        this.styleClasses.forEach((styleClass) => {
            if (styleClass.name == className) {
                console.log(styleClassObject);
                styleClass.styles = styleClassObject;
            }
        });
    }
}

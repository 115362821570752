var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-compare-cell"},[(_vm.showScoreData && _vm.scoreVisible)?[_c('FactorScore',{attrs:{"factor-id":_vm.displayItems['factors'].id,"choiceId":_vm.displayItems['choices'].id,"viewpoint-id":_vm.displayItems['viewpoints'].id,"score":_vm.scoreData,"header":_vm.header,"factor-type":_vm.displayItems['factors'].value_type,"viewpoint-map":_vm.viewpointData,"editable":_vm.scoresEditable,"class-rating":_vm.classRating,"score-display":_vm.scoreDisplay,"selector-settings":_vm.selectorSettings,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"score-rule-btn":_vm.showScoreRuleBtn,"load-score":_vm.loadScore},on:{"factor-toggle":_vm.toggleFactor},scopedSlots:_vm._u([(_vm.showScoreRuleBtn)?{key:"scoreRule",fn:function(){return [_c('v-btn',{staticClass:"vp-compare__score-btn",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFactor.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-marker-distance")])],1)]},proxy:true}:null,(_vm.showWeights)?{key:"weights",fn:function(){return [_c('WeightCounterMenu',{attrs:{"indexes":_vm.indexes,"column-item":_vm.columnItem,"row-item":_vm.rowItem,"icon":_vm.scoreDisplay.weights_icon,"data-item":_vm.dataItem,"editable":_vm.weightsEditable}})]},proxy:true}:null],null,true)},[(
                    _vm.choiceDataVisible &&
                    _vm.displayItems['factors'] &&
                    !_vm.displayItems['factors'].is_table &&
                    !_vm.displayItems['factors'].is_group &&
                    _vm.displayItems['choices']
                )?[_c('div',{staticClass:"vp-padding-top-3"},[_c('FactorValueDisplay',{attrs:{"factor":_vm.displayItems['factors'],"showName":false,"editable":_vm.choiceEditable,"choice-id":_vm.displayItems['choices'].id,"value":_vm.choiceFactorValue ? _vm.choiceFactorValue : {},"isThumbnailDisplay":true,"autoSaveMedia":true,"hideDesc":true}})],1)]:_vm._e()],2)]:[_c('CompareCellContent',{attrs:{"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"blank-score":_vm.showScoreData},scopedSlots:_vm._u([(_vm.showScoreRuleBtn)?{key:"scoreRule",fn:function(){return [_c('v-btn',{staticClass:"vp-compare__score-btn",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFactor.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-marker-distance")])],1)]},proxy:true}:null,(_vm.showWeights)?{key:"weights",fn:function(){return [_c('WeightCounterMenu',{attrs:{"indexes":_vm.indexes,"column-item":_vm.columnItem,"row-item":_vm.rowItem,"icon":_vm.scoreDisplay.weights_icon,"data-item":_vm.dataItem,"editable":_vm.weightsEditable}})]},proxy:true}:null],null,true)},[(
                    _vm.choiceDataVisible &&
                    _vm.displayItems['factors'] &&
                    !_vm.displayItems['factors'].is_table &&
                    !_vm.displayItems['factors'].is_group &&
                    _vm.displayItems['choices']
                )?[_c('div',[_c('FactorValueDisplay',{attrs:{"factor":_vm.displayItems['factors'],"showName":false,"editable":_vm.choiceEditable,"choice-id":_vm.displayItems['choices'].id,"value":_vm.choiceFactorValue ? _vm.choiceFactorValue : {},"isThumbnailDisplay":true,"autoSaveMedia":true,"hideDesc":true}})],1)]:_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
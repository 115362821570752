
import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    ScoreClass,
    Rule,
    ClassSet,
    CategoryClass,
} from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import Scoring from "@/store/modules/Scoring";
import NumberRule from "@/components/viewpoints/viewpointMapping/scoreRule/NumberRule.vue";
import DateRule from "@/components/viewpoints/viewpointMapping/scoreRule/DateRule.vue";
import OptionRule from "@/components/viewpoints/viewpointMapping/scoreRule/OptionRule.vue";
import AggregateRule from "@/components/viewpoints/viewpointMapping/scoreRule/AggregateRule.vue";
import ScoreClassCreator from "@/components/scoring/scoreClass/ScoreClassCreator.vue";
import VpDialog from "@/components/ui/VpDialog.vue";

const modelModule = getModule(Factors);
const scoringModule = getModule(Scoring);

@Component({
    components: {
        NumberRule,
        DateRule,
        OptionRule,
        AggregateRule,
        ScoreClassCreator,
        VpDialog,
    },
})
export default class FactorScoreRule extends Vue {
    @Prop()
    factor!: Factor;

    @PropSync("manualScore", Boolean)
    manual!: boolean;

    @Prop()
    decisionId!: number | null;

    @Watch("factor")
    private onChangeFactor(): void {
        this.manual = this.factor?.m_score ? false : true;
    }

    private editModal = false;
    private selectedScoreClass: ScoreClass | null = null;
    // private scoreRule: Rule | null = null;
    private ruleName = "";

    get scoreRule(): Rule | null {
        if (this.factor.score_rule_id) {
            this.ruleName =
                scoringModule.scoreRules[this.factor.score_rule_id].name;
            return scoringModule.scoreRules[this.factor.score_rule_id];
        }

        return null;
    }

    get classRule(): Rule | null {
        if (this.factor.index_rule_id) {
            return scoringModule.scoreRules[this.factor.index_rule_id];
        }

        return null;
    }

    get classSet(): ClassSet | null {
        if (this.factor.set_id) {
            return scoringModule.classClusters[this.factor.set_id];
        }

        return null;
    }

    get manualLock(): boolean {
        if (this.factor) {
            return (
                !modelModule.autoFactors.includes(this.factor.value_type) ||
                this.factor.is_group ||
                this.factor.is_table
            );
        }
        return false;
    }

    get factorType(): string {
        if (this.factor) {
            return this.factor.value_type;
        }
        return "";
    }

    get isFactorGroupOrTable(): boolean {
        if (this.factor) {
            return this.factor.is_group || this.factor.is_table;
        }
        return false;
    }

    private async toggleClass(id: number): Promise<void> {
        if (id && scoringModule.scoreClasses[id]) {
            this.selectedScoreClass = scoringModule.scoreClasses[id];
            this.editModal = true;
        } else if (id == -1) {
            this.editModal = true;
        }
    }

    private async saveRangeRule(rule: {
        classes: { [index: number]: number };
        ranges: { value: number; score: number }[];
        min: number;
    }): Promise<void> {
        console.log(rule);
    }

    private async saveMatchRule(rule: {
        classList: { [index: number]: number };
        matches: {
            match: string;
            score: number;
            index: number;
            label: string;
        }[];
        items: CategoryClass[];
    }): Promise<void> {
        console.log(rule);
        let name = `${this.factor.name}`;
        let newScoreRule: null | Rule = null;
        let scoreRule = this.factor.score_rule_id
            ? scoringModule.scoreRules[this.factor.score_rule_id]
            : null;
        let classRule = this.factor.index_rule_id
            ? scoringModule.scoreRules[this.factor.index_rule_id]
            : null;
        let cluster = this.factor.set_id
            ? scoringModule.classClusters[this.factor.set_id]
            : null;
        // let res = await saveMatchRuleMap({
        //     cluster: cluster,
        //     rule: rule,
        //     decision_id: this.decisionId ?? -1,
        //     scoreRule: scoreRule,
        //     classRule: classRule,
        //     manual: this.manual,
        //     name: name,
        //     save_options: true,
        // });
        // if (this.factor.score_rule_id == null) {
        //     newScoreRule = await scoringModule.createMatchRuleL({
        //         name: name,
        //         null_value_score: 0,
        //         no_match_score: 0,
        //         decision_id: this.decisionId,
        //         is_index_rule: false,
        //     });
        // }
        // name = `${this.factor.name} - Index Match Rule`;
        // const newClassRule = await scoringModule.createMatchRuleL({
        //     name: name,
        //     null_value_score: 0,
        //     no_match_score: 0,
        //     decision_id: this.decisionId,
        //     is_index_rule: true,
        // });
        // if (newScoreRule && newClassRule) {
        //     rule.matches.forEach((match) => {
        //         scoringModule.createRuleMatchL({
        //             score: match.score,
        //             match: match.match,
        //             rule_id: newScoreRule.id,
        //             label: match.label,
        //         });
        //         scoringModule.createRuleMatchL({
        //             score: match.index,
        //             match: match.match,
        //             rule_id: newClassRule.id,
        //             label: match.label,
        //         });
        //     });
        // }
        // let newClassList = null;
        // if (this.factor.set_id == null) {
        //     newClassList = await scoringModule.createClassSet({
        //         decision_id: this.decisionId ? this.decisionId : -1,
        //         name: `${this.factor.name} - Class Set`,
        //     });
        //     if (newClassList) {
        //         for (let indexKey of Object.keys(rule.classList)) {
        //             let index = parseInt(indexKey);
        //             scoringModule.createClassMapping({
        //                 clusterId: newClassList?.id,
        //                 classId: rule.classList[index],
        //                 index: index,
        //             });
        //         }
        //     }
        // }

        // this.$emit("rules-created", {
        //     class_rule_id: res.class_rule,
        //     score_rule_id: res.score_rule,
        //     set_id: res.cluster,
        // });
    }
}

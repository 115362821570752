
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, Value } from "@/graphql/API";
import Choices from "@/store/modules/Choices";
import ChoiceEditRow from "@/components/choices/editor/ChoiceEditRow.vue";

const choiceModule = getModule(Choices);

@Component({
    components: {
        ChoiceEditRow,
    },
})
export default class ChoiceTableRow extends Vue {
    @Prop({ default: 1, type: Number })
    rowIndex!: number;

    @Prop({ default: () => [], type: Array })
    columns!: Factor[];

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop()
    rowId!: string;

    @Prop()
    choiceId!: number;

    @Prop({ default: true, type: Boolean })
    dragEnabled!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    get valueMap(): { [id: string]: Value } {
        if (this.choiceId) {
            return choiceModule.choiceValues[this.choiceId];
        } else {
            return {};
        }
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Choice,
    Viewpoint,
    Value,
    Score,
    ViewpointMapping,
    ScoreOptions,
    ScoreClassSettings,
    FactorOptions,
    EnhancedScore,
} from "@/graphql/API";
import Choices from "@/store/modules/Choices";
import Scoring from "@/store/modules/Scoring";
import Viewpoints from "@/store/modules/Viewpoints";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";
import FactorScore from "@/components/scoring/FactorScore.vue";
import WeightCounterMenu from "@/components/weights/WeightCounterMenu.vue";
import ChartDialog from "@/components/compare/ui/ChartDialog.vue";
import CompareCellContent from "@/components/compare/CompareCellContent.vue";

const choiceModule = getModule(Choices);
const scoringModule = getModule(Scoring);
const viewpointsModule = getModule(Viewpoints);

@Component({
    components: {
        FactorValueDisplay,
        FactorScore,
        WeightCounterMenu,
        ChartDialog,
        CompareCellContent,
    },
})
export default class CompareCell extends Vue {
    @Prop()
    indexes!: { [id: string]: string };

    @Prop()
    columnItem!: Factor | Choice | Viewpoint;

    @Prop()
    rowItem!: Factor | Choice | Viewpoint;

    @Prop()
    dataItem!: Factor | Choice | Viewpoint | null;

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoresEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    scoreRule!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop()
    selectedChoice!: Choice;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: false, type: Boolean })
    tableCell!: boolean;

    @Prop({ default: true, type: Boolean })
    loadScore!: boolean;

    @Prop()
    header!: string;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private all = [];

    get viewpointMappings(): {
        [viewpointId: number]: {
            [factorId: number]: ViewpointMapping;
        };
    } {
        return viewpointsModule.viewpointMappings;
    }

    get gridItems(): { [id: string]: Factor | Choice | Viewpoint | null } {
        return {
            columns: this.columnItem,
            rows: this.rowItem,
            data: this.dataItem,
        };
    }

    get weightShow(): boolean {
        if (this.viewpointData && this.viewpointData.weight > 0) {
            return true;
        } else {
            return false;
        }
    }

    get displayItems(): { [id: string]: Factor | Choice | Viewpoint | null } {
        return Object.keys(this.indexes).reduce((a, v) => {
            return {
                ...a,
                [this.indexes[v]]: this.gridItems[v],
            };
        }, {});
    }

    get showChoiceData(): boolean {
        if (
            this.indexes["rows"] == "factors" ||
            this.indexes["rows"] == "choices"
        ) {
            if (
                this.indexes["columns"] == "factors" ||
                this.indexes["columns"] == "choices"
            ) {
                return true;
            }
        }
        return false;
    }

    /* Checks if the cell will have a score data
        needs a factor, choice and viewpoints */
    get showScoreData(): boolean {
        if (
            this.displayItems["factors"] &&
            this.displayItems["choices"] &&
            this.displayItems["viewpoints"]
        ) {
            return true;
        } else {
            return false;
        }
    }

    get scoreData(): EnhancedScore | null {
        if (
            this.displayItems["factors"] &&
            this.displayItems["choices"] &&
            this.displayItems["viewpoints"]
        ) {
            const id = `${this.displayItems["viewpoints"].id}-${this.displayItems["choices"].id}-${this.displayItems["factors"].id}`;
            return scoringModule.keyedScores[id];
        }
        return null;
    }

    get viewpointData(): ViewpointMapping | null {
        if (
            this.displayItems["factors"] &&
            this.displayItems["choices"] &&
            this.displayItems["viewpoints"]
        ) {
            if (
                this.viewpointMappings &&
                this.viewpointMappings[this.displayItems["viewpoints"].id]
            ) {
                return this.viewpointMappings[
                    this.displayItems["viewpoints"].id
                ][this.displayItems["factors"].id];
            }
        }
        return null;
    }

    get choiceFactorValue(): Value | null {
        if (this.displayItems && this.displayItems.choices) {
            if (
                choiceModule.choiceValues[this.displayItems.choices.id] &&
                this.displayItems.factors
            ) {
                if (
                    choiceModule.choiceValues[this.displayItems.choices.id][
                        this.displayItems.factors.id
                    ]
                ) {
                    return choiceModule.choiceValues[
                        this.displayItems.choices.id
                    ][this.displayItems.factors.id];
                }
            }
        }
        return null;
    }

    get viewpointNoneSummaryRule(): boolean {
        if (
            this.viewpointData &&
            this.viewpointData.score_rule_id ==
                scoringModule.globalNoneSummaryRuleId
        ) {
            return true;
        } else {
            return false;
        }
    }

    get choiceDataVisible(): boolean {
        if (this.showChoiceData && this.scoreDisplay.choice_data) {
            return true;
        } else {
            return false;
        }
    }

    /* This prop determines if the axises should show weights
        and Score Rule button in the cell */
    get showViewpointCell(): boolean {
        if (
            (this.indexes["columns"] == "viewpoints" &&
                this.indexes["rows"] == "factors") ||
            (this.indexes["rows"] == "viewpoints" &&
                this.indexes["columns"] == "factors")
        ) {
            return true;
        } else {
            return false;
        }
    }

    get showScoreRuleBtn(): boolean {
        if (this.showViewpointCell) {
            return this.scoreRule && !this.tableCell;
        } else {
            return false;
        }
    }

    get showWeights(): boolean {
        if (this.showViewpointCell) {
            return this.scoreDisplay.weights;
        } else {
            return false;
        }
    }

    get scoreVisible(): boolean {
        if (this.viewpointData && this.viewpointData.weight > 0) {
            return true;
        } else {
            return false;
        }
    }

    private toggleFactor(): void {
        if (this.displayItems["factors"] && this.displayItems["viewpoints"]) {
            this.$emit("factor-select", {
                factor: this.displayItems["factors"],
                viewpoint: this.displayItems["viewpoints"],
            });
        }
    }
}

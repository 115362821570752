
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import { App, Tab } from "@/graphql/API";
import AppCreator from "@/components/apps/AppCreator.vue";
import TabCreator from "@/components/apps/tabs/TabCreator.vue";

@Component({
    components: {
        AppCreator,
        TabCreator,
    },
})
export default class AppTabCreator extends Vue {
    @Prop()
    app!: App;

    @Prop()
    tab!: Tab;

    private view = "app";

    private async selectTab(tab: Tab | null): Promise<void> {
        if (tab) {
            this.$emit("select-tab", tab.id);
        }
    }

    private editTab(payload: { app: App; tab: Tab }): void {
        this.$emit("edit-tab", payload);
    }
}

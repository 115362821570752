
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Viewpoint, Choice, ChartValues, ViewpointMapping } from "@/graphql/API";
import BarChart from "@/components/charts/BarChart.vue";
import StackedChart from "@/components/charts/StackedChart.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import Scoring from "@/store/modules/Scoring";
import WorkspaceOptions from "@/store/modules/WorkspaceOptions";
import Viewpoints from "@/store/modules/Viewpoints";

const workspaceOptions = getModule(WorkspaceOptions);
const scoringModule = getModule(Scoring);

@Component({
    components: {
        BarChart,
        VpDialog,
        StackedChart,
    },
})
export default class ChartDialog extends Vue {
    @Prop()
    chartDataValue!: ChartValues;

    @Prop()
    viewpoints!: Viewpoint[];

    @Prop()
    selectedChoice!: Choice;

    get score(): any {
        if (this.viewpoints && this.chartDataValue) {
            return this.viewpoints.map((viewpoint) => {
                return [
                    viewpoint.id.toString(),
                    this.chartDataValue.f_ids
                        ? this.chartDataValue.f_ids.map((id) => {
                              return [
                                  id,
                                  this.getScore(
                                      viewpoint.id,
                                      this.selectedChoice.id,
                                      id
                                  ),
                              ];
                          })
                        : [],
                ];
            });
        } else {
            return [];
        }
    }

    get isBarChartHorizontal(): boolean {
        return workspaceOptions.isBarChartHorizontal;
    }

    set isBarChartHorizontal(isHorizontal: boolean) {
        workspaceOptions.setIsBarChartHorizontal(isHorizontal);
    }

    get viewpointMappings(): {
        [viewpointId: number]: {
            [factorId: number]: ViewpointMapping;
        };
    } {
        return getModule(Viewpoints).viewpointMappings;
    }

    get barChartData() {
        var res = [];
        var series = [];
        let datas = [];
        for (let N = 0; N < this.chartDataValue.categories_factors.length; N++) {
            let inside_datas = [];
            for (let v = 0; v < this.score.length; v++) {
                if (this.score[v][0] === this.chartDataValue.vp_ids[v]) {
                    for (let i = 0; i < this.score[0][1].length; i++) {
                        if (
                            this.score[v][1][i][0] ===
                            this.chartDataValue.f_ids[N]
                        ) {
                            inside_datas.push(
                                this.score[v][1][i][1]
                                    ? this.score[v][1][i][1].toFixed(2)
                                    : 0
                            );
                        }
                    }
                }
            }
            datas.push(inside_datas);
            console.log("DATAAAAAAAAS",datas)
            var inside_series = {
                id: this.chartDataValue.f_ids[N],
                name: this.chartDataValue.categories_factors[N],
                data: datas[N],
            };
            series.push(inside_series);
        }
        var res_data = {
            series: series,
        };
        const res_xaxis = {
            categories: this.chartDataValue.categories_viewpoint,
        };
        res.push({
            data: res_data,
            title: "Viewpoint",
            xaxis: res_xaxis,
        });
        return res[0];
    }

    private getScore(viewpointId: number, choiceId: number, factorId: string) {
        const score =
            scoringModule.keyedScores[`${viewpointId}-${choiceId}-${factorId}`];
        if (score && this.viewpointMappings[viewpointId] && this.viewpointMappings[viewpointId][score.f_id]) {
            console.log("SCORE",score)
            if (this.viewpointMappings[viewpointId][score.f_id].use_m_score) {
                return score.m_score;
            } else {
                return score.c_score;
            }
        } else {
            return 0;
        }
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"vp-table-manager__header-row"},[(_vm.checkboxes)?_c('td',{staticClass:"vp-table-manager__cell vp-table-manager__cell--checkbox"},[(_vm.toggled > 0)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],attrs:{"content":"Clear selected","icon":"","small":""},on:{"click":function($event){return _vm.$emit('clear-selected')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-off-outline")])],1):_vm._e()],1):_vm._e(),(_vm.expand)?_c('th',{staticClass:"vp-table-manager__header vp-table-manager__cell vp-table-manager__cell--expand"}):_vm._e(),_vm._l((_vm.columns),function(column,index){return _c('th',{key:index,staticClass:"vp-table-manager__header vp-table-manager__cell",class:{
            'vp-table-manager__header--active':
                _vm.syncedIndex && _vm.syncedIndex == column.property,
        },on:{"click":function($event){return _vm.changeSort(column.property)}}},[_c('div',{staticClass:"vp-table-manager__cell-wrapper"},[_c('span',{staticClass:"vp-table-manager__cell__content"},[_vm._v(" "+_vm._s(column.label)+" ")]),(
                    _vm.sortable &&
                    _vm.syncedIndex &&
                    _vm.syncedIndex == column.property
                )?_c('v-btn',{staticClass:"vp-table-manager__order",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedDesc = !_vm.syncedDesc}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.syncedDesc ? "mdi-arrow-down" : "mdi-arrow-up")+" ")])],1):_vm._e()],1)])}),(_vm.draggable)?_c('th',{staticClass:"vp-table-manager__header vp-table-manager__cell vp-table-manager__cell--drag"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }

import Vue from "vue";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import VpDialog from "@/components/ui/VpDialog.vue";
import {
    uploadMediaFile,
    getS3FileUrl,
    removeFile,
} from "@/helpers/MediaUploadHelper";
import { v4 as uuidv4 } from "uuid";
import FilePreview from "@/components/common/media/FilePreview.vue";
import DragFileUpload from "@/components/common/media/DragFileUpload.vue";

@Component({
    name: "FileUpload",
    components: {
        VpDialog,
        FilePreview,
        DragFileUpload,
    },
})
export default class FileUpload extends Vue {
    @Prop({ type: String, default: "mdi-image-plus" })
    iconSave!: string;

    @Prop({ type: String, default: "image" })
    fileType!: string;

    @Prop({ type: String, default: "mdi-image-edit" })
    iconEdit!: string;

    @Prop({ type: String, default: "mdi-trash-can-outline" })
    iconDelete!: string;

    @Prop({ type: String, default: "" })
    path!: string;

    @Prop({ type: String, default: "140px" })
    maxWidth!: string;

    @Prop({ type: String, default: "140px" })
    maxHeight!: string;

    @Prop({ type: Boolean, default: false })
    autosave!: boolean;

    @Prop({ type: String, default: "" })
    value!: string;

    @Prop({ type: Boolean, default: false })
    showPreviewModal!: boolean;

    @Prop({ type: Boolean, default: false })
    isFactorTable!: boolean;

    @Prop({ type: Boolean, default: false })
    scoringTab!: boolean;

    @Prop({ default: false, type: Boolean })
    isThumbnailDisplay!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: true, type: Boolean })
    condensed!: boolean;

    @Prop({ default: () => null, type: Object })
    valueJson!: any;

    @Prop({ type: Boolean, default: false })
    deleting!: boolean;

    private file: File | null = null;
    /* Preview File is path to file that has been uploaded to choice */
    private previewFile = "";
    private uploadingFile = false;
    private imagePath = "";
    private refreshCounter = 0;
    private dragStart = false;
    private fileIcon = "mdi-image-plus-outline";

    private uploadPreview = false;

    private loading = false;

    $refs!: {
        fileInput: HTMLFormElement;
    };

    get hasValue(): boolean {
        if (this.value) {
            return true;
        } else {
            return false;
        }
    }

    get hasFile(): boolean {
        if (this.previewFile) {
            return true;
        } else {
            return false;
        }
    }

    get valueFileName(): string | null {
        if (this.valueJson && this.valueJson.srcFile) {
            return this.valueJson.srcFile;
        } else {
            return null;
        }
    }

    get mediaIcons(): string {
        switch (this.fileType) {
            case "image":
                return "mdi-image-plus-outline";
                break;
            case "video":
                return "mdi-play-circle-outline";
                break;
            case "audio":
                return "mdi-music-box-outline";
                break;
            case "document":
                return "mdi-file-document-plus-outline";
                break;
            default:
                return "mdi-file-document-plus-outline";
        }
    }

    get loadingIcon(): string {
        if (this.uploadingFile) {
            return "mdi-content-save";
        } else if (this.deleting) {
            return "mdi-delete";
        } else {
            return this.mediaIcons;
        }
    }

    get btnLabel(): string {
        switch (this.fileType) {
            case "image":
                return "image";
                break;
            case "video":
                return "video";
                break;
            case "audio":
                return "audio";
                break;
            case "document":
                return "file";
                break;
            default:
                return "file";
        }
    }

    get filePDFType(): boolean {
        if (
            this.previewFile.endsWith(".pdf") ||
            (this.file && this.file.type == "application/pdf")
        ) {
            return true;
        } else {
            return false;
        }
    }

    get previewComponent(): string | null {
        if (this.fileType == "image") {
            return "ImagePreview";
        } else if (this.fileType == "video") {
            return "VideoPreview";
        } else if (this.fileType == "audio") {
            return "AudioPreview";
        } else if (this.fileType == "document") {
            return "DocumentPreview";
        } else {
            return null;
        }
    }

    get mediaAccept(): string {
        switch (this.fileType) {
            case "image":
                return "image/*";
                break;
            case "video":
                return "video/*";
                break;
            case "audio":
                return "audio/*";
                break;
            case "document":
                return "";
                break;
            default:
                return "";
        }
    }

    get showLoading(): boolean {
        return this.loading || this.uploadingFile || this.deleting;
    }

    private getExtension(filename: string): string {
        var parts = filename.split(".");
        return "." + parts[parts.length - 1];
    }

    private async deleteFile(): Promise<void> {
        this.$emit("delete-file");
    }

    private async saveFile(file: File): Promise<void> {
        this.uploadPreview = false
        if (file) {
            this.uploadingFile = true;
            let reader = new FileReader();
            const fileName = file
                ? uuidv4() + this.getExtension(file.name)
                : uuidv4();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");

            let result = await uploadMediaFile({
                file: file,
                type: this.fileType,
                name: fileName,
                srcFileName: file.name,
            });
            if (this.value != null && this.value != "") {
                await removeFile({
                    fileName: this.value,
                    type: this.fileType,
                    hideMessage: true,
                });
            }
            this.$emit("created", {
                fileName: result,
                srcFile: file.name,
            });
            this.uploadingFile = false;
        }
    }

    @Watch("path")
    onPathChange(newVal: string): void {
        this.imagePath = newVal;
        this.refreshCounter++;
    }

    @Watch("value")
    async onFileValueChange(val: string): Promise<void> {
        if (val && val != "") {
            this.loading = true;
            this.previewFile = await getS3FileUrl({
                fileName: this.value,
                type: this.fileType,
            });
            this.loading = false;
        } else {
            this.previewFile = "";
        }
    }
}

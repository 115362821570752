import {
    Action,
    config,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import FlashMessage from "../../helpers/ui/FlashMessage";


config.rawError = true;

const name = "FlashNotifications";

if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}
@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class FlashNotifications extends VuexModule {

	messages: FlashMessage[] = [];

    @Mutation
    private push(msg: FlashMessage) {
        this.messages.push(msg);
	}
	
	@Mutation
    private remove(msg: FlashMessage) {
		const currentMsgIndex = this.messages.findIndex((t) => t.uuid = msg.uuid);
		this.messages.splice(currentMsgIndex, 1);
		
    }
    
	@Action
    success (payload: {message: string, duration: number}) {
        this.push(new FlashMessage("success", payload.message, payload.duration));
	}

	@Action
    info (payload: {message: string, duration: number}) {
        this.push(new FlashMessage("info", payload.message, payload.duration));
	}

	@Action
    warning (payload: {message: string, duration: number}) {
        this.push(new FlashMessage("warning", payload.message, payload.duration));
	}

	@Action
    error (payload: {message: string, duration: number}) {
        this.push(new FlashMessage("error", payload.message, payload.duration));
	}
	
	@Action
    removeMessage(msg: FlashMessage) {
        this.remove(msg);
	}
	
}

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Document from "@tiptap/extension-document";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import Text from "@tiptap/extension-text";
import { Editor, EditorContent } from "@tiptap/vue-2";
import ParagraphCustom from "@/plugins/ParagraphCustom.ts";

@Component({
    name: "UnitCreatorAbbreviation",
    components: { EditorContent },
})
export default class UnitCreator extends Vue {
    @Prop({ type: String, default: "" })
    unitAbrv!: string;

    private editor: any = null;
    private unitAbbreviation = "";

    @Watch("unitAbrv")
    onUnitAbrvChange(unitAbrv: string) {
        const isSame = this.editor.getHTML() === unitAbrv;
        if (isSame) {
            return;
        }
        this.unitAbbreviation = this.unitAbrv;
        this.editor.commands.setContent(unitAbrv, false);
    }

    mounted(): void {
        this.editor = this.getEditor();
    }

    private getEditor() {
        return new Editor({
            content: this.unitAbbreviation,
            extensions: [
                Document,
                Text,
                ParagraphCustom,
                Superscript,
                Subscript,
            ],
            onUpdate: () => {
                this.$emit("input", this.editor.getHTML());
            },
        });
    }
}

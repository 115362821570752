
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import FlashNotifications from "@/store/modules/FlashNotifications";
import Logo from "@/components/ui/Logo.vue";
import { Auth } from "aws-amplify";
import PasswordInput from "@/components/ui/PasswordInput.vue";
import { Urls } from "@/Urls";

const flashModule = getModule(FlashNotifications);

@Component({
    components: { PasswordInput },
})
export default class ResetForm extends Vue {
    private email = "";
    private codeSent = false;
    private loading = false;
    private confirmationLoading = false;
    private password = "";
    private confirmPassword = "";
    private resetCode = "";
    private isFormValid = false;
    private errMsg = "";

    private showCheck = false;

    get validEmail(): boolean {
        if (this.email && this.email.length) {
            var re = /\S+@\S+\.\S+/;
            return re.test(this.email);
        } else {
            return false;
        }
    }

    private updatePassword(value: string): void {
        this.password = value;
    }

    private updateConfirmPassword(value: string): void {
        this.confirmPassword = value;
    }

    //Sends verification code email to user's email
    private async sendReset(again = false): Promise<void> {
        try {
            this.loading = true;
            const result = await Auth.forgotPassword(this.email);
            this.codeSent = true;
            this.loading = false;
        } catch (err: any) {
            console.log(err);
            if (err.name === "UserNotFoundException") {
                this.setErrorMsg("User does not exist");
            }
            this.loading = false;
        }

        if (again) {
            this.showCheck = true;
            setTimeout(() => {
                this.showCheck = false;
            }, 5000);
        }
    }

    //Confirms new password
    private async confirmReset() {
        this.errMsg = "";
        this.confirmationLoading = true;
        try {
            console.log(`Trying COnfirm Reset`);
            this.loading = true;
            await Auth.forgotPasswordSubmit(
                this.email.trim(),
                this.resetCode.trim(),
                this.password
            );
            this.loading = false;
            if (this.$refs.form) (this.$refs.form as HTMLFormElement).reset();
            this.codeSent = false;
            flashModule.success({
                message: "Password Reset",
                duration: 5000,
            });
            this.$emit("to-login-form");
        } catch (err) {
            console.log("Confirm Reset: Error Caught");
            console.log(err);
            this.setErrorMsg(err);
            this.loading = false;
        }
        this.confirmationLoading = false;
    }

    private setErrorMsg(error: any): void {
        console.log("error signing in");
        console.log(error);
        this.errMsg = error;
        setTimeout(() => (this.errMsg = ""), 3000);
    }

    private backBtn(): void {
        if (this.codeSent) {
            this.codeSent = false;
        } else {
            this.$emit("to-login-form");
        }
    }
}

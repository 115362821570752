
import { Vue, Component, Prop } from "vue-property-decorator";
import { Urls } from "@/Urls";
import Logo from "@/components/ui/Logo.vue";
import LoginInterface from "@/layouts/LoginInterface.vue";

@Component({
    components: {
        Logo,
        LoginInterface,
    },
})
export default class LoginView extends Vue {
    @Prop({ default: false, type: Boolean })
    invitation!: boolean;

    @Prop({ default: "", type: String })
    nextLink!: string;

    private readonly URLS: { [id: string]: string } = Urls;

    get isParent(): boolean {
        return this.$route.path == this.URLS.LOGIN;
    }

    private toLoginLink(index: string): void {
        if (index) {
            if (this.URLS[index]) {
                this.$router.push({
                    path: this.URLS[index],
                    query: { next: this.nextLink },
                });
            }
        }
    }
}


import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import Domains from "@/store/modules/Domains";
import Decisions from "@/store/modules/Decisions";
import Scoring from "@/store/modules/Scoring";
import _ from "lodash";
import { Factor, Unit, Rule, DefaultClassSets } from "@/graphql/API";
import RichTextEditor from "@/components/ui/RichTextEditor.vue";

const modelModule = getModule(Factors);
const domainsModule = getModule(Domains);
const decisionsModule = getModule(Decisions);
const scoringModule = getModule(Scoring);

@Component({
    components: {
        RichTextEditor,
    },
})
export default class AiFactorCreator extends Vue {
    @Prop()
    parent!: Factor;

    /* New Factor Props */
    private name = "";
    private type: string | null = null;
    private description = "";
    private unitId: number | null = null;
    private newFactorOptions: string[] = [];
    private newOptions = "";

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get orgIdToDefaultRuleMap(): { [org_id: number]: Rule } {
        return scoringModule.defaultRules;
    }

    get defaultClassSets(): DefaultClassSets {
        const workspaceClassSetsList = scoringModule.classClusters;
        return _.chain(workspaceClassSetsList).groupBy("org_id").value();
    }

    get defaultFactorSetId(): number | undefined {
        if (this.defaultClassSets && this.defaultClassSets[-1]) {
            return this.defaultClassSets[-1][0].id;
        } else {
            return undefined;
        }
    }

    get hasParent(): boolean {
        if (this.parent && this.parent.value_type == "group") {
            return true;
        } else {
            return false;
        }
    }

    get parentId(): number {
        if (this.parent && this.parent.value_type == "group") {
            return this.parent.id;
        } else {
            return -1;
        }
    }

    get valueTypes(): { text: string; id: string }[] {
        return modelModule.valueTypes.filter((item) => item.id !== "table");
    }

    get unitsList(): Unit[] {
        return domainsModule.unitsList;
    }

    get saveBtnActive(): boolean {
        if (this.type && this.name.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    private updateTextDescription(descriptionValue: string) {
        this.description = descriptionValue;
    }

    private updateOption(val: string, index: number): void {
        Vue.set(this.newFactorOptions, index, val);
    }

    private deleteOption(index: number): void {
        Vue.delete(this.newFactorOptions, index);
    }

    private createOptions(): void {
        if (this.newOptions.length > 0) {
            const optionsList = this.newOptions.split(/\r?\n/);
            if (this.newFactorOptions) {
                this.newFactorOptions.push(...optionsList);
            } else {
                this.newFactorOptions = [...optionsList];
            }
            this.newOptions = "";
        }
    }

    private saveFactor(): void {
        if (this.selectedDecisionId && this.type && this.name.length > 0) {
            const newFactor: Factor = {
                id: 0,
                name: this.name,
                description: this.description,
                parent_id: this.parent ? this.parent.id : -1,
                order_str: "",
                type: "",
                value_type: this.type,
                json: "",
                is_table: this.type == "table",
                is_group: this.type == "group",
                is_column: false,
                format: "",
                m_score: 0,
                m_index: undefined,
                weight: 1,
                index_rule_id: -2,
                score_rule_id: -1,
                set_id: this.defaultFactorSetId,
                enum_id: undefined,
                unit_id: this.unitId ? this.unitId : undefined,
                decision_id: this.selectedDecisionId,
            };
            switch (this.type) {
                case "number":
                    newFactor.index_rule_id = this.orgIdToDefaultRuleMap[-6].id;
                    newFactor.score_rule_id = this.orgIdToDefaultRuleMap[-5].id;
                    break;
                case "date_time":
                    newFactor.index_rule_id = this.orgIdToDefaultRuleMap[-10].id;
                    newFactor.score_rule_id = this.orgIdToDefaultRuleMap[-7].id;
                    break;
                case "category":
                    newFactor.index_rule_id = this.orgIdToDefaultRuleMap[-4].id;
                    newFactor.score_rule_id = this.orgIdToDefaultRuleMap[-3].id;
                    break;
                case "group":
                    newFactor.index_rule_id = this.orgIdToDefaultRuleMap[-9].id;
                    newFactor.score_rule_id = this.orgIdToDefaultRuleMap[-8].id;
                    break;
                case "table":
                    newFactor.index_rule_id = this.orgIdToDefaultRuleMap[-9].id;
                    newFactor.score_rule_id = this.orgIdToDefaultRuleMap[-8].id;
                    break;
                default:
                    newFactor.index_rule_id = this.orgIdToDefaultRuleMap[-2].id;
                    newFactor.score_rule_id = this.orgIdToDefaultRuleMap[-1].id;
            }
            this.$emit("new-factor", {
                factor: newFactor,
                options: this.newFactorOptions.length
                    ? this.newFactorOptions
                    : null,
            });

            this.reset();
        }
    }

    private reset(): void {
        this.name = "";
        this.type = null;
        this.description = "";
        this.unitId = null;
        this.newFactorOptions = [];
        this.newOptions = "";
    }
}

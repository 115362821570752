
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
    name: "ParentRouteKeep",
    components: {},
})
export default class ParentRouteKeep extends Vue {
    @Prop({ default: () => [], type: Array })
    keepAlive!: string[];
}

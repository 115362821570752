
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import FlashNotifications from "@/store/modules/FlashNotifications";
import FilePreview from "@/components/common/media/FilePreview.vue";

const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    name: "DragFileUpload",
    components: {
        FilePreview,
    },
})
export default class DragFileUpload extends Vue {
    @Prop({ type: String, default: "mdi-image-plus" })
    icon!: string;

    @Prop({ type: String, default: "" })
    accept!: string;

    @Prop({ type: String, default: "image" })
    fileType!: string;

    @Prop({ type: Boolean, default: false })
    autosave!: boolean;

    @Prop({ type: Boolean, default: true })
    btnOnly!: boolean;

    @Prop({ type: Boolean, default: false })
    fullWidth!: boolean;

    private dragStart = false;
    private previewFile: File | null = null;
    private previewContent: string | null = null;
    private counter = 0;

    get btnMessage(): string {
        if (this.btnOnly) {
            return "Upload";
        } else {
            return "Click Here";
        }
    }

    get mediaIcons(): string {
        switch (this.fileType) {
            case "image":
                return "mdi-image-plus-outline";
                break;
            case "video":
                return "mdi-play-circle-outline";
                break;
            case "audio":
                return "mdi-music-box-outline";
                break;
            case "document":
                return "mdi-file-document-plus-outline";
                break;
            default:
                return "mdi-file-document-plus-outline";
        }
    }

    get btnLabel(): string {
        switch (this.fileType) {
            case "image":
                return "image";
                break;
            case "video":
                return "video";
                break;
            case "audio":
                return "audio";
                break;
            case "document":
                return "file";
                break;
            default:
                return "file";
        }
    }

    private hasPreview(): boolean {
        return this.previewFile != null;
    }

    private dragOver(e: Event): void {
        e.preventDefault();
        this.counter++;
        this.dragStart = true;
    }

    private dragOut(e: Event): void {
        e.preventDefault();
        this.counter--;
        if (this.counter == 0) {
            this.dragStart = false;
        }
    }

    private clearFile(): void {
        this.previewFile = null;
        this.previewContent = null;
        this.$emit("preview-upload-removed");
    }

    private saveFile(): void {
        this.$emit("save-file", this.previewFile);
        this.clearFile();
    }

    private async dropMediaFile(e: DragEvent): Promise<void> {
        this.dragStart = false;
        e.preventDefault();

        if (e.dataTransfer) {
            if (e.dataTransfer.items && e.dataTransfer.items.length) {
                /* Use DataTransferItemList interface to access the file(s) */

                if (e.dataTransfer.items[0].kind === "file") {
                    /* If dropped items aren't files, reject them */
                    const file: File | null = e.dataTransfer.items[0].getAsFile();
                    if (file) {
                        this.handleFile(file);
                    }
                }
            } else if (e.dataTransfer.files && e.dataTransfer.files.length) {
                /* Use DataTransfer interface to access the file(s) */
                const file: File | null = e.dataTransfer.files[0];
                if (file) {
                    this.handleFile(file);
                }
            }
        }
    }

    private fileInput(e: Event): void {
        if (e) {
            const target = e.target as HTMLInputElement;
            if (target.files && target.files.length) {
                const file: File = target.files[0];
                if (file) {
                    this.setPreviewFile(file);
                }
            }
        }
    }

    private handleFile(file: File): void {
        if (!file.type.match(this.accept)) {
            flashNotificationsModule.error({
                message: "File Type Not Supported",
                duration: 5000,
            });
        } else {
            this.setPreviewFile(file);
        }
    }

    private setPreviewFile(file: File): void {
        if (this.autosave) {
            this.$emit("save-file", file);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e: any) => {
                this.previewContent = e.target.result;
            };
            this.previewFile = file;
            this.$emit("preview-upload-added");
        }
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.internalItems && _vm.internalItems.length)?_c('div',{staticClass:"vp-viewpoint-scorerule vp-viewpoint-scorerule--list"},_vm._l((_vm.internalItems),function(item,index){return _c('div',{key:index,staticClass:"vp-range-item",class:{
                'vp-range-item--no-match vp-range-item--top':
                    !_vm.manualRule && index == _vm.internalItems.length - 1,
            }},[(!_vm.manualRule && index == _vm.internalItems.length - 1)?_c('div',[_vm._v(" Above Maximum ")]):_vm._e(),_c('v-form',{staticClass:"vp-range-item__form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"vp-range-item__label"},[_c('div',{staticClass:"vp-range-class"},[(_vm.scoreClasses.length > 0)?_c('v-autocomplete',{attrs:{"items":_vm.scoreClasses,"item-text":"name","item-value":"id","dense":"","placeholder":"Score Class","clearable":""},model:{value:(item.class_id),callback:function ($$v) {_vm.$set(item, "class_id", $$v)},expression:"item.class_id"}}):_vm._e(),(item.class_id)?_c('v-btn',{staticClass:"vp-range-class-edit",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleEditScreen(item.class_id, index)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e()],1),(
                            _vm.scoreClassStyles[index + 1] &&
                            _vm.scoreClassStyles[index + 1].icons
                        )?_c('div',{staticClass:"vp-range-item__icons"},_vm._l((_vm.scoreClassStyles[
                                index + 1
                            ].icons),function(icon,index){return _c('span',{key:index},[_c('v-icon',{attrs:{"color":icon.iconColour != ''
                                        ? _vm.palette[icon.iconColour].hex
                                        : '',"small":""}},[_vm._v(" "+_vm._s(icon.iconCode)+" ")])],1)}),0):_vm._e()]),(!_vm.manualRule)?_c('div',{staticClass:"vp-range-item__value",class:{
                        'vp-range-item__value--edit': _vm.editIndex == index,
                        'vp-range-item__value--bottom': index == 0,
                    },on:{"click":function($event){$event.stopPropagation();_vm.editIndex = index}}},[(_vm.editIndex == index)?_c('DateTimePicker',{attrs:{"value":item.value},on:{"save":function($event){return _vm.changeRange($event, index)}}}):_c('span',[(item.value != null)?_c('span',[_vm._v(" "+_vm._s(_vm.formatUnixToDate(item.value))+" ")]):_c('span',{staticClass:"vp-range-item__value__empty"},[_vm._v(" Select a date ")])])],1):_vm._e(),_c('div',{staticClass:"vp-range-item__score"},[_c('v-text-field',{attrs:{"value":_vm.internalItems[index].score,"prefix":"Score:","dense":"","hide-details":"","type":"number"},on:{"change":function($event){return _vm.updateScore($event, index)}}})],1),_c('v-btn',{staticClass:"vp-range-item__delete",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.deleteRange(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle")])],1),(
                        _vm.scoreClassStyles[index + 1] &&
                        _vm.scoreClassStyles[index + 1].bg_colour &&
                        _vm.palette[_vm.scoreClassStyles[index + 1].bg_colour]
                    )?_c('div',{staticClass:"vp-range-item__colour",style:(`background: ${
                        _vm.palette[_vm.scoreClassStyles[index + 1].bg_colour][
                            'hex'
                        ]
                    };`)}):_vm._e()],1)],1)}),0):_vm._e(),(_vm.internalItems && _vm.internalItems.length > 0 && !_vm.manualRule)?_c('div',{staticClass:"vp-range-item vp-range-item--no-match"},[_c('div',{staticClass:"vp-range-item__title"},[_vm._v("Below Minimum")]),_c('v-form',{staticClass:"vp-range-item__form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{staticClass:"vp-range-item__label"},[_c('div',{staticClass:"vp-range-class"},[(_vm.scoreClasses.length > 0)?_c('v-autocomplete',{attrs:{"items":_vm.scoreClasses,"item-text":"name","item-value":"id","dense":"","hide-details":"","placeholder":"Score Class","clearable":""},model:{value:(_vm.internalMinRangeClass),callback:function ($$v) {_vm.internalMinRangeClass=$$v},expression:"internalMinRangeClass"}}):_vm._e(),(_vm.internalMinRangeClass)?_c('v-btn',{staticClass:"vp-range-class-edit",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleEditScreen(_vm.internalMinRangeClass, -1)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e()],1),(_vm.scoreClassStyles[0] && _vm.scoreClassStyles[0].icons)?_c('div',{staticClass:"vp-range-item__icons"},_vm._l((_vm.scoreClassStyles[0].icons),function(icon,index){return _c('span',{key:index},[_c('v-icon',{attrs:{"color":icon.iconColour != ''
                                    ? _vm.palette[icon.iconColour].hex
                                    : '',"small":""}},[_vm._v(" "+_vm._s(icon.iconCode)+" ")])],1)}),0):_vm._e()]),_c('div',{staticClass:"vp-range-item__score"},[_c('v-text-field',{attrs:{"prefix":"Score:","dense":"","hide-details":"","type":"number"},model:{value:(_vm.internalMinRangeScore),callback:function ($$v) {_vm.internalMinRangeScore=$$v},expression:"internalMinRangeScore"}})],1),_c('div',{staticClass:"vp-range-item__offset"}),(
                    _vm.scoreClassStyles[0] &&
                    _vm.scoreClassStyles[0].bg_colour &&
                    _vm.palette[_vm.scoreClassStyles[0].bg_colour]
                )?_c('div',{staticClass:"vp-range-item__colour",style:(`background: ${
                    _vm.palette[_vm.scoreClassStyles[0].bg_colour]['hex']
                };`)}):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"vp-viewpoint-scorerule__add"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createRange}},[_vm._v("Add Range")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { TableHeader } from "@/graphql/API";

@Component({
    components: {},
})
export default class TableManagerItem extends Vue {
    @Prop({ default: () => [], type: Array })
    columns!: { [id: string]: TableHeader };

    @PropSync("sortIndex")
    syncedIndex!: string;

    @PropSync("desc")
    syncedDesc!: boolean;

    @Prop({ type: Boolean, default: false })
    expand!: boolean;

    @Prop({ type: Boolean, default: true })
    sortable!: boolean;

    @Prop({ type: Boolean, default: false })
    draggable!: boolean;

    @Prop({ type: Boolean, default: false })
    checkboxes!: boolean;

    @Prop({ type: Number, default: 0 })
    toggled!: number;

    private changeSort(index: string): void {
        if (this.sortable) {
            this.syncedIndex = index;
        }
    }
}

export const Palette = {
    "vp--bg-accent": {
        name: "Accent",
        hex: "#ABAA66",
        id: "vp--bg-accent",
        editable: true,
    },
    "vp--bg-accent-2": {
        name: "Accent 2",
        hex: "#66ABAA",
        id: "vp--bg-accent-2",
        editable: true,
    },
    "vp--bg-accent-3": {
        name: "Accent 3",
        hex: "#6667AB",
        id: "vp--bg-accent-3",
        editable: true,
    },
    "vp--bg-accent-4": {
        name: "Accent 4",
        hex: "#AA66AB",
        id: "vp--bg-accent-4",
        editable: true,
    },
    "vp--bg-accent-5": {
        name: "Accent 5",
        hex: "#AB6667",
        id: "vp--bg-accent-5",
        editable: true,
    },
    "vp--bg-accent-6": {
        name: "Accent 6",
        hex: "#67AB66",
        id: "vp--bg-accent-6",
        editable: true,
    },
    "vp--bg-grey-1": {
        name: "Grey 1",
        hex: "#FFFFFF",
        id: "vp--bg-grey-1",
        editable: false,
    },
    "vp--bg-grey-2": {
        name: "Grey 2",
        hex: "#F0F0F0",
        id: "vp--bg-grey-3",
        editable: false,
    },
    "vp--bg-grey-3": {
        name: "Grey 1",
        hex: "#DCDCDC",
        id: "vp--bg-grey-1",
        editable: false,
    },
    "vp--bg-grey-4": {
        name: "Grey 4",
        hex: "#C8C8C8",
        id: "vp--bg-grey-4",
        editable: false,
    },
    "vp--bg-grey-5": {
        name: "Grey 5",
        hex: "#B4B4B4",
        id: "vp--bg-grey-5",
        editable: false,
    },
    "vp--bg-grey-6": {
        name: "Grey 6",
        hex: "#A0A0A0",
        id: "vp--bg-grey-6",
        editable: false,
    },
    "vp--bg-grey-7": {
        name: "Grey 7",
        hex: "#8C8C8C",
        id: "vp--bg-grey-7",
        editable: false,
    },
    "vp--bg-grey-8": {
        name: "Grey 8",
        hex: "#787878",
        id: "vp--bg-grey-8",
        editable: false,
    },
    "vp--bg-grey-9": {
        name: "Grey 9",
        hex: "#646464",
        id: "vp--bg-grey-9",
        editable: false,
    },
    "vp--bg-grey-10": {
        name: "Grey 10",
        hex: "#505050",
        id: "vp--bg-grey-10",
        editable: false,
    },
    "vp--bg-grey-11": {
        name: "Grey 11",
        hex: "#3C3C3C",
        id: "vp--bg-grey-11",
        editable: false,
    },
    "vp--bg-grey-12": {
        name: "Grey 12",
        hex: "#282828",
        id: "vp--bg-grey-12",
        editable: false,
    },
    "vp--bg-grey-13": {
        name: "Grey 13",
        hex: "#141414",
        id: "vp--bg-grey-13",
        editable: false,
    },
    "vp--bg-grey-14": {
        name: "Grey 14",
        hex: "#000000",
        id: "vp--bg-grey-14",
        editable: false,
    },
    "vp--bg-preset-1": {
        name: "Preset 1",
        hex: "#FF3F40",
        id: "vp--bg-preset-1",
        editable: false,
    },
    "vp--bg-preset-2": {
        name: "Preset 2",
        hex: "#FF8040",
        id: "vp--bg-preset-2",
        editable: false,
    },
    "vp--bg-preset-3": {
        name: "Preset 3",
        hex: "#E0D040",
        id: "vp--bg-preset-3",
        editable: false,
    },
    "vp--bg-preset-4": {
        name: "Preset 4",
        hex: "#09C623",
        id: "vp--bg-preset-4",
        editable: false,
    },
    "vp--bg-preset-5": {
        name: "Preset 5",
        hex: "#29B2B3",
        id: "vp--bg-preset-5",
        editable: false,
    },
    "vp--bg-preset-6": {
        name: "Preset 6",
        hex: "#6263E0",
        id: "vp--bg-preset-6",
        editable: false,
    },
    "vp--bg-preset-7": {
        name: "Preset 7",
        hex: "#C82896",
        id: "vp--bg-preset-7",
        editable: false,
    },

    "vp--bg-accent-saturation-0": {
        name: "Accent Saturation 0",
        hex: "#C8C79B",
        id: "vp--bg-accent-saturation-0",
        editable: false,
    },
    "vp--bg-accent-saturation-1": {
        name: "Accent Saturation 1",
        hex: "#BEBE89",
        id: "vp--bg-accent-saturation-1",
        editable: false,
    },
    "vp--bg-accent-saturation-2": {
        name: "Accent Saturation 2",
        hex: "#B5B478",
        id: "vp--bg-accent-saturation-2",
        editable: false,
    },
    "vp--bg-accent-saturation-4": {
        name: "Accent Saturation 4",
        hex: "#9F9E57",
        id: "vp--bg-accent-saturation-4",
        editable: false,
    },
    "vp--bg-accent-saturation-5": {
        name: "Accent Saturation 5",
        hex: "#8D8C4D",
        id: "vp--bg-accent-saturation-5",
        editable: false,
    },
    "vp--bg-accent-saturation-6": {
        name: "Accent Saturation 6",
        hex: "#7B7B44",
        id: "vp--bg-accent-saturation-6",
        editable: false,
    },
    "vp--bg-accent-2-saturation-0": {
        name: "Accent 2 Saturation 0",
        hex: "#9BC8C7",
        id: "vp--bg-accent-2-saturation-0",
        editable: false,
    },
    "vp--bg-accent-2-saturation-1": {
        name: "Accent 2 Saturation 1",
        hex: "#89BEBE",
        id: "vp--bg-accent-2-saturation-1",
        editable: false,
    },
    "vp--bg-accent-2-saturation-2": {
        name: "Accent 2 Saturation 2",
        hex: "#78B5B4",
        id: "vp--bg-accent-2-saturation-2",
        editable: false,
    },
    "vp--bg-accent-2-saturation-4": {
        name: "Accent 2 Saturation 4",
        hex: "#579F9E",
        id: "vp--bg-accent-2-saturation-4",
        editable: false,
    },
    "vp--bg-accent-2-saturation-5": {
        name: "Accent 2 Saturation 5",
        hex: "#4D8D8C",
        id: "vp--bg-accent-2-saturation-5",
        editable: false,
    },
    "vp--bg-accent-2-saturation-6": {
        name: "Accent 2 Saturation 6",
        hex: "#447B7B",
        id: "vp--bg-accent-2-saturation-6",
        editable: false,
    },
    "vp--bg-accent-3-saturation-0": {
        name: "Accent 3 Saturation 0",
        hex: "#9B9CC8",
        id: "vp--bg-accent-3-saturation-0",
        editable: false,
    },
    "vp--bg-accent-3-saturation-1": {
        name: "Accent 3 Saturation 1",
        hex: "#898ABE",
        id: "vp--bg-accent-3-saturation-1",
        editable: false,
    },
    "vp--bg-accent-3-saturation-2": {
        name: "Accent 3 Saturation 2",
        hex: "#7879B5",
        id: "vp--bg-accent-3-saturation-2",
        editable: false,
    },
    "vp--bg-accent-3-saturation-4": {
        name: "Accent 3 Saturation 4",
        hex: "#57589F",
        id: "vp--bg-accent-3-saturation-4",
        editable: false,
    },
    "vp--bg-accent-3-saturation-5": {
        name: "Accent 3 Saturation 5",
        hex: "#4D4E8D",
        id: "vp--bg-accent-3-saturation-5",
        editable: false,
    },
    "vp--bg-accent-3-saturation-6": {
        name: "Accent 3 Saturation 6",
        hex: "#44457B",
        id: "vp--bg-accent-3-saturation-6",
        editable: false,
    },
    "vp--bg-accent-4-saturation-0": {
        name: "Accent 4 Saturation 0",
        hex: "#C79BC8",
        id: "vp--bg-accent-4-saturation-0",
        editable: false,
    },
    "vp--bg-accent-4-saturation-1": {
        name: "Accent 4 Saturation 1",
        hex: "#BE89BE",
        id: "vp--bg-accent-4-saturation-1",
        editable: false,
    },
    "vp--bg-accent-4-saturation-2": {
        name: "Accent 4 Saturation 2",
        hex: "#B478B5",
        id: "vp--bg-accent-4-saturation-2",
        editable: false,
    },
    "vp--bg-accent-4-saturation-4": {
        name: "Accent 4 Saturation 4",
        hex: "#9E579F",
        id: "vp--bg-accent-4-saturation-4",
        editable: false,
    },
    "vp--bg-accent-4-saturation-5": {
        name: "Accent 4 Saturation 5",
        hex: "#8C4D8D",
        id: "vp--bg-accent-4-saturation-5",
        editable: false,
    },
    "vp--bg-accent-4-saturation-6": {
        name: "Accent 4 Saturation 6",
        hex: "#7B447B",
        id: "vp--bg-accent-4-saturation-6",
        editable: false,
    },
    "vp--bg-accent-5-saturation-0": {
        name: "Accent 5 Saturation 0",
        hex: "#C89B9C",
        id: "vp--bg-accent-5-saturation-0",
        editable: false,
    },
    "vp--bg-accent-5-saturation-1": {
        name: "Accent 5 Saturation 1",
        hex: "#BE898A",
        id: "vp--bg-accent-5-saturation-1",
        editable: false,
    },
    "vp--bg-accent-5-saturation-2": {
        name: "Accent 5 Saturation 2",
        hex: "#B57879",
        id: "vp--bg-accent-5-saturation-2",
        editable: false,
    },
    "vp--bg-accent-5-saturation-4": {
        name: "Accent 5 Saturation 4",
        hex: "#9F5758",
        id: "vp--bg-accent-5-saturation-4",
        editable: false,
    },
    "vp--bg-accent-5-saturation-5": {
        name: "Accent 5 Saturation 5",
        hex: "#8D4D4E",
        id: "vp--bg-accent-5-saturation-5",
        editable: false,
    },
    "vp--bg-accent-5-saturation-6": {
        name: "Accent 5 Saturation 6",
        hex: "#7B4445",
        id: "vp--bg-accent-5-saturation-6",
        editable: false,
    },
    "vp--bg-accent-6-saturation-0": {
        name: "Accent 6 Saturation 0",
        hex: "#9CC89B",
        id: "vp--bg-accent-6-saturation-0",
        editable: false,
    },
    "vp--bg-accent-6-saturation-1": {
        name: "Accent 6 Saturation 1",
        hex: "#8ABE89",
        id: "vp--bg-accent-6-saturation-1",
        editable: false,
    },
    "vp--bg-accent-6-saturation-2": {
        name: "Accent 6 Saturation 2",
        hex: "#79B578",
        id: "vp--bg-accent-6-saturation-2",
        editable: false,
    },
    "vp--bg-accent-6-saturation-4": {
        name: "Accent 6 Saturation 4",
        hex: "#589F57",
        id: "vp--bg-accent-6-saturation-4",
        editable: false,
    },
    "vp--bg-accent-6-saturation-5": {
        name: "Accent 6 Saturation 5",
        hex: "#4E8D4D",
        id: "vp--bg-accent-6-saturation-5",
        editable: false,
    },
    "vp--bg-accent-6-saturation-6": {
        name: "Accent 6 Saturation 6",
        hex: "#457B44",
        id: "vp--bg-accent-6-saturation-6",
        editable: false,
    }

};

import {
    Action,
    config,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";

config.rawError = true;

const name = "ui";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}
@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Ui extends VuexModule {
    breadCrumbLabel: string | null = null;

    @Mutation
    updateBreadCrumbLabel(value: string | null): void {
        this.breadCrumbLabel = value;
    }

    @Action
    changeBreadCrumb(label: string | null): void {
        this.updateBreadCrumbLabel(label);
    }
}

import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import CompareView from "@/views/CompareView.vue";
import ChoiceEditor from "@/views/ChoiceEditor.vue";

@Component({
    components: {
        CompareView,
        ChoiceEditor,
    },
})
export default class AppDisplay extends Vue {
   @Prop()
   id!: number;

   private tempString = "";
}


import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
    components: {},
})
export default class CompareColumn extends Vue {
    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    full!: boolean;

    @Prop({ default: false, type: Boolean })
    tableCell!: boolean;
}

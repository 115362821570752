
import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import CompareView from "@/views/CompareView.vue";
import ChoiceEditor from "@/views/ChoiceEditor.vue";
import Logo from "@/components/ui/Logo.vue";

@Component({
    components: {
        CompareView,
        ChoiceEditor,
        Logo,
    },
})
export default class TabLoading extends Vue {}

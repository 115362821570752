
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import ChoiceToggle from "@/components/ui/ChoiceToggle.vue";

@Component({
    components: {
        ChoiceToggle,
    },
})
export default class ChoiceEditSidebar extends Vue {
    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop({ default: true, type: Boolean })
    isTab!: boolean;

    @PropSync("editable")
    syncedEditable!: boolean;

    @PropSync("newChoices")
    syncedNewChoices!: boolean;

    @PropSync("allChoices")
    syncedAllChoices!: boolean;

    /*
    @PropSync("choiceList")
    syncedChoiceList!: number[];

    @PropSync("choiceIds")
    syncedChoiceIds!: number[];

    @PropSync("choiceDef")
    syncedChoiceDef!: boolean;

    @Prop({ default: () => [], type: Array })
    userChoices!: number[];

    @PropSync("selectUserChoices")
    syncedSelectUserChoices!: boolean;

    @PropSync("userChoicesEnabled")
    syncedUserChoicesEnabled!: boolean;

    @PropSync("newLock")
    syncedNewLock!: boolean;

    @PropSync("userEnabled")
    syncedUserEnabled!: boolean;

    @PropSync("editable")
    syncedEditable!: boolean;
    */
}

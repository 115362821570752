
import { Vue, Component, Prop } from "vue-property-decorator";
import Logo from "@/components/ui/Logo.vue";
import WelcomeView from "./WelcomeView.vue";
import { Urls } from "@/Urls";

@Component({
    components: {
        Logo,
        WelcomeView,
    },
})
export default class LoggedOutView extends Vue {
    @Prop({ default: false, type: Boolean })
    appLoading!: boolean;

    @Prop({ default: false, type: Boolean })
    loadError!: boolean;

    private readonly URLS = Urls;
    private loading = false;
    private loggedIn = false;

    private next = "";

    /* Checks if current page is either login or child or login page */
    get notLogin(): boolean {
        return !this.$route.fullPath.startsWith(this.URLS.LOGIN);
    }

    get showLoading(): boolean {
        return this.loading || this.appLoading;
    }

    get isHome(): boolean {
        return this.$route.name == "Home";
    }

    get isReset(): boolean {
        return this.$route.name == "Reset";
    }

    get isLogin(): boolean {
        return this.$route.name == "Login" || this.$route.name == "LoginForm";
    }

    get isAppView(): boolean {
        return this.$route.name == "AppView";
    }

    get isOpenPage(): boolean {
        if (this.$route.meta && this.$route.meta.open) {
            return true;
        } else {
            return false;
        }
    }

    get routeKey(): string {
        return this.$route.fullPath;
    }

    get showSplitScreen(): boolean {
        return this.isOpenPage && !this.loading && !this.loggedIn;
    }

    mounted(): void {
        this.loggedIn = false;
        this.loadNextLink();
    }

    private loadNextLink(): void {
        if (
            this.$route.query.next &&
            typeof this.$route.query.next == "string"
        ) {
            this.next = this.$route.query.next;
        } else if (!window.location.pathname.startsWith("/login")) {
            this.next = window.location.pathname;
        }
    }

    private goHome(): void {
        if (!this.isHome) {
            this.$router.push(`${this.URLS.HOME}${this.next}`);
        }
    }

    private goLogin(): void {
        if (!this.isLogin) {
            this.$router.push({
                path: this.URLS.LOGIN,
                query: { next: this.next },
            });
        }
    }
}

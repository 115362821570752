import {
    Score,
    ScoreMap,
    MapRange,
    Rule,
    ClassSet,
    ClusterMapping,
    ScoreClass,
    Changes,
    RuleRange,
    RuleMatch,
    ClassMapping,
    Value,
    EnhancedScore,
    ScoreCombo,
} from "@/graphql/API";
import {
    CreateScoreMap,
    CreateRuleMatchL,
    CreateRuleRangeL,
    CreateMatchRuleL,
    CreateRangeRuleL,
    DeleteScoreL,
    GetScoreMap,
    GetScoreMaps,
    GetRules,
    GetScores,
    UpdateRuleMatchL,
    GetClassSet,
    CreateClassSet,
    GetClasses,
    CreateClassMapping,
    CreateClass,
    UpdateClassMapping,
    DeleteClassMapping,
    UpdateClass,
    DeleteClass,
    UpdateClassSet,
    DeleteClassSet,
    DeleteRuleMatchL,
    UpdateScoreL,
    CreateAggregateRuleL,
    UpdateAggregateRuleL,
    UpdateRangeRuleL,
    UpdateMatchRuleL,
    DeleteRuleRangeL,
    UpdateRuleRangeL,
    DeleteRuleL,
    GetRule,
    GetClassSets,
    RuleRangeUpdateInputL,
    RuleRangeCreateInputL,
    RuleMatchUpdateInputL,
    RuleMatchCreateInputL,
    RuleMatchIDInputL,
    ClassMappingInput,
    ClassMappingIdInput,
    UpdateScoringL,
    UpdateScoringInputL,
    ViewpointMappingID,
    RangeRuleCreateInputL,
    CRangeRuleL,
    ComboRuleRangeCreateInputL,
    AggregateRuleCreateInputL,
    ComboClassMappingInput,
    ClassSetCreateInput,
    CSetL,
    ComboRuleMatchCreateInputL,
    CMatchRuleL,
    MatchRuleCreateInputL,
    Slice,
    GetScoresD,
    CAggRuleL,
} from "@/graphql/custom";
import {
    getClassSet,
    getClasses,
    getScoreMap,
    getScoreMaps,
    getRules,
    getScores,
    getRule,
    getClassSets,
    getScoresD,
} from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API } from "aws-amplify";
import {
    Action,
    config,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import Vue from "vue";
import store from "..";
import {
    createScoreMap,
    createRuleMatchL,
    createRuleRangeL,
    createMatchRuleL,
    createRangeRuleL,
    deleteScoreL,
    updateRuleMatchL,
    createClassSet,
    createClassMapping,
    createClass,
    updateClassMapping,
    deleteClassMapping,
    updateClass,
    deleteClass,
    updateClassSet,
    deleteClassSet,
    deleteRuleMatchL,
    updateScoreL,
    createAggregateRuleL,
    updateAggregateRuleL,
    updateRangeRuleL,
    deleteRuleRangeL,
    updateRuleRangeL,
    deleteRuleL,
    updateMatchRuleL,
    updateScoringL,
    cRangeRuleL,
    cAggRuleL,
    cSetL,
    cMatchRuleL,
} from "@/graphql/mutations";
import Decisions from "./Decisions";
import Workspaces from "./Workspaces";
import Viewpoints from "./Viewpoints";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";
import Factors from "./Factors";
import _ from "lodash";

config.rawError = true;

const name = "scoring";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Scoring extends VuexModule {
    scoreMaps: ScoreMap[] = [];
    classClusters: { [id: number]: ClassSet } = {};
    mapRanges: { [scoreMapId: number]: MapRange[] } = {};
    // Don't think the clusterMappings object will be required - use 'mappings' found on classCluster in classClusters object for now
    // clusterMappings: { [clusterId: number]: ClusterMapping[] } = {};
    scoreClasses: { [id: number]: ScoreClass } = {};
    scoreRules: { [id: number]: Rule } = {};
    keyedScores: { [viewpoint_Choice_FactorId: string]: EnhancedScore } = {};
    createdDeletedRuleRange: number | null = null;
    createdDeletedRuleMatch: number | null = null;
    defaultClasses: number[] = [
        559, -1, -2, -3, -4, -5, 560, -10, -11, -12, -13, -14, -20, -21, -22,
        -23, -24, -25, -26,
    ];
    defaultRules: { [org_id: number]: Rule } = {};
    unsavedRuleChanges = false;
    globalNoneSummaryRuleId = -11;
    scoreClassesLoading = false;

    get scoreClassTotal(): number {
        return Object.values(this.scoreClasses).length;
    }

    get classClusterList(): ClassSet[] {
        return Object.values(this.classClusters);
    }

    get defaultClassSets(): any {
        return _.chain(this.classClusters).groupBy("org_id").value();
    }

    get scoreClassesList(): ScoreClass[] {
        return Object.values(this.scoreClasses);
    }

    get scoreRuleList(): Rule[] {
        return Object.values(this.scoreRules).filter(
            (rule) => !rule.is_index_rule
        );
    }

    get scores(): EnhancedScore[] {
        return Object.values(this.keyedScores);
    }

    get indexRuleList(): Rule[] {
        return Object.values(this.scoreRules).filter(
            (rule) => rule.is_index_rule
        );
    }

    get choiceKeyScores(): { [choiceId: number]: EnhancedScore[] } {
        const dictionary = Object.assign(
            {},
            ...this.scores.map((x) => ({
                [x.c_id]: this.scores.filter((val) => val.c_id === x.c_id),
            }))
        );
        return dictionary;
    }

    @Mutation
    clear(): void {
        this.keyedScores = {};
    }

    @Mutation
    clearDefaultScores(): void {
        this.defaultRules = {};
    }

    @Mutation
    setCreatedDeletedRuleRange(id: number | null): void {
        this.createdDeletedRuleRange = id;
    }

    @Mutation
    setCreatedDeletedRuleMatch(id: number | null): void {
        this.createdDeletedRuleMatch = id;
    }

    @Mutation
    setScores(payload: { scores: Score[] }): void {
        const scores = payload.scores;

        scores.map((score) => {
            let use_m_index = false;
            let use_m_score = false;
            const vpMod = getModule(Viewpoints);
            if (
                vpMod.viewpointMappings[score.viewpoint_id] &&
                vpMod.viewpointMappings[score.viewpoint_id][score.factor_id]
            ) {
                const vpMapping =
                    vpMod.viewpointMappings[score.viewpoint_id][
                        score.factor_id
                    ];
                use_m_index = vpMapping.use_m_index;
                use_m_score = vpMapping.use_m_score;
            } else {
                const factorMod = getModule(Factors);
                if (factorMod.factorMap[score.factor_id]) {
                    use_m_index =
                        factorMod.factorMap[score.factor_id].m_index != null;
                    use_m_score =
                        factorMod.factorMap[score.factor_id].m_score != null;
                }
            }
            const enhancedScore: EnhancedScore = {
                v_id: score.viewpoint_id,
                c_id: score.choice_id,
                f_id: score.factor_id,
                row_id: score.row_id,
                c_index: score.c_index,
                c_score: score.c_score,
                class_id: score.class?.id,
                m_score: score.m_score,
                use_m_index: use_m_index,
                use_m_score: use_m_score,
                m_index: score.m_index,
                order_str: score.order_str,
            };
            Vue.set(
                this.keyedScores,
                `${score.viewpoint_id}-${score.choice_id}-${
                    score.factor_id + (score.row_id ? `-${score.row_id}` : "")
                }`,
                enhancedScore
            );
        });
    }

    @Mutation
    setScoresD(payload: { scores: EnhancedScore[] }): void {
        const scores = payload.scores;

        scores.map((score) => {
            Vue.set(
                this.keyedScores,
                `${score.v_id}-${score.c_id}-${
                    score.f_id + (score.row_id ? `-${score.row_id}` : "")
                }`,
                score
            );
        });
    }

    @Mutation
    setDefaultRules(rules: Rule[]): void {
        for (const rule of rules) {
            if (rule.org_id) {
                Vue.set(this.defaultRules, rule.org_id, rule);
            }
        }
    }

    @Mutation
    moveRowScores(payload: {
        factor_id: number;
        old_row_id: string;
        new_row_id: string;
    }): void {
        const factorId = payload.factor_id;
        const oldRowId = payload.old_row_id;
        const newRowId = payload.new_row_id;
        Object.keys(this.keyedScores).forEach((scoreKey) => {
            // viewpointId-choice_id-factor_id-row_id will have 3 dashes
            // we want to check in case of edge case <choice_id>-<factor_id> == <factor_id>-<row_id>
            if (
                scoreKey.split("-").length >= 3 &&
                scoreKey.endsWith(`-${factorId}-${oldRowId}`)
            ) {
                const tempScore = { ...this.keyedScores[scoreKey] };
                tempScore.row_id = newRowId;
                const newKey = `${tempScore.v_id}-${tempScore.c_id}-${tempScore.f_id}-${newRowId}`;
                Vue.delete(this.keyedScores, scoreKey);
                Vue.set(this.keyedScores, newKey, tempScore);
            }
        });
    }

    @Mutation
    addScore(score: Score): void {
        // const viewpointId = score.viewpoint_id;
        // const itemIndex = this.viewpointScores[viewpointId].findIndex(
        //     (scoreSearch: Score) => {
        //         return (
        //             score.viewpoint_id === scoreSearch.viewpoint_id &&
        //             score.choice_id === scoreSearch.choice_id &&
        //             score.factor_id === scoreSearch.factor_id
        //         );
        //     }
        // );
        // if (itemIndex >= 0) {
        //     this.viewpointScores[viewpointId].splice(itemIndex, 1, score);
        // } else this.viewpointScores[viewpointId].push(score);
        let use_m_index = false;
        let use_m_score = false;
        const vpMod = getModule(Viewpoints);
        if (
            vpMod.viewpointMappings[score.viewpoint_id] &&
            vpMod.viewpointMappings[score.viewpoint_id][score.factor_id]
        ) {
            const vpMapping =
                vpMod.viewpointMappings[score.viewpoint_id][score.factor_id];
            use_m_index = vpMapping.use_m_index;
            use_m_score = vpMapping.use_m_score;
        } else {
            const factorMod = getModule(Factors);
            if (factorMod.factorMap[score.factor_id]) {
                use_m_index =
                    factorMod.factorMap[score.factor_id].m_index != null;
                use_m_score =
                    factorMod.factorMap[score.factor_id].m_score != null;
            }
        }

        const enhancedScore: EnhancedScore = {
            v_id: score.viewpoint_id,
            c_id: score.choice_id,
            f_id: score.factor_id,
            row_id: score.row_id,
            c_index: score.c_index,
            c_score: score.c_score,
            class_id: score.class?.id,
            m_score: score.m_score,
            use_m_index: use_m_index,
            use_m_score: use_m_score,
            m_index: score.m_index,
            order_str: score.order_str,
        };
        Vue.set(
            this.keyedScores,
            `${score.viewpoint_id}-${score.choice_id}-${
                score.factor_id + (score.row_id ? `-${score.row_id}` : "")
            }`,
            enhancedScore
        );
    }

    @Mutation
    setScoreMaps(scoreMaps: ScoreMap[]): void {
        this.scoreMaps = scoreMaps;
    }

    @Mutation
    setClassClusters(classClusters: ClassSet[]): void {
        this.classClusters = Object.assign(
            {},
            ...classClusters.map((x) => ({
                [x.id]: x,
            }))
        );
    }

    @Mutation
    setClassCluster(classCluster: ClassSet): void {
        Vue.set(this.classClusters, classCluster.id, classCluster);
    }

    @Mutation
    removeClassCluster(classCluster: ClassSet): void {
        Vue.delete(this.classClusters, classCluster.id);
    }

    @Mutation
    setScoreRules(scoreRules: Rule[]): void {
        const dictionary = Object.assign(
            {},
            ...scoreRules.map((x) => ({ [x.id]: x }))
        );
        this.scoreRules = dictionary;
    }

    @Mutation
    setScoreRule(scoreRule: Rule): void {
        Vue.set(this.scoreRules, scoreRule.id, scoreRule);
    }

    @Mutation
    removeScoreRule(scoreRule: Rule): void {
        Vue.delete(this.scoreRules, scoreRule.id);
    }

    @Mutation
    setClassLoading(val: boolean): void {
        this.scoreClassesLoading = val;
    }

    //Deprecated
    @Mutation
    setMapRanges(payload: {
        mapRanges: MapRange[];
        score_map_id: number;
    }): void {
        Vue.set(this.mapRanges, payload.score_map_id, payload.mapRanges);
    }

    @Mutation
    setScoreClass(payload: { scoreClass: ScoreClass }): void {
        const scoreClass = payload.scoreClass;
        Vue.set(this.scoreClasses, scoreClass.id, scoreClass);
    }

    @Mutation
    removeScoreClass(payload: { scoreClass: ScoreClass }): void {
        const scoreClass = payload.scoreClass;
        Vue.delete(this.scoreClasses, scoreClass.id);
    }

    @Mutation
    setScoreClasses(payload: { scoreClasses: ScoreClass[] }): void {
        const scoreClasses = payload.scoreClasses;
        const dictionary = Object.assign(
            {},
            ...scoreClasses.map((x) => ({
                [x.id]: x,
            }))
        );
        this.scoreClasses = { ...dictionary };
    }

    @Mutation
    setClassMapping(payload: { classMapping: ClassMapping }): void {
        const clusterMapping = payload.classMapping;
        if (this.classClusters[clusterMapping.set_id]) {
            this.classClusters[clusterMapping.set_id].mappings.splice(
                clusterMapping.index,
                0,
                clusterMapping
            );
        }
    }

    @Mutation
    removeClassMapping(payload: { classMapping: ClassMapping }): void {
        const classMapping = payload.classMapping;
        if (this.classClusters[classMapping.set_id]) {
            const internalIndexFound = this.classClusters[
                classMapping.set_id
            ].mappings.findIndex(
                (mapping) =>
                    mapping.class_id === classMapping.class_id &&
                    mapping.index === classMapping.index
            );
            if (internalIndexFound >= 0)
                this.classClusters[classMapping.set_id].mappings.splice(
                    internalIndexFound,
                    1
                );
        }
    }

    @Mutation
    addMapRange(payload: { mapRange: MapRange; score_map_id: number }): void {
        this.mapRanges[payload.score_map_id].push(payload.mapRange);
    }

    @Mutation
    removeScore(score: Score): void {
        // const viewpointId = score.viewpoint_id;
        // const searchIndex = this.viewpointScores[viewpointId].findIndex(
        //     (searchScore: Score) =>
        //         searchScore.factor_id === score.factor_id &&
        //         searchScore.choice_id === score.choice_id &&
        //         searchScore.viewpoint_id === score.viewpoint_id
        // );
        // if (searchIndex >= 0) {
        //     this.viewpointScores[viewpointId].splice(searchIndex, 1);
        // }
        Vue.delete(
            this.keyedScores,
            `${score.viewpoint_id}-${score.choice_id}-${
                score.factor_id + (score.row_id ? `-${score.row_id}` : "")
            }`
        );
    }

    @Mutation
    setUnsavedRuleChanges(val: boolean): void {
        this.unsavedRuleChanges = val;
    }

    @Action
    async fetchScores(payload: {
        viewpoint_id?: number;
        choice_id?: number;
        factor_id?: number;
    }): Promise<Score[] | null> {
        const viewpoint_id = payload.viewpoint_id;
        const factor_id = payload.factor_id;
        const choice_id = payload.choice_id;

        if (viewpoint_id || choice_id || factor_id) {
            const scores = (await GRAPHQL_API.graphqlQueryRequest({
                query: getScores,
                variables: {
                    viewpoint_id,
                    choice_id,
                    factor_id,
                },
            })) as GraphQLResult<GetScores>;
            if (scores.data?.getScores) {
                this.setScores({
                    scores: scores.data.getScores,
                });
            }
            return scores.data?.getScores || [];
        }
        return [];
    }

    @Action
    async fetchScoresD(payload: {
        d_id: number;
        root?: boolean;
        fp_ids: number[];
        v_ids: number[];
        c_ids: number[];
        v_slice?: Slice;
        c_slice?: Slice;
        f_slice?: Slice;
    }): Promise<ScoreCombo | null> {
        const scores = (await GRAPHQL_API.graphqlQueryRequest({
            query: getScoresD,
            variables: {
                ...payload,
            },
        })) as GraphQLResult<GetScoresD>;
        if (scores.data?.getScoresD) {
            this.setScoresD({
                scores: scores.data.getScoresD.ss,
            });
            return scores.data?.getScoresD || [];
        }

        return null;
    }

    @Action
    async updateScoreL(payload: {
        viewpoint_id: number;
        choice_id: number;
        factor_id: number;
        m_score?: number;
        m_index?: number;
        order_str?: string;
        row_id?: string;
    }): Promise<Score[] | null> {
        const viewpoint_id = payload.viewpoint_id;
        const choice_id = payload.choice_id;
        const factor_id = payload.factor_id;
        const m_score = payload.m_score;
        const m_index = payload.m_index;
        const order_str = payload.order_str;
        const row_id = payload.row_id ? payload.row_id : "";
        // TODO update row_id with a correct value

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateScoreL,
            variables: {
                viewpoint_id,
                choice_id,
                factor_id,
                m_score,
                m_index,
                order_str,
                row_id,
            },
        })) as GraphQLResult<UpdateScoreL>;
        const scores = res.data?.updateScoreL?.scores;
        if (scores) {
            // this.addScore(res.data.setScore);
            this.setScores({ scores: scores });
            // await this.fetchViewpointScores(viewpoint_id);
            return scores;
        }

        return null;
    }

    @Action
    async deleteScoreL(scoreObject: Score) {
        if (scoreObject != null) {
            const viewpoint_id = scoreObject.viewpoint_id;
            const choice_id = scoreObject.choice_id;
            const factor_id = scoreObject.factor_id;
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteScoreL,
                variables: {
                    viewpoint_id,
                    choice_id,
                    factor_id,
                },
            })) as GraphQLResult<DeleteScoreL>;

            if (res.data?.deleteScoreL) {
                // await this.fetchViewpointScores(viewpoint_id);
                const score = res.data?.deleteScoreL;
                if (score) {
                    this.removeScore(score);

                    // await this.fetchViewpointScores(viewpoint_id);
                    return score;
                }
                return res.data.deleteScoreL;
            }
        }
    }

    @Action
    async fetchScoreMaps(): Promise<ScoreMap[] | null> {
        const scoreMaps = (await GRAPHQL_API.graphqlQueryRequest({
            query: getScoreMaps,
        })) as GraphQLResult<GetScoreMaps>;
        if (scoreMaps.data?.getScoreMaps) {
            this.setScoreMaps(scoreMaps.data.getScoreMaps);
            scoreMaps.data.getScoreMaps.forEach((scoreMap) => {
                if (scoreMap.map_ranges != null) {
                    this.setMapRanges({
                        mapRanges: scoreMap.map_ranges,
                        score_map_id: scoreMap.id,
                    });
                }
            });
        }
        return scoreMaps.data?.getScoreMaps || [];
    }

    @Action
    async fetchClassClusters(workspace_id: number): Promise<ClassSet[] | null> {
        const classClustersResult = (await GRAPHQL_API.graphqlQueryRequest({
            query: getClassSets,
            variables: {
                workspace_id,
            },
        })) as GraphQLResult<GetClassSets>;
        const classClusters = classClustersResult.data?.getClassSets;
        if (classClusters) {
            this.setClassClusters(classClusters);
        }
        return classClusters || null;
    }

    @Action
    async fetchClassCluster(set_id: number): Promise<ClassSet | null> {
        const classClustersResult = (await GRAPHQL_API.graphqlQueryRequest({
            query: getClassSet,
            variables: {
                set_id,
            },
        })) as GraphQLResult<GetClassSet>;
        const classCluster = classClustersResult.data?.getClassSet;
        if (classCluster) {
            this.setClassCluster(classCluster);
            // classClusters.forEach((classCluster) => {
            //     if (classCluster.mappings != null) {
            //         this.setClusterMappings({
            //             clusterMappings: classCluster.mappings,
            //             set_id: classCluster.id,
            //         });
            //     }
            // });
        }
        return classCluster || null;
    }

    @Action
    async getClassSets(workspace_id: number): Promise<ClassSet[] | null> {
        const classClustersResult = (await GRAPHQL_API.graphqlQueryRequest({
            query: getClassSets,
            variables: {
                workspace_id,
            },
        })) as GraphQLResult<GetClassSets>;
        const classSets = classClustersResult.data?.getClassSets;
        if (classSets) {
            classSets.forEach((classSet) => {
                this.setClassCluster(classSet);
            });
        }
        return classSets || null;
    }

    @Action
    async createClassSet(payload: {
        workspace_id: number;
        name: string;
        json?: string;
    }): Promise<ClassSet | null> {
        const name = payload.name;
        const workspace_id = payload.workspace_id;
        const json = payload.json;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createClassSet,
            variables: {
                name,
                workspace_id,
                json,
            },
        })) as GraphQLResult<CreateClassSet>;
        const classCluster = res.data?.createClassSet?.class_sets[0];
        if (classCluster) {
            this.setClassCluster(classCluster);
            return classCluster;
        } else return null;
    }

    @Action
    async updateClassSet(payload: {
        set_id: number;
        name: string;
        json?: string;
    }): Promise<ClassSet | null> {
        const name = payload.name;
        const set_id = payload.set_id;
        const json = payload.json;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateClassSet,
            variables: {
                name,
                set_id,
                json,
            },
        })) as GraphQLResult<UpdateClassSet>;
        const classCluster = res.data?.updateClassSet?.class_sets[0];
        if (classCluster) {
            this.setClassCluster(classCluster);
            return classCluster;
        } else return null;
    }

    @Action
    async deleteClassSet(payload: {
        set_id: number;
    }): Promise<ClassSet | null> {
        const set_id = payload.set_id;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteClassSet,
            variables: {
                set_id,
            },
        })) as GraphQLResult<DeleteClassSet>;
        const classCluster = res.data?.deleteClassSet?.class_sets[0];
        if (classCluster) {
            this.removeClassCluster(classCluster);
            return classCluster;
        } else return null;
    }

    @Action
    async createClassMapping(payload: {
        clusterId: number;
        classId: number;
        index: number;
        json?: string;
        fail?: boolean;
        score?: number;
    }): Promise<ClassMapping | null> {
        const set_id = payload.clusterId;
        const class_id = payload.classId;
        const index = payload.index;
        const json = payload.json;
        const fail = payload.fail ? payload.fail : false;
        const score = payload.score;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createClassMapping,
            variables: {
                set_id,
                class_id,
                index,
                json,
                fail,
                score,
            },
        })) as GraphQLResult<CreateClassMapping>;
        const clusterMapping = res.data?.createClassMapping?.class_mappings[0];
        if (clusterMapping) {
            this.setClassMapping({ classMapping: clusterMapping });
            //Workaround for classmapping involves non active decision_id class
            // if (
            //     clusterMapping.class &&
            //     ![decModule.selectedDecisionId, -1].includes(
            //         clusterMapping.class.decision_id
            //     ) &&
            //     workspaceModule.selectedWorkspaceId
            // ) {
            //     this.fetchClassClusters(workspaceModule.selectedWorkspaceId);
            // }
            return clusterMapping;
        } else return null;
    }

    @Action
    async updateClassMapping(payload: {
        clusterId: number;
        classId: number;
        index: number;
        json?: string;
        fail?: boolean;
    }): Promise<ClassMapping | null> {
        const set_id = payload.clusterId;
        const class_id = payload.classId;
        const index = payload.index;
        const json = payload.json;
        const fail = payload.fail ? payload.fail : false;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateClassMapping,
            variables: {
                set_id,
                class_id,
                index,
                json,
                fail,
            },
        })) as GraphQLResult<UpdateClassMapping>;
        const clusterMapping = res.data?.updateClassMapping?.class_mappings[0];
        if (clusterMapping) {
            const decModule = getModule(Decisions);
            const workspaceModule = getModule(Workspaces);
            this.setClassMapping({ classMapping: clusterMapping });

            //Workaround for classmapping involves non active decision_id class
            // if (
            //     clusterMapping.class &&
            //     ![decModule.selectedDecisionId, -1].includes(
            //         clusterMapping.class.decision_id
            //     ) &&
            //     workspaceModule.selectedWorkspaceId
            // ) {
            //     this.fetchClassClusters(workspaceModule.selectedWorkspaceId);
            // }
            return clusterMapping;
        } else return null;
    }

    @Action
    async deleteClassMapping(payload: {
        clusterId: number;
        classId: number;
    }): Promise<ClassMapping | null> {
        const set_id = payload.clusterId;
        const class_id = payload.classId;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteClassMapping,
            variables: {
                set_id,
                class_id,
            },
        })) as GraphQLResult<DeleteClassMapping>;
        const clusterMapping = res.data?.deleteClassMapping?.class_mappings[0];
        if (clusterMapping) {
            const decModule = getModule(Decisions);
            const workspaceModule = getModule(Workspaces);
            this.removeClassMapping({ classMapping: clusterMapping });

            //Workaround for classmapping involves non active decision_id class
            // if (
            //     clusterMapping.class &&
            //     ![decModule.selectedDecisionId, -1].includes(
            //         clusterMapping.class.decision_id
            //     ) &&
            //     workspaceModule.selectedWorkspaceId
            // ) {
            //     this.fetchClassClusters(workspaceModule.selectedWorkspaceId);
            // }
            return clusterMapping;
        } else return null;
    }

    @Action
    async fetchScoreClasses(
        workspace_id: number
    ): Promise<ScoreClass[] | null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getClasses,
            variables: {
                workspace_id,
            },
        })) as GraphQLResult<GetClasses>;
        const scoreClasses = res.data?.getClasses;
        if (scoreClasses) {
            this.setScoreClasses({ scoreClasses: scoreClasses });
            return scoreClasses;
        }
        return null;
    }

    @Action
    async createClass(payload: {
        workspaceId: number;
        name: string;
        description: string;
        json?: string;
    }): Promise<ScoreClass | null> {
        const workspace_id = payload.workspaceId;
        const name = payload.name;
        const json = payload.json;
        const description = payload.description;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createClass,
            variables: {
                workspace_id,
                name,
                description,
                json,
            },
        })) as GraphQLResult<CreateClass>;
        const scoreClass = res.data?.createClass?.classes[0];
        if (scoreClass) {
            this.setScoreClass({ scoreClass: scoreClass });
            return scoreClass;
        }
        return null;
    }

    @Action
    async updateClass(payload: {
        classId: number;
        name: string;
        description: string;
        json?: string;
    }): Promise<ScoreClass | null> {
        const class_id = payload.classId;
        const name = payload.name;
        const description = payload.description;
        const json = payload.json;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateClass,
            variables: {
                class_id,
                name,
                description,
                json,
            },
        })) as GraphQLResult<UpdateClass>;
        const scoreClass = res.data?.updateClass?.classes[0];
        if (scoreClass) {
            this.setScoreClass({ scoreClass: scoreClass });
            return scoreClass;
        }
        return null;
    }

    @Action
    async deleteClass(payload: {
        classId: number;
    }): Promise<ScoreClass | null> {
        const class_id = payload.classId;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteClass,
            variables: {
                class_id,
            },
        })) as GraphQLResult<DeleteClass>;
        const scoreClass = res.data?.deleteClass?.classes[0];
        if (scoreClass) {
            this.removeScoreClass({ scoreClass: scoreClass });
            return scoreClass;
        }
        return null;
    }

    @Action
    async fetchScoreRules(workspace_id: number): Promise<Rule[] | null> {
        const scoreRules = (await GRAPHQL_API.graphqlQueryRequest({
            query: getRules,
            variables: {
                workspace_id,
            },
        })) as GraphQLResult<GetRules>;
        if (scoreRules.data?.getRules) {
            this.setScoreRules(scoreRules.data.getRules);
            this.setDefaultRules(scoreRules.data.getRules);
        }
        return scoreRules.data?.getRules || [];
    }

    @Action
    async getRule(rule_id: number): Promise<Rule | null> {
        const scoreRules = (await API.graphql({
            query: getRule,
            variables: {
                rule_id,
            },
        })) as GraphQLResult<GetRule>;
        if (scoreRules.data?.getRule) {
            this.setScoreRule(scoreRules.data.getRule);
        }
        return scoreRules.data?.getRule || null;
    }

    @Action
    async createRangeRuleL(payload: {
        name: string;
        null_value_score: number;
        min_range_score: number;
        decision_id?: number | null;
        interpolate_range: boolean;
        is_index_rule?: boolean;
        index_uses_score: boolean;
        min?: number;
        max?: number;
    }): Promise<Rule | null> {
        const name = payload.name;
        const null_value_score = payload.null_value_score;
        const min_range_score = payload.min_range_score;
        const decision_id = payload.decision_id;
        const interpolate_range = payload.interpolate_range;
        const is_index_rule = payload.is_index_rule
            ? payload.is_index_rule
            : false;
        const index_uses_score = payload.index_uses_score;
        const min = payload.min;
        const max = payload.max;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createRangeRuleL,
            variables: {
                name,
                null_value_score,
                min_range_score,
                decision_id,
                is_index_rule,
                interpolate_range,
                index_uses_score,
                min,
                max,
            },
        })) as GraphQLResult<CreateRangeRuleL>;
        // await this.fetchScoreRules();
        const scoreRule = res.data?.createRangeRuleL?.rules;
        if (scoreRule) {
            this.setScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async cRangeRuleL(payload: {
        vpm_ids: ViewpointMappingID[];
        f_ids?: number[];
        rule: RangeRuleCreateInputL;
        ranges: ComboRuleRangeCreateInputL[];
    }): Promise<Rule | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: cRangeRuleL,
            variables: {
                ...payload,
            },
        })) as GraphQLResult<CRangeRuleL>;
        // await this.fetchScoreRules();
        const scoreRule = res.data?.cRangeRuleL?.rules;

        const viewpointMappings = res.data?.cRangeRuleL?.viewpoint_mappings;

        if (viewpointMappings) {
            viewpointMappings.forEach((vpMap) => {
                getModule(Viewpoints).setViewpointMapping(vpMap);
            });
        }

        const scores = res.data?.cRangeRuleL?.scores;
        if (scores) {
            for (const score of scores) {
                this.addScore(score);
            }
        }

        if (scoreRule) {
            this.setScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async cMatchRuleL(payload: {
        vpm_ids: ViewpointMappingID[];
        f_ids?: number[];
        rule: MatchRuleCreateInputL;
        matches: ComboRuleMatchCreateInputL[];
    }): Promise<Rule | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: cMatchRuleL,
            variables: {
                ...payload,
            },
        })) as GraphQLResult<CMatchRuleL>;
        // await this.fetchScoreRules();
        const scoreRule = res.data?.cMatchRuleL?.rules;

        const viewpointMappings = res.data?.cMatchRuleL?.viewpoint_mappings;
        const scores = res.data?.cMatchRuleL?.scores;

        if (viewpointMappings) {
            viewpointMappings.forEach((vpMap) => {
                getModule(Viewpoints).setViewpointMapping(vpMap);
            });
        }

        if (scores) {
            for (const score of scores) {
                this.addScore(score);
            }
        }

        if (scoreRule) {
            this.setScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async cAggRuleL(payload: {
        vpm_ids: ViewpointMappingID[];
        f_ids: number[];
        rule: AggregateRuleCreateInputL;
    }): Promise<Rule | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: cAggRuleL,
            variables: {
                ...payload,
            },
        })) as GraphQLResult<CAggRuleL>;
        // await this.fetchScoreRules();
        const scoreRule = res.data?.cAggRuleL?.rules;
        console.log(res.data?.cAggRuleL);

        const viewpointMappings = res.data?.cAggRuleL?.viewpoint_mappings;
        if (viewpointMappings) {
            viewpointMappings.forEach((vpMap) => {
                getModule(Viewpoints).setViewpointMapping(vpMap);
            });
        }
        const scores = res.data?.cAggRuleL?.scores;
        if (scores) {
            for (const score of scores) {
                this.addScore(score);
            }
        }
        if (scoreRule) {
            this.setScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async cSetL(payload: {
        vpm_ids: ViewpointMappingID[];
        f_ids?: number[];
        set: ClassSetCreateInput;
        maps: ComboClassMappingInput[];
    }): Promise<Rule | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: cSetL,
            variables: {
                ...payload,
            },
        })) as GraphQLResult<CSetL>;
        // await this.fetchScoreRules();

        const viewpointMappings = res.data?.cSetL?.viewpoint_mappings;

        if (viewpointMappings) {
            viewpointMappings.forEach((vpMap) => {
                getModule(Viewpoints).setViewpointMapping(vpMap);
            });
        }
        return null;
    }

    @Action
    async updateScoreRuleRange(payload: {
        name: string;
        score_rule_id: number;
        null_value_score: number;
        min_range_score: number;
        decision_id?: number | null;
        interpolate_range: boolean;
        min?: number;
        max?: number;
    }): Promise<Rule | null> {
        const name = payload.name;
        const null_value_score = payload.null_value_score;
        const min_range_score = payload.min_range_score;
        const score_rule_id = payload.score_rule_id;
        const decision_id = payload.decision_id;
        const interpolate_range = payload.interpolate_range;
        const min = payload.min;
        const max = payload.max;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateRangeRuleL,
            variables: {
                name,
                null_value_score,
                min_range_score,
                decision_id,
                id: score_rule_id,
                interpolate_range,
                min,
                max,
            },
        })) as GraphQLResult<UpdateRangeRuleL>;
        // await this.fetchScoreRules();
        const scoreRule = res.data?.updateRangeRuleL?.rules;
        if (scoreRule) {
            this.setScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async createMatchRuleL(payload: {
        name: string;
        null_value_score: number;
        no_match_score: number;
        decision_id?: number | null;
        is_index_rule?: boolean;
    }): Promise<Rule | null> {
        const name = payload.name;
        const null_value_score = payload.null_value_score;
        const no_match_score = payload.no_match_score;
        const decision_id = payload.decision_id;
        const is_index_rule = payload.is_index_rule
            ? payload.is_index_rule
            : false;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createMatchRuleL,
            variables: {
                name,
                null_value_score,
                no_match_score,
                decision_id,
                is_index_rule,
            },
        })) as GraphQLResult<CreateMatchRuleL>;
        // await this.fetchScoreRules();
        const scoreRule = res.data?.createMatchRuleL?.rules;
        if (scoreRule) {
            this.setScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async updateScoreRuleMatch(payload: {
        name: string;
        score_rule_id: number;
        null_value_score: number;
        no_match_score: number;
    }): Promise<Rule | null> {
        const name = payload.name;
        const null_value_score = payload.null_value_score;
        const no_match_score = payload.no_match_score;
        const id = payload.score_rule_id;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateMatchRuleL,
            variables: {
                name,
                null_value_score,
                no_match_score,
                id,
            },
        })) as GraphQLResult<UpdateMatchRuleL>;
        // await this.fetchScoreRules();
        const scoreRule = res.data?.updateMatchRuleL?.rules;
        if (scoreRule) {
            this.setScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async createScoreRuleAggregate(payload: {
        name: string;
        null_value_score: number;
        aggregate_function: string;
        decision_id?: number | null;
        min?: number;
        max?: number;
    }): Promise<Rule | null> {
        const name = payload.name;
        const null_value_score = payload.null_value_score;
        const aggregate_function = payload.aggregate_function;
        const decision_id = payload.decision_id;
        const min = payload.min;
        const max = payload.max;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createAggregateRuleL,
            variables: {
                name,
                null_value_score,
                aggregate_function,
                decision_id,
                min,
                max,
            },
        })) as GraphQLResult<CreateAggregateRuleL>;
        // await this.fetchScoreRules();
        const changes = res.data?.createAggregateRuleL;
        const scoreRule = changes?.rules;
        if (scoreRule) {
            this.setScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async updateScoreRuleAggregate(payload: {
        name: string;
        null_value_score: number;
        aggregate_function: string;
        score_rule_id: number;
        min?: number;
        max?: number;
    }): Promise<Rule | null> {
        const name = payload.name;
        const null_value_score = payload.null_value_score;
        const aggregate_function = payload.aggregate_function;
        const score_rule_id = payload.score_rule_id;
        const min = payload.min;
        const max = payload.max;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateAggregateRuleL,
            variables: {
                name,
                null_value_score,
                aggregate_function,
                id: score_rule_id,
                min,
                max,
            },
        })) as GraphQLResult<UpdateAggregateRuleL>;
        // await this.fetchScoreRules();
        const changes = res.data?.updateAggregateRuleL;
        const scoreRule = changes?.rules;
        if (scoreRule) {
            this.setScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async deleteRuleL(id: number): Promise<Rule | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteRuleL,
            variables: {
                id,
            },
        })) as GraphQLResult<DeleteRuleL>;
        // await this.fetchScoreRules();
        const changes = res.data?.deleteRuleL;
        const scoreRule = changes?.rules;
        if (scoreRule) {
            this.removeScoreRule(scoreRule[0]);
            return scoreRule[0];
        }
        return null;
    }

    @Action
    async createRuleRangeL(payload: {
        score: number;
        at_least: number;
        score_rule_id: number;
        label: string;
        bigger_than: boolean;
    }) {
        const rule_id = payload.score_rule_id;
        const score = payload.score;
        const at_least = payload.at_least;
        const label = payload.label;
        const bigger_than = payload.bigger_than;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createRuleRangeL,
            variables: {
                rule_id,
                score,
                at_least,
                label,
                bigger_than,
            },
        })) as GraphQLResult<CreateRuleRangeL>;
        const ruleRanges = res.data?.createRuleRangeL?.rule_ranges;
        if (ruleRanges) {
            const ruleRange = ruleRanges[0];
            this.setCreatedDeletedRuleRange(ruleRange.id);
            const rule = this.scoreRules[ruleRange.rule_id];
            rule.ranges.push(ruleRange);
            this.setScoreRule(rule);
        }
        return res.data?.createRuleRangeL;
    }

    @Action
    async updateRuleRangeL(payload: {
        id: number;
        score: number;
        at_least: number;
        score_rule_id: number;
        label: string;
        bigger_than: boolean;
    }) {
        const id = payload.id;
        const score = payload.score;
        const at_least = payload.at_least;
        const label = payload.label;
        const bigger_than = payload.bigger_than;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateRuleRangeL,
            variables: {
                id,
                score,
                at_least,
                label,
                bigger_than,
            },
        })) as GraphQLResult<UpdateRuleRangeL>;
        const ranges = res.data?.updateRuleRangeL?.rule_ranges;
        if (ranges && ranges.length > 0) {
            const newRange = ranges[0];
            this.setCreatedDeletedRuleRange(newRange.id);
            const score_rule = this.scoreRules[newRange.rule_id];
            const findIndex = score_rule.ranges.findIndex(
                (findRange) => findRange.id == newRange.id
            );
            if (findIndex >= 0) {
                score_rule.ranges.splice(findIndex, 1, newRange);
            }
            this.setScoreRule(score_rule);
        }
        return res.data?.updateRuleRangeL;
    }

    @Action
    async deleteRuleRangeL(payload: { range_id: number }) {
        const range_id = payload.range_id;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteRuleRangeL,
            variables: {
                id: range_id,
            },
        })) as GraphQLResult<DeleteRuleRangeL>;
        const ranges = res.data?.deleteRuleRangeL?.rule_ranges;
        if (ranges && ranges.length > 0) {
            const deleteRange = ranges[0];
            this.setCreatedDeletedRuleRange(deleteRange.id);
            const score_rule = this.scoreRules[deleteRange.rule_id];
            const findIndex = score_rule.ranges.findIndex(
                (findRange) => findRange.id == deleteRange.id
            );
            if (findIndex >= 0) {
                score_rule.ranges.splice(findIndex, 1);
            }
            this.setScoreRule(score_rule);
        }
        return res.data?.deleteRuleRangeL;
    }

    @Action
    async createRuleMatchL(payload: {
        score: number | string;
        match: string;
        rule_id: number;
        label: string;
    }) {
        const rule_id = payload.rule_id;
        const score = payload.score;
        const match = payload.match;
        const label = payload.label;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createRuleMatchL,
            variables: {
                rule_id,
                score,
                match,
                label,
            },
        })) as GraphQLResult<CreateRuleMatchL>;
        // await this.fetchScoreRules();
        const matches = res.data?.createRuleMatchL?.rule_matches;
        if (matches && matches.length > 0) {
            const newMatch = matches[0];
            this.setCreatedDeletedRuleMatch(newMatch.rule_id);
            const rule = this.scoreRules[newMatch.rule_id];
            rule.matches.push(newMatch);
            this.setScoreRule(rule);
        }
        return res.data?.createRuleMatchL;
    }

    @Action
    async updateRuleMatchL(payload: {
        score: number;
        match: string;
        rule_id: number;
        label: string;
    }) {
        const rule_id = payload.rule_id;
        const score = payload.score;
        const match = payload.match;
        const label = payload.label;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateRuleMatchL,
            variables: {
                rule_id,
                score,
                match,
                label,
            },
        })) as GraphQLResult<UpdateRuleMatchL>;
        const matches = res.data?.updateRuleMatchL?.rule_matches;
        if (matches && matches.length > 0) {
            const newMatch = matches[0];
            this.setCreatedDeletedRuleMatch(newMatch.rule_id);
            const score_rule = this.scoreRules[newMatch.rule_id];
            const findIndex = score_rule.matches.findIndex(
                (findMatch) => findMatch.match == newMatch.match
            );
            if (findIndex >= 0) {
                score_rule.matches.splice(findIndex, 1, newMatch);
            }
            this.setScoreRule(score_rule);
        }
        return res.data?.updateRuleMatchL;
    }

    @Action
    async deleteRuleMatchL(payload: { match: string; rule_id: number }) {
        const rule_id = payload.rule_id;
        const match = payload.match;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteRuleMatchL,
            variables: {
                rule_id,
                match,
            },
        })) as GraphQLResult<DeleteRuleMatchL>;
        const matches = res.data?.deleteRuleMatchL?.rule_matches;
        if (matches && matches.length > 0) {
            const delMatch = matches[0];
            this.setCreatedDeletedRuleMatch(delMatch.rule_id);
            const score_rule = this.scoreRules[delMatch.rule_id];
            const findIndex = score_rule.matches.findIndex(
                (findMatch) => findMatch.match == delMatch.match
            );
            if (findIndex >= 0) {
                score_rule.matches.splice(findIndex, 1);
            }
            this.setScoreRule(score_rule);
        }
        return res.data?.deleteRuleMatchL;
    }

    @Action
    async updateScoringL(
        payload: UpdateScoringInputL
    ): Promise<Changes | null> {
        const input: UpdateScoringInputL = {
            rrc: payload.rrc ? payload.rrc : [],
            rru: payload.rru ? payload.rru : [],
            rrd: payload.rrd ? payload.rrd : [],
            rmu: payload.rmu ? payload.rmu : [],
            rmc: payload.rmc ? payload.rmc : [],
            rmd: payload.rmd ? payload.rmd : [],
            cmc: payload.cmc ? payload.cmc : [],
            cmu: payload.cmu ? payload.cmu : [],
            cmd: payload.cmd ? payload.cmd : [],
        };
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateScoringL,
            variables: {
                ...input,
            },
        })) as GraphQLResult<UpdateScoringL>;

        const changes = res.data?.updateScoringL;
        if (changes != null) {
            return changes;
        }
        return null;
    }

    @Action
    async addUpdateScores(scores: Score[]): Promise<void> {
        if (scores == null) {
            return;
        }

        scores.forEach((score: Score) => {
            if (score.class != null && score.class.id in this.scoreClasses) {
                score.class = this.scoreClasses[score.class.id];
            }
            this.addScore(score);
        });
    }

    @Action
    addUpdateRule(rules: Rule[]): void {
        if (!rules || rules.length <= 0) {
            return;
        }

        rules.forEach((rule) => {
            this.setScoreRule(rule);
        });
    }

    @Action
    removeRules(rules: Rule[]): void {
        if (!rules || rules.length <= 0) {
            return;
        }

        rules.forEach((rule) => {
            this.removeScoreRule(rule);
        });
    }

    @Action
    addUpdateRuleRanges(payload: {
        ranges: RuleRange[];
        mutation: string;
    }): void {
        const ranges = payload.ranges;
        const mutation = payload.mutation;
        if (
            !ranges ||
            ranges.length < 0 ||
            ranges[0].id == this.createdDeletedRuleRange
        ) {
            console.log("SAME ");
            return;
        }

        ranges.forEach((range: RuleRange) => {
            const score_rule = this.scoreRules[range.rule_id];
            if (score_rule) {
                if (mutation.includes("create")) {
                    score_rule.ranges == null
                        ? (score_rule.ranges = [range])
                        : score_rule.ranges.push(range);
                } else {
                    const findIndex = score_rule.ranges.findIndex(
                        (findRange) => findRange.id == range.id
                    );
                    if (findIndex >= 0) {
                        score_rule.ranges.splice(findIndex, 1, range);
                    }
                }
                this.setScoreRule(score_rule);
            }
        });
    }

    @Action
    removeRuleRanges(payload: { ranges: RuleRange[]; mutation: string }): void {
        const ranges = payload.ranges;
        const mutation = payload.mutation;
        if (
            !ranges ||
            ranges.length < 0 ||
            this.createdDeletedRuleRange == ranges[0].id
        ) {
            console.log("DELETE range");
            this.setCreatedDeletedRuleRange(null);
            return;
        }

        ranges.forEach((range: RuleRange) => {
            const score_rule = this.scoreRules[range.rule_id];
            const findIndex = score_rule.ranges.findIndex(
                (findRange) => findRange.id == range.id
            );
            if (findIndex >= 0) {
                score_rule.ranges.splice(findIndex, 1);
            }
            this.setScoreRule(score_rule);
        });
    }

    @Action
    addUpdateRuleMatches(payload: {
        matches: RuleMatch[];
        mutation: string;
    }): void {
        const matches = payload.matches;
        const mutation = payload.mutation;
        if (!matches || matches.length < 0) {
            console.log("SAME MATCH");
            this.setCreatedDeletedRuleMatch(null);
            return;
        }

        matches.forEach((match: RuleMatch) => {
            const score_rule = this.scoreRules[match.rule_id];
            if (score_rule) {
                if (mutation.includes("create")) {
                    score_rule.matches == null
                        ? (score_rule.matches = [match])
                        : score_rule.matches.push(match);
                } else {
                    const findIndex = score_rule.matches.findIndex(
                        (findMatch) => findMatch.match == match.match
                    );
                    if (findIndex >= 0) {
                        score_rule.matches.splice(findIndex, 1, match);
                    }
                }
                this.setScoreRule(score_rule);
            }
        });
    }

    @Action
    removeRuleMatches(payload: {
        matches: RuleMatch[];
        mutation: string;
    }): void {
        const matches = payload.matches;
        const mutation = payload.mutation;
        if (
            !matches ||
            matches.length < 0
            // this.createdDeletedRuleMatch == matches[0].rule_id
        ) {
            console.log("SAME DEL");
            this.setCreatedDeletedRuleMatch(null);
            return;
        }

        matches.forEach((match: RuleMatch) => {
            const score_rule = this.scoreRules[match.rule_id];
            const findIndex = score_rule.matches.findIndex(
                (findMatch) => findMatch.match == match.match
            );
            if (findIndex >= 0) {
                score_rule.matches.splice(findIndex, 1);
            }
            this.setScoreRule(score_rule);
        });
    }

    @Action
    addUpdateClass(payload: { classes: ScoreClass[] }): void {
        const classes = payload.classes;
        if (!classes || classes.length < 0) {
            return;
        }

        classes.forEach((scoreClass: ScoreClass) => {
            this.setScoreClass({ scoreClass: scoreClass });
        });
    }

    @Action
    removeLocalClass(classes: ScoreClass[]): void {
        if (!classes || classes.length < 0) {
            return;
        }

        classes.forEach((scoreClass: ScoreClass) => {
            this.removeScoreClass({ scoreClass: scoreClass });
        });
    }

    @Action
    addUpdateClassSet(payload: { classSet: ClassSet[] }): void {
        const classSet = payload.classSet;
        if (!classSet || classSet.length < 0) {
            return;
        }

        classSet.forEach((classSet: ClassSet) => {
            this.setClassCluster(classSet);
        });
    }

    @Action
    removeLocalClassSet(classSet: ClassSet[]): void {
        if (!classSet || classSet.length < 0) {
            return;
        }

        classSet.forEach((classSet: ClassSet) => {
            this.removeClassCluster(classSet);
        });
    }

    @Action
    addUpdateClassMapping(payload: { classMappings: ClassMapping[] }): void {
        const classMappings = payload.classMappings;
        if (!classMappings || classMappings.length < 0) {
            return;
        }

        classMappings.forEach((classMapping: ClassMapping) => {
            this.setClassMapping({ classMapping: classMapping });
        });
    }

    @Action
    removeLocalClassMapping(classMappings: ClassMapping[]): void {
        if (!classMappings || classMappings.length < 0) {
            return;
        }

        classMappings.forEach((classMapping: ClassMapping) => {
            this.removeClassMapping({ classMapping: classMapping });
        });
    }

    @Action
    moveRowLocalScores(payload: { values: Value[]; old_row_id: string }): void {
        const values = payload.values;
        const old_row_id = payload.old_row_id;

        if (values != null && old_row_id != null) {
            return;
        }

        values.forEach((val) => {
            if (val.row_id)
                this.moveRowScores({
                    factor_id: val.factor_id,
                    old_row_id: old_row_id,
                    new_row_id: val.row_id,
                });
        });
    }

    @Action
    updateLoading(loading: boolean): void {
        this.setClassLoading(loading);
    }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { ScoreMap } from "@/graphql/API";

@Component({
    components: {},
})
export default class ScoringMapSelector extends Vue {
    @Prop()
    currentMap!: ScoreMap;

    @Prop()
    mapList!: ScoreMap[];

    private selectedMap: ScoreMap | null = null;
    private searchTerm = "";

    get filteredList(): ScoreMap[] {
        if (this.searchTerm && this.searchTerm != "") {
            return this.mapList.filter((item) => {
                return item.name.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        } else {
            return this.mapList;
        }
    }

    private selectMap( item: ScoreMap ): void {
        this.selectedMap = item;
    }

    private updateFactorMap(): void {
        this.$emit('map-change', this.selectedMap)
    }
}

export const columnOptions = [
    {
        label: "150px",
        width: 150,
    },
    {
        label: "200px",
        width: 200,
    },
    {
        label: "300px",
        width: 300,
    },
    {
        label: "400px",
        width: 400,
    },
    {
        label: "500px",
        width: 500,
    },
    {
        label: "600px",
        width: 600,
    },
    {
        label: "700px",
        width: 700,
    },
    {
        label: "800px",
        width: 800,
    },
    {
        label: "900px",
        width: 900,
    },
    {
        label: "1000px",
        width: 1000,
    },
];

import Vuex from "vuex";
import Vue from "vue";

import Decisions from "@/store/modules/Decisions";
import { config } from "vuex-module-decorators";
import Factors from "./modules/Factors";
import workspaceOptions from "./modules/WorkspaceOptions";
import Choices from "./modules/Choices";
import Scoring from "./modules/Scoring";
import Viewpoints from "./modules/Viewpoints";
import Workspaces from "./modules/Workspaces";
import Domains from "./modules/Domains";
import FlashNotifications from "./modules/FlashNotifications";
import Apps from "./modules/Apps";
import Users from "./modules/Users";
import Ui from "./modules/Ui";


Vue.use(Vuex);

config.rawError = true;

interface StoreType {
    decisions: Decisions;
    factors: Factors;
    workspaceOptions: workspaceOptions;
    choices: Choices;
    scoring: Scoring;
    viewpoints: Viewpoints;
    workspaces: Workspaces;
    domains: Domains;
    flashNotifications: FlashNotifications;
    apps: Apps;
    users: Users;
    ui: Ui;
}

const store = new Vuex.Store<StoreType>({
    /*
    Ideally if all your modules are dynamic
    then your store is registered initially
    as a completely empty object
    */
});

export default store;


import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
    name: "vp-dialog",
    components: {},
})
export default class VpDialog extends Vue {
    @Prop({ required: true })
    titleText!: string;

    @Prop({ default: 800, required: false })
    width!: string;

    @Prop({ required: false })
    maxWidth!: string;

    @Prop({ required: false })
    maxHeight!: string;

    @Prop({ type: String, default: "200px" })
    headerActionMinWidth!: string;

    @Prop({ type: String })
    activatorButtonText!: string;

    @Prop({ type: String })
    activatorIcon!: string;

    @Prop({ default: false, type: Boolean })
    open!: boolean;

    @Prop({ default: false, type: Boolean })
    value!: boolean;

    @Prop({ required: false })
    activator!: string;

    @Prop({ default: false, type: [String, Boolean] })
    attach!: string | boolean;

    @Prop({ default: false, type: Boolean })
    btnSave!: boolean;

    @Prop({ default: false, type: [String, Boolean] })
    btnConfirm!: string | boolean;

    @Prop()
    btnConfirmIcon!: string;

    @Prop({ default: false, type: Boolean })
    btnDelete!: boolean;

    @Prop({ default: false, type: Boolean })
    btnCancel!: boolean;

    @Prop({ default: true, type: Boolean })
    btnClose!: boolean;

    @Prop({ default: true, type: Boolean })
    confirmClose!: boolean;

    @Prop({ default: 0, type: Number })
    closeDelay!: number;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: false, type: Boolean })
    fullscreen!: boolean;

    @Prop({ default: false, type: Boolean })
    hideOverlay!: boolean;

    @Prop({ default: false, type: Boolean })
    persistent!: boolean;

    @Prop({ default: false, type: Boolean })
    disableBtn!: boolean;

    @Prop({ type: String })
    listActivator!: string;

    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    @Prop({ default: false, type: Boolean })
    preventClosing!: boolean;

    private openDialog = false;
    private preventDialogOpen = false;
    private isUnsavedChanges = false;

    private emitSave() {
        this.$emit("save");
        this.openDialog = false;
    }

    private emitConfirm() {
        this.$emit("confirm");
        if (this.confirmClose) {
            this.openDialog = false;
        }
    }

    private emitCancel() {
        this.$emit("cancel");
        this.openDialog = false;
    }

    private emitClose() {
        if (this.isUnsavedChanges) {
            this.openDialog = true;
            this.preventDialogOpen = true;
        } else {
            this.$emit("close");
            this.openDialog = false;
            this.preventDialogOpen = false;
        }
    }

    private emitDelete() {
        this.$emit("delete");
        this.openDialog = false;
    }

    private clickOutside() {
        this.$emit("cancel");
        this.openDialog = false;
    }

    private closeWithoutSaving() {
        this.isUnsavedChanges = false;
        this.emitClose();
    }

    @Watch("open")
    private onOpenChange(newVal: boolean) {
        this.openDialog = newVal;
    }

    @Watch("value")
    private onValueChange(newVal: boolean) {
        this.openDialog = newVal;
    }

    @Watch("preventClosing")
    private onPreventClosingChange(newVal: boolean) {
        this.isUnsavedChanges = newVal;
    }
}

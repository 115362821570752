
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { TableHeader } from "@/graphql/API";

@Component({
    components: {},
})
export default class TableManagerItem extends Vue {
    @Prop()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item!: any;

    @Prop({ default: () => [], type: Array })
    columns!: TableHeader[];

    @Prop({ type: Boolean, default: false })
    active!: boolean;

    @Prop({ type: Boolean, default: false })
    expand!: boolean;

    @Prop({ type: Boolean, default: false })
    offset!: boolean;

    @Prop({ type: Boolean, default: false })
    draggable!: boolean;

    @Prop({ type: Boolean, default: false })
    menuOpen!: boolean;

    @PropSync("open")
    syncedOpen!: boolean;

    @Prop({ type: Boolean, default: false })
    checkboxes!: boolean;

    private clickItem(e: MouseEvent): void {
        if (!this.checkboxes) {
            this.$emit("click-item", this.item, e.metaKey);
        }
    }

    private checkboxToggle(): void {
        this.$emit("click-item", this.item, true);
    }

    private dblClickItem(): void {
        this.$emit("double-click", this.item);
    }

    private rightClick(e: MouseEvent): void {
        e.preventDefault();
        this.$emit("right-click", {
            x: e.clientX,
            y: e.clientY,
            item: this.item,
        });
    }
}

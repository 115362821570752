
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { Factor } from "@/graphql/API";
import { FactorsIcons } from "@/IconLibrary";

@Component({
    components: {},
})
export default class AiFactor extends Vue {
    @Prop()
    factor!: Factor;

    @Prop({ default: false, type: Boolean })
    selected!: boolean;

    @Prop({ default: false, type: Boolean })
    group!: boolean;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop({ default: false, type: Boolean })
    isGroup!: boolean;

    private readonly Icons: Record<string, string> = FactorsIcons;

    private toggleFactor(): void {
        if (this.factor) {
            this.$emit("toggle-factor", this.factor);
        }
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.factor)?_c('div',{staticClass:"vp-factor-toggle__factor"},[_c('div',{staticClass:"vp-factor-toggle__factor__offset-wrapper",class:{
            'vp-factor-toggle__factor__offset-wrapper--last': _vm.last,
            'vp-factor-toggle__factor__offset-wrapper--first': _vm.first,
        }},[_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"vp-factor-toggle__factor__offset"})}),_vm._t("default")],2),_c('v-btn',{staticClass:"btn-plain btn-plain btn-factor-toggle",class:{
            'vp-btn-active': _vm.active,
            'btn-factor-toggle--invert': _vm.invertColor,
            'btn-factor-toggle--root': _vm.rootId == _vm.factor.id,
        },attrs:{"disabled":_vm.disableBtn,"plain":""},on:{"click":function($event){return _vm.$emit('factor-toggle', _vm.factor)}}},[(_vm.locked)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-lock ")]):_c('v-icon',{staticClass:"btn-factor-toggle__icon",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.active ? _vm.onIcon : _vm.offIcon)+" ")]),_c('span',{staticClass:"btn-factor-toggle__label"},[_vm._v(" "+_vm._s(_vm.factor.name)+" ")])],1),(_vm.showRootBtn)?[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top' }),expression:"{ placement: 'top' }"}],attrs:{"content":"Only show factors in this group","icon":"","small":""},on:{"click":function($event){return _vm.$emit('root-toggle', _vm.factor)}}},[_c('v-icon',{attrs:{"small":"","color":_vm.rootActive ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.rootActive ? "mdi-file-tree" : "mdi-file-tree-outline")+" ")])],1)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
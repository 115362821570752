var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-compare-table-group"},[(_vm.mainColumn && _vm.mainColumn.length)?_c('v-expansion-panels',{staticClass:"vp-accordion vp-accordion--btn-left vp-accordion--plain vp-accordion--left vp-accordion--drag",class:{
            'vp-accordion--full': _vm.viewpoints && _vm.viewpoints.length == 1,
        },attrs:{"accordion":"","flat":"","hover":"","focusable":""}},[_c('draggable',{attrs:{"list":_vm.dragList,"disabled":!_vm.choiceEditable || !_vm.reOrderActive,"handle":".vp-row__drag","ghost-class":"vp-row__ghost"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":function($event){return _vm.dragChange($event)}}},_vm._l((_vm.dragList),function(row,rowIndex){return _c('v-expansion-panel',{key:rowIndex},[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return undefined},proxy:true}],null,true)},[_c('CompareRow',{attrs:{"shadow":rowIndex == 0}},[_c('CompareColumnMain',{attrs:{"main-width":_vm.mainWidth,"depth":_vm.depth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"lines":_vm.lines,"last":rowIndex == _vm.dragList.length - 1}},[_c('div',{staticClass:"vp-row__main"},[_c('div',{staticClass:"vp-row__label"},[_c('v-icon',{staticClass:"vp-accordion__expand-icon"},[_vm._v(" mdi-menu-right ")]),_vm._v(" Row "+_vm._s(rowIndex + 1)+" "),(_vm.choiceEditable)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteDialog(
                                                rowIndex + 1,
                                                row.row_id
                                            )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle")])],1):_vm._e()],1),(_vm.choiceEditable && _vm.reOrderActive)?_c('div',{staticClass:"vp-row__drag",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-drag")])],1):_vm._e()])]),_vm._l((_vm.viewpoints),function(columnItem,index){return _c('CompareColumn',{key:index,attrs:{"column-width":_vm.columnWidth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"full":_vm.viewpoints && _vm.viewpoints.length == 1 && _vm.fullColumn,"border-y":_vm.borderY,"table-cell":""}},[(_vm.choice)?_c('div',[_c('FactorScore',{attrs:{"factor-id":_vm.headers[0].id,"choice-id":_vm.choice.id,"viewpoint-id":columnItem.id,"row-id":row.row_id,"score-display":_vm.scoreDisplay,"viewpoint-map":_vm.viewpointMapping[columnItem.id][
                                            _vm.headers[0].id
                                        ],"selector-settings":_vm.selectorSettings,"editable":_vm.scoresEditable,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY}})],1):_vm._e()])})],2)],1),_c('v-expansion-panel-content',_vm._l((_vm.headers),function(column,factIndex){return _c('div',{key:factIndex},[(
                                _vm.hiddenFactors &&
                                !_vm.hiddenFactors.includes(column.id)
                            )?_c('CompareRow',{attrs:{"shadow":factIndex == 0}},[_c('CompareColumnMain',{attrs:{"main-width":_vm.mainWidth,"depth":_vm.depth + 1,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"lines":rowIndex == _vm.dragList.length - 1 ? [..._vm.lines, _vm.depth] : _vm.lines,"last":factIndex == _vm.headers.length - 1,"show-content":_vm.scoreDisplay.choice_data},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('FactorValueDisplay',{attrs:{"factor":column,"showName":false,"factor-display":_vm.factorDisplay,"editable":_vm.choiceEditable,"choice-id":_vm.choice.id,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"isThumbnailDisplay":true,"autoSaveMedia":true,"row-id":row.row_id,"clear-btn":false,"value":_vm.valueMap[`${column.id}-${row.row_id}`]
                                                ? _vm.valueMap[
                                                    `${column.id}-${row.row_id}`
                                                ]
                                                : {}}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(column.name)+" ")]),_vm._l((_vm.viewpoints),function(columnItem,index){return _c('CompareColumn',{key:index,attrs:{"column-width":_vm.columnWidth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"full":_vm.fullColumn && _vm.viewpoints && _vm.viewpoints.length == 1 && _vm.fullColumn,"table-cell":""}})})],2):_vm._e()],1)}),0)],1)}),1)],1):_vm._e(),(_vm.choiceEditable)?_c('CompareRow',[_c('CompareColumnMain',{attrs:{"main-width":_vm.mainWidth,"depth":_vm.depth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"lines":[..._vm.lines, _vm.depth]}},[_c('v-btn',{attrs:{"small":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.addRow}},[_vm._v(" "+_vm._s(_vm.loading ? "Loading..." : "Add Row")+" ")])],1),_vm._l((_vm.viewpoints),function(columnItem,index){return _c('CompareColumn',{key:index,attrs:{"column-width":_vm.columnWidth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"full":_vm.viewpoints && _vm.viewpoints.length == 1 && _vm.fullColumn,"table-cell":""}})})],2):_vm._e(),_c('VpDialog',{staticClass:"mt-3",attrs:{"titleText":`Delete?`,"btnConfirm":"Confirm","btnCancel":"","headerActionMinWidth":"300px"},on:{"confirm":function($event){return _vm.deleteRow(_vm.deleteRowId)},"cancel":function($event){_vm.deleteIndex = null;
            _vm.deleteRowId = null;
            _vm.deleteOpen = false;},"close":function($event){_vm.deleteIndex = null;
            _vm.deleteRowId = null;
            _vm.deleteOpen = false;}},model:{value:(_vm.deleteOpen),callback:function ($$v) {_vm.deleteOpen=$$v},expression:"deleteOpen"}},[_c('div',{staticClass:"mt-8 text-center"},[_vm._v(" Do you really want to remove: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Row "+_vm._s(_vm.deleteIndex)+" ")]),_vm._v(" ? This process cannot be undone. ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
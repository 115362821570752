
import { Component, Vue, Model, Watch, Prop } from "vue-property-decorator";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Heading from "@tiptap/extension-heading";
import Underline from "@tiptap/extension-underline";
import Strike from "@tiptap/extension-strike";
import Image from "@tiptap/extension-image";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Link from "@tiptap/extension-link";
import { v4 as uuidv4 } from "uuid";
import { uploadMediaFile, getS3FileUrl } from "@/helpers/MediaUploadHelper";

@Component({ name: "RichTextEditor", components: { EditorContent } })
export default class RichTextEditor extends Vue {
    @Prop({ type: String, default: "" })
    value!: string;

    @Prop({ type: Boolean, default: false })
    isDescription!: string;

    @Prop({ type: Number })
    factorsIndex!: number;

    private editor: any = null;
    private imageUrl = "";
    private file: File | null = null;
    private uploadedFile: any = null;
    private uploadingFile = false;
    private newContent = this.value;
    private pasteImage = false;
    private pasteTable = false;
    private showMenu = false;

    @Watch("value")
    onValueChange(value: string) {
        const isSame = this.editor.getHTML() === value;
        if (isSame) {
            return;
        }
        this.newContent = this.value;
        this.editor.commands.setContent(value, false);
    }

    @Watch("showMenu")
    onEditorFocusChange() {
        if (!this.editorFocus)
            this.$emit("saveRichText", this.newContent, this.factorsIndex);
    }

    mounted() {
        this.editor = this.getEditor();
        this.editor.on("update", () => {
            this.newContent = this.editor.getHTML();
        });
        this.editor.on("focus", ({}) => {
            this.showMenu = true;
        });
    }

    get editorFocus(): boolean {
        return this.editor.isFocused && this.showMenu;
    }

    private getEditor() {
        return new Editor({
            content: this.newContent,
            extensions: [
                StarterKit,
                Heading,
                Underline,
                Strike,
                Image.configure({ allowBase64: true }),
                Table.configure({ resizable: true }),
                TableRow,
                TableHeader,
                TableCell,
                Link.configure({ autolink: true }),
            ],
        });
    }
    //TODO fix issues with expired link form S3 bucket, right now image btn is disabled
    private async addImage() {
        if (this.imageUrl) {
            const fileName = uuidv4() + ".jpeg";
            let response = await fetch(this.imageUrl);
            let data = await response.blob();
            let metadata = {
                type: "image/jpeg",
            };
            let file = new File([data], fileName, metadata);
            this.file = file;

            // await this.uploadMediaFile();

            // let result = "";

            // if (this.file) {
            //     result = await uploadMediaFile({
            //         file: this.file,
            //         type: "image",
            //         name: fileName,
            //     });
            // }
            // if (result) {
            //     this.imageUrl = await getS3FileUrl({
            //         fileName: fileName,
            //         type: "image",
            //     });
            // }

            this.editor.chain().focus().setImage({ src: this.imageUrl }).run();
        }
        this.pasteImage = false;
    }

    private setLink() {
        const previousUrl = this.editor.getAttributes("link").href;
        const url = window.prompt("URL", previousUrl);

        // cancelled
        if (url === null) {
            return;
        }

        // empty
        if (url === "") {
            this.editor
                .chain()
                .focus()
                .extendMarkRange("link")
                .unsetLink()
                .run();

            return;
        }

        // update link
        this.editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: url })
            .run();
    }
    //TODO fix issues with expired link form S3 bucket, right now image btn is disabled
    private async uploadMediaFile(): Promise<void> {
        console.log("startUpload");
        let result = "";
        const fileName = uuidv4() + ".jpeg";

        if (this.file) {
            result = await uploadMediaFile({
                file: this.file,
                type: "image",
                name: fileName,
                srcFileName: this.file.name,
            });
        }

        this.imageUrl = await getS3FileUrl({ fileName: result, type: "image" });
    }
}

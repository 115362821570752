
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Workspaces from "@/store/modules/Workspaces";
import { Workspace, WorkspaceStyles, Colour } from "@/graphql/API";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import ColourPaletteList from "@/components/values/ColourPaletteList.vue";
import ColourPicker from "@/components/values/ColourPicker.vue";
import { updateUiColours } from "@/helpers/UiColourHelper";
import vuetify from "@/plugins/vuetify";

const workspacesModule = getModule(Workspaces);

@Component({
    components: {
        DialogCardTitle,
        ColourPaletteList,
        ColourPicker,
    },
})
export default class WorkspaceCreator extends Vue {
    @Prop({ default: "mdi-plus", type: String })
    iconName!: string;

    @Prop({ default: null, type: String })
    listActivator!: string;

    @Prop({ default: false, type: Boolean })
    edit!: boolean;

    @Prop({ default: null, type: Number })
    workspaceId!: number;

    private open = false;
    private workspaceName = "";
    private workspaceOptions: WorkspaceStyles = {};

    mounted(): void {
        if (
            this.edit &&
            this.selectedWorkspaceId != null &&
            this.selectedWorkspace != null
        ) {
            this.workspaceName = this.selectedWorkspace.name || "";
        }
    }

    get workspaces(): Workspace[] {
        return workspacesModule.workspaceList;
    }

    get selectedWorkspaceId(): number | null {
        return workspacesModule.selectedWorkspaceId;
    }

    get selectedWorkspace(): Workspace | null {
        return workspacesModule.selectedWorkspace;
    }

    get workspaceOptionsJson(): string {
        return JSON.stringify(this.workspaceOptions);
    }

    get primaryColour(): string {
        if (this.workspaceOptions?.ui_colours?.primary) {
            return this.workspaceOptions.ui_colours.primary;
        } else {
            return "#673ab7";
        }
    }

    private async createWorkspace() {
        if (this.edit) {
            await workspacesModule.updateWorkspace({
                workspace_id: this.workspaceId,
                name: this.workspaceName,
                json: this.workspaceOptionsJson,
            });
        } else {
            await workspacesModule.createWorkspace({
                name: this.workspaceName,
                json: this.workspaceOptionsJson,
                description: "",
            });
        }

        if (this.workspaceOptions.ui_colours) {
            await updateUiColours(this.workspaceOptions.ui_colours);
        }
        this.open = false;
    }

    private changeIsComplementary(val: boolean) {
        Vue.set(this.workspaceOptions, "is_complementary", val);
    }

    private changeColourList(
        list: { [id: string]: Colour },
        saturation_list: { [id: string]: Colour }
    ) {
        Vue.set(this.workspaceOptions, "palette_list", list);
        Vue.set(this.workspaceOptions, "saturation_palette", saturation_list);
    }

    private setColour(index: string, colour: string) {
        if (this.workspaceOptions && this.workspaceOptions.ui_colours) {
            Vue.set(this.workspaceOptions.ui_colours, index, colour);
        } else {
            Vue.set(this.workspaceOptions, "ui_colours", { [index]: colour });
        }
    }

    private clearColour(index: string) {
        if (this.workspaceOptions.ui_colours) {
            Vue.delete(this.workspaceOptions.ui_colours, index);
        }
    }

    @Watch("open")
    private onWatch() {
        if (
            this.edit &&
            this.selectedWorkspaceId != null &&
            this.selectedWorkspace != null
        ) {
            this.workspaceName = this.selectedWorkspace.name || "";
            this.workspaceOptions = this.selectedWorkspace.json
                ? JSON.parse(this.selectedWorkspace.json)
                : {};
        } else {
            this.workspaceName = "";
            this.workspaceOptions = {};
        }
    }
}

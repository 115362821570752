
import { Component, Vue } from "vue-property-decorator";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import { getModule } from "vuex-module-decorators";
import { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import VpDialog from "@/components/ui/VpDialog.vue";

import FlashNotifications from "@/store/modules/FlashNotifications";

const flashNotificationsModule = getModule(FlashNotifications);
@Component({
    name: "ImageUploader",
    components: {
        DialogCardTitle,
        VpDialog,
    },
})
export default class ImageUploader extends Vue {
    private file: File | null = null;
    private userName = "";
    private fileUploaded = false;
    private isLoading = false;
    private fileName = "";

    async mounted(): Promise<void> {
        let authUser = await Auth.currentAuthenticatedUser();
        this.userName = authUser.username;
    }

    get uploadedImage(): File | string {
        return this.file ? URL.createObjectURL(this.file) : "";
    }

    private async uploadMediaFile(): Promise<void> {
        this.isLoading = true;
        let file = this.file ? this.file : "";
        this.fileName = uuidv4();
        const key = `images/${this.userName}/${this.fileName}`;

        try {
            await Storage.put(key, file, {
                level: "public",
                resumable: true,
                contentType: "image/*",
                completeCallback: () => {
                    flashNotificationsModule.success({
                        message: `Successfully uploaded`,
                        duration: 5000,
                    });
                    this.fileUploaded = true;
                    this.isLoading = false;
                    this.$emit("uploaded", this.fileName);
                },
                errorCallback: (err) => {
                    this.fileUploaded = false;
                    this.isLoading = false;
                    flashNotificationsModule.error({
                        message: `Unexpected error while uploading ${err}`,
                        duration: 10000,
                    });
                },
            });
        } catch (error) {
            console.log(error);
            this.isLoading = false;
            flashNotificationsModule.error({
                message: `Unexpected error while uploading ${error}`,
                duration: 10000,
            });
        }
    }
}

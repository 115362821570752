
import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { App, Tab } from "@/graphql/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Apps from "@/store/modules/Apps";
import Workspaces from "@/store/modules/Workspaces";
import Decisions from "@/store/modules/Decisions";
import FlashNotifications from "@/store/modules/FlashNotifications";
import VpDialog from "@/components/ui/VpDialog.vue";
import { saveTab } from "@/helpers/TabHelper";

const appsModule = getModule(Apps);
const decisionsModule = getModule(Decisions);
const workspaceModule = getModule(Workspaces);
const flashModule = getModule(FlashNotifications);

@Component({
    components: {
        VpDialog,
    },
})
export default class ViewAppCreator extends Vue {
    @PropSync("open")
    syncedOpen!: boolean;

    @Prop()
    tabSettings!: Tab;

    @Prop({ default: () => [], type: Array })
    factors!: number[];

    @Prop({ default: () => [], type: Array })
    choices!: number[];

    @Prop({ default: () => [], type: Array })
    viewpoints!: number[];

    private name: null | string = null;
    private newAppName = "";
    private selectedIndex: number | null = null;

    private newOpen = false;
    private loading = false;

    get workspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get decisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get apps(): App[] {
        return appsModule.appsList;
    }

    get selectedApp(): App | null {
        return this.selectedIndex != null
            ? this.apps[this.selectedIndex]
            : null;
    }

    get saveActive(): boolean {
        if (this.tabSettings && this.name && this.name != "") {
            if (
                this.selectedApp ||
                (this.newOpen && this.newAppName && this.newAppName != "")
            ) {
                return true;
            }
        }
        return false;
    }

    private resetDialog(): void {
        this.name = null;
        this.newAppName = "";
        this.selectedIndex = null;
        this.newOpen = false;
        this.syncedOpen = false;
    }

    private async saveTab(): Promise<void> {
        this.loading = true;
        let parentApp = this.selectedApp ? this.selectedApp : null;
        try {
            if (!parentApp) {
                if (this.decisionId && this.newAppName) {
                    parentApp = await appsModule.createApp({
                        decision_id: this.decisionId,
                        title: this.newAppName,
                        description: "",
                        header: "",
                        json: "",
                        image: "",
                    });
                }
            }

            if (parentApp && this.name) {
                await saveTab({
                    tab: {
                        ...this.tabSettings,
                        title: this.name,
                        decision_id: parentApp.decision_id,
                    },
                    appId: parentApp.id,
                    new: true,
                    factors: this.factors,
                    choices: this.choices,
                    viewpoints: this.viewpoints,
                });
            }
        } catch (e) {
            const err = e as GraphQLResult<any>;
            const message = err?.errors
                ? err.errors[0].message
                : "Something went wrong.";
            console.log("%cError:", "color: red; font-weight: bold;");
            console.log(e);
            flashModule.error({
                message: message,
                duration: 3000,
            });
        }
        this.syncedOpen = false;
        this.loading = false;
        // this.resetDialog();
        // this.loading = false;
    }

    @Watch("selectedIndex")
    onIndexChange(): void {
        if (this.selectedIndex) {
            this.newOpen = false;
        }
    }

    @Watch("newOpen")
    onNewOpenChange(): void {
        if (this.newOpen) {
            this.selectedIndex = null;
        }
    }
}


import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor } from "@/graphql/API";
import Factors from "@/store/modules/Factors";

const modelModule = getModule(Factors);

@Component({
    components: {},
})
export default class FactorNav extends Vue {
    @Prop()
    root!: Factor[];

    @PropSync("selected")
    syncedSelected!: Factor[];

    @PropSync("selectedFactor")
    syncedSelectedFactor!: number | null;

    @Prop({ default: false, type: Boolean })
    singleSelect!: boolean;

    @Prop()
    lockedFactor!: number | null;

    private children = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private factors: any[] = [];
    private search = "";

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get factorTree(): any[] {
        if (this.root) {
            return this.root.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    factor: item,
                    children: item.is_group || item.is_table ? [] : null,
                };
            });
        } else {
            return [];
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async loadChildItems(item: any) {
        await this.loadChildren(item.factor);

        if (modelModule.factorTree[item.id]) {
            let children = await this.formatFactors(
                modelModule.factorTree[item.id]
            );
            Vue.set(item, "children", [...children]);
        } else {
            console.log("No Factors loaded");
        }
    }

    private async loadSubTreeItems(factor: Factor) {
        await this.loadChildren(factor);

        if (modelModule.factorTree[factor.id]) {
            return await this.formatFactors(modelModule.factorTree[factor.id]);
        } else {
            return null;
        }
    }

    private async formatFactors(list: Factor[]): Promise<any[]> {
        return await Promise.all(
            list.map(async (item) => {
                return {
                    id: item.id,
                    name: item.name,
                    factor: item,
                    children:
                        item.is_group || item.is_table
                            ? await this.loadSubTreeItems(item)
                            : null,
                };
            })
        );
    }

    private async loadChildren(factor: Factor) {
        if (factor != null) {
            if (factor.is_group) {
                await modelModule.fetchChildFactors(factor.id);
            } else if (factor.is_table) {
                const factorIds = await modelModule.fetchChildFactors(
                    factor.id
                );
                if (factorIds) {
                    await Promise.all(
                        factorIds.map(async (factorItem: Factor) => {
                            modelModule.fetchChildFactors(factorItem.id);
                        })
                    );
                }
            }
        }
    }

    private getActiveValue(item: number[]) {
        if (item && item.length) {
            this.syncedSelectedFactor = item[0];
        } else {
            this.syncedSelectedFactor = null;
        }
    }

    mounted(): void {
        this.onFactorTreeChange();
    }

    @Watch("factorTree")
    onFactorTreeChange(): void {
        this.factors = [...this.factorTree];
    }
}

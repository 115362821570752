import { Factor, Value, ViewpointMapping } from "@/graphql/API";
import Choices from "@/store/modules/Choices";
import Factors from "@/store/modules/Factors";
import FlashNotifications from "@/store/modules/FlashNotifications";
import Viewpoints from "@/store/modules/Viewpoints";
import { getModule } from "vuex-module-decorators";
import { removeFile } from "./MediaUploadHelper";

export async function createUpdateChoiceValue(payload: {
    choice_id: number;
    factor_id: number;
    row_id: string;
    value?: string;
    enum_value_id?: number;
    json?: string;
}): Promise<Value | null> {
    const newValue = { ...payload };
    const choicesModule = getModule(Choices);
    const stateValueKey =
        newValue.factor_id +
        (newValue.row_id && newValue.row_id != "" ? `-${newValue.row_id}` : "");
    const values = choicesModule.choiceValues;
    let res = null;
    try {
        if (payload.row_id) {
            res = await choicesModule.createValueL(newValue);
        } else {
            res = await choicesModule.updateValueL(newValue);
        }
    } catch (e: any) {
        console.log("%cError:", "color: red; font-weight: bold;");
        console.log(e);
        getModule(FlashNotifications).error({
            message: e.errors[0].errorType,
            duration: 5000,
        });
        return null;
    }
    if (res && res.values) {
        return res.values[0];
    }
    return null;
}

export async function removeChoiceValue(payload: {
    choice_id: number;
    factor_id: number;
    row_id: string;
    value?: string;
    enum_value_id?: number;
    json?: string;
    current_value: string;
    value_type?: string;
}): Promise<Value | null> {
    const choicesModule = getModule(Choices);
    let res = null;
    if (payload.row_id != null && payload.row_id != "") {
        res = await choicesModule.deleteValueL({
            choice_id: payload.choice_id,
            factor_id: payload.factor_id,
            row_id: payload.row_id,
        });
    } else {
        const value = payload.current_value;
        const changes = await choicesModule.updateValueL({
            choice_id: payload.choice_id,
            factor_id: payload.factor_id,
            row_id: payload.row_id,
            value: null,
            json: null,
            enum_value_id: null,
        });
        res = changes ? changes.values[0] : null;
        if (isValueTypeFile(payload.value_type) && payload.value_type) {
            await removeFile({
                type: payload.value_type,
                fileName: value,
            });
        }
    }

    return res;
}

function isValueTypeFile(value_type: string | undefined): boolean {
    switch (value_type) {
        case "image":
        case "video":
        case "document":
        case "audio":
            return true;
        default:
            return false;
    }
}


import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { RouteRecord } from "vue-router";
import { getModule } from "vuex-module-decorators";
import { Workspace, Decision, App, BreadCrumb, Tab } from "@/graphql/API";
import { Urls } from "@/Urls";
import Decisions from "@/store/modules/Decisions";
import Workspaces from "@/store/modules/Workspaces";
import Apps from "@/store/modules/Apps";
import Ui from "@/store/modules/Ui";
import BreadCrumbItems from "@/components/ui/BreadCrumbItems.vue";
import BreadCrumbItem from "@/components/ui/BreadCrumbItem.vue";

const workspacesModule = getModule(Workspaces);
const decisionsModule = getModule(Decisions);
const appsModule = getModule(Apps);
const uiModule = getModule(Ui);

@Component({
    components: {
        BreadCrumbItems,
        BreadCrumbItem,
    },
})
export default class BreadCrumbs extends Vue {
    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop()
    app!: App;

    @Prop()
    tab!: Tab;

    private readonly URLS = Urls;

    private routePath: RouteRecord[] = [];
    private currentRoute = "";

    get headerItems(): BreadCrumb[] {
        return [
            {
                name: "workspaces",
                path: this.URLS.HOME,
                label: this.selectedWorkspace
                    ? this.selectedWorkspace.name
                    : "Workspaces",
                visible: this.selectedWorkspace != null ? true : false,
            },
            {
                name: "frameworks",
                path: this.URLS.HOME,
                label: this.selectedDecision
                    ? this.selectedDecision.name
                    : "Frameworks",
                visible:
                    this.selectDecision != null && this.showFramework
                        ? true
                        : false,
            },
        ];
    }

    get baseItems(): BreadCrumb[] {
        return [
            ...this.headerItems,
            {
                name: "Apps Editor",
                path: this.URLS.APPSLISTEDITOR,
                label: this.selectedApp
                    ? this.selectedApp.title
                    : "Apps Editor",
                visible: this.selectedApp != null && this.isHome ? true : false,
            },
        ];
    }

    get pageItems(): BreadCrumb[] {
        return this.routePath.map((item) => {
            return {
                name: item.name ? item.name : item.path,
                path:
                    item.meta && item.meta.useCustomPath && item.meta.customPath
                        ? item.meta.customPath
                        : item.path,
                label:
                    item.meta && item.meta.label
                        ? item.meta.label()
                        : item.path,
                abrv: item.meta && item.meta.abrv ? item.meta.abrv : undefined,
                visible: true,
                slotOnly: item.meta && item.meta.breadCrumSlotOnly ? true : undefined,
            };
        });
    }

    get linkItems(): BreadCrumb[] {
        if (this.embed) {
            if (this.app) {
                return [
                    ...this.headerItems,
                    {
                        name: this.app.title,
                        path: `${Urls.APPS}/${this.app.id}`,
                        label: this.app.title,
                        visible: true,
                    },
                ];
            } else {
                return this.headerItems;
            }
        } else {
            return [...this.baseItems, ...this.pageItems];
        }
    }

    get selectedWorkspace(): Workspace | null {
        return workspacesModule.selectedWorkspace;
    }

    get selectedDecision(): Decision | null {
        return decisionsModule.selectedDecision;
    }

    get selectedApp(): App | null {
        return appsModule.selectedApp;
    }

    get selectedAppItem(): BreadCrumb | null {
        if (this.selectedApp) {
            return {
                name: "AppManagerReturn",
                path: this.URLS.APPSLISTEDITOR,
                label: this.selectedApp.title,
                visible: true,
            };
        } else {
            return null;
        }
    }

    get showFramework(): boolean {
        if (
            (this.$route.meta && this.$route.meta.requireModel) ||
            this.$route.name == "Home"
        ) {
            return this.selectedDecision ? true : false;
        } else {
            return false;
        }
    }

    get requiresFramework(): boolean {
        if (
            this.$route.meta &&
            this.$route.meta.requireModel &&
            !this.selectedDecision
        ) {
            return true;
        } else {
            return false;
        }
    }

    get isHome(): boolean {
        return this.$route.name == "Home";
    }

    get breadCrumbLabel(): string | null {
        return uiModule.breadCrumbLabel;
    }

    get tabLabel(): string | null {
        return this.tab ? this.tab.title : null;
    }

    private async selectDecision(decision: Decision | null): Promise<void> {
        await decisionsModule.selectDecision(decision ? decision.id : null);
    }

    private async selectWorkspace(workspace: Workspace | null): Promise<void> {
        await workspacesModule.selectWorkspace(workspace ? workspace.id : null);
    }

    private async goHome(): Promise<void> {
        this.$emit("clear-workspace");
        if (!this.isHome) {
            this.$router.push(this.URLS.HOME);
        }
    }

    private async clearDecision(): Promise<void> {
        this.$emit("clear-framework");
    }

    private async clearApp(): Promise<void> {
        appsModule.selectApp(null);
        if (!this.isHome) {
            this.$router.push(this.URLS.HOME);
        }
    }

    private returnToApps(): void {
        this.$router.push(
            `${this.URLS.APPSLISTEDITOR}/${
                this.selectedApp ? this.selectedApp.id : ""
            }`
        );
    }

    @Watch("$route", { immediate: true, deep: true })
    onRouteChange(): void {
        this.currentRoute = this.$route.name ? this.$route.name : "";
        if (this.$route.matched) {
            this.routePath = this.$route.matched.filter((item) => {
                return item.meta && item.meta.breadCrumb;
            });
        }
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ 'vp-padding-x-3 vp-padding-y-3': _vm.narrow }},[_c('div',{staticClass:"vp-create-class__top"},[_c('div',{staticClass:"vp-create-class__header"},[(_vm.narrow)?[_c('h3',[_vm._v(_vm._s(_vm.isEdit ? "Edit" : "Create")+" Class")])]:_c('v-text-field',{staticClass:"vp-textarea--colour",class:`vp-textarea--${_vm.scoreClassOptions.name_size}
                vp-textarea--${_vm.scoreClassOptions.name_style}`,style:(`color: ${
                    _vm.scoreClassOptions.name_colour != undefined &&
                    _vm.palette[_vm.scoreClassOptions.name_colour] &&
                    _vm.scoreClassOptions.name_colour != ''
                        ? _vm.palette[_vm.scoreClassOptions.name_colour].hex
                        : '#000'
                } !important;`),attrs:{"placeholder":"Score Class Name","dense":"","label":"Name","hide-details":"","disabled":_vm.editorDisabled},model:{value:(_vm.scoreClass.name),callback:function ($$v) {_vm.$set(_vm.scoreClass, "name", $$v)},expression:"scoreClass.name"}})],2),(!_vm.editorDisabled)?_c('div',{staticClass:"vp-create-class__btns"},[(!_vm.narrow)?[(!_vm.isDefaultClass && _vm.isEdit)?_c('v-btn',{staticClass:"mr-3",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.deleteClass()}}},[_vm._v(" Delete ")]):_vm._e()]:_vm._e(),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","disabled":!_vm.saveActive},on:{"click":function($event){_vm.isEdit && !_vm.isDefaultClass
                        ? _vm.saveScoreClass()
                        : _vm.createClass()}}},[_vm._v(" "+_vm._s(_vm.isEdit ? !_vm.isDefaultClass ? "Save" : "Save Copy" : "Create")+" ")])],2):_vm._e()]),_c('div',[(_vm.narrow)?[_c('v-text-field',{staticClass:"vp-textarea--colour",class:`vp-textarea--${_vm.scoreClassOptions.name_size}
                vp-textarea--${_vm.scoreClassOptions.name_style}`,style:(`color: ${
                    _vm.scoreClassOptions.name_colour != undefined &&
                    _vm.palette[_vm.scoreClassOptions.name_colour] &&
                    _vm.scoreClassOptions.name_colour != ''
                        ? _vm.palette[_vm.scoreClassOptions.name_colour].hex
                        : '#000'
                } !important;`),attrs:{"placeholder":"Score Class Name","dense":"","label":"Name","hide-details":"","disabled":_vm.editorDisabled},model:{value:(_vm.scoreClass.name),callback:function ($$v) {_vm.$set(_vm.scoreClass, "name", $$v)},expression:"scoreClass.name"}})]:_vm._e(),_c('div',{staticClass:"vp-scoring-class__field"}),_c('div',{staticClass:"vp-scoring-class__field"},[_c('div',{staticClass:"vp-scoring-class__label"},[_vm._v("Description")]),_c('v-textarea',{staticClass:"vp-textarea--colour",class:`vp-textarea--${_vm.scoreClassOptions.desc_size} vp-textarea--${_vm.scoreClassOptions.desc_style}`,style:(`color: ${
                    _vm.scoreClassOptions.desc_colour != undefined &&
                    _vm.palette[_vm.scoreClassOptions.name_colour] &&
                    _vm.scoreClassOptions.desc_colour != -1
                        ? _vm.palette[_vm.scoreClassOptions.desc_colour].hex
                        : '#000'
                } !important;`),attrs:{"rows":1,"disabled":_vm.editorDisabled,"placeholder":'Score Class Description',"auto-grow":"","dense":"","hide-details":""},model:{value:(_vm.scoreClass.description),callback:function ($$v) {_vm.$set(_vm.scoreClass, "description", $$v)},expression:"scoreClass.description"}})],1),_c('div',{staticClass:"vp-scoring-class__field"},[_c('div',{staticClass:"vp-scoring-class__label"},[_vm._v(" Icons "),_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],attrs:{"content":"View available icons"}},[_c('a',{attrs:{"href":"https://materialdesignicons.com/","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-information")])],1)])]),(
                    _vm.scoreClassOptions.icons &&
                    _vm.scoreClassOptions.icons.length > 0
                )?_c('div',{staticClass:"vp-scoring-class__icons"},[_c('span',{staticClass:"vp-scoring-class__icons-list"},_vm._l((_vm.scoreClassOptions.icons),function(icon,index){return _c('v-icon',{key:index,attrs:{"color":icon.iconColour != ''
                                ? _vm.palette[icon.iconColour].hex
                                : ''}},[_vm._v(" "+_vm._s(icon.iconCode)+" ")])}),1),(!_vm.editorDisabled)?_c('v-btn',{staticClass:"vp-btn--icon-primary",attrs:{"icon":"","small":""},on:{"click":_vm.removeIcon}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-backspace-outline")])],1):_vm._e()],1):_c('div',{staticClass:"vp-scoring-class__icons"},[_c('span',{staticClass:"vp-scoring-class__icons-list"},[_vm._v(" No icons added ")])]),(!_vm.editorDisabled)?_c('icon-picker',{on:{"new-icon-selected":_vm.addIcon}}):_vm._e()],1),_c('div',{staticClass:"vp-scoring-class__row"},[_c('div',{staticClass:"vp-scoring-class__field"},[_c('div',{staticClass:"vp-scoring-class__label"},[_vm._v(" Value Presentation ")]),_c('v-select',{attrs:{"placeholder":"Select Value Presentation","items":_vm.presentation,"item-text":"name","item-value":"value","dense":"","attach":_vm.narrow,"disabled":_vm.editorDisabled,"hide-details":""},model:{value:(_vm.scoreClassOptions.score_format),callback:function ($$v) {_vm.$set(_vm.scoreClassOptions, "score_format", $$v)},expression:"scoreClassOptions.score_format"}})],1)]),_c('v-expansion-panels',{staticClass:"vp-accordion",attrs:{"accordion":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',[_vm._v("mdi-menu-down")])]},proxy:true}])},[_c('h2',[_vm._v("Display Options")])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"vp-scoring-class__section",class:{
                            'vp-scoring-class__section--vertical': _vm.narrow,
                        }},[_c('div',{staticClass:"vp-scoring-class__label"},[_vm._v("Name")]),_c('div',{staticClass:"vp-scoring-class__row"},[_c('div',{staticClass:"vp-scoring-class__field"},[_c('v-select',{attrs:{"label":"Text Size","disabled":_vm.editorDisabled,"items":_vm.sizes,"item-text":"name","item-value":"value","dense":"","hide-details":"","attach":_vm.narrow},model:{value:(_vm.scoreClassOptions.name_size),callback:function ($$v) {_vm.$set(_vm.scoreClassOptions, "name_size", $$v)},expression:"scoreClassOptions.name_size"}})],1),_c('div',{staticClass:"vp-scoring-class__field"},[_c('v-select',{attrs:{"label":"Text Style","disabled":_vm.editorDisabled,"items":_vm.styles,"item-text":"name","item-value":"value","dense":"","hide-details":"","attach":_vm.narrow},model:{value:(_vm.scoreClassOptions.name_style),callback:function ($$v) {_vm.$set(_vm.scoreClassOptions, "name_style", $$v)},expression:"scoreClassOptions.name_style"}})],1),_c('div',{staticClass:"vp-scoring-class__field"},[_c('ColourPaletteSelector',{attrs:{"selected-colour":_vm.scoreClassOptions.name_colour,"disabled":_vm.editorDisabled,"data-prop":"name_colour"},on:{"colour-change":_vm.updateColour}},[_vm._v("Text Color")])],1)])]),_c('div',{staticClass:"vp-scoring-class__section",class:{
                            'vp-scoring-class__section--vertical': _vm.narrow,
                        }},[_c('div',{staticClass:"vp-scoring-class__label"},[_vm._v(" Description ")]),_c('div',{staticClass:"vp-scoring-class__row"},[_c('div',{staticClass:"vp-scoring-class__field"},[_c('v-select',{attrs:{"label":"Text Size","disabled":_vm.editorDisabled,"items":_vm.sizes,"item-text":"name","item-value":"value","dense":"","hide-details":"","attach":_vm.narrow},model:{value:(_vm.scoreClassOptions.desc_size),callback:function ($$v) {_vm.$set(_vm.scoreClassOptions, "desc_size", $$v)},expression:"scoreClassOptions.desc_size"}})],1),_c('div',{staticClass:"vp-scoring-class__field"},[_c('v-select',{attrs:{"label":"Text Style","disabled":_vm.editorDisabled,"items":_vm.styles,"item-text":"name","item-value":"value","dense":"","hide-details":"","attach":_vm.narrow},model:{value:(_vm.scoreClassOptions.desc_style),callback:function ($$v) {_vm.$set(_vm.scoreClassOptions, "desc_style", $$v)},expression:"scoreClassOptions.desc_style"}})],1),_c('div',{staticClass:"vp-scoring-class__field"},[_c('ColourPaletteSelector',{attrs:{"selected-colour":_vm.scoreClassOptions.desc_colour,"disabled":_vm.editorDisabled,"data-prop":"desc_colour"},on:{"colour-change":_vm.updateColour}},[_vm._v("Text Color")])],1)])]),_c('div',{staticClass:"vp-scoring-class__section",class:{
                            'vp-scoring-class__section--vertical': _vm.narrow,
                        }},[_c('div',{staticClass:"vp-scoring-class__label"},[_vm._v("Cell")]),_c('div',{staticClass:"vp-scoring-class__row"},[_c('div',{staticClass:"vp-scoring-class__field"},[_c('ColourPaletteSelector',{attrs:{"selected-colour":_vm.scoreClassOptions.bg_colour,"disabled":_vm.editorDisabled,"data-prop":"bg_colour"},on:{"colour-change":_vm.updateColour}},[_vm._v(" Background Color ")])],1),_c('div',{staticClass:"vp-scoring-class__field vp-scoring-class__field--empty"})])]),_c('div',{staticClass:"vp-scoring-class__section",class:{
                            'vp-scoring-class__section--vertical': _vm.narrow,
                        }},[_c('div',{staticClass:"vp-scoring-class__label"},[_vm._v("Slider")]),_c('div',{staticClass:"vp-scoring-class__row"},[_c('div',{staticClass:"vp-scoring-class__field"},[_c('v-select',{attrs:{"label":"Slider Thickness","disabled":_vm.editorDisabled,"items":_vm.sizes,"item-text":"name","item-value":"value","dense":"","hide-details":"","attach":_vm.narrow},model:{value:(_vm.scoreClassOptions.slider_size),callback:function ($$v) {_vm.$set(_vm.scoreClassOptions, "slider_size", $$v)},expression:"scoreClassOptions.slider_size"}})],1),_c('div',{staticClass:"vp-scoring-class__field"},[_c('ColourPaletteSelector',{attrs:{"selected-colour":_vm.scoreClassOptions.slider_colour,"disabled":_vm.editorDisabled,"data-prop":"slider_colour"},on:{"colour-change":_vm.updateColour}},[_vm._v(" Slider Color ")])],1),_c('div',{staticClass:"vp-scoring-class__field"},[_c('v-slider',{staticClass:"vp-slider vp-scoing-class__slider",class:_vm.scoreClassOptions.slider_size
                                            ? `vp-slider--${_vm.scoreClassOptions.slider_size}`
                                            : '',attrs:{"value":50,"dense":"","hide-details":"","color":_vm.scoreClassOptions.slider_colour !=
                                            undefined &&
                                        _vm.scoreClassOptions.slider_colour !=
                                            ''
                                            ? _vm.palette[
                                                  _vm.scoreClassOptions
                                                      .slider_colour
                                              ].hex
                                            : '',"track-color":_vm.scoreClassOptions.slider_colour !=
                                            undefined &&
                                        _vm.scoreClassOptions.slider_colour !=
                                            ''
                                            ? _vm.palette[
                                                  _vm.scoreClassOptions
                                                      .slider_colour
                                              ].hex
                                            : ''}})],1)])])])],1)],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Choice,
    Viewpoint,
    CompareValues,
    ScoreOptions,
    Value,
    ScoreClassSettings,
    FactorOptions,
    ViewpointMapping,
} from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import CompareRow from "@/components/compare/ui/CompareRow.vue";
import CompareColumnMain from "@/components/compare/ui/CompareColumnMain.vue";
import CompareColumn from "@/components/compare/ui/CompareColumn.vue";
import FactorScore from "@/components/scoring/FactorScore.vue";
import { getScore } from "@/helpers/ScoreHelper";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mudder = require("mudder");

const choiceModule = getModule(Choices);
const modelModule = getModule(Factors);
const viewpointsModule = getModule(Viewpoints);

@Component({
    components: {
        CompareRow,
        CompareColumnMain,
        CompareColumn,
        FactorScore,
    },
})
export default class CompareTableColumnsViewpoints extends Vue {
    @PropSync("tableRows", Number)
    syncedRows!: number;

    @PropSync("rowIds")
    syncedRowIds!: string[];

    @Prop()
    tableId!: number;

    @Prop()
    headers!: Factor[];

    @Prop()
    choice!: Choice;

    @Prop()
    viewpoints!: Viewpoint[];

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoresEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    get valueMap(): { [id: string]: Value } {
        if (this.choice) {
            return choiceModule.choiceValues[this.choice.id];
        } else {
            return {};
        }
    }

    get viewpointMapping(): {
        [viewpointId: number]: { [factorId: number]: ViewpointMapping };
    } {
        return viewpointsModule.viewpointMappings;
    }

    get valueKeys(): string[] {
        if (this.valueMap) {
            return Object.keys(this.valueMap);
        } else {
            return [];
        }
    }

    get tableKeys(): string[][] {
        if (this.headers) {
            return this.headers.map((factor) => {
                return this.valueKeys.filter((item) =>
                    item.startsWith(`${factor.id}-`)
                );
            });
        } else {
            return [];
        }
    }

    get mainColumn(): Value[] {
        if (this.tableKeys && this.tableKeys.length) {
            return this.tableKeys[0]
                .map((key) => this.valueMap[key])
                .sort((a, b) => {
                    return a.row_id && b.row_id
                        ? a.row_id.localeCompare(b.row_id)
                        : 1;
                });
        } else {
            return [];
        }
    }

    get longestIndex(): number {
        if (this.columnLengths && this.columnLengths.length) {
            return this.columnLengths.indexOf(Math.max(...this.columnLengths));
        } else {
            return 0;
        }
    }

    get columnLengths(): number[] {
        if (this.tableKeys) {
            return this.tableKeys.map((column) => column.length);
        } else {
            return [];
        }
    }

    get nextRowId(): string {
        if (
            this.mainColumn &&
            this.mainColumn.length &&
            this.mainColumn[this.mainColumn.length - 1].row_id
        ) {
            return mudder.base62.mudder(
                this.mainColumn[this.mainColumn.length - 1].row_id,
                "",
                1,
                undefined,
                20
            )[0];
        } else {
            return mudder.base62.mudder("0", undefined, 1, undefined, 20)[0];
        }
    }

    get tableLength(): number {
        if (this.mainColumn && this.mainColumn.length) {
            return this.mainColumn.length;
        } else {
            return 1;
        }
    }

    get tableRowIds(): string[] {
        if (this.mainColumn && this.mainColumn.length) {
            return this.mainColumn.map((val) => {
                return val.row_id ? val.row_id : "";
            });
        } else {
            return [];
        }
    }

    /* Load Values */
    private async loadChoiceValues(): Promise<void> {
        if (this.tableId && this.choice) {
            await choiceModule.getChoiceValues({
                choice_id: this.choice.id,
                parent_id: this.tableId,
            });
        }
    }

    private async loadVpData(viewpoints: Viewpoint[]): Promise<void> {
        if (viewpoints.length && this.tableId) {
            await Promise.all(
                viewpoints.map(async (vp) => {
                    console.log(`Fetching Mappings: ${vp.name}`)
                    viewpointsModule.fetchViewpointMappings({
                        viewpoint_id: vp.id,
                        parent_id: this.tableId,
                    });
                    if (this.choice) {
                        getScore({
                            viewpoint_id: vp.id,
                            choice_id: this.choice.id,
                            factor_id: this.tableId,
                        });
                    }
                })
            );
        }
    }

    mounted(): void {
        this.onTableLengthChange();
        this.onRowIdsChange();
        this.onTableIdChange();
    }

    @Watch("tableLength")
    onTableLengthChange(): void {
        this.syncedRows = this.tableLength;
    }

    @Watch("tableRowIds")
    onRowIdsChange(): void {
        this.syncedRowIds = this.tableRowIds;
    }

    @Watch("tableId")
    onTableIdChange(): void {
        if (this.tableId) {
            this.loadChoiceValues();
            this.loadVpData(this.viewpoints);
        }
    }

    @Watch("choice", { immediate: true, deep: true })
    onChoiceChange(): void {
        if (this.choice) {
            this.loadChoiceValues();
            this.loadVpData(this.viewpoints);
        }
    }

    @Watch("viewpoints", { immediate: true, deep: true })
    async onViewpointsChange(
        newVal: Viewpoint[],
        oldVal: Viewpoint[] = []
    ): Promise<void> {
        if (newVal && newVal.length) {
            this.loadVpData(newVal.filter((x) => !oldVal.includes(x)));
        }
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import {
    Factor,
    FactorType,
    FactorCreateInputL,
    Unit,
    Enum,
    Task,
    Workspace,
    Decision,
} from "@/graphql/API";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import Decisions from "@/store/modules/Decisions";
import StyleEditor from "@/components/common/StyleEditor.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";
import Workspaces from "@/store/modules/Workspaces";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import Apps from "@/store/modules/Apps";

const modelModule = getModule(Factors);
const flashNotificationsModule = getModule(FlashNotifications);
const workspaceModule = getModule(Workspaces);
const decisionModule = getModule(Decisions);

@Component({
    components: {
        DialogCardTitle,
        StyleEditor,
        VpDialog,
    },
})
export default class DecisionImport extends Vue {
    @Prop({ default: "mdi-plus", type: String })
    icon!: string;

    @Prop({ default: "", type: String })
    btnText!: string;

    private filterWorkspaceId: number | null = null;
    private importDecisionId: number | null = null;
    private listDecisions: Decision[] = [];
    private loading = true;
    private isFormValid = false;

    private mounted() {
        this.filterWorkspaceId = this.selectedWorkspaceId;
        this.listDecisions = this.decisionList;
    }

    get selectedWorkspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get selectedDecisionId(): number | null {
        return decisionModule.selectedDecisionId;
    }

    get workspaceList(): Workspace[] {
        return workspaceModule.workspaceList;
    }

    get decisionList(): Decision[] {
        return decisionModule.decisionsList;
    }

    get importBtnDisabled(): boolean {
        if (this.importDecisionId == null) {
            return true;
        }

        return false;
    }

    @Watch("filterWorkspaceId")
    private async onChangeWorkspaceId(): Promise<void> {
        this.loading = true;
        if (this.filterWorkspaceId === this.selectedWorkspaceId) {
            this.listDecisions = this.decisionList;
        } else if (this.filterWorkspaceId != null) {
            this.listDecisions = await GRAPHQL_API.fetchDecisions(
                this.filterWorkspaceId
            );
        }
        this.importDecisionId = null;
        this.loading = false;
    }

    private async importFramework(): Promise<void> {
        if (this.importDecisionId && this.selectedDecisionId) {
            const tempDate = new Date();
            const appSuffix = ` ${tempDate.getFullYear()}-${(
                "0" +
                (tempDate.getUTCMonth() + 1)
            ).slice(-2)}-${("0" + tempDate.getUTCDate()).slice(-2)} ${
                ("00" + tempDate.getHours()).slice(-2) +
                ":" + ("00" + tempDate.getMinutes()).slice(-2)
            }`;
            await decisionModule.copyDecisionInto({
                source_d_id: this.importDecisionId,
                dest_d_id: this.selectedDecisionId,
                app_suffix: appSuffix,
            });
            getModule(Factors).fetchRootFactors(this.selectedDecisionId);
            getModule(Choices).fetchChoices(this.selectedDecisionId);
            getModule(Viewpoints).fetchViewpoints(this.selectedDecisionId);
            getModule(Apps).getApps(this.selectedDecisionId);
        }
    }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import { Factor } from "@/graphql/API";
import FactorCreator from "@/components/model/FactorCreator.vue";

const modelModule = getModule(Factors);
@Component({
    components: {
        FactorCreator,
    },
})
export default class FactorDisplay extends Vue {
    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop({ required: true })
    factor!: Factor;

    private open = false;

    get factors(): Factor[] {
        return modelModule.factors;
    }

    get types(): { text: string; id: string }[] {
        return modelModule.valueTypes;
    }

    private async deleteFactor(factor: Factor) {
        if (factor != null) {
            await modelModule.deleteFactorL([factor.id]);
            // await modelModule.deleteFactor(factor.id);
        }
    }
}


import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BreadCrumb } from "@/graphql/API";

@Component({
    components: {},
})
export default class BreadCrumbItems extends Vue {
    @Prop()
    item!: BreadCrumb;
}

import Choices from "@/store/modules/Choices";
import UsersModule from "@/store/modules/Users";
import Viewpoints from "@/store/modules/Viewpoints";
import Workspaces from "@/store/modules/Workspaces";
import { Auth } from "aws-amplify";
import { getModule } from "vuex-module-decorators";

export function isChoiceWriteValid(userId: string): boolean {
    const userPermission = getModule(UsersModule).currentPermissions;
    if (
        !userPermission ||
        (!userPermission.ch_w && !userPermission.owner_only)
    ) {
        return false;
    }
    const userChoices = getModule(Choices).userChoices;

    if (userPermission.ch_c && userChoices.length >= userPermission.ch_c) {
        return false;
    }

    return true;
}

export function isViewpointWriteValid(userId: string): boolean {
    const userPermission = getModule(UsersModule).currentPermissions;
    console.log(userPermission);
    if (
        !userPermission ||
        (!userPermission.vp_w && !userPermission.owner_only)
    ) {
        return false;
    }
    const userViewpoints = getModule(Viewpoints).userViewpoints;
    if (
        userPermission.vp_c != null &&
        userViewpoints.length >= userPermission.vp_c
    ) {
        return false;
    }

    return true;
}

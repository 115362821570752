
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, Value } from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import ChoiceEditRow from "@/components/choices/editor/ChoiceEditRow.vue";
import ChoiceTable from "@/components/choices/editor/ChoiceTable.vue";

const modelModule = getModule(Factors);
const choiceModule = getModule(Choices);
@Component({
    name: "RecursiveChoiceFactors",
    components: {
        ChoiceEditRow,
        ChoiceTable,
    },
})
export default class RecursiveChoice extends Vue {
    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop()
    choiceId!: number;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    private factorsLoading = false;
    private choiceLoading = false;
    private open: { [id: number]: number[] } = {};

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get valueMap(): { [id: number]: Value } {
        if (choiceModule.choiceValues[this.choiceId]) {
            return choiceModule.choiceValues[this.choiceId];
        } else {
            return {};
        }
    }

    get currentTreeLevel(): Factor[] {
        if (this.factorTree[this.parentId]) {
            return this.factorTree[this.parentId];
        } else return [];
    }

    private async loadChoiceValues(): Promise<void> {
        if (this.choiceId) {
            this.choiceLoading = true;
            await choiceModule.getChoiceValues({
                choice_id: this.choiceId,
                parent_id: this.parentId,
            });
            this.choiceLoading = false;
        }
    }

    private async loadFactors(): Promise<void> {
        if (this.parentId != -1 && !this.factorTree[this.parentId]) {
            this.factorsLoading = true;
            await modelModule.fetchChildFactors(this.parentId);
            this.factorsLoading = false;
        }
    }

    private toggleGroup(icon: boolean, id: number): void {
        if (this.open.hasOwnProperty(id) && this.open[id].length > 0 && icon) {
            Vue.set(this.open, id, []);
        } else {
            Vue.set(this.open, id, [0]);
        }
    }

    mounted(): void {
        this.onParentChange();
    }

    @Watch("choiceId")
    async onChoiceChange(): Promise<void> {
        this.loadChoiceValues();
    }

    @Watch("parentId")
    async onParentChange(): Promise<void> {
        await Promise.all([this.loadFactors(), this.loadChoiceValues()]);
    }
}

import {
    Action,
    config,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { API } from "aws-amplify";
import {
    getChildFactors,
    getEnum,
    getEnums,
    getFactor,
    getFactors,
    getRootFactors,
    getPdfResult,
    getTasks,
    getGeneratedFactors,
    getGeneratedFactorsResults,
    getGeneratedCategoryOptions,
    getGeneratedCategoryOptionsResults,
} from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import {
    CreateColumn,
    CreateColumnL,
    CreateEnums,
    CreateEnumValues,
    CreateFactor,
    CreateFactorL,
    CreateGroup,
    CreateGroupL,
    CreateRow,
    CreateTable,
    CreateTableL,
    DeleteColumn,
    DeleteColumnL,
    DeleteEnum,
    DeleteEnumValues,
    DeleteFactor,
    DeleteFactorL,
    DeleteGroup,
    DeleteGroupL,
    DeleteRow,
    DeleteTable,
    DeleteTableL,
    GetPdfResult,
    GetTasks,
    GetEnum,
    GetEnums,
    GetFactor,
    GetFactors,
    OnFactorChange,
    UpdateColumn,
    UpdateColumnL,
    UpdateEnum,
    UpdateEnumValue,
    UpdateFactor,
    UpdateFactorL,
    UpdateGroup,
    UpdateGroupL,
    UpdateTable,
    UpdateTableL,
    ProcessPdf,
    GetGeneratedFactors,
    GeneratedResult,
    GeneratedFactorsResults,
    GetGeneratedCategoryOptions,
    GetGeneratedCategoryOptionsResults,
    GeneratedOptionsResult,
    OpenAiResult,
    GetGeneratedSecondLevelFactors,
} from "@/graphql/custom";
import Decisions from "./Decisions";
import Vue from "vue";
import {
    createColumnL,
    createEnums,
    createEnumValues,
    createFactorL,
    createGroupL,
    createTableL,
    deleteColumnL,
    deleteEnum,
    deleteEnumValues,
    deleteFactorL,
    deleteGroupL,
    deleteTableL,
    processPdf,
    updateColumnL,
    updateEnum,
    updateEnumValue,
    updateFactorL,
    updateGroupL,
    updateTableL,
    getGeneratedSecondLevelFactors,
} from "@/graphql/mutations";
import {
    ColumnRowChange,
    Enum,
    EnumValue,
    Factor,
    FactorType,
    FactorCreateInputL,
    GroupCreateInputL,
    PdfResult,
    TableCreateInputL,
    Task,
    Title,
    ColumnCreateInputL,
    ViewpointMapping,
} from "@/graphql/API";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";

config.rawError = true;

const name = "factors";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Factors extends VuexModule {
    factors: Factor[] = [];
    factorTree: { [id: number]: Factor[] } = {};
    factorCreateSubscription: any = null;
    factorMap: { [id: number]: Factor } = {};
    createdDeletedFactors: Factor[] = [];
    factorTypes: { [id: number]: FactorType } = {};
    enums: { [id: number]: Enum } = {};
    autoFactors = ["number", "date_time", "category"];
    rootLoading = false;
    valueTypes = [
        //Object for vuetify dropdown separation
        {
            text: "Value Placeholder",
            id: "primitive",
            divider: true,
            header: "Basic Types",
        },
        {
            text: "Text",
            id: "string",
        },
        {
            text: "Number",
            id: "number",
        },
        {
            text: "Multiple Choice",
            id: "category",
        },
        {
            text: "Date and Time",
            id: "date_time",
        },
        //Object for vuetify dropdown separation
        {
            text: "Organization Types",
            id: "display",
            divider: true,
            header: "Organization Types",
        },
        {
            text: "Group",
            id: "group",
        },
        {
            text: "Label",
            id: "label",
        },

        {
            text: "Table",
            id: "table",
        },
        //Object for vuetify dropdown separation
        {
            text: "File Placeholder",
            id: "file",
            divider: true,
            header: "File Types",
        },
        {
            text: "Image",
            id: "image",
        },
        {
            text: "Video",
            id: "video",
        },
        {
            text: "Audio",
            id: "audio",
        },
        {
            text: "Document",
            id: "document",
        },
    ];

    formatDict = {
        string: {
            values: ["single-line", "multi-line", "rich"],
        },
        category: {
            values: ["buttons", "selector", "auto-complete"],
        },
    };

    get factorTypeList(): FactorType[] {
        return Object.values(this.factorTypes);
    }

    get factorRoot(): Factor | undefined {
        const rootFactor = this.factors.find((factor) => {
            return factor.parent_id === null;
        });
        return rootFactor;
    }

    @Mutation
    setLoading(val: boolean): void {
        this.rootLoading = val;
    }

    @Mutation
    clear(): void {
        this.factorTree = {};
        this.factors = [];
        this.factorMap = {};
        this.factorTypes = {};
        this.enums = {};
    }

    @Mutation
    setFactors(factors: Factor[] | null): void {
        if (factors == null) {
            this.factors = [];
        } else this.factors.splice(0, 0, ...factors);
    }

    @Mutation
    setFactorMap(factors: Factor[] | null): void {
        if (factors == null) {
            return;
        }

        for (const factor of factors) {
            Vue.set(this.factorMap, factor.id, factor);
        }
    }

    @Mutation
    setFactorTreeLevel(payload: { parentId: number; factors: Factor[] }): void {
        // if(this.factorTree[payload.parentId] != null) {
        //     this.factorTree[payload.parentId].push(payload.factors);
        // } else {
        const factorsSorted = payload.factors.sort((a: Factor, b: Factor) => {
            if (a.order_str === b.order_str) {
                return 0;
            }

            if (a.order_str > b.order_str) {
                return 1;
            }

            return -1;
        });
        Vue.set(this.factorTree, payload.parentId, factorsSorted);
        // }
    }

    @Mutation
    addFactor(payload: { parentId: number; factor: Factor }): void {
        Vue.set(this.factorMap, payload.factor.id, payload.factor);
        if (
            this.factorTree[payload.parentId] != null &&
            this.factorTree[payload.parentId].length > 0
        ) {
            const factorFound = this.factorTree[payload.parentId].findIndex(
                (searchFactor: Factor) => payload.factor.id === searchFactor.id
            );
            if (factorFound != -1) {
                this.factorTree[payload.parentId].splice(
                    factorFound,
                    1,
                    payload.factor
                );
                this.factorTree[payload.parentId].sort(
                    (a: Factor, b: Factor) => {
                        if (a.order_str === b.order_str) {
                            return 0;
                        }

                        if (a.order_str > b.order_str) {
                            return 1;
                        }

                        return -1;
                    }
                );
                return;
            } else {
                this.factorTree[payload.parentId].push(payload.factor);
                // This sort is required for batch create done using loop - if batch changed to one call then can sort added factors before hand
                // Can possibly be removed if batch changed
                this.factorTree[payload.parentId].sort(
                    (a: Factor, b: Factor) => {
                        if (a.order_str === b.order_str) {
                            return 0;
                        }

                        if (a.order_str > b.order_str) {
                            return 1;
                        }

                        return -1;
                    }
                );
            }
        } else {
            Vue.set(this.factorTree, payload.parentId, [payload.factor]);
        }
    }

    @Mutation
    addFactors(payload: { parentId: number; factors: Factor[] }): void {
        this.factors.splice(this.factors.length, 0, ...payload.factors);

        //If the parent Id exists already in the factor tree then add to that level of the tree
        if (this.factorTree[payload.parentId] != null) {
            this.factorTree[payload.parentId].splice(
                this.factorTree[payload.parentId].length,
                0,
                ...payload.factors
            );
            //Otherwise set a new level in the tree
        } else {
            Vue.set(this.factorTree, payload.parentId, payload.factors);
        }
    }

    @Mutation
    removeFactor(factor: Factor): void {
        const parent_id = factor.parent_id != null ? factor.parent_id : -1;
        const factorIndex = this.factors.findIndex(
            (searchFactor: Factor) => searchFactor.id === factor.id
        );

        if (factorIndex >= 0) {
            this.factors.splice(factorIndex, 1);
        }

        if (factor.is_group || factor.is_column) {
            if (this.factorTree[factor.id]) {
                this.factorTree[factor.id].map((childFactor) =>
                    Vue.delete(this.factorMap, childFactor.id)
                );
            }
            Vue.delete(this.factorTree, factor.id);
        }

        if (this.factorTree[parent_id] != null) {
            const factorTreeIndex = this.factorTree[parent_id].findIndex(
                (searchFactor: Factor) => searchFactor.id === factor.id
            );
            factorTreeIndex >= 0
                ? this.factorTree[parent_id].splice(factorTreeIndex, 1)
                : "";
        }

        Vue.delete(this.factorMap, factor.id);
    }

    //Set factorType dictionary keyed by factorType id
    @Mutation
    setFactorTypes(factorTypes: FactorType[]): void {
        const dictionary = Object.assign(
            {},
            ...factorTypes.map((x) => ({ [x.id]: x }))
        );
        this.factorTypes = dictionary;
    }

    //Set factorType dictionary keyed by factorType id
    @Mutation
    setFactorType(factorType: FactorType): void {
        if (factorType) Vue.set(this.factorTypes, factorType.id, factorType);
    }

    //Delete factorType from factorTypes dictionary
    @Mutation
    removeFactorType(factorType: FactorType): void {
        Vue.delete(this.factorTypes, factorType.id);
    }

    @Mutation
    setEnums(enums: Enum[]): void {
        enums.forEach((enumObject: Enum) =>
            Vue.set(this.enums, enumObject.id, enumObject)
        );
    }

    @Mutation
    setEnum(enumObject: Enum): void {
        Vue.set(this.enums, enumObject.id, enumObject);
    }

    @Mutation
    removeEnum(enumObject: Enum): void {
        Vue.delete(this.enums, enumObject.id);
    }

    @Mutation
    setEnumValues(payload: {
        enum_id: number;
        enum_values: EnumValue[];
    }): void {
        const enum_id = payload.enum_id;
        const enum_values = payload.enum_values;
        if (this.enums[enum_id])
            Vue.set(this.enums[enum_id], "values", enum_values);
    }

    @Mutation
    setEnumValue(enumValue: EnumValue): void {
        const enumIndex = this.enums[enumValue.enum_id].values.findIndex(
            (searchEnumValue) => searchEnumValue.id == enumValue.id
        );

        if (enumIndex >= 0) {
            this.enums[enumValue.enum_id].values.splice(
                enumIndex,
                1,
                enumValue
            );
        } else {
            this.enums[enumValue.enum_id].values.splice(
                this.enums[enumValue.enum_id].values.length,
                0,
                enumValue
            );
        }
    }

    @Mutation
    removeEnumValue(enumValue: EnumValue): void {
        const enumIndex = this.enums[enumValue.enum_id].values.findIndex(
            (searchEnumValue) => searchEnumValue.id == enumValue.id
        );

        if (enumIndex >= 0) {
            this.enums[enumValue.enum_id].values.splice(enumIndex, 1);
        }
    }

    //-FetchRootFactors- gets all factors with parent_id = null
    @Action
    async fetchRootFactors(decisionId: number): Promise<Factor[] | null> {
        if (decisionId != null) {
            this.clear();
            const factors = (await GRAPHQL_API.graphqlQueryRequest({
                query: getRootFactors,
                variables: {
                    decision_id: decisionId,
                    root_only: true,
                },
            })) as GraphQLResult<GetFactors>;
            if (factors.data?.getFactors) {
                this.setFactors(factors.data?.getFactors || null);
                this.setFactorMap(factors.data?.getFactors);
                this.setFactorTreeLevel({
                    parentId: -1,
                    factors: factors.data?.getFactors,
                });
                return factors.data.getFactors || null;
            }
        }
        return null;
    }

    @Action
    async fetchAllFactors(decisionId: number): Promise<Factor[] | null> {
        if (decisionId != null) {
            const factors = (await GRAPHQL_API.graphqlQueryRequest({
                query: getRootFactors,
                variables: {
                    decision_id: decisionId,
                    root_only: false,
                },
            })) as GraphQLResult<GetFactors>;
            if (factors.data?.getFactors) {
                return factors.data.getFactors || null;
            }
        }
        return null;
    }

    //-FetchChildFactors- gets all factors with parent_id = <parent_id>
    @Action
    async fetchChildFactors(parentId: number): Promise<Factor[] | null> {
        const decisionId = getModule(Decisions).selectedDecisionId;
        if (decisionId != null && parentId != -1) {
            const factors = (await GRAPHQL_API.graphqlQueryRequest({
                query: getChildFactors,
                variables: {
                    decision_id: decisionId,
                    parent_id: parentId,
                },
            })) as GraphQLResult<GetFactors>;

            this.setFactors(factors.data?.getFactors || null);
            if (factors.data?.getFactors != null) {
                this.addFactors({
                    parentId: parentId,
                    factors: factors.data.getFactors,
                });
                this.setFactorMap(factors.data?.getFactors);
                this.setFactorTreeLevel({
                    parentId: parentId,
                    factors: factors.data.getFactors,
                });
                return factors.data.getFactors || null;
            }
        }
        return null;
    }

    @Action
    async fetchFactor(factorId: number): Promise<Factor | null> {
        if (factorId != null) {
            const factors = (await GRAPHQL_API.graphqlQueryRequest({
                query: getFactor,
                variables: {
                    factor_id: factorId,
                },
            })) as GraphQLResult<GetFactor>;
            if (factors.data?.getFactor) {
                this.setFactors([factors.data?.getFactor] || null);
                this.setFactorMap([factors.data?.getFactor]);

                return factors.data.getFactor || null;
            }
        }
        return null;
    }

    //-CreateFactor- creates factor in specified group/root level. Subscription trigger adds factor to local state
    @Action
    async createFactorL(payload: {
        parentId: number;
        names: string[];
        description?: string;
        value_type: string;
        ordering: string[];
        json?: string;
        format?: string;
        unit_id?: number;
        enum_ids?: number[];
        m_score?: number;
        m_index?: number;
        weight: number;
        score_rule_id: number;
        index_rule_id: number;
        set_id: number;
        decision_id?: number;
        min?: number | null;
        max?: number | null;
    }): Promise<Factor[] | null> {
        const decision_id = payload.decision_id
            ? payload.decision_id
            : getModule(Decisions).selectedDecisionId;
        const parent_id = payload.parentId;
        const names = payload.names;
        const description = payload.description;
        const order_str = payload.ordering;
        const value_type = payload.value_type;
        const json = payload.json;
        const format = payload.format;
        const unit_id = payload.unit_id;
        const enum_ids = payload.enum_ids;
        const m_score = payload.m_score;
        const m_index = payload.m_index;
        const weight = payload.weight;
        const score_rule_id = payload.score_rule_id;
        const index_rule_id = payload.index_rule_id;
        const set_id = payload.set_id;
        const input: FactorCreateInputL[] = [];
        const min = payload.min;
        const max = payload.max;
        if (names && decision_id) {
            names.map((name, index) => {
                const factorInputObj = {
                    decision_id: decision_id,
                    name: name,
                    description: description,
                    value_type: value_type,
                    json: json,
                    order_str: order_str[index],
                    format: format,
                    unit_id: unit_id,
                    score_rule_id: score_rule_id,
                    m_score: m_score,
                    index_rule_id: index_rule_id,
                    m_index: m_index,
                    set_id: set_id,
                    weight: weight,
                    enum_id: enum_ids ? enum_ids[index] : undefined,
                    min: min,
                    max: max,
                };
                if (parent_id > 0) {
                    Vue.set(factorInputObj, "parent_id", payload.parentId);
                } else {
                    Vue.set(factorInputObj, "parent_id", undefined);
                }
                input.push(factorInputObj);
            });
        }

        if (input && input.length > 0) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createFactorL,
                variables: { input },
            })) as GraphQLResult<CreateFactorL>;
            const factors = res.data?.createFactorL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.addFactor({
                        parentId: factor.parent_id ? factor.parent_id : -1,
                        factor: factor,
                    });
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async createSuggestedFactors(
        facorsList: FactorCreateInputL[]
    ): Promise<Factor[] | null> {
        const input: FactorCreateInputL[] = facorsList;
        if (facorsList && facorsList.length > 0) {
            const res = (await API.graphql({
                query: createFactorL,
                variables: { input },
            })) as GraphQLResult<CreateFactorL>;
            const factors = res.data?.createFactorL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.addFactor({
                        parentId: factor.parent_id ? factor.parent_id : -1,
                        factor: factor,
                    });
                });
                return factors;
            }
        }
        return null;
    }

    //-UpdateFactorL- Updates factor. Subscription trigger updates factor in local state
    @Action
    async updateFactorL(payload: {
        id: number;
        name?: string;
        description?: string;
        value_type?: string;
        ordering?: string;
        parent_id?: number | null;
        score_map_id?: number;
        json?: string;
        is_bp?: boolean;
        format?: string;
        m_score?: number;
        m_index?: number;
        weight?: number;
        score_rule_id?: number;
        index_rule_id?: number;
        set_id?: number;
        unit_id?: number;
        min?: number | null;
        max?: number | null;
    }): Promise<Factor[] | null> {
        const id = payload.id;
        const name = payload.name;
        const description = payload.description;
        const order_str = payload.ordering;
        const value_type = payload.value_type;
        const parent_id = payload.parent_id != -1 ? payload.parent_id : null;
        // const is_bp = payload.is_bp ? payload.is_bp : false;
        const score_map_id = payload.score_map_id;
        const json = payload.json;
        const format = payload.format;
        const m_index = payload.m_index;
        const m_score = payload.m_score;
        const weight = payload.weight;
        const score_rule_id = payload.score_rule_id;
        const index_rule_id = payload.index_rule_id;
        const set_id = payload.set_id;
        const unit_id = payload.unit_id;
        const min = payload.min;
        const max = payload.max;
        const input = {
            name: name,
            description: description,
            factor_id: id,
            order_str: order_str,
            value_type: value_type,
            parent_id: parent_id,
            score_map_id: score_map_id,
            // json: json,
            // is_bp: is_bp,
            format,
            m_score,
            m_index,
            weight,
            score_rule_id,
            index_rule_id,
            set_id,
            unit_id,
            min,
            max,
        };

        if (id != null) {
            //const input = { name: name };
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: updateFactorL,
                variables: {
                    ...input,
                },
            })) as GraphQLResult<UpdateFactorL>;
            const factors = res.data?.updateFactorL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.addFactor({
                        parentId: factor.parent_id ? factor.parent_id : -1,
                        factor: factor,
                    });
                });
                return factors;
            }
        }
        return null;
    }

    //-UpdateGroupL- Updates a Group. Subscription trigger updates Group in local state
    @Action
    async updateGroupL(payload: {
        id: number;
        name?: string;
        description?: string;
        ordering?: string;
        parent_id?: number;
        json?: string;
        m_score?: number;
        m_index?: number;
        weight?: number;
        score_rule_id?: number;
        index_rule_id?: number;
        set_id?: number;
    }): Promise<Factor[] | null> {
        const id = payload.id;
        const name = payload.name;
        const description = payload.description;
        const order_str = payload.ordering;
        const parent_id = payload.parent_id != -1 ? payload.parent_id : null;
        const json = payload.json;
        const m_score = payload.m_score;
        const m_index = payload.m_index;
        const weight = payload.weight;
        const score_rule_id = payload.score_rule_id;
        const index_rule_id = payload.index_rule_id;
        const set_id = payload.set_id;
        const input = {
            name: name,
            description: description,
            factor_id: id,
            order_str: order_str,
            parent_id: parent_id,
            json: json,
            m_score,
            m_index,
            weight,
            score_rule_id,
            index_rule_id,
            set_id,
        };

        if (id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: updateGroupL,
                variables: {
                    ...input,
                },
            })) as GraphQLResult<UpdateGroupL>;

            const factors = res.data?.updateGroupL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.addFactor({
                        parentId: factor.parent_id ? factor.parent_id : -1,
                        factor: factor,
                    });
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async createTableL(payload: {
        parentId: number;
        names: string[];
        description?: string;
        ordering: string[];
        json?: string;
        m_score?: number;
        m_index?: number;
        weight: number;
        score_rule_id: number;
        index_rule_id: number;
        set_id: number;
        decision_id?: number;
    }): Promise<Factor[] | null> {
        const decision_id = payload.decision_id
            ? payload.decision_id
            : getModule(Decisions).selectedDecisionId;
        const parent_id = payload.parentId;
        const names = payload.names;
        const description = payload.description;
        const order_str = payload.ordering;
        const json = payload.json;
        const m_score = payload.m_score;
        const m_index = payload.m_index;
        const weight = payload.weight;
        const score_rule_id = payload.score_rule_id;
        const index_rule_id = payload.index_rule_id;
        const set_id = payload.set_id;
        const input: TableCreateInputL[] = [];

        if (names && decision_id) {
            names.map((name, index) => {
                const factorInputObj = {
                    decision_id: decision_id,
                    name: name,
                    description: description,
                    json: json,
                    order_str: order_str[index],
                    score_rule_id: score_rule_id,
                    m_score: m_score,
                    index_rule_id: index_rule_id,
                    m_index: m_index,
                    set_id: set_id,
                    weight: weight,
                };
                if (parent_id > 0) {
                    Vue.set(factorInputObj, "parent_id", payload.parentId);
                }
                input.push(factorInputObj);
            });
        }
        if (input && input.length > 0) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createTableL,
                variables: { input },
            })) as GraphQLResult<CreateTableL>;

            const factors = res.data?.createTableL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.addFactor({
                        parentId: factor.parent_id ? factor.parent_id : -1,
                        factor: factor,
                    });
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async updateTableL(payload: {
        id: number;
        parentId?: number;
        name?: string;
        description?: string;
        ordering?: string;
        json?: string;
        m_score?: number;
        m_index?: number;
        weight?: number;
        score_rule_id?: number;
        index_rule_id?: number;
        set_id?: number;
    }): Promise<Factor[] | null> {
        const factor_id = payload.id;
        const name = payload.name;
        const description = payload.description;
        const order_str = payload.ordering;
        const json = payload.json;
        const m_index = payload.m_index;
        const m_score = payload.m_score;
        const weight = payload.weight;
        const score_rule_id = payload.score_rule_id;
        const index_rule_id = payload.index_rule_id;
        const set_id = payload.set_id;
        const input = {
            factor_id: factor_id,
            name: name,
            description: description,
            order_str: order_str,
            json: json,
            m_index,
            m_score,
            weight,
            score_rule_id,
            index_rule_id,
            set_id,
        };

        if (name != null) {
            //const input = { name: name };
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: updateTableL,
                variables: {
                    ...input,
                },
            })) as GraphQLResult<UpdateTableL>;

            const factors = res.data?.updateTableL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.addFactor({
                        parentId: factor.parent_id ? factor.parent_id : -1,
                        factor: factor,
                    });
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async createColumnL(payload: {
        parentId: number;
        names: string[];
        description?: string;
        value_type: string;
        ordering: string[];
        json?: string;
        unit_id?: number;
        format?: string;
        enum_ids?: number[];
        m_score?: number;
        m_index?: number;
        weight: number;
        score_rule_id: number;
        index_rule_id: number;
        set_id: number;
        decision_id?: number;
    }): Promise<Factor[] | null> {
        const decision_id = payload.decision_id
            ? payload.decision_id
            : getModule(Decisions).selectedDecisionId;
        const parent_id = payload.parentId;
        const names = payload.names;
        const description = payload.description;
        const order_str = payload.ordering;
        const value_type = payload.value_type;
        // const is_bp = payload.is_bp ? payload.is_bp : false;
        const json = payload.json;
        const unit_id = payload.unit_id;
        const format = payload.format;
        const enum_ids = payload.enum_ids;
        const m_score = payload.m_score;
        const m_index = payload.m_index;
        const weight = payload.weight;
        const score_rule_id = payload.score_rule_id;
        const index_rule_id = payload.index_rule_id;
        const set_id = payload.set_id;
        const input: ColumnCreateInputL[] = [];

        if (names && decision_id) {
            names.map((name, index) => {
                const factorInputObj = {
                    decision_id: decision_id,
                    parent_id: parent_id,
                    name: name,
                    description: description,
                    value_type: value_type,
                    json: json,
                    order_str: order_str[index],
                    format: format,
                    unit_id: unit_id,
                    score_rule_id: score_rule_id,
                    m_score: m_score,
                    index_rule_id: index_rule_id,
                    m_index: m_index,
                    set_id: set_id,
                    weight: weight,
                    enum_id: enum_ids ? enum_ids[index] : undefined,
                };
                input.push(factorInputObj);
            });
        }
        if (name != null) {
            //const input = { name: name };
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createColumnL,
                variables: { input },
            })) as GraphQLResult<CreateColumnL>;

            const factors = res.data?.createColumnL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.addFactor({
                        parentId: factor.parent_id ? factor.parent_id : -1,
                        factor: factor,
                    });
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async updateColumnL(payload: {
        factorId: number;
        name?: string;
        description?: string;
        ordering: string;
        json?: string;
        m_score?: number;
        m_index?: number;
        weight?: number;
        score_rule_id?: number;
        index_rule_id?: number;
        set_id?: number;
    }): Promise<Factor[] | null> {
        const factor_id = payload.factorId;
        const name = payload.name;
        const description = payload.description;
        const order_str = payload.ordering;
        const json = payload.json;
        const m_index = payload.m_index;
        const m_score = payload.m_score;
        const weight = payload.weight;
        const score_rule_id = payload.score_rule_id;
        const index_rule_id = payload.index_rule_id;
        const set_id = payload.set_id;
        const input = {
            name: name,
            description: description,
            order_str: order_str,
            factor_id: factor_id,
            json: json,
            m_index,
            m_score,
            weight,
            score_rule_id,
            index_rule_id,
            set_id,
        };

        if (name != null) {
            //const input = { name: name };
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: updateColumnL,
                variables: {
                    ...input,
                },
            })) as GraphQLResult<UpdateColumnL>;

            const factors = res.data?.updateColumnL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.addFactor({
                        parentId: factor.parent_id ? factor.parent_id : -1,
                        factor: factor,
                    });
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async createGroupL(payload: {
        parentId: number;
        names: string[];
        description?: string;
        ordering: string[];
        json?: string;
        m_score?: number;
        m_index?: number;
        weight: number;
        score_rule_id: number;
        index_rule_id: number;
        set_id: number;
        decision_id?: number;
    }): Promise<Factor[] | null> {
        const decision_id = payload.decision_id
            ? payload.decision_id
            : getModule(Decisions).selectedDecisionId;
        const parent_id = payload.parentId;
        const names = payload.names;
        const description = payload.description;
        const order_str = payload.ordering;
        const json = payload.json;
        const m_score = payload.m_score;
        const m_index = payload.m_index;
        const weight = payload.weight;
        const score_rule_id = payload.score_rule_id;
        const index_rule_id = payload.index_rule_id;
        const set_id = payload.set_id;
        const input: GroupCreateInputL[] = [];

        if (names && decision_id) {
            names.map((name, index) => {
                const factorInputObj = {
                    decision_id: decision_id,
                    name: name,
                    description: description,
                    json: json,
                    order_str: order_str[index],
                    score_rule_id: score_rule_id,
                    m_score: m_score,
                    index_rule_id: index_rule_id,
                    m_index: m_index,
                    set_id: set_id,
                    weight: weight,
                };
                if (parent_id > 0) {
                    Vue.set(factorInputObj, "parent_id", payload.parentId);
                } else {
                    Vue.set(factorInputObj, "parent_id", undefined);
                }
                input.push(factorInputObj);
            });
        }
        if (input && input.length > 0) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createGroupL,
                variables: { input },
            })) as GraphQLResult<CreateGroupL>;

            const factors = res.data?.createGroupL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.addFactor({
                        parentId: factor.parent_id ? factor.parent_id : -1,
                        factor: factor,
                    });
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async deleteFactorL(id: number[]): Promise<Factor[] | null> {
        if (id.length > 0) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteFactorL,
                variables: {
                    id,
                },
            })) as GraphQLResult<DeleteFactorL>;

            const factors = res.data?.deleteFactorL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.removeFactor(factor);
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async deleteGroupL(id: number[]): Promise<Factor[] | null> {
        if (id.length > 0) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteGroupL,
                variables: {
                    id,
                },
            })) as GraphQLResult<DeleteGroupL>;

            const factors = res.data?.deleteGroupL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.removeFactor(factor);
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async deleteTableL(id: number[]): Promise<Factor[] | null> {
        if (id.length > 0) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteTableL,
                variables: {
                    id,
                },
            })) as GraphQLResult<DeleteTableL>;

            //Subscription handling this action now
            const factors = res.data?.deleteTableL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.removeFactor(factor);
                });
                return factors;
            }
        }
        return null;
    }

    @Action
    async deleteColumnL(id: number[]): Promise<Factor[] | null> {
        if (id.length > 0) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteColumnL,
                variables: {
                    id,
                },
            })) as GraphQLResult<DeleteColumnL>;

            const factors = res.data?.deleteColumnL?.factors;
            if (factors) {
                factors.forEach((factor: Factor) => {
                    this.removeFactor(factor);
                });
                return factors;
            }
        }
        return null;
    }

    // @Action
    // async fetchFactorTypes(): Promise<FactorType[] | null> {
    //     const res = (await API.graphql({
    //         query: getFactorTypes,
    //         variables: {},
    //     })) as GraphQLResult<GetFactorTypes>;
    //     const factorTypes = res.data?.getFactorTypes;
    //     if (factorTypes) {
    //         this.setFactorTypes(factorTypes);
    //         return factorTypes;
    //     }

    //     return null;
    // }

    @Action
    async clearFactorState(): Promise<void> {
        this.clear();
    }

    @Action
    async createUpdateFactors(payload: {
        factors: Factor[];
        mutation: string;
    }): Promise<void> {
        const factors = payload.factors;
        const mutation = payload.mutation;
        if (factors == null || factors.length === 0) {
            return;
        }
        factors.forEach((factor) => {
            const parent = factor.parent_id ? factor.parent_id : -1;
            if (mutation.includes("update")) {
                if (
                    factor.id in this.factorMap &&
                    factor.parent_id != this.factorMap[factor.id].parent_id
                ) {
                    this.removeFactor(this.factorMap[factor.id]);
                }
            }
            this.addFactor({ parentId: parent, factor: factor });
            this.setFactorMap([factor]);
        });
    }

    @Action
    async removeLocalFactor(payload: {
        factors: Factor[];
        mutation: string;
    }): Promise<void> {
        const factors = payload.factors;
        const mutation = payload.mutation;
        if (!factors || factors.length <= 0) {
            return;
        }

        factors.forEach((factor: Factor) => {
            const parent = factor.parent_id != null ? factor.parent_id : -1;
            this.removeFactor(factor);
        });
    }

    @Action
    async getEnums(decision_id: number): Promise<Enum[] | null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getEnums,
            variables: {
                decision_id,
            },
        })) as GraphQLResult<GetEnums>;

        const enums = res.data?.getEnums;

        if (enums) {
            this.setEnums(enums);
            return enums;
        }

        return null;
    }

    @Action
    async getEnum(enum_id: number): Promise<Enum | null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getEnum,
            variables: {
                enum_id,
            },
        })) as GraphQLResult<GetEnum>;

        const enumObject = res.data?.getEnum;

        if (enumObject) {
            this.setEnumValues({
                enum_id: enum_id,
                enum_values: enumObject.values,
            });
            return enumObject;
        }

        return null;
    }

    @Action
    async createEnums(payload: {
        decision_id: number;
        names: string[];
    }): Promise<Enum[] | null> {
        const names = payload.names;
        const decision_id = payload.decision_id;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createEnums,
            variables: {
                decision_id,
                names,
            },
        })) as GraphQLResult<CreateEnums>;

        const enumObject = res.data?.createEnums;

        if (enumObject != null) {
            this.setEnums(enumObject);
            return enumObject;
        }

        return null;
    }

    @Action
    async updateEnum(payload: {
        name: string;
        enum_id: number;
    }): Promise<Enum | null> {
        const name = payload.name;
        const enum_id = payload.enum_id;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateEnum,
            variables: {
                name,
                enum_id,
            },
        })) as GraphQLResult<UpdateEnum>;

        const enumObject = res.data?.updateEnum;

        if (enumObject != null) {
            this.setEnum(enumObject);
            return enumObject;
        }

        return null;
    }

    @Action
    async deleteEnum(payload: { enum_id: number }): Promise<Enum | null> {
        const enum_id = payload.enum_id;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteEnum,
            variables: {
                enum_id,
            },
        })) as GraphQLResult<DeleteEnum>;

        const enumObject = res.data?.deleteEnum;

        if (enumObject != null) {
            this.removeEnum(enumObject);
            return enumObject;
        }

        return null;
    }

    @Action
    async createEnumValues(payload: {
        enum_id: number;
        values: string[];
    }): Promise<EnumValue[] | null> {
        const enum_id = payload.enum_id;
        const values = payload.values;

        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createEnumValues,
            variables: {
                enum_id,
                values,
            },
        })) as GraphQLResult<CreateEnumValues>;

        const enumValues = res.data?.createEnumValues;

        if (enumValues != null) {
            enumValues.map((enumValue) => this.setEnumValue(enumValue));
            return enumValues;
        }

        return null;
    }

    @Action
    async updateEnumValue(payload: {
        value: string;
        enum_value_id: number;
        deprecated: boolean;
    }): Promise<EnumValue | null> {
        const value = payload.value;
        const enum_value_id = payload.enum_value_id;
        const deprecated = payload.deprecated;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateEnumValue,
            variables: {
                value,
                deprecated,
                enum_value_id,
            },
        })) as GraphQLResult<UpdateEnumValue>;

        const enumValue = res.data?.updateEnumValue;

        if (enumValue != null) {
            this.setEnumValue(enumValue);
            return enumValue;
        }

        return null;
    }

    @Action
    async deleteEnumValues(payload: {
        enum_id: number;
        enum_value_ids: number[];
    }): Promise<EnumValue[] | null> {
        const enum_id = payload.enum_id;
        const enum_value_ids = payload.enum_value_ids;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteEnumValues,
            variables: {
                enum_id,
                enum_value_ids,
            },
        })) as GraphQLResult<DeleteEnumValues>;

        const enumValues = res.data?.deleteEnumValues;
        if (enumValues != null) {
            enumValues.map((enumValue) => {
                this.removeEnumValue(enumValue);
            });
            return enumValues;
        }

        return null;
    }

    @Action
    async processPdf(payload: {
        pdf_id: string;
        first_page?: number;
        last_page?: number;
    }): Promise<Task | null> {
        const pdf_id = payload.pdf_id;
        const first_page = payload.first_page;
        const last_page = payload.last_page;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: processPdf,
            variables: {
                pdf_id,
                first_page,
                last_page,
            },
        })) as GraphQLResult<ProcessPdf>;
        const changes = res.data?.processPdf;

        if (changes != null) {
            return changes;
        }

        return null;
    }

    @Action
    async getPdfResults(task_id: string): Promise<PdfResult | null> {
        if (task_id != null) {
            const res = (await GRAPHQL_API.graphqlQueryRequest({
                query: getPdfResult,
                variables: {
                    task_id,
                },
            })) as GraphQLResult<GetPdfResult>;
            const changes = res.data?.getPdfResult;
            if (changes) {
                return changes;
            }
        }

        return null;
    }

    @Action
    async getTasks(payload: {
        limit: number;
        status: string;
    }): Promise<Task[] | null> {
        const limit = payload.limit;
        const status = payload.status;
        if (limit != null && status != null) {
            const res = (await GRAPHQL_API.graphqlQueryRequest({
                query: getTasks,
                variables: {
                    limit,
                    status,
                },
            })) as GraphQLResult<GetTasks>;
            const changes = res.data?.getTasks;
            if (changes) {
                return changes;
            }
        }
        return null;
    }

    @Action
    async getGeneratedFactors(payload: {
        topic_area: string;
        creativity: number;
        persona: string;
    }): Promise<Task | null> {
        const topic_area = payload.topic_area;
        const creativity = payload.creativity;
        const persona = payload.persona;
        const stage = process.env.VUE_APP_STAGE;

        if (topic_area && creativity != null) {
            const res = (await API.graphql({
                query: getGeneratedFactors,
                variables: {
                    topic_area,
                    creativity,
                    persona,
                    stage,
                },
            })) as GraphQLResult<GetGeneratedFactors>;
            const changes = res.data?.getGeneratedFactors;
            if (changes) {
                return changes;
            }
        }
        return null;
    }

    @Action
    async getGeneratedFactorsResults(payload: {
        task_id: string;
    }): Promise<GeneratedResult | null> {
        const task_id = payload.task_id;
        const stage = process.env.VUE_APP_STAGE;

        if (task_id && stage != null) {
            const res = (await API.graphql({
                query: getGeneratedFactorsResults,
                variables: {
                    task_id,
                    stage,
                },
            })) as GraphQLResult<GeneratedFactorsResults>;
            const changes = res.data?.getGeneratedFactorsResults;
            if (changes) {
                return changes;
            }
        }
        return null;
    }

    @Action
    async getGeneratedCategoryOptions(payload: {
        topic_area: string;
        creativity: number;
        categorical_factors: string;
    }): Promise<Task | null> {
        const topic_area = payload.topic_area;
        const creativity = payload.creativity;
        const categorical_factors = payload.categorical_factors;
        const stage = process.env.VUE_APP_STAGE;

        if (topic_area && creativity != null) {
            const res = (await API.graphql({
                query: getGeneratedCategoryOptions,
                variables: {
                    topic_area,
                    creativity,
                    stage,
                    categorical_factors,
                },
            })) as GraphQLResult<GetGeneratedCategoryOptions>;
            const changes = res.data?.getGeneratedCategoryOptions;
            if (changes) {
                return changes;
            }
        }
        return null;
    }

    @Action
    async getGeneratedCategoryOptionsResults(payload: {
        task_id: string;
    }): Promise<GeneratedOptionsResult | null> {
        const task_id = payload.task_id;
        const stage = process.env.VUE_APP_STAGE;

        if (task_id && stage != null) {
            const res = (await API.graphql({
                query: getGeneratedCategoryOptionsResults,
                variables: {
                    task_id,
                    stage,
                },
            })) as GraphQLResult<GetGeneratedCategoryOptionsResults>;
            console.log(res);
            const changes = res.data?.getGeneratedCategoryOptionsResults;
            if (changes) {
                return changes;
            }
        }
        return null;
    }

    @Action
    async getGeneratedSecondLevelFactors(payload: {
        groups: OpenAiResult[];
        topic_area: string;
        creativity: number;
        persona: string;
    }): Promise<Task | null> {
        const groups = payload.groups;
        const topic_area = payload.topic_area;
        const creativity = payload.creativity;
        const persona = payload.persona;
        const stage = process.env.VUE_APP_STAGE;

        if (topic_area && creativity != null) {
            const res = (await API.graphql({
                query: getGeneratedSecondLevelFactors,
                variables: {
                    groups,
                    topic_area,
                    creativity,
                    persona,
                    stage,
                },
            })) as GraphQLResult<GetGeneratedSecondLevelFactors>;
            const changes = res.data?.getGeneratedSecondLevelFactors;
            if (changes) {
                return changes;
            }
        }
        return null;
    }

    @Action
    updateLoading(loading: boolean): void {
        this.setLoading(loading);
    }
}

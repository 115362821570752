
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
    components: {},
})
export default class Modal extends Vue {
    @Prop({ default: "", type: String })
    title!: string;

    @Prop({ default: false, type: Boolean })
    active!: boolean;

    @Prop({ default: false, type: Boolean })
    pageModal!: boolean;
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-colour-picker"},[(!_vm.accordion)?_c('v-menu',{staticClass:"vp-colour-palette__dropdown",staticStyle:{"max-width":"100%","z-index":"100"},attrs:{"offset-y":"","disabled":_vm.disabled,"attach":_vm.attach,"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"rounded-0",class:_vm.blankBtn ? 'padding-0 vp-colour-picker__btn-blank' : '',attrs:{"disabled":_vm.disabled,"elevation":"0","plain":"","min-width":_vm.fullWidth ? '100%' : ''}},on),[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.blankBtn ? "" : "Colour"))]}),_c('span',{staticClass:"colour-preview",class:{
                        'ml-2': !_vm.blankBtn,
                        'colour-preview--none': _vm.colourIndex == '',
                    },style:(`background: ${
                        _vm.colourIndex == '' ? '' : _vm.palette[_vm.colourIndex].hex
                    };`)})],2)]}}],null,true)},[_c('div',{staticClass:"vp-colour__dropdown"},_vm._l((_vm.colourList),function(list,index){return _c('div',{key:index,staticClass:"vp-colour-dropdown__list"},[_vm._l((list),function(colour,index){return _c('div',{key:index,staticClass:"vp-colour__block",on:{"click":function($event){return _vm.selectColour(colour.id)}}},[_c('div',{staticClass:"vp-color__preview"},[_c('div',{staticClass:"vp-color__preview-color",class:colour.id == 'vp--bg-grey-1'
                                    ? 'vp-color__preview-color--border'
                                    : '',style:(`background: ${colour.hex};`)})])])}),(index == 0)?_c('div',{staticClass:"vp-colour__block",on:{"click":function($event){return _vm.selectColour('')}}},[_c('div',{staticClass:"vp-color__preview"},[_c('v-icon',[_vm._v("mdi-border-none-variant")])],1)]):_vm._e()],2)}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Workspace, TableHeader, TableAction, User } from "@/graphql/API";
import { TableIcons } from "@/IconLibrary";
import { Urls } from "@/Urls";
import Workspaces from "@/store/modules/Workspaces";
import Users from "@/store/modules/Users";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import WorkspaceSelector from "@/components/workspaces/WorkspaceSelector.vue";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import WorkspaceEditor from "@/components/workspaces/WorkspaceEditor.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import UsersView from "@/views/UsersView.vue";
import { DateTimeFormatOptions } from "vue-i18n";
import TableManagerView from "@/components/managers/TableManagerView.vue";

const workspacesModule = getModule(Workspaces);
const userModule = getModule(Users);

@Component({
    components: {
        ViewWrapper,
        WorkspaceSelector,
        TableManager,
        TableManagerSettings,
        WorkspaceEditor,
        TableManagerTools,
        VpDialog,
        UsersView,
        TableManagerView,
    },
})
export default class WorkspacesView extends Vue {
    @Prop({ default: false, type: Boolean })
    frameworkRedirect!: boolean;

    private selected: number[] = [];
    private settingsMode = false;
    private usersMap: { [id: string]: User } = {};

    private readonly urls = Urls;

    private searchTerm = "";

    private sideTrigger = "settings";
    private deleteOpen = false;
    private manageUserOpen = false;
    private loading = false;

    private readonly Icons: Record<string, string> = TableIcons;

    private headers: { [id: string]: TableHeader } = {
        name: {
            label: "Name",
            property: "name",
            visible: false,
            required: true,
            enabled: false,
            locked: false,
        },

        description: {
            label: "Description",
            property: "description",
            visible: false,
            enabled: false,
            locked: false,
        },
        updated: {
            label: "Last Updated",
            property: "updated",
            visible: false,
            enabled: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date = timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions);
            },
            locked: false,
        },
        created: {
            label: "Created",
            property: "created",
            visible: false,
            enabled: false,
            parseFunction: (value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const timestamp = require("unix-timestamp");
                const date = timestamp.toDate(value) as Date;
                const dateDisplayOptions: DateTimeFormatOptions = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    minute: "numeric",
                    hour: "numeric",
                };
                return date.toLocaleString("en-US", dateDisplayOptions);
            },
            locked: false,
            defaultOff: true,
        },
    };

    private actions: { [id: string]: TableAction } = {
        edit: {
            id: "edit",
            label: "Edit",
            icon: TableIcons.edit,
            active: false,
            enabled: false,
            locked: false,
        },
        copy: {
            id: "copy",
            label: "Copy",
            icon: TableIcons.copy,
            active: false,
            blocked: true,
            enabled: false,
            locked: false,
        },
        paste: {
            id: "paste",
            label: "Paste",
            icon: TableIcons.paste,
            active: false,
            blocked: true,
            enabled: false,
            locked: false,
        },
        merge: {
            id: "merge",
            label: "Merge",
            icon: TableIcons.merge,
            active: false,
            blocked: true,
            enabled: false,
            locked: false,
        },
        checkpoint: {
            id: "checkpoint",
            label: "Checkpoint",
            icon: TableIcons.checkpoint,
            active: false,
            blocked: true,
            enabled: false,
            locked: false,
        },
        archive: {
            id: "archive",
            label: "Archive",
            icon: TableIcons.archive,
            active: false,
            blocked: true,
            enabled: false,
            locked: false,
        },
        delete: {
            id: "delete",
            label: "Delete",
            icon: TableIcons.delete,
            active: false,
            multi: true,
            enabled: false,
            locked: false,
        },
        users: {
            id: "users",
            label: "Manage Users",
            icon: TableIcons.users,
            active: false,
            enabled: false,
            locked: false,
        },
    };

    private tools: { [id: string]: TableAction } = {
        new: {
            id: "new",
            label: "New",
            icon: TableIcons.new,
            active: false,
            enabled: false,
            locked: false,
        },
    };

    get selectedId(): number | null {
        if (this.selected.length) {
            return this.selected[0];
        } else {
            return null;
        }
    }

    get selectedWorkspaces(): Workspace[] {
        if (this.selected.length) {
            return this.selected.map((id) => workspacesModule.workspaces[id]);
        } else {
            return [];
        }
    }

    get selectedWorkspace(): Workspace | null {
        if (this.selectedWorkspaces.length) {
            return this.selectedWorkspaces[0];
        } else {
            return null;
        }
    }

    get pageLoading(): boolean {
        return workspacesModule.dataLoading;
    }

    get workspaces(): Workspace[] {
        return workspacesModule.workspaceList;
    }

    get workspacesList(): Workspace[] {
        if (this.searchTerm && this.searchTerm.length) {
            return this.workspaces.filter((workspace) => {
                return workspace.name
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase());
            });
        } else {
            return this.workspaces;
        }
    }

    private async selectWorkspace(workspace: Workspace | null): Promise<void> {
        await workspacesModule.selectWorkspace(workspace ? workspace.id : null);
        if (userModule.currentUserId && workspace) {
            localStorage.setItem(
                "viewpoint-settings",
                JSON.stringify({
                    user_id: userModule.currentUserId,
                    workspace_id: workspace.id,
                })
            );
        }
        if (this.frameworkRedirect && workspace) {
            this.$router.push(this.urls.DECISIONS);
        }
    }

    private editWorkspace(workspace: Workspace): void {
        if (workspace) {
            this.selected = [workspace.id];
            this.openEdit();
        }
    }

    private openEdit(): void {
        this.settingsMode = false;
        this.sideTrigger = `edit-workspace`;
    }

    private toggleUser(workspace: Workspace): void {
        if (workspace) {
            this.selected = [workspace.id];
            this.manageUserOpen = true;
        }
    }

    private toggleDelete(workspace: Workspace | null): void {
        if (workspace) {
            this.selected = [workspace.id];
            this.deleteOpen = true;
        }
    }

    private async deleteWorkspace(): Promise<void> {
        if (this.selectedWorkspaces.length) {
            this.loading = true;
            await Promise.all(
                this.selectedWorkspaces.map(async (workspace) => {
                    if (workspace) {
                        await workspacesModule.deleteWorkspace(workspace.id);
                    }
                })
            );
            this.deleteOpen = false;
            this.loading = false;
        }
    }

    private createNew(): void {
        this.selected = [];
        this.settingsMode = false;
        this.sideTrigger = "new";
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "new":
                this.createNew();
                break;
            case "edit":
                this.openEdit();
                break;
            case "delete":
                this.deleteOpen = true;
                break;
            case "users":
                this.manageUserOpen = true;
                break;
        }
    }
}

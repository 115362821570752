
import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { App, Tab } from "@/graphql/API";
import Apps from "@/store/modules/Apps";
import Workspaces from "@/store/modules/Workspaces";
import Decisions from "@/store/modules/Decisions";
import { TabSettings } from "@/graphql/API";
import CompareView from "@/views/CompareView.vue";
import ChoiceEditor from "@/views/ChoiceEditor.vue";
import Logo from "@/components/ui/Logo.vue";

const appsModule = getModule(Apps);
const decisionsModule = getModule(Decisions);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {
        CompareView,
        ChoiceEditor,
        Logo,
    },
})
export default class TabError extends Vue {
    @Prop()
    tabSettings!: Tab;

    @Prop()
    app!: App;

    get workspaceLoading(): boolean {
        return workspaceModule.dataLoading;
    }

    get errorMessage(): string {
        if (this.app) {
            return "Tab Not Found";
        } else {
            return "App Not Found";
        }
    }

    private setAppManager(): void {
        this.$router.push({
            name: "AppManager",
            params: {},
        });
    }
}

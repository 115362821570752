var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
        _vm.indexes['rows'] != '' &&
        _vm.indexes['columns'] != '' &&
        _vm.indexes['data'] != '' &&
        _vm.values
    )?_c('div',{staticClass:"vp-compare__table"},_vm._l((_vm.rowItems),function(rowItem,index){return _c('div',{key:index},[(
                _vm.indexes['rows'] == 'factors' &&
                rowItem &&
                (rowItem.is_group || rowItem.is_table)
            )?_c('v-expansion-panels',{staticClass:"vp-accordion vp-accordion--btn-left vp-accordion--plain vp-compare-factor-group",class:`vp-compare-factor-group-${_vm.depth}`,attrs:{"accordion":"","flat":"","hover":"","focusable":"","multiple":""},model:{value:(_vm.open[rowItem.id]),callback:function ($$v) {_vm.$set(_vm.open, rowItem.id, $$v)},expression:"open[rowItem.id]"}},[_c('v-expansion-panel',{ref:`factor-${rowItem.id}`,refInFor:true},[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return undefined},proxy:true}],null,true)},[_c('CompareRow',{attrs:{"custom-class":`vp-group-row vp-group-row-${_vm.depth}`,"depth":_vm.depth,"shadow":_vm.depth > 0 && index == 0}},[_c('CompareColumnMain',{attrs:{"main-width":_vm.mainWidth,"depth":_vm.depth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"show-btns":_vm.showViewpointCell,"group":"","lines":_vm.lines,"last":index == _vm.rowItems.length - 1},scopedSlots:_vm._u([(_vm.showViewpointCell)?{key:"buttons",fn:function(){return [(_vm.scoreDisplay.weights)?_c('WeightCounterMenu',{attrs:{"indexes":_vm.indexes,"row-item":rowItem,"data-item":_vm.dataItem,"editable":_vm.weightsEditable,"main-column":""}}):_vm._e(),(_vm.scoreRule)?[(_vm.choicesFactors)?_c('v-btn',{staticClass:"vp-compare__score-btn",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectFactor(rowItem, _vm.dataItem)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-map-marker-distance ")])],1):_vm._e()]:_vm._e()]},proxy:true}:null],null,true)},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"vp-accordion__expand-icon"},[_vm._v(" mdi-menu-right ")]),_c('div',[_vm._v(" "+_vm._s(rowItem.name)+" ")])],1),(
                                    _vm.showDescription && rowItem.description
                                )?_c('div',{staticClass:"vp-compare__factor__description"},[_c('span',{domProps:{"innerHTML":_vm._s(rowItem.description)}})]):_vm._e()]),_vm._l((_vm.displayValues[
                                _vm.indexes['columns']
                            ]),function(columnItem,colIndex){return _c('CompareColumn',{key:colIndex,attrs:{"column-width":_vm.columnWidth,"full":_vm.fullColumn &&
                                _vm.displayValues[_vm.indexes['columns']] &&
                                _vm.displayValues[_vm.indexes['columns']].length ==
                                    1,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY}},[_c('CompareCell',{attrs:{"indexes":_vm.indexes,"column-item":columnItem,"row-item":rowItem,"data-item":_vm.dataItem,"choice-editable":_vm.choiceEditable,"factor-display":_vm.factorDisplay,"scores-editable":_vm.scoresEditable,"score-rule":_vm.scoreRule,"custom-score":_vm.customScore,"weights-editable":_vm.weightsEditable,"class-rating":_vm.classRating,"score-display":_vm.scoreDisplay,"selector-settings":_vm.selectorSettings,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY},on:{"factor-select":function($event){return _vm.$emit('factor-select', $event)}}})],1)})],2)],1),_c('v-expansion-panel-content',[(rowItem.is_group)?_c('CompareRecursive',{attrs:{"indexes":_vm.indexes,"depth":_vm.depth + 1,"values":_vm.values,"data-item":_vm.dataItem,"factor-root":rowItem.id,"main-width":_vm.mainWidth,"column-width":_vm.columnWidth,"choice-editable":_vm.choiceEditable,"scores-editable":_vm.scoresEditable,"weights-editable":_vm.weightsEditable,"class-rating":_vm.classRating,"custom-score":_vm.customScore,"score-rule":_vm.scoreRule,"score-display":_vm.scoreDisplay,"selector-settings":_vm.selectorSettings,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"full-column":_vm.fullColumn,"factor-display":_vm.factorDisplay,"border-y":_vm.borderY,"group-open":_vm.open[rowItem.id] && _vm.open[rowItem.id].length > 0,"lines":index == _vm.rowItems.length - 1
                                ? [..._vm.lines, _vm.depth]
                                : _vm.lines,"parent-open":_vm.groupActive,"factor-selection":_vm.factorSelection,"hidden-factors":_vm.hiddenFactors,"decision-id":_vm.decisionId},on:{"factor-select":function($event){return _vm.$emit('factor-select', $event)},"toggle-charts":function($event){return _vm.$emit('toggle-charts', $event)}}}):(rowItem.is_table)?_c('CompareTable',{attrs:{"indexes":_vm.indexes,"depth":_vm.depth + 1,"values":_vm.values,"data-item":_vm.dataItem,"factor-root":rowItem.id,"main-width":_vm.mainWidth,"column-width":_vm.columnWidth,"choice-editable":_vm.choiceEditable,"scores-editable":_vm.scoresEditable,"weights-editable":_vm.weightsEditable,"class-rating":_vm.classRating,"custom-score":_vm.customScore,"score-rule":_vm.scoreRule,"score-display":_vm.scoreDisplay,"selector-settings":_vm.selectorSettings,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"full-column":_vm.fullColumn,"factor-display":_vm.factorDisplay,"factor-selection":_vm.factorSelection,"hidden-factors":_vm.hiddenFactors,"group-open":_vm.open[rowItem.id] && _vm.open[rowItem.id].length > 0,"lines":index == _vm.rowItems.length - 1
                                ? [..._vm.lines, _vm.depth]
                                : _vm.lines,"parent-open":_vm.groupActive},on:{"factor-select":function($event){return _vm.$emit('factor-select', $event)}}}):_vm._e()],1)],1)],1):_c('CompareRow',{attrs:{"shadow":_vm.depth > 0 && index == 0}},[_c('CompareColumnMain',{attrs:{"main-width":_vm.mainWidth,"depth":_vm.depth,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY,"lines":_vm.lines,"show-btns":_vm.showViewpointCell,"last":index == _vm.rowItems.length - 1,"show-content":_vm.choiceDataMain && _vm.scoreDisplay.choice_data},scopedSlots:_vm._u([(!(rowItem.is_group || rowItem.is_table))?{key:"content",fn:function(){return [(rowItem && _vm.dataItem && _vm.choiceDataMain)?[(_vm.factorsChoice)?_c('FactorValueDisplay',{attrs:{"factor":rowItem,"showName":false,"factor-display":_vm.factorDisplay,"editable":_vm.choiceEditable,"choice-id":_vm.dataItem.id,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"isThumbnailDisplay":true,"autoSaveMedia":true,"hide-desc":true,"value":_vm.choiceValues[rowItem.id]
                                    ? _vm.choiceValues[rowItem.id]
                                    : {}}}):_c('FactorValueDisplay',{key:`${rowItem.id}-${_vm.dataItem.id}`,attrs:{"factor":_vm.dataItem,"showName":false,"factor-display":_vm.factorDisplay,"editable":_vm.choiceEditable,"choice-id":rowItem.id,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"isThumbnailDisplay":true,"autoSaveMedia":true,"hide-desc":true,"value":_vm.allChoiceValues[rowItem.id] &&
                                _vm.allChoiceValues[rowItem.id][_vm.dataItem.id]
                                    ? _vm.allChoiceValues[rowItem.id] &&
                                      _vm.allChoiceValues[rowItem.id][
                                          _vm.dataItem.id
                                      ]
                                    : {}}})]:_vm._e()]},proxy:true}:null,(_vm.showViewpointCell)?{key:"buttons",fn:function(){return [(_vm.scoreDisplay.weights)?_c('WeightCounterMenu',{attrs:{"indexes":_vm.indexes,"row-item":rowItem,"data-item":_vm.dataItem,"editable":_vm.weightsEditable,"main-column":""}}):_vm._e(),(_vm.scoreRule)?[(_vm.choicesFactors)?_c('v-btn',{staticClass:"vp-compare__score-btn",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectFactor(rowItem, _vm.dataItem)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-map-marker-distance ")])],1):_vm._e(),(_vm.choicesViewpoints)?_c('v-btn',{staticClass:"vp-compare__score-btn",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectFactor(_vm.dataItem, rowItem)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-map-marker-distance ")])],1):_vm._e()]:_vm._e()]},proxy:true}:null],null,true)},[(
                        _vm.indexes['rows'] != 'factors' && rowItem.description
                    )?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'right' }),expression:"{ placement: 'right' }"}],attrs:{"content":rowItem.description}},[_vm._v(" "+_vm._s(rowItem.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(rowItem.name)+" ")]),(_vm.showDescription && rowItem.description)?_c('div',{staticClass:"vp-compare__factor__description"},[_c('span',{domProps:{"innerHTML":_vm._s(rowItem.description)}})]):_vm._e()]),_vm._l((_vm.displayValues[
                    _vm.indexes['columns']
                ]),function(columnItem,colIndex){return _c('CompareColumn',{key:colIndex,attrs:{"column-width":_vm.columnWidth,"full":_vm.fullColumn &&
                    _vm.displayValues[_vm.indexes['columns']] &&
                    _vm.displayValues[_vm.indexes['columns']].length == 1,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"border-x":_vm.borderX,"border-y":_vm.borderY}},[_c('CompareCell',{attrs:{"indexes":_vm.indexes,"column-item":columnItem,"row-item":rowItem,"data-item":_vm.dataItem,"choice-editable":_vm.choiceEditable,"factor-display":_vm.factorDisplay,"scores-editable":_vm.scoresEditable,"custom-score":_vm.customScore,"weights-editable":_vm.weightsEditable,"class-rating":_vm.classRating,"score-rule":_vm.scoreRule,"score-display":_vm.scoreDisplay,"selector-settings":_vm.selectorSettings,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY},on:{"factor-select":function($event){return _vm.$emit('factor-select', $event)}}})],1)})],2)],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Workspace, Decision } from "@/graphql/API";
import Workspaces from "@/store/modules/Workspaces";
import Decisions from "@/store/modules/Decisions";
import { Urls } from "@/Urls";

const workspacesModule = getModule(Workspaces);
const decisionsModule = getModule(Decisions);

@Component({
    components: {},
})
export default class NavigationQuickLinks extends Vue {
    private readonly URLS = Urls;

    get selectedWorkspace(): Workspace | null {
        return workspacesModule.selectedWorkspace;
    }
    get selectedDecision(): Decision | null {
        return decisionsModule.selectedDecision;
    }

    private goToUrl(url: string) {
        if (this.$route.path !== url) this.$router.push({ path: url });
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import TableBase from "@/components/mixins/TableBase";
import Domains from "@/store/modules/Domains";
import FlashNotifications from "@/store/modules/FlashNotifications";
import { Domain, TableHeader, TableAction, Unit } from "@/graphql/API";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import UnitCreator from "@/components/domains/units/UnitCreator.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import TableManagerView from "@/components/managers/TableManagerView.vue";

const domainModule = getModule(Domains);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    components: {
        ViewWrapper,
        UnitCreator,
        VpDialog,
        TableManager,
        TableManagerTools,
        TableManagerSettings,
        TableManagerView,
    },
})
export default class UnitView extends mixins(TableBase) {
    private searchTerm = "";
    private deleteOpen = false;

    private selected: number[] = [];
    private settingsMode = false;

    private sideTrigger = "settings";
    private headers: { [id: string]: TableHeader } = {
        name: {
            label: "Name",
            property: "name",
            visible: false,
            required: true,
            enabled: false,
            locked: false,
        },
        abrv: {
            label: "Unit",
            property: "abrv",
            visible: false,
            enabled: false,
            locked: false,
        },
    };

    private tools: { [id: string]: TableAction } = {
        new: {
            id: "new",
            label: "New",
            icon: "mdi-plus",
            active: false,
            enabled: false,
            locked: false,
        },
    };

    private actions: { [id: string]: TableAction } = {
        edit: {
            id: "edit",
            label: "Edit",
            icon: "mdi-pencil",
            active: false,
            enabled: false,
            locked: false,
        },
        delete: {
            id: "delete",
            label: "Delete",
            icon: "mdi-delete",
            active: false,
            enabled: false,
            locked: false,
        },
    };

    get pageLoading(): boolean {
        return domainModule.dataLoading;
    }

    get selectedId(): number | null {
        if (this.selected.length) {
            return this.selected[0];
        } else {
            return null;
        }
    }

    get selectedUnit(): Unit | null {
        if (this.selectedId && domainModule.units[this.selectedId]) {
            return domainModule.units[this.selectedId];
        } else {
            return null;
        }
    }

    get domains(): { [id: number]: Domain } {
        return domainModule.domains;
    }

    get units(): Unit[] {
        return domainModule.unitsList;
    }

    get unitsList(): Unit[] {
        if (this.searchTerm != "" && this.searchTerm != null) {
            return this.units.filter((unit: Unit) => {
                if (
                    unit.name
                        .toLowerCase()
                        .includes(this.searchTerm.toLowerCase())
                ) {
                    return true;
                } else {
                    return unit.abrv
                        .toLowerCase()
                        .includes(this.searchTerm.toLowerCase());
                }
            });
        } else {
            return this.units;
        }
    }

    private editUnit(unit: Unit): void {
        if (unit) {
            this.selected = [unit.id];
            this.settingsMode = false;
            this.sideTrigger = `edit-${unit.id}`;
        }
    }

    private toggleDelete(unit: Unit): void {
        if (unit) {
            this.selected = [unit.id];
            this.deleteOpen = true;
        }
    }

    private createNew(): void {
        this.selected = [];
        this.settingsMode = false;
        this.sideTrigger = "new";
    }

    private async deleteUnit(): Promise<void> {
        if (this.selectedUnit) {
            try {
                const msg = `${this.selectedUnit.name} was successfully deleted`;
                await domainModule.deleteUnit({
                    unit_id: this.selectedUnit.id,
                });
                flashNotificationsModule.success({
                    message: msg,
                    duration: 3000,
                });
            } catch (e) {
                flashNotificationsModule.error({
                    message: "Something went wrong. Please try again",
                    duration: 3000,
                });
            }
        }
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "new":
                this.createNew();
                break;
            case "edit":
                if (this.selectedUnit) {
                    this.editUnit(this.selectedUnit);
                }
                break;
            case "delete":
                if (this.selectedUnit) {
                    this.toggleDelete(this.selectedUnit);
                }
                this.deleteOpen = true;
                break;
        }
    }
}


import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { App, Tab } from "@/graphql/API";
import Apps from "@/store/modules/Apps";
import Decisions from "@/store/modules/Decisions";
import { TabSettings } from "@/graphql/API";
import CompareView from "@/views/CompareView.vue";
import ChoiceEditor from "@/views/ChoiceEditor.vue";
import TabError from "@/components/apps/tabs/TabError.vue";
import TabLoading from "@/components/apps/tabs/TabLoading.vue";
import RankView from "@/views/RankView.vue";
import ModelView from "@/views/ModelView.vue";
import ChoicesView from "@/views/ChoicesView.vue";
import ScoreClassTable from "@/views/ScoreClassTable.vue";
import ViewpointsView from "@/views/ViewpointsView.vue";
import UnitView from "@/views/UnitView.vue";
import UsersView from "@/views/UsersView.vue";

const appsModule = getModule(Apps);
const decisionsModule = getModule(Decisions);

@Component({
    components: {
        CompareView,
        ChoiceEditor,
        TabError,
        RankView,
        ModelView,
        TabLoading,
        ChoicesView,
        ScoreClassTable,
        ViewpointsView,
        UnitView,
        UsersView,
    },
})
export default class TabEditor extends Vue {
    @Prop()
    tab!: Tab;

    @Prop()
    app!: App;

    @PropSync("routeLock")
    syncedRouteLock!: boolean;

    @PropSync("routeConfirmation")
    syncedRouteConfirmation!: boolean;

    private loading = false;

    get appsLoading(): boolean {
        return appsModule.appsLoading;
    }

    get tabId(): number | null {
        return this.tab ? this.tab.id : null;
    }

    get compoenntName(): string {
        if (this.loading || this.appsLoading) {
            return "TabLoading";
        } else {
            return this.tab ? this.tab.type : "TabError";
        }
    }

    get currentDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get tabDecisionId(): number | null {
        return this.tab ? this.tab.decision_id : null;
    }

    mounted(): void {
        this.onTabDecisionIdChange();
        this.onTabChange();
    }

    @Watch("tabId")
    async onTabChange(): Promise<void> {
        if (this.tabId) {
            this.loading = true;
            await Promise.all([
                appsModule.getTabFactors(this.tabId),
                appsModule.getTabChoices(this.tabId),
                appsModule.getTabViewpoints(this.tabId),
            ]);
            this.loading = false;
        }
    }

    @Watch("tabDecisionId")
    async onTabDecisionIdChange(): Promise<void> {
        if (this.tabDecisionId) {
            if (this.tabDecisionId != this.currentDecisionId) {
                decisionsModule.selectDecision(this.tabDecisionId);
            }
        }
    }

}

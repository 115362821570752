
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { App, Tab, TableHeader, TableAction, AppTabMapping } from "@/graphql/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Apps from "@/store/modules/Apps";
import FlashNotifications from "@/store/modules/FlashNotifications";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import TabCreator from "@/components/apps/tabs/TabCreator.vue";
import AppTabDeletor from "@/components/apps/AppTabDeletor.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import { Urls } from "@/Urls";
import TableManagerView from "@/components/managers/TableManagerView.vue";

const appsModule = getModule(Apps);
const flashModule = getModule(FlashNotifications);

@Component({
    components: {
        ViewWrapper,
        TableManager,
        TableManagerTools,
        TableManagerSettings,
        TabCreator,
        AppTabDeletor,
        VpDialog,
        TableManagerView,
    },
})
export default class TabsView extends Vue {
    private selected: number[] = [];
    private settingsMode = true;
    private sideTrigger = "settings";
    private loading = false;
    private searchTerm = "";

    private deletedTab: Tab | null = null;
    private deleteOpen = false;

    private headers: { [id: string]: TableHeader } = {
        name: {
            label: "Name",
            property: "title",
            visible: true,
            required: true,
            enabled: true,
            locked: false,
        },
        description: {
            label: "Description",
            property: "description",
            visible: false,
            enabled: false,
            locked: false,
        },
        type: {
            label: "Type",
            property: "type",
            visible: false,
            enabled: false,
            locked: false,
        },
    };

    private actions: { [id: string]: TableAction } = {
        link: {
            id: "link",
            label: "Copy Link",
            icon: "mdi-link",
            active: false,
            enabled: false,
            locked: false,
        },
        edit: {
            id: "edit",
            label: "Edit",
            icon: "mdi-cog",
            active: false,
            enabled: false,
            locked: false,
        },
        configure: {
            id: "configure",
            label: "Configure",
            icon: "mdi-pencil",
            active: false,
            enabled: false,
            locked: false,
        },
        launch: {
            id: "launch",
            label: "Launch",
            icon: "mdi-open-in-new",
            active: false,
            enabled: false,
            locked: false,
        },
        delete: {
            id: "delete",
            label: "Delete",
            icon: "mdi-delete",
            active: false,
            enabled: false,
            multi: true,
            locked: false,
        },
    };

    private tools: { [id: string]: TableAction } = {
        new: {
            id: "new",
            label: "New",
            icon: "mdi-plus",
            active: false,
            enabled: false,
            locked: false,
        },
    };

    /* Start of tab refactor */
    get tabTypes(): {
        [id: string]: { name: string; value: string; table?: boolean };
    } {
        return appsModule.tabTypes;
    }

    get selectedAppId(): number | null {
        return appsModule.selectedAppId;
    }

    get selectedApp(): App | null {
        return appsModule.selectedApp;
    }

    get appTabMappings(): AppTabMapping[] {
        if (this.selectedAppId) {
            return Object.values(appsModule.appTabMappings[this.selectedAppId]);
        } else {
            return [];
        }
    }

    get allKeyedTabs(): { [tabId: number]: Tab } {
        return appsModule.keyedTabs;
    }

    get tabs(): Tab[] {
        return this.appTabMappings
            .map((appMap) => this.allKeyedTabs[appMap.tab_id])
            .filter((tab) => tab != undefined);
    }

    get keyedTabs(): { [id: number]: Tab } {
        return this.tabs.reduce((acc, a) => {
            return {
                ...acc,
                [a.id]: a,
            };
        }, {});
    }

    get tabsOrdered(): Tab[] {
        return this.tabs.sort((a, b) => {
            if (a.order_str && b.order_str) {
                return a.order_str.localeCompare(b.order_str);
            } else if (a.order_str && !b.order_str) {
                return -1;
            } else if (!a.order_str && b.order_str) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    get tabsList(): Tab[] {
        if (this.searchTerm && this.searchTerm.length) {
            return this.tabsOrdered.filter((tab) => {
                return tab.title
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase());
            });
        } else {
            return this.tabsOrdered;
        }
    }

    /* End of tab refactor */

    /* Tab Selection */


    get selectedTabs(): Tab[] {
        if (this.selected.length) {
            return this.selected.map((id) => this.keyedTabs[id]);
        } else {
            return [];
        }
    }

    get selectedTab(): Tab | null {
        if (this.selectedTabs.length) {
            return this.selectedTabs[0];
        } else {
            return null;
        }
    }

    /* End of Tab Selection */

    private copyLink(tab: Tab): void {
        if (tab && this.selectedApp) {
            navigator.clipboard.writeText(
                `${window.location.host}${Urls.APPS}/${this.selectedApp.id}/${tab.id}`
            );
            flashModule.success({
                message: "Link Copied",
                duration: 3000,
            });
        }
    }

    private editTab(tab: Tab): void {
        if (tab) {
            this.selected = [tab.id];
            this.sideTrigger = `edit-${tab.id}`;
            this.settingsMode = false;
        }
    }

    private launchTab(tab: Tab): void {
        if (tab && this.selectedApp) {
            let link = this.$router.resolve({
                path: `${Urls.APPS}/${this.selectedApp.id}/${tab.id}`,
            });
            window.open(link.href, "_self");
        }
    }

    private deleteTab(tab: Tab): void {
        if (tab) {
            this.deletedTab = { ...tab };
            this.deleteOpen = true;
        }
    }

    private async removeTabs(): Promise<void> {
        if (this.selectedTabs.length) {
            await Promise.all(
                this.selectedTabs.map(async (tab) => {
                    if (tab) {
                        await appsModule.deleteTab(tab.id);
                    }
                })
            );
            this.selected = [];
            this.deleteOpen = false;
        }
    }

    private configureTab(tab: Tab): void {
        if (tab && this.selectedApp) {
            this.$router.push({
                name: "TabEditorView",
                params: {
                    appId: this.selectedApp.id.toString(),
                    tabId: tab.id.toString(),
                },
            });
        }
    }

    private createNew(): void {
        this.selected = [];
        this.sideTrigger = "new";
        this.settingsMode = false;
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "link":
                if (this.selectedTab) {
                    this.copyLink(this.selectedTab);
                }
                break;
            case "new":
                this.createNew();
                break;
            case "edit":
                this.sideTrigger = "edit-tab";
                break;
            case "delete":
                if (this.selectedTab) {
                    this.deleteTab(this.selectedTab);
                }
                break;
            case "launch":
                if (this.selectedTab) {
                    this.launchTab(this.selectedTab);
                }
                break;
            case "configure":
                if (this.selectedTab) {
                    this.configureTab(this.selectedTab);
                }
                break;
        }
    }
}

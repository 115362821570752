
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { App, Tab } from "@/graphql/API";
import { mixins } from "vue-class-component";
import AppUrl from "@/components/mixins/AppUrl";

@Component({
    components: {},
})
export default class AppEditor extends mixins(AppUrl) {
    @Prop()
    app!: App;

    get appId(): number | null {
        return this.app ? this.app.id : null;
    }

    get tabs(): Tab[] {
        if (this.appId && this.appTabsIndex[this.appId]) {
            return this.appTabsIndex[this.appId];
        } else {
            return [];
        }
    }

    private setTab(id: number): void {
        if (this.app) {
            this.$router.push({
                name: "AppView",
                params: {
                    appId: this.app.id.toString(),
                    tabId: id.toString(),
                },
            });
        }
    }
    private showTooltip(tab: Tab): boolean {
        return tab.description ? true : false;
    }
}

import { render, staticRenderFns } from "./ChartMainVp.vue?vue&type=template&id=b89a34c0&"
import script from "./ChartMainVp.vue?vue&type=script&lang=ts&"
export * from "./ChartMainVp.vue?vue&type=script&lang=ts&"
import style0 from "./ChartMainVp.vue?vue&type=style&index=0&id=b89a34c0&prod&scope=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
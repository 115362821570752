
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Decision,
    Permission,
    PermissionIdInput,
    UserPermission,
    Workspace,
} from "@/graphql/API";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import Workspaces from "@/store/modules/Workspaces";
import FlashNotifications from "@/store/modules/FlashNotifications";
import UsersModule from "@/store/modules/Users";
import { UpdatePermissionInput } from "@/graphql/custom";
import PermissionsEditor from "./PermissionsEditor.vue";

const usersModule = getModule(UsersModule);
const flashNotificationsModule = getModule(FlashNotifications);
@Component({
    components: {
        DialogCardTitle,
        PermissionsEditor,
    },
})
export default class UserEditor extends Vue {
    @Prop({ default: false, type: Boolean })
    edit!: boolean;

    @Prop({ default: null, type: Number })
    workspaceId!: number;

    @Prop()
    user!: UserPermission;

    private open = false;
    private userName = "";
    private formValid = false;
    private destWorkspaceId: number | null = null;
    private loading = false;
    private permissions: Permission | undefined = undefined;

    private permissionOptions: { text: string; id: string }[] = [
        {
            text: "Read",
            id: "read",
        },
        {
            text: "Write",
            id: "write",
        },
        {
            text: "Admin",
            id: "admin",
        },
    ];

    get objectPermissions(): { text: string; id: string }[] {
        return this.permissionOptions.filter(
            (permission) => permission.id != "admin"
        );
    }

    get title(): string {
        if (this.user) {
            return `Edit User Permissions`;
        } else {
            return "Add User";
        }
    }

    get currentUser(): string | null {
        return usersModule.currentUserId;
    }

    @Watch("edit")
    onEditChange(): void {
        this.onUserChange();
    }

    @Watch("user", { immediate: true, deep: true })
    async onUserChange(): Promise<void> {
        if (this.user != null) {
            this.userName = `${
                this.user.user.first ? this.user.user.first : ""
            } ${this.user.user.last ? this.user.user.last : ""}`;
            this.permissions = this.user.permission;
        } else {
            this.userName = "";
        }
    }

    private changePermission(permission: Permission): void {
        this.permissions = permission;
    }

    private async saveUserPermissions(): Promise<void> {
        const userIdInput: PermissionIdInput = {
            user_id: this.user.user.id,
            workspace_id: this.workspaceId,
        };
        const permissionsUpdateInput = this.permissions;
        if (permissionsUpdateInput) {
            const userPermission = await usersModule.updatePermissions({
                id: userIdInput,
                input: permissionsUpdateInput,
            });
            if (userPermission) {
                let newUserPermission: UserPermission = {
                    user: this.user.user,
                    permission: userPermission,
                };
                this.$emit("permission-updated", newUserPermission);
            }
        }
    }
}

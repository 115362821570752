
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
    name: "VideoPreview",
    components: {},
})
export default class VideoPreview extends Vue {
    @Prop()
    path!: string;

    @Prop({ type: String, default: "File" })
    name!: string;

    @Prop({ type: Boolean, default: false })
    isFactorTable!: boolean;

    @Prop({ type: String, default: "140px" })
    maxWidth!: string;

    @Prop({ type: String, default: "140px" })
    maxHeight!: string;

    private defaultStyles = "height: auto; width: auto; ";

    get styles(): string {
        if (this.isFactorTable) {
            return (
                this.defaultStyles +
                `max-height: 10vw; max-width: ${this.maxWidth}`
            );
        } else {
            return (
                this.defaultStyles +
                `max-height: ${this.maxHeight}; max-width: ${this.maxWidth}`
            );
        }
    }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Viewpoint,
    Choice,
    ChartValues,
    ViewpointMapping,
} from "@/graphql/API";
import BarChart from "@/components/charts/BarChart.vue";
import StackedChart from "@/components/charts/StackedChart.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import Scoring from "@/store/modules/Scoring";
import WorkspaceOptions from "@/store/modules/WorkspaceOptions";
import Viewpoints from "@/store/modules/Viewpoints";

const workspaceOptions = getModule(WorkspaceOptions);
const scoringModule = getModule(Scoring);

@Component({
    components: {
        BarChart,
        VpDialog,
        StackedChart,
    },
})
export default class ChartFv extends Vue {
    @Prop()
    chartDataValue!: ChartValues;

    @Prop()
    choices!: Choice[];

    @Prop({ default: () => ({}), type: Array })
    viewpoints!: Viewpoint[];

    @Prop()
    selectedFactor!: Factor;

    // @Prop({ default: () => {[]}, type: Array })
    // factors!: Factor[];

    @Prop()
    selectedViewpoint!: Viewpoint;

    // @Prop()
    // selectedChoice!: Choice;

    @Prop({ default: false, type: Boolean })
    isBarChartHorizontal!: boolean;

    get viewpointMappings(): {
        [viewpointId: number]: {
            [factorId: number]: ViewpointMapping;
        };
    } {
        return getModule(Viewpoints).viewpointMappings;
    }

    get barChartData() {
        if (this.selectedViewpoint && this.selectedFactor) {
        return {
            data: {
                series: this.choices.map((choice) => {
                    return {
                        id: choice.id.toString(),
                        name: choice.name,
                        data: [this.getScore(this.selectedViewpoint.id, choice.id, this.selectedFactor.id)],
                    }
                }),
            },
            title: "Factor",
            xaxis: {
                categories: [this.selectedFactor ? this.selectedFactor.name : "Factor"],
            },
        };
        }
    }

    private roundNumber(value: number, digits: number): number {
        return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
    }

    private getScore(
        viewpointId: number,
        choiceId: number,
        factorId: number
    ): number {
        const score =
            scoringModule.keyedScores[`${viewpointId}-${choiceId}-${factorId}`];

        // console.log(`${viewpointId}-${choiceId}-${factorId}`);
        // console.log(score);
        if (
            score &&
            this.viewpointMappings[viewpointId] &&
            this.viewpointMappings[viewpointId][factorId]
        ) {
            console.log(`VP Map: ${viewpointId}-${choiceId}-${factorId}`)
            console.log(this.viewpointMappings[viewpointId][factorId]);
            if (
                this.viewpointMappings[viewpointId][factorId].use_m_score
            ) {
                return this.roundNumber(score.m_score, 2);
            } else {
                return this.roundNumber(score.c_score, 2);
            }
        } else {
            console.log(`No VP Map ${viewpointId}-${choiceId}-${factorId}`)
            return 0;
        }
    }
}

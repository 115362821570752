
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Urls } from "@/Urls";
import AppUrl from "@/components/mixins/AppUrl";
import Decisions from "@/store/modules/Decisions";
import Workspaces from "@/store/modules/Workspaces";
import { getModule } from "vuex-module-decorators";
import Apps from "@/store/modules/Apps";
import { TabSettings } from "@/graphql/API";

const appsModule = getModule(Apps);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {},
})
export default class HeaderTabs extends mixins(AppUrl) {
    get isAppTab(): boolean {
        if (this.$route.name) {
            return (
                this.$route.name == "AppManager" ||
                this.$route.name == "TabEditorView"
            );
        }
        return false;
    }

    get workspaceLoading(): boolean {
        return workspaceModule.dataLoading;
    }

    get links(): { component: string; name: string; link: string }[][] {
        return [
            [
                {
                    component: "Model",
                    name: "Factors",
                    link: Urls.MODEL,
                },
                // {
                //     component: "Model Admin",
                //     name: "Factor Admin",
                //     link: Urls.MODEL_ADMIN,
                // },
            ],
            [
                {
                    component: "Choice",
                    name: "Choice Editor",
                    link: Urls.CHOICE,
                },
            ],
            [
                {
                    component: "ScoringClass",
                    name: "Score Classes",
                    link: Urls.SCORINGCLASS,
                },
            ],
            [
                {
                    component: "Compare",
                    name: "Compare",
                    link: Urls.COMPARE,
                },
                {
                    component: "RankView",
                    name: "Ranking",
                    link: Urls.RANK,
                },
            ],
            [
                {
                    component: "Units",
                    name: "Units",
                    link: Urls.UNITS,
                },
            ],
            [
                {
                    component: "AppManager",
                    name: "App Manager",
                    link: Urls.APPMANAGER,
                },
                {
                    component: "TabEditorView",
                    name: "Tab Editor",
                    link: Urls.TABEDITOR,
                },
            ],
        ];
    }

    get currTabName(): string {
        if (this.currTab) {
            return this.currTab.title;
        } else {
            return this.workspaceLoading ? "Loading..." : "Not Found";
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get tabsList(): any {
        if (this.$route.name) {
            return this.links.filter((list) => {
                return (
                    list.filter((item) => item.component === this.$route.name)
                        .length > 0
                );
            });
        }
        return [];
    }

    get currentPage(): string {
        if (this.$route.name && this.$route.name !== null) {
            return this.$route.name;
        } else {
            return "";
        }
    }

    //ADMIN PANEL WORKAROUND - select decisionId = -1 when going to model_admin
    private clickNavTab(toPath: string) {
        if (toPath === Urls.MODEL_ADMIN) {
            getModule(Decisions).selectDecision(-1);
        }
    }

    private setAppManager(): void {
        if (this.appIdParam || this.tabIdParam) {
            this.$router.push({
                name: "AppManager",
                params: {},
            });
        }
    }
}


import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import { Pagination } from "@/graphql/API";
@Component({
    components: {},
})
export default class ComparePagination extends Vue {
    @PropSync("pagination")
    syncedPagination!: Pagination;

    @Prop({ default: 1, type: Number })
    pages!: number;

    @Prop({ default: 200, type: Number })
    width!: number;

    @Prop()
    offset!: number;

    @Prop({ default: 0, type: Number })
    items!: number;

    @Prop({ default: 0, type: Number })
    columns!: number;

    get currentPosition(): number {
        if (this.syncedPagination) {
            return this.syncedPagination.columns.position;
        } else {
            return 0;
        }
    }

    get currentItems(): number {
        if (this.syncedPagination) {
            return this.syncedPagination.columns.items;
        } else {
            return 0;
        }
    }

    /* Returns indexes of visible ones / total number of columns */
    get currentIndex(): string {
        if (this.currentPosition + this.currentItems <= this.items) {
            return `${this.currentPosition + 1} - ${
                this.currentPosition + this.currentItems
            } of ${this.items}`;
        } else {
            return `${this.currentPosition + 1} - ${this.items} / ${
                this.items
            }`;
        }
    }

    get pageMinusDisabled(): boolean {
        return !(this.currentPosition > 0);
    }

    get pagePlusDisabled(): boolean {
        return !(this.currentPosition + this.currentItems < this.items);
    }

    private decreasePage(): void {
        if (this.currentPosition - this.currentItems > 0) {
            Vue.set(
                this.syncedPagination.columns,
                "position",
                this.currentPosition - this.currentItems
            );
        } else {
            Vue.set(this.syncedPagination.columns, "position", 0);
        }
    }

    private increasePage(): void {
        if (this.currentPosition + this.currentItems < this.items) {
            Vue.set(
                this.syncedPagination.columns,
                "position",
                this.currentPosition + this.currentItems
            );
        } else {
            Vue.set(
                this.syncedPagination.columns,
                "position",
                this.items - this.currentItems
            );
        }
    }
}

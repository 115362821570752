var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-table-manager-tools"},[_c('v-btn',{staticClass:"mr-1",class:{ 'vp-btn--icon-primary': _vm.syncedCheckBoxes },attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.syncedCheckBoxes = !_vm.syncedCheckBoxes}}},[_c('v-icon',[_vm._v("mdi-format-list-checks")])],1),_vm._l((_vm.tools),function(tool,index){return [(tool.active)?_c('v-btn',{key:`tool-${index}`,staticClass:"mr-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.btnClick(tool.id)}}},[_c('v-icon',[_vm._v(_vm._s(tool.icon))])],1):_vm._e()]}),_vm._l((_vm.actions),function(action,index){return [(action.toolTip)?[(action.active)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'bottom' }),expression:"{ placement: 'bottom' }"}],key:`action-${index}`,staticClass:"mr-1",attrs:{"content":action.toolTip,"icon":"","small":"","disabled":_vm.numberOfSelected == 0 ||
                    (_vm.numberOfSelected > 1 && !action.multi) ||
                    action.blocked ||
                    action.disabled},on:{"click":function($event){return _vm.btnClick(action.id)}}},[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1):_vm._e()]:[(action.active)?_c('v-btn',{key:`action-${index}`,staticClass:"mr-1",attrs:{"icon":"","small":"","disabled":_vm.numberOfSelected == 0 ||
                    (_vm.numberOfSelected > 1 && !action.multi) ||
                    action.blocked ||
                    (action.disabled != null && action.disabled(_vm.selectedId))},on:{"click":function($event){return _vm.btnClick(action.id)}}},[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1):_vm._e()]]}),(_vm.showSearch)?[_c('v-text-field',{attrs:{"placeholder":"Search...","clearable":"","dense":"","hide-details":"","prepend-icon":"mdi-magnify"},model:{value:(_vm.syncedSearch),callback:function ($$v) {_vm.syncedSearch=$$v},expression:"syncedSearch"}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { User } from "@/graphql/API";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";
import {
    InviteUserInput,
    InviteUserLInput,
    PermissionInviteInput,
    UpdatePermissionInput,
    UserInviteInput,
} from "@/graphql/custom";
import PermissionsEditor from "../users/PermissionsEditor.vue";

@Component({
    components: { PermissionsEditor },
})
export default class UserEmailInput extends Vue {
    private inputEmailList = "";
    private isFormValid = false;
    private user: User | null = null;
    private timeoutId: any;
    private loading = false;
    private nameList = "";
    private permissions: UpdatePermissionInput | undefined = undefined;
    @Prop()
    errorThrown!: boolean;

    private changePermissions(permissions: UpdatePermissionInput): void {
        this.permissions = permissions;
    }

    private rules = {
        email: (value: string) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail";
        },
        required: (value: string) => {
            return (value != null && value != "") || "Name Required";
        },
    };

    private addUser(): void {
        this.loading = true;
        const emails = this.inputEmailList
            .trim()
            .split(/\r?\n/)
            .map((email) => email.trim());
        const names = this.nameList
            .trim()
            .split(/\r?\n/)
            .map((name) => name.trim());
        const users: UserInviteInput[] = [];
        if (this.permissions) {
            const permission: PermissionInviteInput = {
                ...this.permissions,
                workspace_id: -1,
            };
            for (const emailIndex in emails) {
                var re = /([a-zA-Z0-9.+_-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;

                if (
                    this.rules.email(emails[emailIndex]) &&
                    names[emailIndex] &&
                    emails[emailIndex].match(re)
                ) {
                    const emailMatches = emails[emailIndex].match(re);
                    if (emailMatches) {
                        users.push({
                            email: emailMatches[0],
                            name: names[emailIndex],
                        });
                    }
                }
            }
            let inviteUser: InviteUserLInput = {
                users: users,
                permission: permission,
            };
            this.$emit("user-email", inviteUser);
        }

        this.loading = false;
        this.clear();
    }

    private clear(): void {
        this.inputEmailList = "";
        this.nameList = "";
        if (this.$refs.form) (this.$refs.form as HTMLFormElement).reset();
    }

    @Watch("errorThrown")
    private onErrorThrown() {
        if (this.errorThrown) {
            this.clear();
            this.loading = false;
            this.$emit("error-resolved");
        }
    }
}

import {
    Action,
    config,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";

config.rawError = true;

const name = "workspaceOptions";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class WorkspaceOptions extends VuexModule {
    leftDrawer = false;
    manualScoreOverride = false;

    isBarChartHorizontal = false;

    showAppBanner = true;

    @Mutation
    toggleAppBanner(show: boolean) {
        this.showAppBanner = show;
    }

    @Mutation
    private setLeftDrawer(show: boolean) {
        this.leftDrawer = show;
    }

    @Mutation
    private setManualScoreOverride(value: boolean): void  {
        this.manualScoreOverride = value;
    }

    
    @Mutation
    setIsBarChartHorizontal(isHorizontal: boolean): void {
        this.isBarChartHorizontal = isHorizontal;
    }

    @Action
    changeLeftDrawer(show: boolean): void {
        this.setLeftDrawer(show);
    }

    @Action
    changeManualScoreOverride(value: boolean): void {
        this.setManualScoreOverride(value);
    }

    @Action
    changeAppBannerShow(value: boolean): void {
        this.toggleAppBanner(value);
    }
}

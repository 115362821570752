
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Choice,
    Viewpoint,
    ScoreOptions,
    Value,
    ScoreClassSettings,
    FactorOptions,
    ViewpointMapping,
} from "@/graphql/API";
import { createUpdateChoiceValue } from "@/helpers/ChoiceHelper";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import CompareCell from "@/components/compare/CompareCell.vue";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import CompareRow from "@/components/compare/ui/CompareRow.vue";
import CompareColumnMain from "@/components/compare/ui/CompareColumnMain.vue";
import CompareColumn from "@/components/compare/ui/CompareColumn.vue";
import draggable from "vuedraggable";
import FactorScore from "@/components/scoring/FactorScore.vue";
import { getScore } from "@/helpers/ScoreHelper";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mudder = require("mudder");

const choiceModule = getModule(Choices);
const viewpointsModule = getModule(Viewpoints);

@Component({
    components: {
        CompareCell,
        FactorValueDisplay,
        VpDialog,
        CompareRow,
        CompareColumnMain,
        CompareColumn,
        draggable,
        FactorScore,
    },
})
export default class CompareTableViewpoints extends Vue {
    @Prop()
    headers!: Factor[];

    @Prop()
    hiddenFactors!: number[];

    @Prop()
    choice!: Choice;

    @Prop()
    viewpoints!: Viewpoint[];

    @Prop()
    tableId!: number;

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoresEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop({ default: () => [], type: Array })
    lines!: number[];

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private loading = false;
    private deleteOpen = false;
    private deleteIndex: null | number = null;
    private deleteRowId: null | string = null;

    private dragList: Value[] = [];

    private drag = false;
    private reOrderActive = true;

    get choiceId(): number | null {
        return this.choice ? this.choice.id : null;
    }

    get viewpointMapping(): {
        [viewpointId: number]: { [factorId: number]: ViewpointMapping };
    } {
        return viewpointsModule.viewpointMappings;
    }

    get valueMap(): { [id: string]: Value } {
        if (this.choice) {
            return choiceModule.choiceValues[this.choice.id];
        } else {
            return {};
        }
    }

    get valueKeys(): string[] {
        if (this.valueMap) {
            return Object.keys(this.valueMap);
        } else {
            return [];
        }
    }

    get tableKeys(): string[][] {
        if (this.headers) {
            return this.headers.map((factor) => {
                return this.valueKeys.filter((item) =>
                    item.startsWith(`${factor.id}-`)
                );
            });
        } else {
            return [];
        }
    }

    get mainColumn(): Value[] {
        if (this.tableKeys && this.tableKeys.length) {
            return this.tableKeys[0]
                .map((key) => this.valueMap[key])
                .sort((a, b) => {
                    return a.row_id && b.row_id
                        ? a.row_id.localeCompare(b.row_id)
                        : 1;
                });
        } else {
            return [];
        }
    }

    get longestIndex(): number {
        if (this.columnLengths && this.columnLengths.length) {
            return this.columnLengths.indexOf(Math.max(...this.columnLengths));
        } else {
            return 0;
        }
    }

    get columnLengths(): number[] {
        if (this.tableKeys) {
            return this.tableKeys.map((column) => column.length);
        } else {
            return [];
        }
    }

    get nextRowId(): string {
        if (
            this.mainColumn &&
            this.mainColumn.length &&
            this.mainColumn[this.mainColumn.length - 1].row_id
        ) {
            return mudder.base62.mudder(
                this.mainColumn[this.mainColumn.length - 1].row_id,
                "",
                1,
                undefined,
                20
            )[0];
        } else {
            return mudder.base62.mudder("0", undefined, 1, undefined, 20)[0];
        }
    }

    private async deleteDialog(index: number, rowId: string): Promise<void> {
        this.deleteIndex = index;
        this.deleteRowId = rowId;
        this.deleteOpen = true;
    }

    private async addRow(): Promise<void> {
        this.loading = true;
        await createUpdateChoiceValue({
            choice_id: this.choice.id,
            factor_id: this.headers[this.longestIndex].id,
            row_id: this.nextRowId,
            value: "",
        });
        this.loading = false;
    }

    private async deleteRow(rowId: string): Promise<void> {
        this.loading = true;
        await Promise.all(
            this.headers.map(async (factor) => {
                if (this.valueMap[`${factor.id}-${rowId}`]) {
                    await choiceModule.deleteValueL({
                        choice_id: this.choice.id,
                        factor_id: factor.id,
                        row_id: rowId,
                    });
                }
            })
        );
        this.deleteOpen = false;
        this.loading = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private dragChange(e: any) {
        this.reOrderRow(
            e.moved.element.row_id,
            e.moved.oldIndex,
            e.moved.newIndex
        );
    }

    private async reOrderRow(
        rowId: string,
        oldIndex: number,
        newIndex: number
    ): Promise<void> {
        let beforeIndex = null;
        let afterIndex = null;

        if (newIndex == 0) {
            // Item has moved to start of list
            beforeIndex = "0";
            afterIndex = this.mainColumn[1]?.row_id;
        } else if (newIndex == this.mainColumn.length - 1) {
            // Item has moved to end of list
            beforeIndex = this.mainColumn[newIndex]?.row_id;
            afterIndex = null;
        } else if (newIndex > oldIndex) {
            // Item has moved down list
            beforeIndex = this.mainColumn[newIndex]?.row_id;
            afterIndex = this.mainColumn[newIndex + 1]?.row_id;
        } else {
            // Item has moved up list
            beforeIndex = this.mainColumn[newIndex - 1]?.row_id;
            afterIndex = this.mainColumn[newIndex]?.row_id;
        }

        const newRowId = mudder.base62.mudder(
            beforeIndex,
            afterIndex,
            1,
            undefined,
            20
        )[0];

        if (this.tableId && newRowId) {
            await choiceModule.moveRowL({
                table_id: this.tableId,
                old_row_id: rowId,
                new_row_id: newRowId,
            });
        }
    }

    private async getChoiceValues(): Promise<void> {
        if (this.choiceId && this.tableId) {
            await choiceModule.getChoiceValues({
                choice_id: this.choiceId,
                parent_id: this.tableId,
            });
        }
    }

    private async loadVpData(viewpoints: Viewpoint[]): Promise<void> {
        if (viewpoints.length && this.tableId) {
            await Promise.all(
                viewpoints.map(async (vp) => {
                    viewpointsModule.fetchViewpointMappings({
                        viewpoint_id: vp.id,
                        parent_id: this.tableId,
                    });
                    if (this.choiceId) {
                        getScore({
                            viewpoint_id: vp.id,
                            choice_id: this.choiceId,
                            factor_id: this.tableId,
                        });
                    }
                })
            );
        }
    }

    mounted(): void {
        this.onMainColumnChange();
        this.getChoiceValues();
    }

    @Watch("viewpoints", { immediate: true, deep: true })
    async onViewpointsChange(
        newVal: Viewpoint[],
        oldVal: Viewpoint[] = []
    ): Promise<void> {
        if (newVal && newVal.length) {
            this.loadVpData(newVal.filter((x) => !oldVal.includes(x)));
        }
    }

    @Watch("mainColumn", { immediate: true, deep: true })
    onMainColumnChange(): void {
        this.dragList = [...this.mainColumn];
    }

    @Watch("tableId")
    onTableIdChange(): void {
        this.getChoiceValues();
    }

    @Watch("choiceId")
    onChoiceIdChange(): void {
        this.getChoiceValues();
    }
}

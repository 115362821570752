
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ScoreClass, TableHeader, TableAction } from "@/graphql/API";
import { TableIcons } from "@/IconLibrary";
import { mixins } from "vue-class-component";
import TableBase from "@/components/mixins/TableBase";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import Scoring from "@/store/modules/Scoring";
import ScoreClassCreator from "@/components/scoring/scoreClass/ScoreClassCreator.vue";
import ScoreClassList from "@/components/scoring/scoreClass/ScoreClassList.vue";
import TableManager from "@/components/managers/TableManager.vue";
import TableManagerSettings from "@/components/managers/TableManagerSettings.vue";
import TableManagerTools from "@/components/managers/TableManagerTools.vue";
import IconDisplay from "@/components/values/IconDisplay.vue";
import IconColour from "@/components/values/IconColour.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import TableManagerView from "@/components/managers/TableManagerView.vue";

const scoringModule = getModule(Scoring);

@Component({
    components: {
        ViewWrapper,
        ScoreClassCreator,
        ScoreClassList,
        TableManager,
        TableManagerSettings,
        TableManagerTools,
        IconDisplay,
        IconColour,
        VpDialog,
        TableManagerView,
    },
})
export default class ScoreClassTable extends mixins(TableBase) {
    private settingsMode = true;
    private editorOpen = false;
    private deleteMode = false;
    private loading = false;

    private searchTerm = "";
    private sideTrigger = "settings";

    private selected: number[] = [];

    private headers: { [id: string]: TableHeader } = {
        name: {
            label: "Name",
            property: "name",
            visible: true,
            required: true,
            enabled: true,
            locked: false,
        },
        icons: {
            label: "Icons",
            property: "icons",
            visible: false,
            enabled: false,
            locked: false,
        },
        description: {
            label: "Description",
            property: "description",
            visible: false,
            enabled: false,
            locked: false,
        },
        color: {
            label: "Color",
            property: "color",
            visible: false,
            enabled: false,
            locked: false,
        },
    };

    private tools: { [id: string]: TableAction } = {
        new: {
            id: "new",
            label: "New",
            icon: TableIcons.new,
            active: false,
            enabled: false,
            locked: false,
        },
    };

    private actions: { [id: string]: TableAction } = {
        edit: {
            id: "edit",
            label: "Edit",
            icon: TableIcons.edit,
            active: false,
            enabled: false,
            locked: false,
        },
        delete: {
            id: "delete",
            label: "Delete",
            icon: TableIcons.delete,
            active: true,
            multi: false,
            enabled: false,
            locked: false,
        },
    };

    get dialogTitle(): string {
        if (this.selectedClass) {
            if (this.deleteMode) {
                return "Remove this Class";
            } else {
                return "Edit Class";
            }
        } else {
            return "Create Class";
        }
    }

    get selectedId(): number | null {
        if (this.selected.length) {
            return this.selected[0];
        } else {
            return null;
        }
    }

    get selectedClasses(): ScoreClass[] {
        if (this.selected.length) {
            return this.selected.map((id) => this.scoreClasses[id]);
        } else {
            return [];
        }
    }

    get selectedClass(): ScoreClass | null {
        if (this.selectedClasses.length) {
            return this.selectedClasses[0];
        } else {
            return null;
        }
    }

    get scoreClasses(): { [id: number]: ScoreClass } {
        return scoringModule.scoreClasses;
    }

    get scoreClassArray(): ScoreClass[] {
        return Object.values(scoringModule.scoreClasses);
    }

    get scoreClassList(): ScoreClass[] {
        if (this.searchTerm && this.searchTerm.length) {
            return this.scoreClassArray.filter((item) => {
                return item.name
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase());
            });
        } else {
            return this.scoreClassArray;
        }
    }

    get pageLoading(): boolean {
        return scoringModule.scoreClassesLoading;
    }

    private toolClick(eventId: string): void {
        switch (eventId) {
            case "new":
                this.createNew();
                break;
            case "edit":
                if (this.selectedClass) {
                    this.sideTrigger = `edit-${this.selectedClass.id}`;
                }
                break;
            case "delete":
                this.deleteMode = true;
                this.editorOpen = true;
                break;
        }
    }

    private createNew(): void {
        this.selected = [];
        this.deleteMode = false;
        this.sideTrigger = "new-class";
        // this.editorOpen = true;
    }

    private editClass(item: ScoreClass): void {
        if (item) {
            this.deleteMode = false;
            this.selected = [item.id];
            // this.editorOpen = true;
            this.sideTrigger = `edit-${item.id}`;
        }
    }

    private deleteClass(item: ScoreClass): void {
        if (item) {
            this.deleteMode = true;
            this.selected = [item.id];
            this.editorOpen = true;
        }
    }

    private async deleteSelectedClass(): Promise<void> {
        if (this.selectedClasses.length) {
            this.loading = true;
            await Promise.all(
                this.selectedClasses.map(async (item) => {
                    if (item) {
                        try {
                            await scoringModule.deleteClass({
                                classId: item.id,
                            });
                        } catch (e) {
                            console.log(
                                `%cError:`,
                                "color: red; font-weight: bold;"
                            );
                            console.log(e);
                        }
                    }
                })
            );
            this.editorOpen = false;
            this.deleteMode = false;
            this.loading = false;
        }
        this.deleteMode = true;
    }
}

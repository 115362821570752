
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { Factor } from "@/graphql/API";

@Component({
    components: {},
})
export default class FactorToggle extends Vue {
    @Prop()
    factor!: Factor;

    @Prop({ default: "mdi-checkbox-blank-outline", type: String })
    offIcon!: string;

    @Prop({ default: "mdi-checkbox-marked", type: String })
    onIcon!: string;

    @Prop({ default: false, type: Boolean })
    invertColor!: boolean;

    @Prop({ default: false, type: Boolean })
    active!: boolean;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: false, type: Boolean })
    locked!: boolean;

    @Prop({ default: 1, type: Number })
    depth!: number;

    @Prop({ default: false, type: Boolean })
    showRootBtn!: boolean;

    @Prop({ default: false, type: Boolean })
    rootActive!: boolean;

    @Prop({ default: false, type: Boolean })
    first!: boolean;

    @Prop({ default: false, type: Boolean })
    last!: boolean;

    @Prop({ default: -1, type: Number })
    rootId!: number;

    get disableBtn(): boolean {
        return this.disabled || this.locked;
    }
}

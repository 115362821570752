
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ClassSet, Factor, ScoreClass, ScoreMap, Rule } from "@/graphql/API";
import { FactorsIcons } from "@/IconLibrary";
import Scoring from "@/store/modules/Scoring";
import Factors from "@/store/modules/Factors";

const scoringModule = getModule(Scoring);
const modelModule = getModule(Factors);

@Component({})
export default class FactorScoringOptions extends Vue {
    @Prop()
    factor!: Factor;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: false, type: Boolean })
    isRange!: boolean;

    private selectedRule: number | null = null;
    private weight = 1.0;
    private selectedClassRule: number | null = null;
    private useManualScore = true;
    private useManualIndex = true;
    private selectedClassCluster: number | null = null;

    get scoreClasses(): { [id: number]: ScoreClass } {
        return scoringModule.scoreClasses;
    }

    get scoreClassesList(): ScoreClass[] {
        return scoringModule.scoreClassesList;
    }

    get scoreRulesList(): Rule[] {
        return scoringModule.scoreRuleList.filter((rule) => {
            return rule.is_range == this.isRange;
        });
    }

    get classRulesList(): Rule[] {
        return scoringModule.indexRuleList.filter((rule) => {
            return rule.is_range == this.isRange;
        });
    }

    get classClusterList(): ClassSet[] {
        return scoringModule.classClusterList;
    }

    get showManualToggle(): boolean {
        if (this.factor) {
            return (
                modelModule.autoFactors.includes(this.factor.value_type) ||
                this.factor.value_type == "group" ||
                this.factor.value_type == "table"
            );
        } else {
            return false;
        }
    }

    private changeWeight(val: number) {
        console.log(val);
        this.$emit("weight-change", val);
    }

    private changeRule(val: number) {
        console.log(val);
        this.$emit("rule-change", val);
    }

    private changeClassRule(val: number) {
        console.log(val);
        this.$emit("classrule-change", val);
    }

    private changeManualScore(val: number) {
        console.log(val);
        this.$emit("manual-score", val);
    }

    private changeManualIndex(val: number) {
        console.log(val);
        this.$emit("manual-index", val);
    }

    private changeClassCluster(val: number) {
        console.log(val);
        this.$emit("class-cluster", val);
    }

    @Watch("factor", { deep: true })
    onFactorIdChange(): void {
        this.selectedRule = this.factor.score_rule_id ?? null;
        this.weight = this.factor.weight ?? 0;
        this.selectedClassRule = this.factor.index_rule_id ?? null;
        this.selectedClassCluster = this.factor.set_id ?? null;
        this.useManualScore = this.factor.m_score ? true : false;
        this.useManualIndex = this.factor.m_index ? true : false;
    }
}

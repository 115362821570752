
import { Component, Vue, Prop, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import vuetify from "@/plugins/vuetify";
import Modal from "@/components/ui/Modal.vue";
import DragResizeHandler from "@/components/ui/DragResizeHandler.vue";
import AppBanner from "@/components/ui/AppBanner.vue";
import WorkspaceOptions from "@/store/modules/WorkspaceOptions";
import Logo from "@/components/ui/Logo.vue";

const workspaceOptionsModule = getModule(WorkspaceOptions);

@Component({
    components: {
        Modal,
        DragResizeHandler,
        AppBanner,
        Logo,
    },
})
export default class ViewWrapper extends Vue {
    @Prop({ default: false, type: Boolean })
    bgMain!: boolean;

    @Prop({ default: false, type: Boolean })
    padding!: boolean;

    @Prop({ default: false, type: Boolean })
    openSideBar!: boolean;

    @Prop({ default: false, type: Boolean })
    pageModalOpen!: boolean;

    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    @PropSync("sidebarTrigger")
    triggerIndex!: string;

    @Prop({ default: null, type: String })
    mobileTriggerIndex!: string;

    @Prop({ default: false, type: Boolean })
    loadingOverlay!: boolean;

    @Prop({ default: false, type: Boolean })
    bannerScroll!: boolean;

    @Prop({ default: false, type: Boolean })
    darkBorder!: boolean;

    @Prop()
    header!: string;

    @Prop()
    bannerImage!: string;

    @Prop()
    bannerAlign!: string;

    @Prop({ default: false, type: Boolean })
    logoOverlay!: boolean;

    @Prop({ default: false, type: Boolean })
    onlyContent!: boolean;

    @Prop({ default: "", type: String })
    modalTitle!: string;

    private syncedDown = false;

    private sideBarOpen = false;
    private sideBarOpenMobile = false;

    private throttleTime = 100;
    /*
    private prevPos = 0;
    private currentDistance = 0;
    private scrollThreshold = 50;
    */

    private wrapper: HTMLElement | null = null;
    private wrapperWidth = 0;
    private wrapperHeight = 0;

    private scrollSection: HTMLElement | null = null;
    private scrollTop = 0;
    private scrollLeft = 0;

    get languageRight(): boolean {
        return vuetify.framework.rtl;
    }

    get showbanner(): boolean {
        return workspaceOptionsModule.showAppBanner;
    }

    get hasScrollTop(): boolean {
        return this.$slots.hasOwnProperty("scrollTop");
    }

    get hasFilterBar(): boolean {
        return this.$slots.hasOwnProperty("filterBar");
    }

    get hasSideBar(): boolean {
        return this.$slots.hasOwnProperty("sideBar");
    }

    get hasIntro(): boolean {
        return this.$slots.hasOwnProperty("intro");
    }

    get hasToolBar(): boolean {
        return (
            this.$slots.hasOwnProperty("toolBar") ||
            this.$slots.hasOwnProperty("actions")
        );
    }

    get entirePageOverlay(): boolean {
        return !this.hasToolBar && !this.hasIntro && this.loadingOverlay;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private scrollWatch(): void {
        if (this.scrollSection) {
            this.scrollTop = this.scrollSection.scrollTop;
            this.scrollLeft = this.scrollSection.scrollLeft;
        }
    }

    private resizeBrowser() {
        if (this.wrapper) {
            this.wrapperWidth = this.wrapper.offsetWidth;
            this.wrapperHeight = this.wrapper.offsetHeight;
        }
    }

    mounted(): void {
        this.wrapper = this.$refs["viewWrapper"] as HTMLElement | null;
        this.scrollSection = this.$refs["scroll-section"] as HTMLElement | null;

        this.resizeBrowser();
        window.addEventListener("resize", this.resizeBrowser);
        if (this.scrollSection) {
            this.scrollWatch();
            this.scrollSection.addEventListener("scroll", this.scrollWatch);
        }
    }

    destroyed(): void {
        window.removeEventListener("resize", this.resizeBrowser);
        if (this.scrollSection) {
            this.scrollSection.removeEventListener("scroll", this.scrollWatch);
        }
    }

    @Watch("triggerIndex", { immediate: true, deep: true })
    onTriggerIndexChange(val: string): void {
        if (val == "sidebar-close") {
            this.sideBarOpen = false;
        } else {
            this.sideBarOpen = true;
        }
    }

    @Watch("mobileTriggerIndex", { immediate: true, deep: true })
    onMobileTriggerIndexChange(val: string): void {
        if (val == "sidebar-close") {
            this.sideBarOpenMobile = false;
        } else if (val != "") {
            this.sideBarOpenMobile = true;
        }
    }

    @Watch("sideBarOpen")
    onSidebarOpenChange(val: boolean): void {
        if (!val) {
            this.triggerIndex = "sidebar-close";
        }
    }
}

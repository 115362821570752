
import VpDialog from "@/components/ui/VpDialog.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    name: "DocumentPreview",
    components: {
        VpDialog,
    },
})
export default class DocumentPreview extends Vue {
    @Prop()
    path!: string;

    @Prop({ type: String, default: "File"})
    name!: string;

    @Prop({ type: Boolean, default: false })
    isFactorTable!: boolean;

    @Prop({ type: String, default: "140px" })
    maxWidth!: string;

    @Prop({ type: String, default: "140px" })
    maxHeight!: string;

    private previewOpen = false;
}

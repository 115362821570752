
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
    components: {},
})
export default class DragResizeHandler extends Vue {
    @Prop()
    elementRef!: string;

    @Prop({ default: true, type: Boolean })
    autoAdjust!: boolean;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    @Prop({ default: false, type: Boolean })
    triangle!: boolean;

    @Prop({ default: false, type: Boolean })
    noMinimum!: boolean;

    private dragActive = false;

    mounted(): void {
        if (this.elementRef) {
            this.setEvents();
        }
    }

    private setEvents() {
        this.$el?.addEventListener("mousedown", () => {
            this.dragActive = true;
            document.body.style.cursor = "ns-resize";
            document.addEventListener("mousemove", this.resize, false);
        });
        document.addEventListener("mouseup", () => {
            if (this.dragActive && !this.disabled) {
                this.$emit("drag-resize-complete");
            }
            this.dragActive = false;
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", this.resize, false);
        });
    }

    private resize(e: MouseEvent) {
        if (this.$el.parentElement && !this.disabled) {
            let pos = this.$el.parentElement.getBoundingClientRect();

            if (this.noMinimum ? e.pageY >= pos.top : e.pageY > pos.top) {
                if (this.autoAdjust) {
                    this.$el.parentElement.style.height = `${
                        e.pageY - pos.top
                    }px`;
                }

                this.$emit("drag-resize-change", e.pageY - pos.top);
            } else if (this.noMinimum && e.pageY < pos.top) {
                this.$emit("drag-resize-change", 0);
            }
        }
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Scoring from "@/store/modules/Scoring";
import { Unit, UnitConv } from "@/graphql/API";
import Domains from "@/store/modules/Domains";

const domainsModule = getModule(Domains);

@Component({
    components: {},
})
export default class UnitConvEditor extends Vue {
    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop({ default: -1, type: Number })
    fromUnitId!: number;

    @Prop({
        default: (unitConv: UnitConv) => {
            return unitConv;
        },
        type: Object,
    })
    editUnitConv!: UnitConv;

    @Prop()
    otherValues!: number[];

    private blankUnitConv: UnitConv = {
        from_id: this.fromUnitId,
        multiplier: "1",
        to_id: -1,
    };

    private internalUnitConv: UnitConv = { ...this.blankUnitConv };

    private loading = false;
    private formValid = false;
    private valueRules = [
        (v: number) => !!v || "Value Required",
        (v: number) => {
            if (this.isEdit) {
                if (
                    v != this.editUnitConv.to_id &&
                    this.otherValues.includes(v)
                ) {
                    return "Duplicate Value";
                }
            } else {
                return !this.otherValues.includes(v) || "Duplicate Value";
            }
            return true;
        },
    ];

    get saveActive(): boolean {
        if (this.isEdit) {
            return (
                this.internalUnitConv.from_id != this.editUnitConv.from_id ||
                this.internalUnitConv.multiplier !=
                    this.editUnitConv.multiplier ||
                this.internalUnitConv.to_id != this.editUnitConv.to_id
            );
        }

        return this.internalUnitConv.to_id != -1;
    }

    get unitConvs() {
        console.log(domainsModule.unitConvs);
        return domainsModule.unitConvs;
    }

    get unitsDict(): { [id: number]: Unit } {
        return domainsModule.units;
    }

    get unitsList(): Unit[] {
        return domainsModule.unitsList;
    }

    get filteredUnitsList(): Unit[] {
        return this.unitsList.filter(
            (unit: Unit) => unit.id != this.fromUnitId
        );
    }

    private async saveUnitConv(): Promise<void> {
        this.loading = true;
        if (this.isEdit) {
            if (this.internalUnitConv.to_id != this.editUnitConv.to_id) {
                domainsModule.createUnitConversion({
                    from_id: this.internalUnitConv.from_id,
                    multiplier: this.internalUnitConv.multiplier,
                    to_id: this.editUnitConv.to_id,
                });
                domainsModule.deleteUnitConversion({
                    from_id: this.internalUnitConv.from_id,
                    to_id: this.internalUnitConv.to_id,
                });
            } else {
                domainsModule.updateUnitConversion({
                    from_id: this.internalUnitConv.from_id,
                    multiplier: this.internalUnitConv.multiplier,
                    to_id: this.internalUnitConv.to_id,
                });
            }
        } else {
            this.createUnitConversionsProcess(
                this.internalUnitConv.from_id,
                this.internalUnitConv.multiplier,
                this.internalUnitConv.to_id
            );
        }
        this.internalUnitConv = { ...this.blankUnitConv };
        this.$emit("new-unit-conv");
        this.loading = false;
    }

    private async deleteUnitConv(): Promise<void> {
        await domainsModule.deleteUnitConversion({
            from_id: this.fromUnitId,
            to_id: this.internalUnitConv.to_id,
        });
    }

    private createUnitConversionsProcess(
        from_id: number,
        multiplier: string,
        to_id: number
    ) {
        domainsModule.createUnitConversion({
            from_id: from_id,
            multiplier: multiplier,
            to_id: to_id,
        });
        const reverseMultiplier = `${1 / parseInt(multiplier)}`;
        if (!Object.keys(this.unitConvs).includes(`${to_id}-${from_id}`)) {
            domainsModule.createUnitConversion({
                from_id: to_id,
                multiplier: reverseMultiplier,
                to_id: from_id,
            });
        }
    }

    mounted(): void {
        this.onUnitConvChange();
    }

    @Watch("editUnitConv", { immediate: true, deep: true })
    onUnitConvChange(): void {
        if (this.isEdit) {
            this.internalUnitConv = { ...this.editUnitConv };
        } else {
            this.internalUnitConv = { ...this.blankUnitConv };
        }
    }
}


import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Rule, ClassSet, Factor } from "@/graphql/API";

@Component({
    components: {},
})
export default class AggregateRule extends Vue {
    @Prop()
    factor!: Factor;

    @Prop()
    scoreRule!: Rule;

    @Prop()
    cluster!: ClassSet;

    @Prop({ default: false, type: Boolean })
    dialogLoading!: boolean;

    // Euclidian distance is added to the functions list but needs some FE changes
    private aggregateOptions = [
        {
            text: "Weighted Sum",
            id: "wsum",
        },
        { text: "Minimum", id: "min" },
        { text: "Maximum", id: "max" },
        { text: "Sum", id: "sum" },
        { text: "Normalized Weighted Sum", id: "nwsum" },
        { text: "Weighted Mean", id: "wmean" },
        { text: "Median", id: "median" },
        { text: "Mean", id: "mean" },
        { text: "Euclidian Distance", id: "eucdist" },
        { text: "None", id: "none" },
    ];
    private loading = false;

    private selectedAggregate: null | string = null;

    get scoreRuleId(): number | null {
        if (this.scoreRule) {
            return this.scoreRule.id;
        } else {
            return null;
        }
    }

    private async saveAggregateFunction(val: string): Promise<void> {
        this.$emit("save-aggregate", val);
    }

    mounted(): void {
        this.onScoreRuleChange();
    }

    @Watch("scoreRuleId")
    onScoreRuleChange(): void {
        if (this.scoreRule != null) {
            this.selectedAggregate = this.scoreRule.aggregate_function
                ? this.scoreRule.aggregate_function
                : "none";
        }
    }
}

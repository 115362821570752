
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {},
})
export default class ColourPicker extends Vue {
    @Prop({ default: "#000", type: String })
    defaultColour!: string;

    @Prop({ default: "", type: String })
    btnClass!: string;

    @Prop({ default: false, type: Boolean })
    accordion!: boolean;

    @Prop({ default: false, type: Boolean })
    blankBtn!: boolean;

    @Prop({ default: false, type: Boolean })
    fullWidth!: boolean;

    @Prop({ default: true, type: Boolean })
    showPreview!: boolean;

    @Prop({ default: false, type: Boolean })
    customContent!: boolean;

    @Prop({ default: false, type: Boolean })
    attach!: boolean;

    @Prop({ default: true, type: Boolean })
    hex!: boolean;

    private pickerOpen = false;
    private colour = "";

    mounted(): void {
        this.onDefaultColourChange();
    }

    @Watch("defaultColour")
    private onDefaultColourChange() {
        if (this.defaultColour == "") {
            this.colour = "#000";
        } else {
            this.colour = this.defaultColour;
        }
    }

    private onColourChange() {
        if (this.colour != "#000") {
            this.$emit("change", this.colour);
            this.pickerOpen = false;
        }
    }
}

import {
    Changes,
    ClusterMapping,
    Factor,
    Score,
    Value,
    ViewpointMapping,
} from "@/graphql/API";
import { Slice } from "@/graphql/custom";
import Choices from "@/store/modules/Choices";
import Factors from "@/store/modules/Factors";
import FlashNotifications from "@/store/modules/FlashNotifications";
import Scoring from "@/store/modules/Scoring";
import Viewpoints from "@/store/modules/Viewpoints";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getModule } from "vuex-module-decorators";

export async function createUpdateScoreHelper(payload: {
    viewpoint_id: number;
    choice_id: number;
    factor_id: number;
    m_score?: number;
    m_index?: number;
    order_str?: string;
    row_id?: string;
}): Promise<Score[] | null> {
    const newValue = { ...payload };
    const scoringModule = getModule(Scoring);

    const scores = scoringModule.keyedScores;
    const scoreKey = `${payload.viewpoint_id}-${payload.choice_id}-${
        payload.factor_id
    }${payload.row_id && payload.row_id != "" ? "-" + payload.row_id : ""}`;

    let res = null;
    try {
        // if (scores.hasOwnProperty(scoreKey)) {
        res = await scoringModule.updateScoreL(newValue);
        // } else {
        //     res = await scoringModule.createScoreL(newValue);
        // }
    } catch (e) {
        const err = e as GraphQLResult<unknown>;
        const message = err?.errors ? err.errors[0].message : "Something went wrong";
        console.log("%cError:", "color: red; font-weight: bold;");
        console.log(message);
        getModule(FlashNotifications).error({
            message: message,
            duration: 5000,
        });
        return null;
    }

    return res;
}

export async function scoreFromClusterMapping(payload: {
    clusterMapping: ClusterMapping;
    factor_id: number;
    viewpoint_id: number;
    choice_id: number;
}): Promise<number | null> {
    const clusterMapping = payload.clusterMapping;
    const factor_id = payload.factor_id;
    const viewpoint_id = payload.viewpoint_id;
    const choice_id = payload.choice_id;
    if (clusterMapping.score) {
        await createUpdateScoreHelper({
            viewpoint_id: viewpoint_id,
            choice_id: choice_id,
            factor_id: factor_id,
            m_score: clusterMapping.score,
            m_index: clusterMapping.index,
        });

        return clusterMapping.score;
    }
    return 0;
}

export async function getScore(payload: {
    viewpoint_id: number;
    choice_id: number;
    factor_id: number;
}): Promise<void> {
    if (
        !getModule(Scoring).keyedScores[
            `${payload.viewpoint_id}-${payload.choice_id}-${payload.factor_id}`
        ]
    ) {
        try {
            await getModule(Scoring).fetchScores({
                viewpoint_id: payload.viewpoint_id,
                choice_id: payload.choice_id,
                factor_id: payload.factor_id,
            });
        } catch (e) {
            console.log("%cError:", "color: red; font-weight: bold;");
            console.log(e);
        }
    }
}

export async function getScoresD(payload: {
    d_id: number;
    root?: boolean;
    fp_ids: number[];
    v_ids: number[];
    c_ids: number[];
    v_slice?: Slice;
    c_slice?: Slice;
    f_slice?: Slice;
}): Promise<void> {
    //Hack to remove duplicate factor_ids in payload
    const fp_ids = [... new Set(payload.fp_ids)];
    payload.fp_ids = fp_ids;
    await getModule(Scoring).fetchScoresD(payload);
}

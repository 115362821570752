var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"factors-block",class:{
        'factors-block--right': _vm.languageRight,
        'factors-block--drag': _vm.drag,
        'factors-block--inner': _vm.depth > 0,
    }},[_c('draggable',{attrs:{"list":_vm.currentTreeLevel,"group":"factor-tree","disabled":!_vm.editModel,"ghost-class":"factor-ghost","drag-class":"factor-drag-class","handle":".factor__drag"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":function($event){return _vm.dragChange($event, _vm.parentId)}}},_vm._l((_vm.currentTreeLevel),function(factor){return _c('div',{key:factor.id,staticClass:"factor-wrapper",class:{
                'factor-wrapper--active': _vm.selectedFactors.includes(
                    factor.id
                ),
                'factor-wrapper--group': factor.is_group || factor.is_table,
            }},[(factor.is_group)?_c('div',{staticClass:"factor-expansion-panel"},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""}},[_c('v-expansion-panel',{on:{"change":function($event){return _vm.loadChildren(factor.id)}}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"factor__offset factor__offset--ingroup",class:index == _vm.depth
                                            ? 'factor__offset--lines'
                                            : ''})}),_c('v-btn',{staticClass:"btn-vp-expand",attrs:{"icon":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.languageRight ? "mdi-menu-left" : "mdi-menu-right")+" ")])],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"factor-row"},[_c('v-btn',{staticClass:"factor-group",attrs:{"plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickFactor($event, factor, false)}}},[_c('div',{staticClass:"factor__label",class:[
                                            _vm.selectedFactors.includes(
                                                factor.id
                                            )
                                                ? 'factor-label--active'
                                                : '',
                                            factor.json
                                                ? factor.json
                                                : 'vp-body-1',
                                        ]},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" "+_vm._s(_vm.Icons["group"])+" ")]),_c('div',{staticClass:"factor__name",class:factor.json
                                                    ? _vm.getFactorStyleClass(
                                                          factor
                                                      )
                                                    : 'vp-body-1'},[_vm._v(" "+_vm._s(factor.name)+" ")])],1)]),_c('div',{staticClass:"factor__mobile-toggle sidebar-mobile-show"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickFactor(
                                                $event,
                                                factor,
                                                true
                                            )}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('div',{staticClass:"factor__drag",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-drag")])],1)],1)]),_c('v-expansion-panel-content',[_c('div',{staticClass:"factor__expansion__offset"},_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"factor__offset factor__offset--ingroup"})}),0),(factor.is_group)?_c('RecursiveModelFactors',{attrs:{"selectedFactors":_vm.selectedFactors,"parentId":factor.id,"depth":_vm.depth + 1,"editModel":_vm.editModel,"filters":_vm.filters},on:{"toggle-factor":_vm.sendToggledFactor}}):_vm._e()],1)],1)],1)],1):(factor.is_table)?_c('div',{staticClass:"factor-expansion-panel"},[_c('ModelTableFactor',{attrs:{"selectedFactors":_vm.selectedFactors,"parentId":factor.id,"factor":factor,"depth":_vm.depth,"editModel":_vm.editModel,"filters":_vm.filters},on:{"toggle-factor":_vm.sendToggledFactor}})],1):_c('div',{staticClass:"factor-row"},[_c('v-btn',{staticClass:"factor factor--single",attrs:{"plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickFactor($event, factor, false)}}},[_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"factor__offset factor__offset--ingroup",class:index == _vm.depth ? 'factor__offset--lines' : ''})}),_c('div',{staticClass:"factor__label",class:_vm.selectedFactors.includes(factor.id)
                                ? 'factor-label--active'
                                : ''},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.Icons[factor.value_type]))]),_c('div',{staticClass:"factor__name",class:factor.json
                                    ? _vm.getFactorStyleClass(factor)
                                    : 'vp-body-1'},[_vm._v(" "+_vm._s(factor.name)+" ")])],1)],2),_c('div',{staticClass:"factor__mobile-toggle sidebar-mobile-show"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickFactor($event, factor, true)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('div',{staticClass:"factor__drag"},[_c('v-icon',[_vm._v("mdi-drag")])],1)],1)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
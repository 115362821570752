
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import FlashNotifications from "@/store/modules/FlashNotifications";

const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    components: {},
})
export default class FlashMessage extends Vue {
    get flashMessages(): any {
        return this.$store.state.FlashNotifications.messages;
    }

    private closeAlert(toggle: any, flashMessage: any) {
        flashNotificationsModule.removeMessage(flashMessage);
        toggle();
    }
}

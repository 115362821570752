
import { Vue, Component } from "vue-property-decorator";
import Interface from "@/layouts/Interface.vue";
@Component({
    components: {
        Interface,
    },
})
export default class NotFound extends Vue {

}

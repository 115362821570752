import { getModule } from "vuex-module-decorators";
import FlashNotifications from "@/store/modules/FlashNotifications";

let uuid = 0;

export default class FlashMessage {
		type: string;
		msg: string;
		duration: number;
		uuid: number;
		timer: any;
	constructor(type: string, msg: string, duration: number) {
		this.type = type;
		this.msg = msg;
		this.duration = duration || 3000;
		this.uuid = uuid++;
		this.timer = setTimeout(() => {this.remove();}, this.duration);
	}

	remove() {
		const flashNotificationsModule = getModule(FlashNotifications);
		flashNotificationsModule.removeMessage(this);

	}
}
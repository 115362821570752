import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { App, Tab } from "@/graphql/API";
import Apps from "@/store/modules/Apps";

const appsModule = getModule(Apps);

@Component
export default class AppUrl extends Vue {
    get apps(): { [appId: number]: App } {
        return appsModule.keyedApps;
    }

    get appIdParam(): number | null {
        return this.$route.params.appId
            ? parseInt(this.$route.params.appId)
            : null;
    }

    get tabIdParam(): number | null {
        return this.$route.params.tabId
            ? parseInt(this.$route.params.tabId)
            : null;
    }

    get currApp(): App | null {
        if (this.appIdParam && this.apps[this.appIdParam]) {
            return this.apps[this.appIdParam];
        } else {
            return null;
        }
    }

    get appTabs(): { [tabId: number]: Tab } | null {
        if (this.currApp) {
            return this.currApp.tabs.reduce((acc, a) => {
                return {
                    ...acc,
                    [a.id]: a,
                };
            }, {});
        } else {
            return null;
        }
    }

    get appTabsIndex(): { [id: number]: Tab[] } {
        return appsModule.tabIndex;
    }

    get currTab(): Tab | null {
        if (this.tabIdParam && appsModule.keyedTabs[this.tabIdParam]) {
            return appsModule.keyedTabs[this.tabIdParam];
        } else {
            return null;
        }
    }
}

import { Node, mergeAttributes } from '@tiptap/core';
import Paragraph from '@tiptap/extension-paragraph';
// Tip Tap Paragraph Extension that changes the default tag to div from p
// Based on: https://github.com/ueberdosis/tiptap/tree/main/packages/extension-paragraph
const ParagraphCustom = Paragraph.extend({
	name: 'ParagraphCustom',

	priority: 1000,

	defaultOptions: {
		HTMLAttributes: {},
	},

	group: 'block',

	content: 'inline*',

	parseHTML() {
		return [
			{ tag: 'span' },
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
	},

	addCommands() {
		return {
			setParagraph: () => ({ commands }) => {
				return commands.toggleNode('ParagraphCustom', 'ParagraphCustom')
			},
		}
	},
});

export default ParagraphCustom;

import { Component, Prop, Vue } from "vue-property-decorator";
import { Factor, Enum, EnumValue } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";

const modelModule = getModule(Factors);

@Component({
    components: {},
})
export default class CategoryValueDisplay extends Vue {
    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop({ required: true })
    factor!: Factor;

    @Prop({ default: null, type: Number })
    value!: number;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    @Prop({ default: true, type: Boolean })
    toggleInput!: boolean;

    @Prop({ default: "buttons", type: String })
    format!: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get jsonProps(): any {
        if (this.factor.json) {
            try {
                return JSON.parse(this.factor.json);
            } catch {
                return {};
            }
        }
        return {};
    }

    get factorEnum(): Enum | null {
        if (this.factor && this.factor.enum_id) {
            if (modelModule.enums[this.factor.enum_id]) {
                return modelModule.enums[this.factor.enum_id];
            }
        }
        return null;
    }

    get enumValues(): EnumValue[] {
        if (this.factorEnum) {
            return this.factorEnum.values.filter((item) => {
                return item.deprecated == false;
            });
        }
        return [];
    }

    get categories(): string[] {
        if (this.jsonProps && this.jsonProps.hasOwnProperty("categories")) {
            return this.jsonProps.categories;
        }
        return [];
    }

    private updateValue(value: number | null): void {
        if (value) {
            this.$emit("category-update", value);
        }
    }
}

import { render, staticRenderFns } from "./DialogCardTitle.vue?vue&type=template&id=026a0071&scoped=true&"
import script from "./DialogCardTitle.vue?vue&type=script&lang=ts&"
export * from "./DialogCardTitle.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026a0071",
  null
  
)

export default component.exports
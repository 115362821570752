
import { Component, Vue } from "vue-property-decorator";
import vuetify from "@/plugins/vuetify";

@Component({
    components: {},
})
export default class Settings extends Vue {
    private darkMode = vuetify.framework.theme.dark;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get locales(): any {
        return this.$i18n.availableLocales.map((local) => {
            return { name: this.$t("locales." + local), val: local };
        });
    }

    // private toggleDarkMode(): void {
    //     vuetify.framework.theme.dark = !vuetify.framework.theme.dark;
    // }

    private clearStorage(): void {
        localStorage.removeItem("viewpoint-compare-settings");
    }
}


import { Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import { ScoreClass } from "@/graphql/API";
import Scoring from "@/store/modules/Scoring";
import ColourPalette from "@/components/mixins/ColourPalette";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import ScoreClassCreator from "@/components/scoring/scoreClass/ScoreClassCreator.vue";
import VpDialog from "@/components/ui/VpDialog.vue";

const scoringModule = getModule(Scoring);

@Component({
    components: {
        DialogCardTitle,
        ScoreClassCreator,
        VpDialog,
    },
})
export default class ScoringClassList extends mixins(ColourPalette) {
    @Prop(Array)
    list!: ScoreClass[];

    @Prop({ default: "Score Classes", type: String })
    title!: string;

    private headers = [
        {
            text: "Name",
            align: "start",
            value: "scoreClass.name",
            width: "50%",
        },
        {
            text: "Icons",
            value: "styles.icons",
            width: "50%",
        },
    ];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private internalList: {
        scoreClass: ScoreClass;
        styles: any;
        index?: number;
    }[] = [];
    private selectedClass: ScoreClass | null = null;
    private modalOpen = false;
    private showMenu = false;
    private x = 0;
    private y = 0;
    private editScreen = true;
    private loading = false;
    private searchTerm = "";

    get defaultClasses(): number[] {
        return scoringModule.defaultClasses;
    }

    get classList(): { scoreClass: ScoreClass; styles: any; index?: number }[] {
        if (this.searchTerm != "" && this.searchTerm != null) {
            return this.internalList.filter((item) => {
                if (
                    item.scoreClass.name
                        .toLowerCase()
                        .includes(this.searchTerm.toLowerCase())
                ) {
                    return true;
                } else {
                    if (item.styles.hasOwnProperty("icons")) {
                        return item.styles.icons.filter((icon: any) => {
                                return icon.iconCode.includes(
                                    this.searchTerm.toLowerCase()
                                );
                        }).length > 0;
                    }
                    return false;
                }
            });
        }
        return this.internalList;
    }

    mounted(): void {
        this.onListChange();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private rowDoubleClick(event: any, rowObject: any): any {
        this.editScreen = true;
        this.selectedClass = { ...rowObject.item.scoreClass };
        this.modalOpen = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private rowControlClick(event: any, rowObject: any): any {
        this.selectedClass = { ...rowObject.item.scoreClass };

        this.x = event.clientX;
        this.y = event.clientY;
        this.$nextTick(() => {
            this.showMenu = true;
        });

        event.preventDefault();
    }

    private loadStyles(json: string): any {
        if (json) {
            try {
                let options = JSON.parse(json);
                return options;
            } catch {
                return {};
            }
        }
        return {};
    }

    private async deleteClass(): Promise<void> {
        this.loading = true;
        this.modalOpen = false;
        if (this.selectedClass) {
            await scoringModule.deleteClass({
                classId: this.selectedClass.id,
            });
        }
        this.editScreen = false;
        this.selectedClass = null;
        this.loading = false;
    }

    @Watch("list")
    onListChange(): void {
        this.internalList = this.list.map((scoreClass) => {
            let classStyles = this.loadStyles(scoreClass.json);
            return {
                scoreClass: scoreClass,
                styles: classStyles,
            };
        });
    }
}

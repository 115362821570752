
import { Vue, Prop, PropSync, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor } from "@/graphql/API";
import Factors from "@/store/modules/Factors";

const modelModule = getModule(Factors);

@Component({
    components: {}
})
export default class CompareBreadCrumbs extends Vue {
    @Prop({ default: -1, type: Number })
    rootId!: number;

    @PropSync("selectedRoot")
    syncedSelectedRoot!: number;

    get factors(): Factor[] {
        return this.getParent(this.syncedSelectedRoot).reverse();
    }

    get rootFactor(): Factor | null {
        if (this.rootId != -1) {
            return modelModule.factorMap[this.rootId];
        } else {
            return null;
        }
    }

    private getParent(factorId: number): Factor[] {
        const factor = modelModule.factorMap[factorId];
        if (factor.parent_id == null || factor.parent_id == this.rootId) {
            return [modelModule.factorMap[factorId]];
        } else {
            return [
                modelModule.factorMap[factorId],
                ...this.getParent(factor.parent_id),
            ];
        }
    }
}

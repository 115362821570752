
import { Component, Watch, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ColourPalette from "@/components/mixins/ColourPalette";

@Component({
    components: {},
})
export default class CircleIcon extends mixins(ColourPalette) {
    @Prop({ default: 100, type: Number })
    percent!: number;

    @Prop({ default: null, type: String })
    color!: string;

    get stroke(): string {
        if (this.color) {
            if (this.color.charAt(0) == "#") {
                return this.color;
            } else if (this.palette[this.color]) {
                return this.palette[this.color].hex;
            }
        }
        return "";
    }
}


import { Component, Vue, Model, Watch, Prop } from "vue-property-decorator";

@Component({})
export default class PasswordInput extends Vue {
    @Prop({ type: String, default: "" })
    value!: string;

    @Prop({ type: String, default: "" })
    matchingPassword!: string;

    @Prop({ type: String, default: "" })
    id!: string;

    @Prop({ type: String, default: "Password" })
    label!: string;

    @Prop({ type: Boolean, default: false })
    hideRules!: boolean;

    @Prop({ type: Boolean, default: false })
    outlined!: boolean;

    @Prop({ type: Boolean, default: false })
    dense!: boolean;

    @Prop({ type: Boolean, default: false })
    required!: boolean;

    @Prop()
    autoComplete!: string;

    private showText = false;

    private specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    private inputPassword = "";
    private rules = {
        required: (value: string) => !!value || "Required.",
        min: (v: string) => (v && v.length >= 12) || "Min 12 characters",
        specialChar: (v: string) =>
            v && this.specialChars.test(v)
                ? true
                : "1 Special Character Required",
        uppercase: (v: string) =>
            (v && v.toLowerCase() != v) || "1 Uppercase Character Required",
        lowercase: (v: string) =>
            (v && v.toUpperCase() != v) || "1 Lowercase Character Required",
        matching: (v: string, v2: string) => {
            return v2 === '' || v === v2 ? true : "Passwords Must Match";
        },
    };

    @Watch("value")
    onValueChange(value: string): void {
        this.inputPassword = value;
    }

    @Watch("inputPassword")
    onPasswordChange(): void {
        this.$emit("update-password", this.inputPassword);
    }
}


import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import WorkspaceOptions from "@/store/modules/WorkspaceOptions";
import vuetify from "@/plugins/vuetify";
import { Auth, Storage } from "aws-amplify";

const workspaceOptionsModule = getModule(WorkspaceOptions);

@Component({
    components: {},
})
export default class AppBanner extends Vue {
    @Prop()
    header!: string;

    @Prop()
    bannerImage!: string;

    @Prop()
    align!: string;

    private heroImageLoaded = false;
    private bannerImageSrc = "";
    private userName = "";

    get languageRight(): boolean {
        return vuetify.framework.rtl;
    }

    private hideBanner(): void {
        workspaceOptionsModule.changeAppBannerShow(false);
    }

    private async imageUrl(image: string) {
        const s3ImageUrl = await this.getS3ImageUrl(image);
        return s3ImageUrl ? s3ImageUrl.toString() : image;
    }

    private async getS3ImageUrl(imageName: string) {
        let imageURL = "";
        try {
            Storage.configure({ level: "public" });
            imageURL = await Storage.get(`images/${this.userName}/${imageName}`);
            return imageURL;
        } catch (e) {
            return "";
        }
    }

    private async setBannerImageSrc(name: string) {
        this.bannerImageSrc = await this.imageUrl(name);
    }

    async mounted(): Promise<void> {
        let authUser = await Auth.currentAuthenticatedUser();
        this.userName = authUser.username;
        if (this.bannerImage) {
            await this.setBannerImageSrc(this.bannerImage);
        }
    }
}


import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { Choice, Viewpoint } from "@/graphql/API";
import ChoiceCreator from "@/components/choices/ChoiceCreator.vue";
import ViewpointCreator from "@/components/viewpoints/ViewpointCreator.vue";
import draggable from "vuedraggable";
@Component({
    components: {
        ChoiceCreator,
        ViewpointCreator,
        draggable,
    },
})
export default class ListSelector extends Vue {
    @Prop({ default: () => [], type: Array })
    items!: (Choice | Viewpoint)[];

    @Prop()
    title!: string;

    @Prop()
    type!: string;

    @PropSync("selected")
    syncedSelected!: number[];

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @PropSync("newLock")
    syncedLock!: boolean;

    private selectAll = true;
    private editorOpen = false;
    private editItem: Viewpoint | Choice | null = null;
    private isCopy = false;
    private drag = false;

    private allSelected = false;

    get dragDisabled(): boolean {
        return true;
    }

    get allItemsSelected(): boolean {
        return this.syncedSelected.length == this.items.length;
    }

    private selectAllItems(): void {
        if (!this.allItemsSelected) {
            this.syncedSelected = this.items.map((item) => item.id);
        } else {
            this.syncedSelected = [];
        }
    }

    private selectItem(val: Choice | Viewpoint, isCopy: boolean) {
        this.isCopy = isCopy;
        this.editItem = val;
        this.editorOpen = true;
    }

    private toggleItem(item: Choice | Viewpoint): void {
        if (this.syncedSelected.includes(item.id)) {
            this.syncedSelected.splice(this.syncedSelected.indexOf(item.id), 1);
        } else {
            this.syncedSelected.push(item.id);
        }
    }

    @Watch("allItemsSelected")
    onAllItemsSelected(): void {
        this.$emit("all-items", {
            type: this.type,
            val: this.allItemsSelected,
        });
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import { ScoreClass, ScoreClassOptions, Icon } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Scoring from "@/store/modules/Scoring";
import FlashNotifications from "@/store/modules/FlashNotifications";
import ColourPicker from "@/components/values/ColourPicker.vue";
import ColourPaletteSelector from "@/components/values/ColourPaletteSelector.vue";
import IconPicker from "@/components/values/IconPicker.vue";
import ColourPalette from "@/components/mixins/ColourPalette";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Workspaces from "@/store/modules/Workspaces";

const decisionModule = getModule(Decisions);
const scoringModule = getModule(Scoring);
const flashNotificationsModule = getModule(FlashNotifications);
const workspaceModule = getModule(Workspaces);
@Component({
    components: {
        ColourPicker,
        IconPicker,
        ColourPaletteSelector,
    },
})
export default class ScoringClassCreator extends mixins(ColourPalette) {
    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop({
        default: (scoreClass: ScoreClass) => {
            return scoreClass;
        },
        type: Object,
    })
    editScoreClass!: ScoreClass;

    @Prop({ default: false, type: Boolean })
    narrow!: boolean;

    private advancedEdit = false;

    private blankScoreClass: ScoreClass = {
        id: 0,
        workspace_id: 0,
        name: "",
        description: "",
        json: "",
        mutation: "",
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private scoreClassOptions: ScoreClassOptions = {};

    private scoreClass: ScoreClass = { ...this.blankScoreClass };

    private colour = "#000";
    private presentation = [
        {
            name: "Percentage",
            value: "percent",
        },
        {
            name: "Ratio",
            value: "ratio",
        },
        {
            name: "Floating Point",
            value: "float",
        },
        {
            name: "Integer",
            value: "int",
        },
        {
            name: "Default Index",
            value: "index",
        },
    ];

    private styles = [
        {
            name: "Normal",
            value: "normal",
        },
        {
            name: "Bold",
            value: "bold",
        },
        {
            name: "Italic",
            value: "italic",
        },
    ];
    private sizes = [
        {
            name: "Small",
            value: "small",
        },
        {
            name: "Medium",
            value: "medium",
        },
        {
            name: "Large",
            value: "large",
        },
    ];

    get isDefaultClass(): boolean {
        return (
            this.editScoreClass &&
            scoringModule.defaultClasses.includes(this.editScoreClass.id)
        );
    }

    get editorDisabled(): boolean {
        return false;
    }

    get editDescription(): string {
        if (this.editScoreClass && this.editScoreClass.description) {
            return this.editScoreClass.description;
        } else {
            return "";
        }
    }

    get saveActive(): boolean {
        if (this.scoreClass) {
            if (this.isEdit && this.editScoreClass) {
                return (
                    this.scoreClass.name != this.editScoreClass.name ||
                    this.scoreClassOptionsJson != this.editScoreClass.json || 
                    this.descriptionChanged
                );
            } else {
                return (
                    this.scoreClass &&
                    this.scoreClass.name != null &&
                    this.scoreClass.name != ""
                );
            }
        }
        return false;
    }

    get descriptionChanged(): boolean {
        if (
            this.scoreClass.description == null &&
            this.editDescription.length == 0
        ) {
            return false;
        } else {
            return this.scoreClass.description != this.editDescription;
        }
    }

    get selectedDecisionId(): number | null {
        return decisionModule.selectedDecisionId;
    }

    get selectedWorkspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get scoreClassOptionsJson(): string {
        return JSON.stringify(this.scoreClassOptions);
    }

    private addIcon(icon: string, colour: string) {
        const newIcon: Icon = {
            iconCode: icon,
            iconColour: colour,
        };
        if (
            this.scoreClassOptions.hasOwnProperty("icons") &&
            Array.isArray(this.scoreClassOptions.icons)
        ) {
            Vue.set(this.scoreClassOptions, "icons", [
                ...this.scoreClassOptions.icons,
                newIcon,
            ]);
        } else {
            Vue.set(this.scoreClassOptions, "icons", [newIcon]);
        }
    }

    private removeIcon() {
        if (this.scoreClassOptions.icons) {
            Vue.set(this.scoreClassOptions, "icons", [
                ...this.scoreClassOptions.icons.slice(0, -1),
            ]);
        }
    }

    private updateColour(colour: string, prop: string) {
        if (prop) {
            Vue.set(this.scoreClassOptions, prop, colour);
        }
    }

    private async saveScoreClass() {
        if (this.scoreClass.id)
            try {
                await scoringModule.updateClass({
                    classId: this.scoreClass.id,
                    name: this.scoreClass.name,
                    description: this.scoreClass.description
                        ? this.scoreClass.description
                        : "",
                    json: this.scoreClassOptionsJson,
                });
                flashNotificationsModule.success({
                    message: `${this.scoreClass.name} was successfully saved`,
                    duration: 5000,
                });
            } catch (e) {
                const err = e as GraphQLResult<any>;
                const message = err?.errors
                    ? err.errors[0].message
                    : "Something went wrong.";
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
                flashNotificationsModule.error({
                    message: message,
                    duration: 3000,
                });
            }
    }

    private async deleteClass() {
        if (this.scoreClass.id) {
            this.$emit("delete-score-class");
            await scoringModule.deleteClass({
                classId: this.scoreClass.id,
            });
        }
    }

    private async createClass() {
        if (this.selectedWorkspaceId) {
            let newScoreClass = await scoringModule.createClass({
                workspaceId: this.selectedWorkspaceId,
                name: this.scoreClass.name,
                description: this.scoreClass.description
                    ? this.scoreClass.description
                    : "",
                json: this.scoreClassOptionsJson,
            });
            if (newScoreClass) {
                flashNotificationsModule.success({
                    message: `${newScoreClass.name} was successfully created`,
                    duration: 5000,
                });
            }
            if (newScoreClass && this.isDefaultClass) {
                this.$emit("new-default", newScoreClass);
            } else if (newScoreClass) {
                this.$emit("new-score-class", newScoreClass.id);
            }
        }
    }

    @Watch("editScoreClass", { immediate: true, deep: true })
    onEditScoreClassChange(): void {
        if (this.isEdit) {
            this.scoreClass = { ...this.editScoreClass };
            if (this.editScoreClass.json) {
                this.scoreClassOptions = JSON.parse(this.editScoreClass.json);
            } else {
                this.scoreClassOptions = {};
            }
        } else {
            this.scoreClass = { ...this.blankScoreClass };
            this.scoreClassOptions = {};
        }
    }
}

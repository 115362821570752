
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { getS3FileUrl } from "@/helpers/MediaUploadHelper";
import Logo from "@/components/ui/Logo.vue";
import Workspaces from "@/store/modules/Workspaces";
import Decisions from "@/store/modules/Decisions";
import { Urls } from "@/Urls";

const workspacesModule = getModule(Workspaces);
const decisionsModule = getModule(Decisions);

@Component({
    name: "HeaderLogo",
    components: {
        Logo,
    },
})
export default class HeaderLogo extends Vue {
    private url: string | null = null;
    private loading = false;
    private logoLoaded = false;
    private readonly URLS = Urls;

    get customLogoName(): string | null {
        return workspacesModule.workspaceLogo;
    }

    get customLogo(): boolean {
        if (this.customLogoName != null) {
            return true;
        } else {
            return false;
        }
    }

    get frameworkRedirect(): boolean {
        if (
            workspacesModule.workspaceStyles &&
            workspacesModule.workspaceStyles.logoHome
        ) {
            return true;
        } else {
            return false;
        }
    }

    private async goHome(logo: boolean): Promise<void> {
        if (this.frameworkRedirect && logo) {
            console.log("Clicked Framework Redirect")
            this.$emit("clear-framework");
        } else {
            console.log("Clicked Workspace Redirect")
            this.$emit("clear-workspace");
        }
    }

    mounted(): void {
        this.onLogoNameChange(this.customLogoName);
    }

    @Watch("customLogoName")
    async onLogoNameChange(val: string | null): Promise<void> {
        if (val != null) {
            this.logoLoaded = false;
            this.loading = true;
            this.url = await getS3FileUrl({
                fileName: val,
                type: "image",
            });
            this.loading = false;
        } else {
            this.url = null;
        }
    }
}

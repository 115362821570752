
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { User } from "@/graphql/API";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import UsersModule from "@/store/modules/Users";
import FlashNotifications from "@/store/modules/FlashNotifications";

const usersModule = getModule(UsersModule);
const flashModule = getModule(FlashNotifications);

@Component({
    components: {
        ViewWrapper,
    },
})
export default class AccountView extends Vue {
    private loading = false;

    private inputFirst = "";
    private inputLast = "";
    private oldPassword = "";
    private newPassword = "";
    private confirmPassword = "";
    private specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    private isPasswordFormValid = false;
    private rules = {
        required: (value: string) => !!value || "Required.",
        min: (v: string) => v && v.length >= 12 || "Min 12 characters",
        specialChar: (v: string) =>
            v && this.specialChars.test(v) ? true : "1 Special Character Required",
        uppercase: (v: string) =>
            v && v.toLowerCase() != v || "1 Uppercase Character Required",
        lowercase: (v: string) =>
            v && v.toUpperCase() != v || "1 Lowercase Character Required",
        matching: (v: string, v2: string) => {
            return v === v2 ? true : "New Passwords Must Match";
        },
    };

    mounted(): void {
        this.setParams();
    }

    get currentUser(): User | null {
        return usersModule.currentUser;
    }

    private async saveChanges(): Promise<void> {
        this.loading = true;
        await usersModule.updateUser({
            inputFirst: this.inputFirst,
            inputLast: this.inputLast,
        });
        flashModule.success({
            message: `User Information Updated`,
            duration: 3000,
        });
        this.setParams();
        this.loading = false;
    }

    private async updatePassword(): Promise<void> {
        this.loading = true;
        try {
            await usersModule.updateUserPassword({
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
            });
            this.clearPasswords();
            flashModule.success({
                message: `Password Updated`,
                duration: 3000,
            });
        } catch (e: any) {
            flashModule.error({
                message: e.message,
                duration: 3000,
            });
            this.loading = false;
        }
        this.loading = false;
    }

    private clearPasswords(): void {
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmPassword = "";
        if (this.$refs.form) (this.$refs.form as HTMLFormElement).reset();
    }

    private setParams(): void {
        if (this.currentUser != null) {
            this.inputFirst = this.currentUser.first
                ? this.currentUser.first
                : "";
            this.inputLast = this.currentUser.last ? this.currentUser.last : "";
        }
    }
}


import { Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import AppUrl from "@/components/mixins/AppUrl";
import Ui from "@/store/modules/Ui";
import Apps from "@/store/modules/Apps";
import TabEditor from "@/components/apps/TabEditor.vue";

const uiModule = getModule(Ui);
const appsModule = getModule(Apps);

@Component({
    components: {
        TabEditor,
    },
})
export default class TabEditorView extends mixins(AppUrl) {
    get tabTitle(): string | null {
        if (this.currTab) {
            return this.currTab.title;
        } else {
            return null;
        }
    }

    get selectedAppId(): number | null {
        return appsModule.selectedAppId;
    }

    mounted(): void {
        this.onTabTitleChange(this.tabTitle);
        this.onAppIdParamChange();
    }

    @Watch("tabTitle")
    onTabTitleChange(val: string | null): void {
        uiModule.changeBreadCrumb(val);
    }

    @Watch("appIdParam")
    onAppIdParamChange(): void {
        if (!this.selectedAppId && this.appIdParam) {
            appsModule.selectApp(this.appIdParam);
        }
    }
}


import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Choice,
    Viewpoint,
    ScoreOptions,
    Value,
    ScoreClassSettings,
    FactorOptions,
    ViewpointMapping,
} from "@/graphql/API";
import { createUpdateChoiceValue } from "@/helpers/ChoiceHelper";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import CompareCell from "@/components/compare/CompareCell.vue";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import CompareRow from "@/components/compare/ui/CompareRow.vue";
import CompareColumnMain from "@/components/compare/ui/CompareColumnMain.vue";
import CompareColumn from "@/components/compare/ui/CompareColumn.vue";
import FactorScore from "@/components/scoring/FactorScore.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getScore } from "@/helpers/ScoreHelper";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mudder = require("mudder");

const choiceModule = getModule(Choices);
const viewpointsModule = getModule(Viewpoints);

@Component({
    components: {
        CompareCell,
        FactorValueDisplay,
        VpDialog,
        CompareRow,
        CompareColumnMain,
        CompareColumn,
        FactorScore,
    },
})
export default class CompareTableChoices extends Vue {
    @Prop()
    tableId!: number;

    @Prop()
    headers!: Factor[];

    @Prop()
    hiddenFactors!: number[];

    @Prop({ default: () => [], type: Array })
    choices!: Choice[];

    @Prop()
    viewpoint!: Viewpoint;

    @Prop()
    dataItem!: Viewpoint;

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoresEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop({ default: () => [], type: Array })
    lines!: number[];

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private loading = false;
    private deleteOpen = false;
    private deleteChoice: null | Choice = null;
    private deleteIndex: null | number = null;
    private deleteRowId: null | string = null;

    get viewpointId(): number | null {
        return this.dataItem ? this.dataItem.id : null;
    }

    private firstFactorId(): number | null {
        if (this.headers && this.headers.length) {
            return this.headers[0].id;
        } else {
            return null;
        }
    }

    get viewpointMapping(): {
        [viewpointId: number]: { [factorId: number]: ViewpointMapping };
    } {
        return viewpointsModule.viewpointMappings;
    }

    get valueMap(): { [choice_id: number]: { [id: string]: Value } } {
        return choiceModule.choiceValues;
    }

    get valueKeys(): { [chioce_id: number]: { id: number; keys: string[][] } } {
        if (this.choices && this.headers) {
            return this.choices.reduce((acc, choice) => {
                return {
                    ...acc,
                    [choice.id]: {
                        id: choice.id,
                        keys: this.headers.map((factor) => {
                            if (this.valueMap[choice.id]) {
                                return Object.keys(this.valueMap[choice.id]).filter(
                                    (item) => item.startsWith(`${factor.id}-`)
                                );
                            } else {
                                return [];
                            }
                        }),
                    },
                };
            }, {});
        } else {
            return {};
        }
    }

    get columnData(): {
        [id: number]: {
            id: number;
            factor: Factor;
            values: { index: number; length: number };
            data: Value[];
        };
    } {
        if (this.columnLengths && this.valueKeys) {
            return this.columnLengths.reduce((acc, item) => {
                return {
                    ...acc,
                    [item.id]: {
                        ...item,
                        factor: this.headers[item.values.index],
                        data:
                            this.valueKeys[item.id] &&
                            this.valueKeys[item.id].keys &&
                            this.valueKeys[item.id].keys[item.values.index]
                                ? this.valueKeys[item.id].keys[
                                      item.values.index
                                  ]
                                      .map((key: string) => {
                                          return this.valueMap[item.id][key];
                                      })
                                      .sort((a, b) => {
                                          return a.row_id && b.row_id
                                              ? a.row_id.localeCompare(b.row_id)
                                              : 1;
                                      })
                                : [],
                    },
                };
            }, {});
        } else {
            return {};
        }
    }

    get nextRowId(): { [id: number]: { id: number; nextId: string } } {
        return Object.values(this.columnData)
            .map((item) => {
                return {
                    id: item.id,
                    nextId: this.getNextRowId(
                        item.data.length
                            ? item.data[item.data.length - 1].row_id
                            : undefined
                    ),
                };
            })
            .reduce((acc, item) => {
                return {
                    ...acc,
                    [item.id]: item,
                };
            }, {});
    }

    get columnLengths(): {
        id: number;
        values: { index: number; length: number };
    }[] {
        if (this.valueKeys) {
            return Object.values(this.valueKeys).map((choice) => {
                return {
                    id: choice.id,
                    values: this.maxValue(
                        choice.keys.map((column) => column.length)
                    ),
                };
            });
        } else {
            return [];
        }
    }

    get tableLength(): number {
        return this.columnLengths.reduce((acc, item) => {
            if (item.values.length > acc) {
                return item.values.length;
            } else {
                return acc;
            }
        }, 0);
    }

    get longestChoice(): {
        id: number;
        values: { index: number; length: number };
    } {
        return this.columnLengths.reduce((acc, item) => {
            if (item.values.length > acc.values.length) {
                return item;
            } else {
                return acc;
            }
        }, this.columnLengths[0]);
    }

    get mainColumn(): Value[] {
        if (
            this.valueKeys &&
            this.valueKeys[this.longestChoice.id].keys &&
            this.valueKeys[this.longestChoice.id].keys[
                this.longestChoice.values.index
            ]
        ) {
            return this.valueKeys[this.longestChoice.id].keys[
                this.longestChoice.values.index
            ].map((key) => {
                return this.valueMap[this.longestChoice.id][key];
            });
        } else {
            return [];
        }
    }

    private maxValue(list: number[]) {
        return {
            index: list.indexOf(Math.max(...list)),
            length: Math.max(...list),
        };
    }

    private getNextRowId(last: string | undefined) {
        if (last) {
            return mudder.base62.mudder(last, "", 1, undefined, 20)[0];
        } else {
            return mudder.base62.mudder("0", undefined, 1, undefined, 20)[0];
        }
    }

    private async addRow(choiceId: number) {
        this.loading = true;
        try {
            await createUpdateChoiceValue({
                choice_id: choiceId,
                factor_id: this.columnData[choiceId].factor.id,
                row_id: this.nextRowId[choiceId].nextId,
                value: "",
            });
        } catch (e) {
            const err = e as GraphQLResult<any>;
            const message = err?.errors
                ? err.errors[0].message
                : "Something went wrong.";
            console.log("%cError:", "color: red; font-weight: bold;");
            console.log(e);
            getModule(FlashNotifications).error({
                message: message,
                duration: 3000,
            });
        }
        this.loading = false;
    }

    private deleteDialog(choice: Choice, row: number, rowId: string): void {
        this.deleteChoice = choice;
        this.deleteIndex = row;
        this.deleteRowId = rowId;
        this.deleteOpen = true;
    }

    private async deleteRow(choice: Choice, rowId: string): Promise<void> {
        this.loading = true;

        await Promise.all(
            this.headers.map(async (factor) => {
                if (this.valueMap[choice.id][`${factor.id}-${rowId}`]) {
                    await choiceModule.deleteValueL({
                        choice_id: choice.id,
                        factor_id: factor.id,
                        row_id: rowId,
                    });
                }
            })
        );
        this.deleteChoice = null;
        this.deleteIndex = null;
        this.deleteRowId = null;
        this.deleteOpen = false;

        this.loading = false;
    }

    private async loadChoiceValues(choices: Choice[]): Promise<void> {
        if (choices.length) {
            await Promise.all(
                choices.map(async (choice) => {
                    if (this.tableId) {
                        await choiceModule.getChoiceValues({
                            choice_id: choice.id,
                            parent_id: this.tableId,
                        });
                    }
                })
            );
        }
    }

    private async loadChoiceScores(choices: Choice[]): Promise<void> {
        if (choices.length) {
            await Promise.all(
                choices.map(async (choice) => {
                    if (
                        this.firstFactorId != null &&
                        this.dataItem &&
                        this.tableId
                    ) {
                        await getScore({
                            viewpoint_id: this.dataItem.id,
                            choice_id: choice.id,
                            factor_id: this.tableId,
                        });
                    }
                })
            );
        }
    }

    mounted(): void {
        this.onViewpointChange();
    }

    @Watch("choices", { immediate: true, deep: true })
    onChoicesChange(newVal: Choice[], oldVal: Choice[] = []): void {
        if (newVal && newVal.length) {
            this.loadChoiceValues(newVal.filter((x) => !oldVal.includes(x)));
            this.loadChoiceScores(newVal.filter((x) => !oldVal.includes(x)));
        }
    }

    @Watch("tableId")
    onTableIdChange(): void {
        this.loadChoiceValues(this.choices);
        this.loadChoiceScores(this.choices);
    }

    @Watch("viewpointId")
    onViewpointChange(): void {
        if (this.viewpointId != null && this.tableId) {
            this.loadChoiceScores(this.choices);
            viewpointsModule.fetchViewpointMappings({
                viewpoint_id: this.viewpointId,
                parent_id: this.tableId,
            });
        }
    }

    /*
    private async loadScores(viewpoints: Viewpoint[]): Promise<void> {
        if (viewpoints.length && this.headers.length) {
            await Promise.all(
                viewpoints.map(async (vp) => {
                    // await getScore(
                    //     viewpoint_id: this.viewpoint_id,
                    //     choice_id: 
                    // );
                })
            );
        }
    }

    @Watch("viewpoints", { immediate: true, deep: true })
    async onViewpointsChange(
        newVal: Viewpoint[],
        oldVal: Viewpoint[] = []
    ): Promise<void> {
        if (newVal && newVal.length) {
            this.loadScores(newVal.filter((x) => !oldVal.includes(x)))
        }
    }
    */
}

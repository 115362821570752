
import { Component, Vue, Prop } from "vue-property-decorator";
import vuetify from "@/plugins/vuetify";

@Component({
    components: {},
})
export default class Logo extends Vue {
    @Prop({ default: false, type: Boolean })
    icon!: boolean;

    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    @Prop()
    color!: string;

    get fillColor(): string {
        if (this.color) {
            return this.color;
        } else {
            return "#1E1919";
        }
    }

    get viewBox(): string {
        if (this.icon) {
            return "0 0 31 25";
        } else {
            return "0 0 153 25";
        }
    }
}


import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { App, Tab } from "@/graphql/API";
import Header from "@/components/ui/Header.vue";
import SideNav from "@/components/SideNav.vue";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import Modal from "@/components/ui/Modal.vue";
import Settings from "@/components/ui/Settings.vue";
import vuetify from "@/plugins/vuetify";
import HeaderActions from "@/components/ui/HeaderActions.vue";
import DragResizeHandler from "@/components/ui/DragResizeHandler.vue";
import HeaderTabs from "@/components/ui/HeaderTabs.vue";
import FlashMessage from "@/components/ui/FlashMessage.vue";
import AppNav from "@/components/ui/AppNav.vue";
import Logo from "@/components/ui/Logo.vue";
import BreadCrumbs from "@/components/ui/BreadCrumbs.vue";
import CustomLogo from "@/components/ui/CustomLogo.vue";
import HeaderLogo from "@/components/ui/HeaderLogo.vue";
import { mixins } from "vue-class-component";
import UiBase from "@/components/mixins/UiBase";

@Component({
    components: {
        Header,
        SideNav,
        Modal,
        ViewWrapper,
        Settings,
        HeaderActions,
        DragResizeHandler,
        HeaderTabs,
        FlashMessage,
        AppNav,
        Logo,
        BreadCrumbs,
        CustomLogo,
        HeaderLogo,
    },
})
export default class AppInterface extends mixins(UiBase) {
    @Prop({ default: false, type: Boolean })
    decisionSelected!: boolean;

    @Prop()
    app!: App;

    @Prop()
    tab!: Tab;

    @Prop()
    header!: string;

    @PropSync("openLeft")
    panelLeftOpen!: boolean;

    @PropSync("mobileOpen")
    mobileMenu!: boolean;

    private modalOpen = false;
    private currentModal = "";

    private darkMode = false;

    private leftPanelWidth = 0;
    private windowWidth = 0;

    private headerBreakPoint = 800;

    private leftPanelElement: HTMLElement | null = null;

    get languageRight(): boolean {
        return vuetify.framework.rtl;
    }

    get viewWidth(): number {
        if (this.windowWidth > this.headerBreakPoint) {
            return this.windowWidth - this.leftPanelWidth;
        } else {
            return this.windowWidth;
        }
    }

    get viewClass(): string {
        if (this.viewWidth != 0) {
            if (this.viewWidth <= 600) {
                return "main-slot-wrapper--xs";
            } else if (this.viewWidth > 600 && this.viewWidth <= 960) {
                return "main-slot-wrapper--xs main-slot-wrapper--sm";
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    mounted(): void {
        this.onResize();
        this.leftPanelElement = this.$refs["left-panel"] as HTMLElement | null;
        if (this.leftPanelElement) {
            this.leftPanelWidth = this.leftPanelElement.offsetWidth;
        }
    }

    created(): void {
        window.addEventListener("resize", this.onResize);
    }

    destroyed(): void {
        window.removeEventListener("resize", this.onResize);
    }

    private onResize(): void {
        this.windowWidth = window.innerWidth;
        if (
            this.windowWidth > this.headerBreakPoint &&
            this.leftPanelWidth > this.windowWidth * 0.4
        ) {
            this.leftPanelWidth = this.windowWidth * 0.4;
            if (this.leftPanelElement) {
                this.leftPanelElement.style.width = `${this.leftPanelWidth}px`;
            }
        }
    }

    private toggleDarkMode() {
        vuetify.framework.theme.dark = !vuetify.framework.theme.dark;
        this.darkMode = !this.darkMode;
    }

    private toggleModal(screen: string) {
        if (this.currentModal == screen && this.modalOpen) {
            this.modalOpen = false;
        } else {
            this.modalOpen = true;
        }

        this.currentModal = screen;
    }

    private toggleLeftPanel(open: boolean) {
        this.panelLeftOpen = open;
        if (this.leftPanelElement) {
            this.leftPanelWidth = open ? this.leftPanelElement.offsetWidth : 0;
        }
    }

    @Watch("$route", { immediate: true, deep: true })
    onRouteChange(): void {
        this.modalOpen = false;
    }
}


import { Vue, Component, Prop } from "vue-property-decorator";
import Logo from "@/components/ui/Logo.vue";
import { User } from "@/graphql/API";
import { Urls } from "@/Urls";
import { Auth } from "aws-amplify";
import { getModule } from "vuex-module-decorators";
import UsersModule from "@/store/modules/Users";

@Component({
    components: {},
})
export default class InviteForm extends Vue {
    @Prop()
    user!: User;

    @Prop()
    workspaceId!: number;

    @Prop()
    code!: string;

    private readonly URLS = Urls;
    private loading = false;

    private async logout(): Promise<void> {
        this.$emit("loading-start");
        this.loading = true;
        await Auth.signOut();
        this.loading = false;
        this.$emit("loading-end");
    }

    private async openWorkspace(): Promise<void> {
        if (this.workspaceId) {
            if (this.code) {
                await getModule(UsersModule).redeemPermission(this.code);
            }
            this.$router.push({
                path: this.URLS.HOME,
                query: {
                    w: `${this.workspaceId}`,
                },
            });
        }
    }
}

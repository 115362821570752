
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Task, Factor } from "@/graphql/API";
import { OpenAiResult } from "@/graphql/custom";
import Factors from "@/store/modules/Factors";
import FlashNotifications from "@/store/modules/FlashNotifications";

const modelModule = getModule(Factors);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    components: {},
})
export default class AiPrompt extends Vue {
    @PropSync("topic")
    syncedTopic!: string;

    @PropSync("persona")
    syncedPersona!: string;

    @PropSync("creativity")
    syncedCreativity!: number;

    @PropSync("aiLoading")
    loading!: boolean;

    @Prop({ default: false, type: Boolean })
    testing!: boolean;

    @Prop()
    parent!: Factor;

    private testFactors = [
        {
            factor_id: 0,
            task_id: "3b21896a-2f60-4078-90e6-a50a47d1e0af",
            name: "Location",
            description:
                "The location of a home is a critical factor to consider when buying a property. As a young family, you may want to look for a location with good schools, amenities, and easy access to public transportation.",
            type: "Text",
        },
        {
            factor_id: 1,
            task_id: "3b21896a-2f60-4078-90e6-a50a47d1e0af",
            name: "Size of the Property",
            description:
                "The size of a property is essential to consider when buying a home. As a young family, you may need at least three bedrooms, two bathrooms, and a spacious living area.",
            type: "Number",
        },
        {
            factor_id: 2,
            task_id: "3b21896a-2f60-4078-90e6-a50a47d1e0af",
            name: "School District",
            description:
                "Living in a good school district is essential for young families. Look for homes located in districts with good schools and excellent educational programs.",
            type: "Categorical",
        },
        {
            factor_id: 3,
            task_id: "3b21896a-2f60-4078-90e6-a50a47d1e0af",
            name: "Home Security",
            description:
                "Home security is an important factor to consider, especially if you are raising young children. Look for properties with security features such as a gated community, security cameras, or alarm systems.",
            type: "Categorical",
        },
        {
            factor_id: 4,
            task_id: "3b21896a-2f60-4078-90e6-a50a47d1e0af",
            name: "Child-Friendly Amenities",
            description:
                "Look for properties located near parks, playgrounds, swimming pools, and other amenities suitable for children.",
            type: "Categorical",
        },
        {
            factor_id: 5,
            task_id: "3b21896a-2f60-4078-90e6-a50a47d1e0af",
            name: "Budget",
            description:
                "Consider your monthly mortgage payments, property taxes, and any other expenses associated with homeownership.",
            type: "Number",
        },
        {
            factor_id: 6,
            task_id: "3b21896a-2f60-4078-90e6-a50a47d1e0af",
            name: "Home Condition",
            description:
                "The condition of the property is important to consider when buying a home. Look for homes that are move-in ready or that require minimal repairs.",
            type: "Categorical",
        },
        {
            factor_id: 7,
            task_id: "3b21896a-2f60-4078-90e6-a50a47d1e0af",
            name: "Resale Value",
            description:
                "When buying a home, consider its resale value. Look for properties in neighborhoods with high demand, growing populations, and positive economic indicators.",
            type: "Number",
        },
        {
            factor_id: 8,
            task_id: "3b21896a-2f60-4078-90e6-a50a47d1e0af",
            name: "Home Safety",
            description:
                "The safety of the home is an important factor to consider, especially if you have young children. Look for homes with features such as locks on windows, pool safety fences, and smoke alarms.",
            type: "Categorical",
        },
    ];

    get generateBtnEnabled(): boolean {
        if (this.syncedTopic && this.syncedTopic.length > 0) {
            if (this.syncedPersona && this.syncedPersona.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    private decrement() {
        this.syncedCreativity = this.syncedCreativity - 0.01;
    }
    private increment() {
        this.syncedCreativity = this.syncedCreativity + 0.01;
    }

    private async generateFactors(): Promise<void> {
        if (this.syncedTopic && this.syncedTopic.length > 0) {
            this.loading = true;

            if (this.testing) {
                this.$emit("generated", {
                    factors: this.testFactors,
                    parent: this.parent ? this.parent.id : -1,
                });
            } else {
                try {
                    const generatedFactorsTask =
                        await modelModule.getGeneratedFactors({
                            topic_area: this.syncedTopic,
                            persona: this.syncedPersona,
                            creativity: this.syncedCreativity,
                        });
                    let results = await this.getGeneratedFactors(
                        generatedFactorsTask,
                        0
                    );
                    if (results && results.length) {
                        this.$emit("generated", {
                            factors: results,
                            parent: this.parent ? this.parent.id : -1,
                        });
                    } else {
                        this.$emit("generated", {
                            factors: [],
                            parent: this.parent ? this.parent.id : -1,
                        });
                    }
                } catch (error) {
                    flashNotificationsModule.error({
                        message: `Unexpected error: ${error}`,
                        duration: 5000,
                    });
                }
            }
            /*
            */
            this.loading = false;
        }
    }

    private async getGeneratedFactors(
        task: Task | null,
        requests: number
    ): Promise<OpenAiResult[] | null | undefined> {
        if (task) {
            try {
                let results = await modelModule.getGeneratedFactorsResults({
                    task_id: task.id,
                });
                if (results) {
                    let taskStatus = results.task.status;
                    if (taskStatus == "SUCCESS") {
                        return results.result;
                    } else if (taskStatus == "PENDING" && requests <= 6) {
                        /*
                            If pending then returns a promise of a timeout that waits
                            and calls the function again with requests + 1

                            Promise required an undefined type
                        */
                        return await new Promise<
                            OpenAiResult[] | null | undefined
                        >((resolve) => {
                            setTimeout(() => {
                                this.getGeneratedFactors(
                                    task,
                                    requests + 1
                                ).then(
                                    (
                                        values:
                                            | OpenAiResult[]
                                            | null
                                            | undefined
                                    ) => {
                                        resolve(values ? values : null);
                                    }
                                );
                            }, 15000);
                        }).then((values: OpenAiResult[] | null | undefined) => {
                            return values;
                        });
                    } else if (taskStatus == "FAILURE") {
                        flashNotificationsModule.error({
                            message: `Unexpected error, task has status FAILURE`,
                            duration: 5000,
                        });
                        return null;
                    } else {
                        flashNotificationsModule.warning({
                            message: `It looks like the process is taking too long. Try one more time.`,
                            duration: 10000,
                        });
                        return null;
                    }
                }
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error: ${error}`,
                    duration: 10000,
                });
                return null;
            }
        }
    }
}
